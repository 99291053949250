import {Injectable} from '@angular/core';
import {IContextualActionBarItem} from '../../action-bars/contextual-action-bar/model/IContextualActionBarItem';
import {ActionContext} from './ActionContext';
import {Observable} from 'rxjs/Observable';
import {TargetsDescription} from './TargetsDescription';
import { Subject } from 'rxjs/Subject';

/**
 * This is a base service that ALWAYS has to be overridden in order to work with actions.
 * Otherwise, this service will throw an exception as not implemented.
 */
@Injectable()
export class ActionsListService<T = object> {

    public get actionsList(): Observable<IContextualActionBarItem<ActionContext<T>>[]> {
        throw new Error('Please make sure to override the ActionsListService in your module that uses <soti-action-bar-panel>');
    }

    public get targetsDescriptionData(): Observable<TargetsDescription> {
        throw new Error('Please make sure to override the ActionsListService in your module that uses <soti-action-bar-panel>');
    }

    public get unselectedItem(): Subject<string> {
        throw new Error('Please make sure to override the ActionsListService in your module that uses <soti-action-bar-panel>');
    }

    public deselected: Subject<string> = new Subject<string>();

    constructor() {
    }


    public init(): void {
        throw new Error('Please make sure to override the ActionsListService in your module that uses <soti-action-bar-panel>');
    }

    public onTargetsDescriptionClick(event: MouseEvent, targetsDescription: TargetsDescription): void {
        throw new Error('Please make sure to override the ActionsListService in your module that uses <soti-action-bar-panel>');
    }

    public executeAction(actionContext: ActionContext<T>): void {
        throw new Error('Please make sure to override the ActionsListService in your module that uses <soti-action-bar-panel>');
    }
    
    public deselect(targetsDescription: TargetsDescription): void {
        throw new Error('Please make sure to ovverride the ActionListService in your module that uses <soti-action-bar-panel>');
    }

    public dispose(): void {
        throw new Error('Please make sure to override the ActionsListService in your module that uses <soti-action-bar-panel>');
    }
}
