import { Constants } from "src/app/constants";
import { Component, EventEmitter, ViewChild, OnDestroy, SimpleChanges, Input, HostBinding } from "@angular/core";
import { Location } from '@angular/common';
import { PortalPreference } from "src/app/models/PortalPreferenceList.model";
import { UserBackgroundImage } from "src/app/models/UserBackgroundImage";
import { UserPreferenceModel } from "src/app/models/UserPreference.model";
import { User } from "src/app/models/user.model";
import { AccountService } from "src/app/services/account-services/account.service";
import { AppTranslationService } from "src/app/services/app-translation.service";
import { BrowserUtilsService } from "src/app/services/browserUtil/browserUtils.service";
import { DBkeys } from "src/app/services/db-keys";
import { LocalStoreManager } from "src/app/services/local-store-manager.service";
import { UserPreferenceService } from "src/app/services/user-preference-service/user-preference-service";
import { UserService } from "src/app/services/user-services/user.service";
import { Utilities } from "src/app/services/utilities";
import { ModalDialogService2 } from "src/app/shared/controls/dialogs";
import { FileUploadControl, FileUploadEvent } from "src/app/shared/controls/file-upload/file-upload.ctrl";
import { appMessenger } from "src/app/shared/controls/toaster-messages/AppMessenger";
import { AlertSeverityEnum } from "src/app/shared/enums/AlertSeverityEnum";
import { ErrorCode } from "src/app/shared/enums/ErrorCode";
import { UserPreferenceType } from "src/app/shared/enums/UserPreferenceType";
import { BackgroundTypeEnum } from "src/app/shared/enums/backgroundTypeEnum";
import { ISelectionChangeArgs } from "src/app/shared/soti-node-modules/select-box";
import { DisposalBag } from "src/app/shared/utils/DisposalBag";
import { ClientNotificationMessage, NotificationType } from "src/app/shared/utils/toast-utils/ClientNotificationMessage";
import { SotiThemeService } from "src/app/services/soti-theme/soti-theme.service";
import { ThemeType } from "src/app/shared/enums/ThemeType";
import { DarkTheme, LightTheme } from "src/app/services/soti-theme/theme";

@Component({
  selector: 'portal-options',
  templateUrl: './portal-options.ctrl.html',
  styleUrls: ['./portal-options.ctrl.scss']
})
export class PortalOptionsComponent implements OnDestroy{
  selectedTab: string = "Frequent";
  public portalPreferenceList: PortalPreference[];
  public portalPreferenceNames: string[];
  public PortalList: PortalPreference[];
  public portalId: number;
  public pristine: boolean;
  @Input() public isMobileView: boolean = true;
  public onClose: EventEmitter<void> = new EventEmitter();
  @ViewChild('FileUploadControlBackground')
    public fileUploadControl: FileUploadControl;
  public dragAndDropIcon: string = 'identity-icon-no-data-found';
  public userBackgroundImages: UserBackgroundImage[] = [];
  public currentUser: User;
  public getImagePath: string;
  public selectedBackground: number;
  public selectedBackgroundId: number;
  public showButton: boolean;
  public initialPrefList: UserPreferenceModel[] = [];
  public portalPrefList: UserPreferenceModel[] = [];
  public maxBackGroundImageSize: boolean = false;
  public badImageUploaded: boolean = false;
  public uploadInProgress: boolean = false;
  private _disposalBag: DisposalBag = new DisposalBag();
  public postunsubscribe:any;
  public addCustomeBackgroundImage: File ;
  public hideUploadSection: boolean = false;
  public isLoaded: boolean = false;
  public sotionetheme: boolean;
  public themeMode: UserPreferenceModel = {
    TypeId: UserPreferenceType.THEME,
    Preference: ThemeType.LIGHT
};
public lightThemeType: number = ThemeType.LIGHT;
public darkThemeType: number = ThemeType.DARK;
@HostBinding('class.dark-mode-bg')
public isDarkMode: boolean = false;


  constructor(private accountService: AccountService,
    private _modalDialogService: ModalDialogService2,
    private translationService: AppTranslationService,
    private _browserUtilService: BrowserUtilsService,    
    private _sotiThemeService: SotiThemeService,
    private location: Location,
    private localStorage: LocalStoreManager,
    private userService: UserService,
    private userPreferenceService: UserPreferenceService){}

  ngOnDestroy(): void {
    this.userPreferenceService.cleanUp();
    this._disposalBag.dispose();
  }

  ngOnInit(): void {
    this.pristine = true;
    this.getImagePath = Utilities.getAssestPath();
    this.accountService.getPortalPreferenceList().subscribe(result => {
      this.portalPreferenceList = result;
      this.portalPreferenceNames = this.portalPreferenceList.map(p=>p.Name);

      this.userService.getPreferences().subscribe(result => {
        this.initialPrefList = result;
        this.isLoaded = true;
        if(result.length === 0){
          this.getTenantBackgroundPreference();
        }else{
          this.setSelectedBackgroundImg(result); 
        }
        this.portalId = result.find(x => x.TypeId == UserPreferenceType.PORTALTYPE)?.Preference ?? 1;
      });
    });
    this.currentUser = this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
    this.updateUserBackgroundImages();
    this.sotionetheme = this._sotiThemeService._isSotiTheme;
        if (this.isMobileView && this.sotionetheme) {
            this._sotiThemeService.setTheme(new LightTheme());
            this.loadPreferences();
        }
  }
  public changePortalPrefernce(): void {
    let portalTabPref = new UserPreferenceModel();
    portalTabPref.TypeId = UserPreferenceType.PORTALTYPE;
    portalTabPref.Preference = this.portalId;
    if(this.selectedBackgroundId !== this.initialPrefList.find(x=>x.TypeId===UserPreferenceType.USERBACKGROUNDIMAGE)?.Preference){
      let portalBackgroundPref = new UserPreferenceModel();
      portalBackgroundPref.TypeId = UserPreferenceType.USERBACKGROUNDIMAGE;
      portalBackgroundPref.Preference = this.selectedBackgroundId;
      this.portalPrefList.push(portalBackgroundPref)
    }
    if(this.portalId !== this.initialPrefList.find(x=>x.TypeId===UserPreferenceType.PORTALTYPE)?.Preference){
      this.portalPrefList.push(portalTabPref)
    }
    
    this.userService.savePreferences(this.portalPrefList).subscribe(() => {
      this.handleSuccess(this.portalPrefList)
    }, error => {
      this.handleError()    
    });
  }

  public getTenantBackgroundPreference(){
      const _backgroundImage = this.localStorage.getData(UserPreferenceService.PREFERENCE);
      let image: UserBackgroundImage = this.userBackgroundImages.find(x=>x.ImageUrl === _backgroundImage.backgroundImage)
      if(image){
        this.selectedBackgroundId = image.Id;
        this.selectedBackground = image.Id;
      }else{
        if(_backgroundImage.backgroundImage.indexOf('null') > -1){
          this.selectedBackgroundId = this.userBackgroundImages.find(x=>!x.ImageUrl).Id;
          this.selectedBackground = this.userBackgroundImages.find(x=>!x.ImageUrl).Id;
        }else{
          this.selectedBackgroundId = Constants.DefaultBackgrounImageId;
          this.selectedBackground = Constants.DefaultBackgrounImageId;
        }
      }
  }

  public closeModal(): void {
    this._modalDialogService.closeModal();
  }
  public backClicked(): void {        
    this.onClose.emit();

    if (this.isMobileView) {
        this.location.back();
    }
    
}
  public onPortalChange(change: ISelectionChangeArgs): void {
    this.portalId = parseInt(change.value);
    this.pristine = this.initialPrefList.find(x=>x.TypeId===UserPreferenceType.PORTALTYPE).Preference === this.portalId && 
                    this.initialPrefList.find(x=>x.TypeId===UserPreferenceType.USERBACKGROUNDIMAGE)?.Preference === this.selectedBackgroundId
  }

  private setSelectedBackgroundImg(result: UserPreferenceModel[]): void {
    const _backgroundImage = result.find(element => element.TypeId === UserPreferenceType.USERBACKGROUNDIMAGE);
    if(_backgroundImage) {
        this.selectedBackgroundId = _backgroundImage.Preference ;
        this.selectedBackground = _backgroundImage.Preference;        
    } else{
        this.selectedBackground = null;
        this.selectedBackgroundId = 0;
    }
    
}

public selectBackground(backgroundImgId: number): void {
  this.pristine = this.initialPrefList.find(x=>x.TypeId===UserPreferenceType.USERBACKGROUNDIMAGE)?.Preference === backgroundImgId &&
                  this.initialPrefList.find(x=>x.TypeId===UserPreferenceType.PORTALTYPE).Preference === this.portalId
  if(this.selectedBackground === backgroundImgId){
      this.showButton = false;
  } else{
      this.showButton = true;
  }              
  this.selectedBackgroundId = backgroundImgId;
}

  public updateUserBackgroundImages() {
    this.userService.getUserBackgroundImages(this.currentUser.ReferenceId).subscribe((result: UserBackgroundImage[]) => {
      result.forEach(element => {
        this.hideUploadSection = false;
        if (element.Type == BackgroundTypeEnum.Default && element.ImageUrl !== null) {
          element.ImageUrl = this.getImagePath + element.ImageUrl;
        }
        if (element.Type == BackgroundTypeEnum.Custom) {
          this.hideUploadSection = true;
          element.Title = this.translationService.getTranslation('lbl_custom')
        };
      });
      this.userBackgroundImages = result;
    })
  }

  public uploadCustomeBackground(fileUploadEvent: FileUploadEvent): void {
    this.uploadInProgress = true;
    this.badImageUploaded = false;
    this.maxBackGroundImageSize = false;

    this.addCustomeBackgroundImage = fileUploadEvent.file;
    if (!this.addCustomeBackgroundImage) {
      this.uploadInProgress = false;
    } else {
      this._disposalBag.nextSub = this.postunsubscribe = this.userService.postCustomBackgroundImage(this.addCustomeBackgroundImage)
        .subscribe(() => {
          this.notification('msg_custom_background_successfully_updated_success', NotificationType.Success);
          this.showButton = false;
          this.uploadInProgress = false;
          this.hideUploadSection = true;
          this.updateUserBackgroundImages();
        },
          (error: any) => {
            this.uploadInProgress = false;
            this.fileUploadControl.removeFile(event);
            switch (error.error.ErrorCode) {
              case ErrorCode.InvalidFileFormatServerError: {
                this.badImageUploaded = true
                break;
              }
              case ErrorCode.InvalidFileDimensionsServerError: {
                this.maxBackGroundImageSize = true
                break;
              }
              default: {
                this.notification('err_failed_upload', NotificationType.Error);
              }
            }
          });
    }
  }

  public deleteCustomBackground(referenceId: string, event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this._disposalBag.nextSub = this.userService.deleteCustomBackgroundImage(referenceId).subscribe(() => {        
        this.isLoaded = true;
        this.updateUserBackgroundImages();
        this._disposalBag.nextSub = this.userService.getPreferences().subscribe(result => {
          if(this.selectedBackgroundId>5){
            this.setSelectedBackgroundImg(result);
          }
          let imgUrl = this.userBackgroundImages.find(x => x.Id == this.selectedBackgroundId)?.ImageUrl ? this.userBackgroundImages.find(x => x.Id == this.selectedBackgroundId)?.ImageUrl.toString() : "";
          this.userPreferenceService.updateBackgroundImage(imgUrl); 
        });
    });
    
}

  public processCancel(): void {
    if (this.uploadInProgress) {
      this.uploadInProgress = false;
      this.unsubscribe();
    }
  }

  private unsubscribe(): void {
    if (this.postunsubscribe) {
      this.postunsubscribe.unsubscribe();
    }
  }

private handleSuccess(preferences: UserPreferenceModel[]): void{
  this.userPreferenceService.updatePortalPref(preferences.find(x => x.TypeId == UserPreferenceType.PORTALTYPE)?.Preference.toString());
  let imgUrl = this.userBackgroundImages.find(x => x.Id == this.selectedBackgroundId)?.ImageUrl ? this.userBackgroundImages.find(x => x.Id == this.selectedBackgroundId)?.ImageUrl.toString() : "";
  this.userPreferenceService.updateBackgroundImage(imgUrl);
  if(this.portalPrefList.filter(x=>x.TypeId===UserPreferenceType.PORTALTYPE).length>0){
    this.notification('msg_tab_changed_successfully',NotificationType.Success)
  }
  if(this.portalPrefList.filter(x=>x.TypeId===UserPreferenceType.USERBACKGROUNDIMAGE).length>0){
    this.notification('msg_user_background_changed_successfully',NotificationType.Success)
  }
  this.portalPrefList = [];
  this.closeModal()
}

private handleError(): void{
  this.notification('msg_Somethingwentworng',NotificationType.Error)
  this.closeModal()
}

public notification(message_type: string, type: NotificationType): void {
  let message = this.translationService.getTranslation(message_type);
  appMessenger.postMessage(new ClientNotificationMessage(
      message,
      message,
      type,
      AlertSeverityEnum.Minor));
  } 
  
  public setTheme(themeType: ThemeType, savePreference: boolean = true): void {
    this.themeMode.Preference = themeType;

    if (savePreference) {
        this.savePreferences(this.themeMode);
    }

    if (themeType == ThemeType.LIGHT) {
        this._sotiThemeService.setTheme(new LightTheme());
        this.isDarkMode = false;
    } else {
        this._sotiThemeService.setTheme(new DarkTheme());
        this.isDarkMode = true;
    }
}

private savePreferences(userPreference) {
  this.userService.savePreferences([userPreference]).subscribe();
}

  public loadPreferences() {
    this.sotionetheme = this._sotiThemeService._isSotiTheme;
    this._disposalBag.nextSub = this.userService.getPreferences().subscribe((data) => {
        const themePreference = data.find(x => x.TypeId == UserPreferenceType.THEME);
        if (themePreference) {
            this.setTheme(themePreference.Preference, false);
        }
    });
}

}
 