export class InfoMessageBarPagerOption {
    public totalItems: number;
    public currentPage: number;
    public itemIndex: number;

    constructor(_totalItems: number,
                _currentPage: number,
                _itemIndex: number) {

        this.totalItems = _totalItems;
        this.currentPage = _currentPage;
        this.itemIndex = _itemIndex;
    }
}