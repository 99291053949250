import { ListResponse } from './../../models/list-response.model';
import { FilteringParams } from 'src/app/models/filteringparams';
import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from "../configuration.service";
import { EndpointFactory } from "../endpoint-factory.service";
import { DomainListUI } from 'src/app/models/domain-list-ui.model';
import { DomainVerificationMethod } from 'src/app/models/domain-verification-methods.model';
import { DomainVerification } from 'src/app/models/domain-verification.model';
import { Observable, of } from "rxjs";
import { DomainRegister } from "src/app/models/domainRegister.modal";
import { DomainVerificationResponse } from "src/app/models/domain-verification-response";
import { DomainStatusEnum } from 'src/app/shared/enums/DomainStatusEnum';
import { FilterDomain } from '../../models/filter-domain.model';
@Injectable()
export class DomainEndpoint extends EndpointFactory {
    private readonly _domainUrl: string = 'Domain';
    private readonly _verificationMethodsUrl: string = '/VerificationMethods';
    private readonly _sendDomainVerificationLinkUrl: string = '/SendDomainVerificationLink';
    private readonly _validateDomainNameUrl: string = '/ValidateDomainName';
    private readonly _verifyDomainUrl: string = '/VerifyDomain';
    private readonly _domainSummaryUrl: string = '/Summary';
    private readonly _deleteDomainUrl: string = '/Delete';
    private readonly _updateDomainUrl: string = '/UpdateDomain';
    private readonly _filterDomain: string = '/FilterDomain';

    public get DomainUrl(): string {
        return `${this.configurations.baseUrl}${this._domainUrl}`;
    }

    public get SendVerificationLink(): string {
        return `${this.configurations.baseUrl}${this._domainUrl}${this._sendDomainVerificationLinkUrl}`;
    }

    public get getFilterDomain(): string {
        return `${this.configurations.baseUrl}${this._domainUrl}${this._filterDomain}`;
    }
    
    public get getDomainSummaryUrl(): string {
        return `${this.configurations.baseUrl}${this._domainUrl}${this._domainSummaryUrl}`;
    }

    public get VerificationMethodsUrl(): string {
        return `${this.configurations.baseUrl}${this._domainUrl}${this._verificationMethodsUrl}`;
    }

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {
        super(http, configurations, injector);
    }

    public getAllDomainVerificationMethods(): Observable<DomainVerificationMethod[]> {
        return this.http.get<DomainVerificationMethod[]>(this.VerificationMethodsUrl, this.getRequestHeaders());
    }

    public registerDomains(domains: DomainRegister): Observable<DomainRegister> {
        return this.http.post<DomainRegister>(this.DomainUrl, domains, this.getRequestHeaders());
    }

    public sendDomainVerificationLink(domainReferenceId: string, email: string): Observable<void> {
        let domainVerification = new DomainVerification();
        domainVerification.ReferenceId = domainReferenceId;
        domainVerification.Email =  email;
        return this.http.post<void>(this.SendVerificationLink, domainVerification , this.getRequestHeaders());
    }

    public verifyDomain(domain: string): Observable<DomainVerificationResponse> {
        const url = this.DomainUrl + this._verifyDomainUrl + `\\${domain}`;
        return this.http.get<DomainVerificationResponse>(url, this.getRequestHeaders());
    }

    public getCompanyDomains( param: FilteringParams, allDomains: boolean, status?: DomainStatusEnum ): Observable<ListResponse<DomainListUI>> {
        let filterDomain = new FilterDomain();
        filterDomain.Params = param;
        filterDomain.AllDomains = allDomains;
        if ( status ) {
            filterDomain.Status[0] = status;
        }
        return this.http.post<ListResponse<DomainListUI>>( this.getFilterDomain, filterDomain, this.getRequestHeaders() );
    }

    public deleteDomain(domainReferenceId: string): Observable<void> {
        const url = this.DomainUrl + this._deleteDomainUrl + `/${domainReferenceId}`;
        return this.http.delete<void>(url, this.getRequestHeaders());
    }

    public updateDomain(domains: DomainRegister): Observable<void> {
      
        return this.http.put<void>(this.DomainUrl, domains, this.getRequestHeaders());
    }
}