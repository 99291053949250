import {
    OnInit,
    ChangeDetectorRef,
    Component,
    TemplateRef,
    ViewChild,
    ElementRef,
    Input,
    EventEmitter,
    Output,
    ContentChild,
    ViewEncapsulation
} from '@angular/core';
import { VerticalConnectionPos, HorizontalConnectionPos } from '@angular/cdk/overlay';
import { PopUpContent } from './pop-up-content.directive';

type HorizontalVertical = [HorizontalConnectionPos, VerticalConnectionPos];

// tslint:disable:jsdoc-format
/**
 * Positions the popup and the beak
 
 +-------------+                 +---------------+               +---------------+
 |    Origin   |                 |     Origin    |               |    Origin     |
 |             |                 |               |               |               |
 +-------------+                 +---------------+               +---------------+
 /\                                      /\                                     /\
 +--------------------+       +----------------------+     +---------------------+
 |                    |       |                      |     |                     |
 |     (startTop)     |       |     (centerTop)      |     |      (endTop)       |
 |       Popup        |       |        Popup         |     |        Popup        |
 |                    |       |      -default-       |     |                     |
 +--------------------+       +----------------------+     +---------------------+


 +--------------------+       +----------------------+      +---------------------+
 |                    |       |                      |      |                     |
 |   (startBottom)    |       |    (centerBottom)    |      |     (endBottom)     |
 |       Popup        |       |        Popup         |      |        Popup        |
 |                    |       |                      |      |                     |
 +--------------------+       +----------------------+      +---------------------+
 \/                                      \/                                      \/
 +-------------+                 +---------------+                +---------------+
 |    Origin   |                 |     Origin    |                |    Origin     |
 |             |                 |               |                |               |
 +-------------+                 +---------------+                +---------------+


                 +--------------------+     +--------------------+
+-------------+  |                    |     |                    |  +-------------+
|             |  |   (startCenter)    |     |    (endCenter)     |  |             |
|    Origin   +<-+       Popup        |     |       Popup        +->+   Origin    |
|             |  |                    |     |                    |  |             |
+-------------+  |                    |     |                    |  +-------------+
                 +--------------------+     +--------------------+

 */
export interface Positions {
    startTop: HorizontalVertical;
  startBottom: HorizontalVertical;
  startCenter: HorizontalVertical;
  endCenter: HorizontalVertical;
  endTop: HorizontalVertical;
  endBottom: HorizontalVertical;
  centerTop: HorizontalVertical;
  centerBottom: HorizontalVertical;
}

@Component({
    selector: 'soti-pop-up',
    templateUrl: './pop-up2.ctrl.html',
    styleUrls: ['./pop-up2.ctrl.scss'],
    encapsulation: ViewEncapsulation.None,
    exportAs: 'sotiPopUp'
})
/**
 * Popup component that positions a beak to the origin. Must be used with `PopUpTrigger`. Please look at the wiki for usage.
 * http://wiki.soti.net/index.php?title=MobiControl_NGUI_PopUp#Usage
 *
 *
 */
export class PopUp2 implements OnInit {
    @Input() public position: keyof Positions = 'centerTop';
    @Input() public noBeak: boolean = false;
    @Input() public closeOnOutSideClick: boolean = false;
    @Input() public noFallback: boolean = false;
    @Input() public offsetX: number = 0;
    @Input() public offsetY: number = 0;
    @Input() public focusIndex: number = 0;
    @Input() public cdkTrap: boolean = true;
    @Input() public name: string = 'pop-up';
    @Input() public soTheme: boolean = false;
    @ViewChild(TemplateRef) public template: TemplateRef<this>;
    @ViewChild('container') public popupContainer: ElementRef;
    @ContentChild(PopUpContent) public popupContentTemplate: PopUpContent;
    public closed: EventEmitter<void> = new EventEmitter<void>();

    public positionClass: string;

    public get beakStyle() {
        return {
          transform: `translate(${this.offsetX}px, ${this.offsetY}px)`
        };
      }
    public get x(): HorizontalConnectionPos {
        return this.positions[this.position][0];
    }
    public get y(): VerticalConnectionPos {
        return this.positions[this.position][1];
    }

    public get positions(): Positions {
        return {
            startTop: ['start', 'top'],
            startBottom: ['start', 'bottom'],
            startCenter: ['start', 'center'],
            endCenter: ['end', 'center'],
            endTop: ['end', 'top'],
            endBottom: ['end', 'bottom'],
            centerTop: ['center', 'top'],
            centerBottom: ['center', 'bottom']
        };
    }

    constructor(private _el: ElementRef, private _cdr: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this._checkPosition();
        this.setBeakPosition([this.x, this.y], null);
    }

    public convertPosition([horizontal, vertical]: HorizontalVertical): keyof Positions {
        if (!horizontal && !vertical) {
            return;
        }

        return (horizontal + vertical[0].toUpperCase() + vertical.slice(1)) as keyof Positions;
    }

    public setBeakPosition(overlay: HorizontalVertical, origin: HorizontalVertical): void {
        let originPosition,
            overlayPosition = '';

        overlayPosition = this.convertPosition(overlay);

        if (origin) {
            originPosition = this.convertPosition(origin);
        }

        this.positionClass = `${originPosition ? `origin-${originPosition}` : ''} overlay-${overlayPosition}`;
        // If a parent component has `changeDetectionStrategy.OnPush`, we should be able to always position the beak
        this._cdr.markForCheck();
    }

    public close(): void {
        this.closed.emit();
    }

    private _checkPosition(): void {
        if (!this.positions[this.position]) {
            throw Error(`
            <;> Unrecognized position. Please use one of the following positions:
            ${Object.keys(this.positions).join(', ')}
            `);
        }
    }
}
