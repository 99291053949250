/**
 * Created by rgubarenko on 2016-10-23.
 */

import {Input} from "@angular/core";
import {Directive, TemplateRef, ViewContainerRef, EmbeddedViewRef} from "@angular/core";

@Directive({
    selector: '[scopeVar][scopeVarOf]'
})
export class ScopeVarDirective {

    private _viewRef: EmbeddedViewRef<any> = null;

    constructor(private _viewContainer: ViewContainerRef,
                private _templateRef: TemplateRef<any>) {
    }

    @Input('scopeVarOf')
    public set scopeVarOf(expression) {
        if(this._viewContainer.length) {
            this._viewRef = <EmbeddedViewRef<any>>this._viewContainer.get(0);
        } else {
            this._viewRef = this._viewContainer.createEmbeddedView(this._templateRef);
        }

        this._viewRef.context.$implicit = expression;
    }
}