import { Language } from './../../models/language.model';
import { LdapType } from 'src/app/models/ldapType.model';
import { EncryptionType } from 'src/app/models/EncryptionType.model';

import { Injectable } from '@angular/core';
import { AccountEndpoint } from './account-endpoint.service';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthenticationType } from 'src/app/models/authenticationType.model';
import { ValidateResetPasswordTokenResponse } from 'src/app/models/validateResetPasswordTokenResponse';
import { ValidateGAuthTokenResponse } from 'src/app/models/validateGAuthTokenResponse';
import { ChangePassword } from 'src/app/models/ChangePassword.model';
import { CaptchaResult } from 'src/app/models/captchaResult.model';
import { ForgotPassword } from 'src/app/models/ForgotPassword.modal';
import { SystemAttributeType } from 'src/app/shared/enums/SystemAttributeType';
import { ContactUsMessage } from 'src/app/models/ContactUsMessage';
import { LoginRedirectInfo } from 'src/app/models/login-redirect-indo.model';
import { Certificate } from 'src/app/models/certificate';
import { CertificateValidationModel } from 'src/app/models/certificateValidationModel';
import { Algorithms } from 'src/app/models/algorithm.model';
import { NameIdFormats } from 'src/app/models/nameIDFormat.model';
import { ValidateMicrosoftAuthSetupTokenResponse } from 'src/app/models/validateMicrosoftAuthTokenResponse';
import { Localization } from 'src/app/models/Localization.model';
import { UICaptchaScreenEnum } from 'src/app/shared/enums//UICaptchaScreenEnum';
import { PortalPreference } from 'src/app/models/PortalPreferenceList.model';

@Injectable()
export class AccountService {


    constructor(
        private accountEndpoint: AccountEndpoint) {

    }

    public validateResetPasswordToken(token: string): Observable<ValidateResetPasswordTokenResponse> {
        return this.accountEndpoint.validateResetPasswordToken(token);
    }

    public validateGAuthSetupToken(token: string): Observable<ValidateGAuthTokenResponse> {
        return this.accountEndpoint.validateGAuthSetupToken(token);
    }

    public validateMicrosoftAuthSetupToken(token: string): Observable<ValidateMicrosoftAuthSetupTokenResponse> {
        return this.accountEndpoint.validateMicrosoftAuthSetupToken(token);
    }

    public getPasswordPolicyRegex(): Observable<string> {
        return this.accountEndpoint.getPasswordPolicyRegex();
    }

    public changePassword(changePassword: ChangePassword): Observable<void> {
        return this.accountEndpoint.changePassword(changePassword);
    }

    public getredirectSSO(relayState: string) {
        return this.accountEndpoint.redirectToSSOLoginEndpoint(relayState);

    }

    public readAndValidateCertificateFile(param: Certificate): Observable<CertificateValidationModel> {
        return this.accountEndpoint.readAndValidateCertificateFile(param);

    }

    public getSystemAttributes(systemAttribute: SystemAttributeType = SystemAttributeType.All) {
        return this.accountEndpoint.getSystemAttributes(systemAttribute);
    }
    public getSystemParams() {
        return this.accountEndpoint.getSystemParams();
    }
    public logout() {
        return this.accountEndpoint.logoutUser();
    }

    public logoutForRestrictedUser(relayState: string) {
        return this.accountEndpoint.logoutForRestrictedUser(relayState);
    }

    public getCaptcha(): Observable<CaptchaResult> {
        return this.accountEndpoint.getCaptcha();
    }

    public forgotPassword(forgotPassword: ForgotPassword, token: string): Observable<void> {
        return this.accountEndpoint.forgotPassword(forgotPassword, token);
    }

    public sendContactUsMessage(message: ContactUsMessage): Observable<void> {
        return this.accountEndpoint.sendContactUsMessage(message);
    }

    public getLoginRedirectInfo(token: string, subStatus: string): Observable<LoginRedirectInfo> {
        return this.accountEndpoint.getLoginRedirectInfo(token, subStatus);
    }

    public getAlgorithms(): Observable<Algorithms[]> {
        return this.accountEndpoint.getAlgorithms();
    }
    public getNameIdFormats(): Observable<NameIdFormats[]> {
        return this.accountEndpoint.getNameIdFormats();
    }
    public getLanguageList(): Observable<Language[]>{
        return this.accountEndpoint.getLanguageList();
    }
    public getPortalPreferenceList(): Observable<PortalPreference[]>{
        return this.accountEndpoint.getPortalPreferenceList();
    }
    public getCaptchaApplicable(uiScreen: UICaptchaScreenEnum): Observable<boolean>{
        return this.accountEndpoint.getCaptchaApplicable(uiScreen);
    }
    
}
