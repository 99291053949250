import { CoreCommonModule } from './../../core-common.module';
import {NgModule} from '@angular/core';
import {NavigationMenuControl} from './navigation-menu.ctrl';
import { MenuContentControl } from './menu-content.ctrl';
import { SharedTooltipModule } from '../../controls/tooltip';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateLanguageLoader } from 'src/app/services/app-translation.service';
import { RouterModule } from '@angular/router';
import { PopUpModule } from '../../controls/dialogs/pop-up/pop-up.module';
import { OverlayModule } from '@angular/cdk/overlay';
 

@NgModule({
    imports: [
        CoreCommonModule,
        SharedTooltipModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        RouterModule,
        PopUpModule,
        OverlayModule
    ],
    declarations: [
        NavigationMenuControl,
        MenuContentControl,
    ],
    providers: [],
    exports: [
        NavigationMenuControl,
    ]
})
export class NavigationMenuModule {}
