import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TenantLinkingListService {
    public tenantLinkingListValueObserver : Subject<boolean> = new Subject<boolean>();

    constructor() {

    }
}
