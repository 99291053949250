import { InfoMessageBarTypeEnum } from './InfoMessageBarTypeEnum';

export class InfoMessageBarOptions {
    public infoType: InfoMessageBarTypeEnum;
    public iconClass: string;
    public message: string;

    constructor(_alertType: InfoMessageBarTypeEnum,
                _message: string,
                _iconClass?: string) {

        this.infoType = _alertType;
        this.message = _message;
        this.iconClass = _iconClass;
    }

    public getAlertType(): string {
        return InfoMessageBarTypeEnum[this.infoType];
    }
}