

import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';

import { ConfigurationService } from './configuration.service';


@Injectable()
export class EndpointFactory {

    static readonly apiVersion: string = '1';


    constructor(protected http: HttpClient, protected configurations: ConfigurationService, private injector: Injector) {

    }





    protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; }, withCredentials: boolean } {
        const headers = new HttpHeaders({          
            'Content-Type': 'application/json',
            'Accept': `application/vnd.iman.v${EndpointFactory.apiVersion}+json, application/json, text/plain, */*`,
            'App-Version': ConfigurationService.appVersion
        });

        return { headers: headers, withCredentials: true };
    }

    protected getRequestHeadersByToken(token: string): { headers: HttpHeaders | { [header: string]: string | string[]; }, withCredentials: boolean } {
        const headers = new HttpHeaders({           
            'Content-Type': 'application/json',
            'Accept': `application/vnd.iman.v${EndpointFactory.apiVersion}+json, application/json, text/plain, */*`,
            'App-Version': ConfigurationService.appVersion
        });

        return { headers: headers, withCredentials: true};
    }

    protected getFormDataRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; }, withCredentials: boolean } {
        const headers = new HttpHeaders({         
        });
           return { headers: headers, withCredentials: true };
    }

    protected getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({           
            'Content-Type': 'application/json',
            'Accept': `application/vnd.iman.v${EndpointFactory.apiVersion}+json, application/json, text/plain, */*`,
            'App-Version': ConfigurationService.appVersion
        });

        return headers;
    }

    protected getParams(params: object): HttpParams {
        let httpParams = new HttpParams();
        Object.keys(params)
            .filter(key => {
                const v = params[key];
                return (Array.isArray(v) || typeof v === 'string') ?
                    (v.length > 0) :
                    (v !== null && v !== undefined);
            })
            .forEach(key => {
                httpParams = httpParams.set(key, params[key]);
            });
        return httpParams;
    }

    

}
