/**
 * Created by rgubarenko on 2016-02-18.
 */
import { queueManager, IQueueClient, QueueOperationPriority } from '../../utils/toast-utils/QueueManager';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { CountedTrie } from '../../utils/toast-utils/CountedTrie';
import { Subject } from 'rxjs/Subject';
import {NotificationAggregator, NotificationConfig} from '../../utils/toast-utils/notification-aggregator';
// Re-export so user's of this service don't need to import from another location


export class NotificationEvent {
    public constructor(public notificationItem: NotificationConfig, public count: number) {}
}
// tslint:disable
@Injectable()
export class ToastNotificationService {

    public itemAdded: Observable<NotificationEvent>;
    public itemRemoved: Observable<string>;

    private _itemAddedSubject: Subject<NotificationEvent>;
    private _itemRemovedSubject: Subject<string>;    
    

    private _notificationStateManager: NotificationStateManager;
    private _notificationTrie: CountedTrie<NotificationConfig>;

    constructor( private _notificationAggregator: NotificationAggregator) {
        this._notificationStateManager = new NotificationStateManager((i: string) => { this.removeItem(i); });
        this._notificationTrie = new CountedTrie<NotificationConfig>();
        this._itemAddedSubject = new Subject<NotificationEvent>();
        this._itemRemovedSubject = new Subject<string>();

        this.itemAdded = this._itemAddedSubject.asObservable();
        this.itemRemoved = this._itemRemovedSubject.asObservable();
        

        _notificationAggregator.messageStream.subscribe((msg) => this._addItem(msg));
    }

    private _addItem(message: NotificationConfig): void {
       
        let count = this._notificationTrie.add(message.identifier, message);
        this._notificationStateManager.add(message.identifier, message.timeout);
        this._itemAddedSubject.next(new NotificationEvent(message, count));
    }

    public removeItem(identifier: string): void {
        this._notificationTrie.remove(identifier);
        this._notificationStateManager.delete(identifier);
        this._itemRemovedSubject.next(identifier);
    }

    private _doAction(identifier: string, action: (item: NotificationConfig) => void): void {
        this._notificationTrie.apply(identifier, (i: NotificationConfig) => action(i));
    }

    public pinItem(identifier: string): void {
        this._notificationStateManager.clear(identifier);
    }

    public unpinItem(identifier: string): void {
        this._notificationStateManager.update(identifier);
    }
}

// region STATE MANAGEMENT
class NotificationStateManager {
    private _timerMap: Map<string, number> = new Map<string, number>();
    private _operationQueue: IQueueClient = queueManager.build();
    private _notificationStateMap: Map<string, NotificationState> = new Map<string, NotificationState>();

    private _mapHas = (i: string) => this._notificationStateMap.has(i);

    public constructor(private _itemRemoveFunc: (i: string) => void) {}

    public add(id: string, timeout: number): void {
        if (this._mapHas(id)) {
            return this.update(id, timeout);
        }

        this._notificationStateMap.set(id, 
            new NotificationState(id, 
                timeout, this._timerMap, 
                (i: string) => { this._itemRemoveFunc(i); }, 
                (i: string) => { this._deleteNotificationState(i); }, 
                this._operationQueue));
    }

    public update(id: string, timeout?: number): void {
        if (this._mapHas(id)) {
            this._notificationStateMap.get(id).addAction(StateActionType.Update);
        } else if (timeout && timeout != null) {
            this.add(id, timeout);
        }
    }

    public clear(id: string): void {
        if (this._mapHas(id)) {
            this._notificationStateMap.get(id).addAction(StateActionType.Clear);
        }
    }

    public delete(id: string): void {
        if (this._mapHas(id)) {
            this._notificationStateMap.get(id).addAction(StateActionType.Delete);
        }
    }

    private _deleteNotificationState(id: string): void {
        if (this._mapHas(id)) {
            this._notificationStateMap.delete(id);
        }
    }
}

class NotificationState {
    private _timerCreate: boolean;
    private _timerUpdate: boolean;
    private _timerClear: boolean;
    private _itemDelete: boolean;
    private _amDeleted: boolean;

    constructor(private _id, 
        private _timeout: number, 
        private _mapRef: Map<string, number>, 
        private _remFunc: (i: string) => void, 
        private _deleteCallback: (i: string) => void, 
        private _opQueue: IQueueClient) {

        this.addAction(StateActionType.Create);
    }    

    public addAction(opType: StateActionType): void {
        let priority: QueueOperationPriority = QueueOperationPriority.Low;

        switch (opType) {
            case StateActionType.Create:
                this._createTimer();
                priority = QueueOperationPriority.High;
                break;
            case StateActionType.Update:
                this._updateTimer();
                break;
            case StateActionType.Clear:
                this._clearTimer();
                break;
            default:
                this._delete();
                priority = QueueOperationPriority.High;
        }

        this._opQueue.add(() => { this.performCurrentOp(); }, priority);
    }
    
    // This is not a queued operation
    private _createTimer(): void {
        this._timerCreate = true;        
    }

    private _updateTimer(): void {
        this._timerUpdate = true;
    }

    private _clearTimer(): void {        
        this._timerClear = true;
    }

    private _delete(): void {
        this._itemDelete = true;
    }

    public performCurrentOp(): void {
        if (this._amDeleted) {
            return;
        }

        if (this._timerCreate) {
            this._timerCreate = false;
            this._mapRef.set(this._id, window.setTimeout(() => { this.addAction(StateActionType.Delete); }, this._timeout));
            return;
        }

        if (this._timerClear) {
            this._timerClear = false;
            if (this._timerUpdate) {
                this._opQueue.add(() => { this.performCurrentOp(); });
                return;
            }

            if (!this._mapRef.has(this._id)) {
                return;
            }
            window.clearTimeout(this._mapRef.get(this._id));
            return;
        }
        
        if (this._itemDelete) {
            this._itemDelete = false;
            if (!this._mapRef.has(this._id)) {
                return;
            }
            this._amDeleted = true;

            this._remFunc(this._id);
            this._deleteCallback(this._id);
            return;
        }

        if (this._timerUpdate) {
            this._timerUpdate = false;
            if (this._mapRef.has(this._id)) {
                window.clearTimeout(this._mapRef.get(this._id));
            }
            this.addAction(StateActionType.Create)
            return;
        }        
    }
}

enum StateActionType {
    Create,
    Update,
    Clear,
    Delete
}
// endregion