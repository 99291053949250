import {OnInit, Directive, ElementRef, Renderer2, Input, AfterViewInit, OnChanges, SimpleChanges, SimpleChange} from '@angular/core';
import { AppTranslationService } from '../../../services/app-translation.service';

@Directive({
  selector: '[show-more-detail]',
  exportAs: 'show-more-detail'
})
export class ShowMoreDetailDirective implements OnInit, OnChanges {


  @Input ('detail') public detail: string;
  @Input('showDetail') public showDetail: boolean;
  @Input('minCharLength') public minCharLength: number;
  @Input('verificationRequiredForThreeDot') public verificationRequiredForThreeDot: boolean = false;

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {}


  public ngOnInit():void {       
    this.showLimitedMessage();
  }

  public ngOnChanges(changes: SimpleChanges): void {
      let change: SimpleChange = changes['showDetail'];
      if (change && !change.isFirstChange()) {
        if (change && change.currentValue) {
            this.showFullMessage();
        } else if (change) {
            this.showLimitedMessage();
        }
      }
    
  }
    public showLimitedMessage(): void {
        if (this.detail && this.detail.length > 0) {
          if (this.verificationRequiredForThreeDot && this.detail.length <= this.minCharLength) {
            this._elRef.nativeElement.innerText = this.detail;
          } else {
            this._elRef.nativeElement.innerText = this.detail.substr(0, this.minCharLength) + '...';
          }
        this._renderer.setStyle(this._elRef.nativeElement.parentElement.parentElement, 'height', null);
        }
    }
    public showFullMessage(): void {
        this._elRef.nativeElement.innerText = this.detail;
        this._renderer.setStyle(this._elRef.nativeElement.parentElement.parentElement, 'height', parseInt(this._elRef.nativeElement.offsetHeight) + 45 + 'px');
    }
}
