import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    OnChanges,
    OnDestroy,
    Optional,
    SimpleChanges
} from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { FormPanelCtrl } from '../form-panel.ctrl';
import { Subject, Observable } from 'rxjs';

@Component({
    selector: 'im-form-panel-body',
    exportAs: 'imFromPanelBody',
    templateUrl: './form-panel-body.ctrl.html',
    styleUrls: ['./form-panel-body.ctrl.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['disabled', 'expanded'],
    outputs: ['opened', 'closed', 'expandedChange'],
})
export class FormPanelBodyCtrl  implements OnChanges, OnDestroy {
    @HostBinding('class.panel-spacing')
    

    public get inputChanges(): Observable<SimpleChanges> {
        return this._inputChanges.asObservable();
    }

    private readonly _inputChanges: Subject<SimpleChanges> = new Subject<SimpleChanges>();

    public ngOnChanges(changes: SimpleChanges): void {
        this._inputChanges.next(changes);
    }

    public ngOnDestroy(): void {
        this._inputChanges.complete();
    }

    
}
