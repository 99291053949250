import { Directive, ElementRef, Input, HostListener, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[scrollToViewOnFocus]'
})
export class ScrollToViewDirective {
  @Input() topOffset = 0;
  @Input() bottomOffset = 0;

  constructor(private el: ElementRef) {}

  @HostListener('focus')
  onFocus(): void {
    this.scrollIntoView();
  }

  ngOnInit() {
    const observer = new MutationObserver(() => {
      if (this.el.nativeElement.classList.contains('focus'))
      {
        this.scrollIntoView();
      }
    });

    observer.observe(this.el.nativeElement, { attributes: true, attributeFilter: ['class'] });
  }

  private scrollIntoView(): void {
    let focusedElement = this.el.nativeElement;
    const targetPosition_top = focusedElement.getBoundingClientRect().top;
    const targetPosition_bottem = focusedElement.getBoundingClientRect().bottom;
    let scrollelement = this.findScrollableParent(this.el.nativeElement);
    const scrollElementTop = scrollelement.getBoundingClientRect().top;
    const scrollElementBottom = scrollelement.getBoundingClientRect().bottom;
    
    if (this.topOffset + scrollElementTop > targetPosition_top) 
    {
      scrollelement.scrollTo({ top: scrollelement.scrollTop - (scrollElementTop + this.topOffset - targetPosition_top) , behavior: 'smooth' });
    }
    else if (scrollElementBottom - this.bottomOffset  <  targetPosition_bottem)
    {
      scrollelement.scrollTo({ top: scrollelement.scrollTop + ( targetPosition_bottem - (scrollElementBottom - this.bottomOffset) ) , behavior: 'smooth' });
    }


  }

  private findScrollableParent(element) {
    if (!element) {
      return null;
    }
  
    const style = getComputedStyle(element);
    const overflowY = style.overflowY;
  
    if (overflowY === 'auto' || overflowY === 'scroll') {
      return element;
    }
  
    return this.findScrollableParent(element.parentElement);
  }
}
