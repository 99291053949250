import { Injectable, Injector } from '@angular/core';
import { Constants } from 'src/app/constants';
import { AuthService } from 'src/app/services/auth.service';
import { formatInCSVFormat } from 'src/app/shared/utils/csv-formatter';
@Injectable()
export class FileDownloadService {

    private _authService: AuthService;
    private get authService() {
        if (!this._authService) {
            this._authService = this.injector.get(AuthService);
        }

        return this._authService;
    }

    constructor(private injector: Injector) {

    }

    public sendRequestAsync(request: FileDownloadRequestDetails, useBlob: boolean = false, setHeaderValue: boolean = true): Promise<FileDownloadRequestResult> {

        return new Promise((resolve, reject) => {
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            xhr.open(request.method, request.url, true);
            xhr.withCredentials = true;
            xhr.responseType = 'blob';
            let token = this.authService.accessToken ? this.authService.accessToken : this.authService.tempAccessToken;
            if(setHeaderValue){
            xhr.setRequestHeader(Constants.userReferenceHeader, token);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    let result = FileDownloadService._responseToRequestResult(xhr, useBlob);
                    if (result.success) {
                        resolve(result);
                    }
                    else {
                        reject(result);
                    }
                }
            };

            xhr.send();
        });
    }

    public downloadFileAsCSV<T>(data: Array<T>, jsonKeys:string[], headers: string[]){
        let csvData = this.ConvertToCSV(data, jsonKeys, headers);
        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        return  URL.createObjectURL(blob);
    }

    public downloadCsvFile(csvData: string) {
        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        return  URL.createObjectURL(blob);
    }

    public downloadFile(downloadUri: string, fileName: string, useBlob: boolean) {
        let requestDetails: FileDownloadRequestDetails = {
            method: 'GET',
            url: downloadUri
        };
        let isCdnReq = this.hasCdn(downloadUri);

        let requestResult = this.sendRequestAsync(requestDetails, useBlob, !isCdnReq);

        requestResult.then(result => {
            if (result.success) {
                this._downloadFile(result.blobUri, fileName);
            }
        });
    }

    ///checks whether uri has cdn
    /// attaching the user-reference headers in cdn strings throws cors error from cloudfront
    private hasCdn(str: string): boolean {
        return str.includes("cdn.");
      }

    private _downloadFile(uri: string | Blob, fileName: string): void {
        const link = document.createElement('a');
        if (typeof uri === 'string') {
            link.setAttribute('href', uri);
        } else {
            link.setAttribute('href', URL.createObjectURL(uri));
        }

        link.setAttribute('download', fileName);
        link.setAttribute("target", "_self;");
        document.body.appendChild(link);
        link.click();
        // Cleanup the DOM
        document.body.removeChild(link);
    }

    private ConvertToCSV<T>(objArray: Array<T>, jsonKeys:string [],headerList: string[]): string {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';

        for (let index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in jsonKeys) {
               let head = jsonKeys[index];
               if(array[i][head]){
                    if(array[i][head] instanceof Date){
                    let dateValue =  <Date>array[i][head];

                    line += '"' + formatInCSVFormat(dateValue.toUTCString()) +'",';;
                    }
                   else{
                    line += '"' + formatInCSVFormat(array[i][head]) +'",';
                   }
               } else {
                line +='N/A,'
               }
            }
            line = line.slice(0, -1);
            str += line + '\r\n';
        }
        return str;
    }

    private static _responseToRequestResult(xhr: XMLHttpRequest, useBlob: boolean): FileDownloadRequestResult {
        let blobUri: string | Blob;
        if (useBlob) {
           blobUri = new Blob([xhr.response], { type: 'application/octet-stream' });
        } else {
            blobUri = URL.createObjectURL(xhr.response);
        }
        if (xhr.status >= 200 && xhr.status < 300) {
            return {
                blobUri,
                success: true,
                httpStatus: xhr.status,
                errorMessage: null
            };
        }

        return {
            blobUri: null,
            success: false,
            httpStatus: xhr.status,
            errorMessage: xhr.statusText
        };
    }
}

export interface FileDownloadRequestDetails {
    url: string;
    method: string;
}

export interface FileDownloadRequestResult {
    success: boolean;
    blobUri: string | Blob;
    httpStatus: number;
    errorMessage: string;
}
