import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { Directive, HostListener, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[x-tabbing]'
})
export class TabbingClickDirective implements OnInit {

    ngOnInit(): void {
        if (this.tabindex == "") {
            this.tabindex = "0";
        }

    }

    // default event behaviour
    // used for attaching only tab index and disable event.
    @Input() public enableEvent: boolean = true;

    @Input('x-tabbing')
    @HostBinding('attr.tabindex') tabindex;

    @HostListener('keydown', ['$event'])
    onKeyDown(e: any) {
        if (this.enableEvent) {
            if (e.which === ENTER || e.which === SPACE) {
                e.preventDefault();
                e.target.click();
            }
        }
    }

}