// ====================================================
//  
// 
// ====================================================

import { Injectable } from '@angular/core';

@Injectable()
export class DBkeys {

    // tslint:disable-next-line:typedef
    public static readonly CURRENT_USER = 'current_user';
    // tslint:disable-next-line:typedef
    public static readonly CURRENT_COMPANY = 'current_company';
    // tslint:disable-next-line:typedef
    public static readonly DEFAULT_APPLICATION = 'Default_Application';
    // tslint:disable-next-line:typedef
    public static readonly SYSTEM_PARAMTER = 'System_Parameter';
    // tslint:disable-next-line:typedef
    public static readonly USER_PERMISSIONS = 'user_permissions';
    // tslint:disable-next-line:typedef
    public static readonly IsUserLoggedIn = 'index';
    // tslint:disable-next-line:typedef
    public static readonly ID_TOKEN = 'id_token';
    // tslint:disable-next-line:typedef
    public static readonly REFRESH_TOKEN = 'refresh_token';
    // tslint:disable-next-line:typedef
    public static readonly TOKEN_EXPIRES_IN = 'expires_in';
    // tslint:disable-next-line:typedef
    public static readonly Shared_Enums = 'App_Enums';
    // tslint:disable-next-line:typedef
    public static readonly SoftwareVersion = 'Software_Version';
    // tslint:disable-next-line:typedef
    public static readonly REMEMBER_ME = 'remember_me';
    // tslint:disable-next-line:typedef
    public static readonly CURRENT_USER_CUSTOMSETTIGNS = 'current_user_customSettings';
     // tslint:disable-next-line:typedef
     public static readonly GOOGLE_WORKSPACE_ENABLED = 'google_workspace_enabled';
    

    // tslint:disable-next-line:typedef
    public static readonly LANGUAGE = 'language';
    // tslint:disable-next-line:typedef
    public static readonly HOME_URL = 'home_url';
    // tslint:disable-next-line:typedef
    public static readonly THEME = 'theme';
    // tslint:disable-next-line:typedef
    public static readonly SHOW_DASHBOARD_STATISTICS = 'show_dashboard_statistics';
    // tslint:disable-next-line:typedef
    public static readonly SHOW_DASHBOARD_NOTIFICATIONS = 'show_dashboard_notifications';
    // tslint:disable-next-line:typedef
    public static readonly SHOW_DASHBOARD_TODO = 'show_dashboard_todo';
    // tslint:disable-next-line:typedef
    public static readonly SHOW_DASHBOARD_BANNER = 'show_dashboard_banner';
    // tslint:disable-next-line:typedef
    public static readonly Api_Token = 'token';
    // tslint:disable-next-line:typedef
    public static readonly Relay_State = 'RelayState';
    // tslint:disable-next-line:typedef
    public static readonly Error_Info = 'errorInfo';
    // tslint:disable-next-line:typedef
    public static readonly IDP_URL = 'IdpUrl';
    // tslint:disable-next-line:typedef
    public static readonly IDP_GENERIC_URL = 'IdpGenericBaseUrl';
    // tslint:disable-next-line:typedef
    public static readonly APPLICATION_REDIRECT_INFO = 'ApplicationRedirectInfo';
    // tslint:disable-next-line:typedef
    public static readonly READLOCK = 'READLOCK_';
}
