import { Component } from '@angular/core';

@Component({
    selector: 'soti-loader',
    templateUrl: './loading.ctrl.html',
    styleUrls: ['./loading.ctrl.scss']
})
export class LoadingControl {
    private _loadingText: string;

    public set loadingText(text: string) {
        this._loadingText = text;
    }

    public get loadingText() {
        return this._loadingText;
    }
}