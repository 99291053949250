import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizationModalComponent } from './Modals/localization-modal/localization-modal.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppTranslationService, TranslateLanguageLoader } from 'src/app/services/app-translation.service';
import { ModalDialogModule, ModalDialog2Module, SharedDialogsModule } from 'src/app/shared/controls/dialogs';
import { SharedFormPanelModule } from 'src/app/shared/controls/form-panel';
import { SharedInputIconModule } from 'src/app/shared/controls/input-icon';
import { StatusNotificationBarModule } from 'src/app/shared/controls/toaster-messages/shared-status-notification-bar.module';
import { SharedTooltipModule } from 'src/app/shared/controls/tooltip';
import { SharedLoadingDirectiveModule } from 'src/app/shared/directives/loading/shared-loading.module';
import { AnnounceService } from 'src/app/services/announce.service';
import { TooltipService } from 'src/app/shared/controls/tooltip/tooltip.service';
import { CheckPasswordService } from 'src/app/shared/services/check-password/check-password.service';
import { SharedSelectBoxModule } from 'src/app/shared/soti-node-modules/select-box';
import { LocalizationManagementViewComponent } from './localization-management.view';
import { LocalizationFrmSectionComponent } from './localization-frm-section/localization-frm-section.component';



@NgModule({
    declarations: [LocalizationModalComponent,
        LocalizationManagementViewComponent,
        LocalizationFrmSectionComponent
    ],
    imports: [
        FormsModule,
        SharedInputIconModule,
        ModalDialogModule,
        ModalDialog2Module,
        SharedFormPanelModule,
        StatusNotificationBarModule,
        SharedLoadingDirectiveModule,
        SharedDialogsModule,
        SharedTooltipModule,
        SharedSelectBoxModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        CommonModule
    ],
    exports: [LocalizationModalComponent,
        LocalizationManagementViewComponent,
        LocalizationFrmSectionComponent
    ],
    providers: [
        AppTranslationService,
        AnnounceService,
    ]
})
export class LocalizationModule { }
