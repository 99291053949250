import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import {BrowserUtilsService} from 'src/app/services/browserUtil/browserUtils.service'

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    public parse(url: string): UrlTree {
        // Optional Step: Do some stuff with the url if needed.

        // If you lower it in the optional step 
        // you don't need to use "toLowerCase" 
        // when you pass it down to the next function
        let browserUtilsService: BrowserUtilsService = new BrowserUtilsService();
        return super.parse(browserUtilsService.getUrlPart(url).toLowerCase() + browserUtilsService.getQueryString(url)); 
    }
}