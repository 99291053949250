import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { AnnounceService } from '../../../../services/announce.service';
import { LiveAnnouncementType } from '../../../enums/LiveAnnouncementType';
import { AppTranslationService } from 'src/app/services/app-translation.service';

@Directive({
  selector: 'button:[type=submit]'
})
export class ValidateFormOnSubmissionDirective  {


  constructor(
    private _elRef: ElementRef,
    private announceService: AnnounceService, 
    private translationService : AppTranslationService
  ) {
  }


  private loadCount: number = 0;
  private isNotAnnounced : boolean = true;
  @HostListener('click', ['$event'])
  private clickEvent($event: Event){ 
    let button = this._elRef.nativeElement;
    button.setAttribute('aria-hidden', 'true');
    setTimeout(() => {
      let loading = Array.from(this._elRef.nativeElement.querySelectorAll('soti-loader')).length > 0
      if (loading){
        this.clickEvent($event);
        button.removeAttribute('aria-hidden');
        this.loadCount += 1;
      }
      else if (!loading && this.loadCount >= 0){
        let elements = Array.from(this._elRef.nativeElement.form.querySelectorAll('.form-group.has-error'))
        let infoBarElements = Array.from(this._elRef.nativeElement.form.querySelectorAll('soti-info-message-bar'))
        infoBarElements.forEach((x : HTMLDivElement) => {
          if(x.childElementCount >= 0){
            Array.from(x.children).forEach(element => {
              if( element.classList.contains('Error')){
                element.classList.add("errorMessage");
              }
              
            });
          }
          
        })
        elements.push(...infoBarElements)
        if(this.isNotAnnounced)
          {
            const btnlabel = this.translationService.getTranslation('lbl_button');
            const buttonText = button.innerText || button.textContent || '';
            this.announceService.announce(LiveAnnouncementType.ButtonClicked, buttonText + btnlabel, null, null, 'assertive');
            this.announceService.announce(LiveAnnouncementType.ValidationError, null, null, elements, 'assertive');
            this.isNotAnnounced = false;
        }
        this.loadCount = 0;
      }
    }, 50);
  }

  
  
}
