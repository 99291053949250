
import {
    Component,
    Host,
    OnDestroy
} from '@angular/core';
import { DisposalBag } from '../../../utils/DisposalBag';


@Component({
    selector: 'im-form-panel-header',
    templateUrl: './form-panel-header.ctrl.html',
    styleUrls: ['./form-panel-header.ctrl.scss'],
    
})
export class FormPanelHeaderCtrl {
    
    private _disposalBag: DisposalBag = new DisposalBag();

    constructor() {
        
    }

    public ngOnDestroy(): void {
        this._disposalBag.dispose();
    }

    
}
