/**
 * Created by jcammisuli on 2016-05-18.
 */
import { DropdownComponent } from './dropdown/dropdown.component';
import { DropdownNodeComponent } from './dropdown-node/dropdown-node.component';
import { SubDropdownComponent } from './sub-dropdown/sub-dropdown.component';
import {DropdownHeaderNodeComponent} from './dropdown-header-node/dropdown-header-node.component';

export const DROPDOWN_DIRECTIVES = [
    DropdownHeaderNodeComponent,
    SubDropdownComponent
];