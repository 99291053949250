import { NgModule } from '@angular/core';
import { SharedInputIconModule } from '../../shared/controls/input-icon';
import { ModalDialog2Module, ModalDialogModule } from '../../shared/controls/dialogs';
import { FormsModule }   from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateLanguageLoader, AppTranslationService } from 'src/app/services/app-translation.service';
import { SharedFormPanelModule } from 'src/app/shared/controls/form-panel/shared-form-panel.module';
import { SharedLoadingDirectiveModule } from 'src/app/shared/directives/loading/shared-loading.module';
import { StatusNotificationBarModule } from 'src/app/shared/controls/toaster-messages/shared-status-notification-bar.module';
import { SharedDialogsModule } from 'src/app/shared/controls/dialogs';
import { SharedTooltipModule } from 'src/app/shared/controls/tooltip/shared-tooltip.module';
import { TooltipService } from 'src/app/shared/controls/tooltip/tooltip.service';
import { SharedInfoMessageBarModule } from 'src/app/shared/controls/info-message-bar';
import { AnnounceService } from '../../services/announce.service';
import { CheckPasswordService } from 'src/app/shared/services/check-password/check-password.service';
import { SharedAccordionModule } from 'src/app/shared/controls/accordion/shared-accordion.module';
import { ChangeDefaultXsightServerModal } from './Modal/change-default-xsight-server-modal/change-default-xsight-server-modal.ctrl';
import { ChangeDefaultXsightServerComponent } from './change-default-xsight-server/change-default-xsight-server.ctrl';
import { SharedSelectBoxModule } from 'src/app/shared/soti-node-modules/select-box';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedInputIconModule,
        ModalDialogModule,
        ModalDialog2Module,
        SharedFormPanelModule,
        StatusNotificationBarModule,
        SharedLoadingDirectiveModule,
        SharedDialogsModule,
        SharedAccordionModule,
        SharedSelectBoxModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        SharedInfoMessageBarModule
    ],
    declarations: [
        ChangeDefaultXsightServerModal,
        ChangeDefaultXsightServerComponent
    ],
    exports: [
        ChangeDefaultXsightServerComponent
    ],
    providers: [
        AppTranslationService,
        AnnounceService
    ]
})
export class DefaultXsightServerManagementModule {}