import { OnInit, Component } from "@angular/core";
import { ActivatedRoute, Params } from '@angular/router';
import { Constants } from "src/app/constants";

@Component({
    selector: 'connection-oauth-response',
    styleUrls: ['./connection-oauth-response.ctrl.scss'],
    templateUrl: './connection-oauth-response.ctrl.html'
})
export class ConnectionOAuthResponseControl implements OnInit {
    constructor (
        private route: ActivatedRoute
    ) {
        
    }
    public ngOnInit(): void {
        this.route.queryParams.subscribe((params: Params) => {           
            if (window.opener) {
                let result = params['result'];
                let status = params['statusCode'];
                let data = {
                    result: result,
                    statusCode: status
                 };
              window.opener.postMessage(data,Constants.DefaultWindowOpenerTargetOrigin);
            }
        });
     }
   
    }
