import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class PolicyListService {
    public policyListValueObserver : Subject<boolean> = new Subject<boolean>();

    constructor() {

    }
}
