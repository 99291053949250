import { PopUpModule } from 'src/app/shared/controls/dialogs/pop-up/pop-up.module';
import { TranslateLanguageLoader } from '../../../services/app-translation.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CoreCommonModule } from '../../core-common.module';
import { WarnignBoxControl } from './im-warning-dialog.ctrl';
import { A11yModule } from '@angular/cdk/a11y';
import { ModalDialogService2 } from '../dialogs';
import {SharedHelpModule} from '../../internal-controls/help/shared-help.module';

@NgModule({
    declarations: [
        WarnignBoxControl,
    ],
    imports: [
        CommonModule,
        CoreCommonModule,
        PopUpModule,
        SharedHelpModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        A11yModule
    ],
    exports: [
        WarnignBoxControl
    ],
    providers: [
        ModalDialogService2
    ],
    bootstrap: []
})
export class WarningDialogBoxModule {}