import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppTranslationService, TranslateLanguageLoader } from 'src/app/services/app-translation.service';
import { SharedDialogsModule } from 'src/app/shared/controls/dialogs';
import { SharedFormPanelModule } from 'src/app/shared/controls/form-panel/shared-form-panel.module';
import { SharedInfoMessageBarModule } from 'src/app/shared/controls/info-message-bar';
import { SharedTabSwitcherModule } from 'src/app/shared/controls/tab-switcher/shared-tab-switcher.module';
import { StatusNotificationBarModule } from 'src/app/shared/controls/toaster-messages/shared-status-notification-bar.module';
import { SharedTooltipModule } from 'src/app/shared/controls/tooltip/shared-tooltip.module';
import { TooltipService } from 'src/app/shared/controls/tooltip/tooltip.service';
import { SharedLoadingDirectiveModule } from 'src/app/shared/directives/loading/shared-loading.module';
import { CheckPasswordService } from 'src/app/shared/services/check-password/check-password.service';
import { AnnounceService } from '../../services/announce.service';
import { ModalDialog2Module, ModalDialogModule } from '../../shared/controls/dialogs';
import { SharedInputIconModule } from '../../shared/controls/input-icon';
import { PasswordManagementModule } from '../PasswordManagement/password-management.module';
import { PreferencesModal } from './Modals/preferences-modal/preferences-modal.ctrl';
import { ProfileDetailModule } from '../ProfileManagement/profile-detail/profile-detail.module';
import { PortalOptionsComponent } from  './components/portal-options/portal-options.ctrl';
import { LocalizationModule } from '../localization/localization.module';
import { SharedSelectBoxModule } from 'src/app/shared/soti-node-modules/select-box';
import { SharedAccordionModule } from 'src/app/shared/controls/accordion';
import { SharedFileUploadModule } from 'src/app/shared/controls/file-upload/shared-file-upload.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedInputIconModule,
        ModalDialogModule,
        ModalDialog2Module,
        SharedFormPanelModule,
        StatusNotificationBarModule,
        SharedLoadingDirectiveModule,
        SharedDialogsModule,
        SharedAccordionModule,
        SharedTooltipModule,
        PasswordManagementModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        SharedInfoMessageBarModule,
        SharedTabSwitcherModule,
        ProfileDetailModule,
        LocalizationModule,
        SharedFileUploadModule,
        SharedSelectBoxModule
    ],
    declarations: [
        PreferencesModal,
        PortalOptionsComponent
    ],
    exports: [
    ],
    providers: [
        AppTranslationService,
        TooltipService,
        AnnounceService,
        CheckPasswordService
    ]
})
export class PreferencesManagementtModule {}