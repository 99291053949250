
export var formatInCSVFormat = (value: string): string => {
    let formattedString: string = "";
    for (let i = 0; i < value.length; i++) {
        switch(value[i]){
            case '"': formattedString += '"' + value[i] ;
            break;
            default: formattedString += value[i];
            break;
        }
    }
    return formattedString;
};
