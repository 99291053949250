import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ThemeType } from 'src/app/shared/enums/ThemeType';
import { ITheme } from './theme';

@Injectable()
export class SotiThemeService {
    
    public _isSotiTheme: boolean = false;
    public selectedTheme: BehaviorSubject<ThemeType> = new BehaviorSubject<ThemeType>(ThemeType.LIGHT);
    private active: ITheme;
    

    public isDarkTheme(theme: ITheme): boolean {
        return this.active.name === theme.name;
    }
    public setTheme(theme: ITheme): void {
        this.setActiveTheme(theme);
        this.selectedTheme.next(theme.themeType);
    }
    private setActiveTheme(theme: ITheme): void {
        this.active = theme;

        Object.keys(this.active.properties).forEach(property => {
            document.documentElement.style.setProperty(
                property,
                this.active.properties[property]
            );
        });
    }

}
