import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class GlobalVariables
{
    private isMobileResolution: boolean;
    public IsSideBarOpened : BehaviorSubject<boolean> = new BehaviorSubject(false);      
    constructor() {
        if (window.innerWidth <= 1023) {          
          this.IsSideBarOpened.next(false);        
        } else {          
          this.IsSideBarOpened.next(true);         
        }
      }
      
}