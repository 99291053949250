
import {Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'error-msg',
    templateUrl: './error-msg.component.html',
    styleUrls: ['./error-msg.component.scss']
})

export class Errorssmsg implements OnInit {
    @Input()  title: string = '';
    @Input()  errorsMessage: string = '';
    constructor() {

    }
    ngOnInit() {
     console.log(this.errorsMessage);

    }
} 
