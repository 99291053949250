import {
    Injectable, Inject, Renderer2, OnDestroy, ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs/Observable';
import 'rxjs/operator/debounceTime';
import { DialogRequestBase } from '../../dropdown-requests/DialogRequestBase';
import { OpenDialogRequest } from '../../dropdown-requests/OpenDialogRequest';
import { IDropdown } from '../dropdown-dialog-model/dropdown-dialog.model';
 
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { CloseDialogRequest } from '../../dropdown-requests/CloseDialogRequest';

/**
 * Service for handling the opening and closing of the drop down dialog
 */
@Injectable()
export class DropdownDialogService implements OnDestroy {

    /**
     * The observer to listen on dialog actions.
     * @type {Subject}
     */
    public dropdownSubject: BehaviorSubject<DialogRequestBase> = new BehaviorSubject<DialogRequestBase>(null);

    private _resizeListener: Function;
    private _renderer: Renderer2 = null;
    private _doc: Document;

    constructor() {
        this._doc = document;
        window.addEventListener('resize', this._windowSizeChanged.bind(this));
    }

    /**
     * Used to open the drop down dialog with a specified component loaded.
     * @param dropdown
     * @param event
     * @returns {Observable<DialogRequestBase>}
     */
    public open(dropdown: IDropdown, event: MouseEvent): Observable<DialogRequestBase> {
        const dropdownRequest = new OpenDialogRequest();
        dropdownRequest.dropdown = dropdown;
        dropdownRequest.event = event;

        this.dropdownSubject.next(dropdownRequest);
        return this.dropdownSubject.asObservable();
    }

    public close(): void {
        this.dropdownSubject.next(new CloseDialogRequest);
    }

    public ngOnDestroy(): void {
        window.removeEventListener('resize', this._windowSizeChanged.bind(this));
    }

    private _windowSizeChanged(): void {
        var dropdownElement: HTMLElement = <HTMLElement>this._doc.querySelector('.dropdown-container');
        if (dropdownElement && dropdownElement.getBoundingClientRect().top < 0) {
            // right now a margin of 30px top is given, designers should advise on what should happen at this point
            dropdownElement.style.bottom = '';
            dropdownElement.style.top = '30px';
        }
    }
}

