import { NgModule } from '@angular/core';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppTranslationService, TranslateLanguageLoader } from '../../services/app-translation.service';
import { SotiOneLogo } from './soti-one-logo';

@NgModule({
    imports: [

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        })
     ],

     providers: [
        AppTranslationService

    ],
    declarations: [
        SotiOneLogo
    ],
    exports: [
        SotiOneLogo
    ]
})
export class SotiOneLogoModule {}
