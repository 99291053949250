import { DatePipe } from '@angular/common';
import { Component, DoCheck, Input, OnInit, ViewChild } from '@angular/core';
import { AnnouncementNotification } from 'src/app/models/announcementLog.model';
import { BaseNotification } from 'src/app/models/base-notification.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from '../../../services/notification.service';
import { PopUp2 } from '../../../shared/controls/dialogs/pop-up/pop-up2.ctrl';
import { NotificationTypeEnum } from 'src/app/shared/enums/notification-type.enum';


@Component({
  selector: 'app-simple-announcement',
  templateUrl: './simple-announcement.component.html',
  styleUrls: ['./simple-announcement.component.scss'],
})
export class SimpleAnnouncementComponent implements OnInit, DoCheck {

  @Input('notification')
  public notification: BaseNotification;
  private announcementLogNotification: AnnouncementNotification;

  public icon: string;
  public showIcon: boolean = true;
  public title: string;
  public message: string;
  public date: string;
  public notificationIdsToDelete: string[] = [];
  public downloadReportName: string;
  public unReadNotification: boolean = false;
  public supportUrl: string;
  public viewUpdateUrl: string;
  public supportMessage: string;
  public more:string;
  public less:string;
  public isReadMore:boolean = true;
  public label:string;



  constructor(private translationService: AppTranslationService, 
      private notificationService: NotificationService,
              private authService: AuthService,
              private datePipe: DatePipe) {
  }
  ngDoCheck(): void {
    this.unReadNotification = !this.notification.read
}
  
  @ViewChild('popup')
  public attributesPopup: PopUp2;   

  public ngOnInit(): void {
      this.setNotificationTitle();
      this.setDate();
    
      this.announcementLogNotification = (<AnnouncementNotification>this.notification)
      this.setNotificationMessage();
      if(!this.notification.read){
          this.unReadNotification = true;
      }
      this.label = this.translationService.getTranslation('lbl_more');
      this.icon = 'identity-icon-maintenance-announcement';
      
  }   

 

  /**
   *Action use to deleteAnnouncement.
   */
  public deleteAnnouncement() : void {
      this.notificationIdsToDelete = [];
      this.notificationIdsToDelete.push(this.notification.id);
      this.notificationService.deleteNotifications(this.authService.currentUser.ReferenceId, this.notificationIdsToDelete, NotificationTypeEnum.Announcement);
      this.attributesPopup.close();
  }
  
  public markAsReadNotification(){
      this.unReadNotification = false;           
      this.notificationService.updateNotificationReadStatus(this.authService.currentUser.ReferenceId, [this.notification.id], NotificationTypeEnum.Announcement);  
      this.attributesPopup.close();
  }
  
  public setViewUpdate(){
    this.viewUpdateUrl = this.announcementLogNotification.ViewUpdateUrl;
  }

  
  private setDate(): void {
      if (this.notification.createdOn) {
      let difference:number = (new Date()).getTime() - this.notification.createdOn.getTime();
      if (difference / 86400000 > 1) {
          this.date =this.datePipe.transform(this.notification.createdOn.toString(),'medium') ;
      } else if ((difference % 86400000 / 3600000) > 1) {
          this.date = Math.round((difference % 86400000) / 3600000).toString() + ' ' + this.translationService.getTranslation('lblHrs') +' ' + this.translationService.getTranslation('lblAgo');
        } else {
          this.date = Math.round(((difference % 86400000) % 3600000) / 60000).toString() + ' ' + this.translationService.getTranslation('lblMins') +' ' + this.translationService.getTranslation('lblAgo');
        }
      }
  }

  private setNotificationTitle(): void {
      if (this.notification.translated) {
          this.title = this.notification.title;
      } else {
          this.title = this.translationService.getTranslation(this.notification.title);
      }
  }

  private setNotificationMessage(): void {
    this.message = this.notification.text;
    this.supportMessage = this.translationService.getTranslation('lbl_announcement_support_messgae');
    this.supportUrl = this.announcementLogNotification.SupportUrl;
    this.more = this.translationService.getTranslation('lbl_more');
    this.less = this.translationService.getTranslation('lbl_less');
    this.supportUrl = this.announcementLogNotification.SupportUrl;
  }


  showText() {
     this.isReadMore = !this.isReadMore
     this.label = this.isReadMore ? this.more : this.less;
  }
  

}
