import { TranslateService, TranslateLoader } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Rx';
import { Observable } from 'rxjs';

@Injectable()
export class AppTranslationService {

    private _languageChanged = new Subject<string>();
    readonly defaultLanguage = "en-US";

    constructor(private translate: TranslateService) {
        const storedDefaultLanguage = localStorage.getItem("defaultlanguage");
        const defaultLanguageOnChanged = storedDefaultLanguage || "en-US";
        localStorage.setItem("defaultlanguage", defaultLanguageOnChanged);
        this.changeLanguage(defaultLanguageOnChanged);
        this.setDefaultLanguage(defaultLanguageOnChanged);
    }

    getCurrentLanguage(): string {
        return localStorage.getItem("defaultlanguage") || this.getDefaultLanguage();
    }

    addLanguages(lang: string[]) {
        this.translate.addLangs(lang);
    }


    setDefaultLanguage(lang: string) {
        this.translate.setDefaultLang(lang);
    }

    getDefaultLanguage() {
        return this.translate.defaultLang;
    }

    getBrowserLanguage() {
        return this.translate.getBrowserLang();
    }


    useBrowserLanguage(): string | void {
        const browserLang = this.getBrowserLanguage();

        if (browserLang.match(/en|fr|de|ar|ko|pt|ja-JP/)) {
            this.changeLanguage(browserLang);
            return browserLang;
        }
    }

    changeLanguage(language: string = "en-US") {

        if (!language) {
            language = this.translate.defaultLang;
        }

        if (language !== this.translate.currentLang) {
            setTimeout(() => {
                this.translate.use(language);
                this._languageChanged.next(language);
                localStorage.setItem("defaultlanguage", language);
            });
        }

        return language;
    }


    getTranslation(key: string | Array<string>, interpolateParams?: Object): string | any {

        return this.translate.instant(key, interpolateParams);
    }


    getTranslationAsync(key: string | Array<string>, interpolateParams?: Object): Observable<string | any> {

        return this.translate.get(key, interpolateParams);
    }


    languageChangedEvent() {
        return this._languageChanged.asObservable();
    }

}


export class TranslateLanguageLoader implements TranslateLoader {

    /**
     * Gets the translations from webpack
     * @param lang
     * @returns {any}
     */
    getTranslation(lang: string): any {

        switch (lang) {
            case "en-US":
                return Observable.of(require("../assets/locale/en.json"));
            case "ja-JP":
                return Observable.of(require("../assets/locale/ja-JP.json"));
            default:
        }
    }
}
