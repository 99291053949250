import {NgModule, ModuleWithProviders} from '@angular/core';
import {CoreCommonModule} from '../../core-common.module';
import {TooltipService} from './tooltip.service';
import {Tooltip} from './tooltip.ctrl';
import {TooltipContent} from './tooltip-content/tooltip-content.component';
import { SharedLoadingDirectiveModule } from '../../directives/loading/shared-loading.module';

@NgModule({
    imports: [
        CoreCommonModule,
        SharedLoadingDirectiveModule
    ],
    declarations: [
        Tooltip,
        TooltipContent
    ],
    exports: [
        Tooltip,
        TooltipContent
    ]
})
export class SharedTooltipModule {
    public static forRoot(): ModuleWithProviders<SharedTooltipModule> {
        return <ModuleWithProviders<SharedTooltipModule>>{
            ngModule: SharedTooltipModule,
            providers: [
                TooltipService
            ]
        };
    }
}