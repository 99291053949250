import { Permissions } from './../../enums/Permissions';
import { AuthService } from './../../../services/auth.service';

import { ViewContainerRef, Input, Directive, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';



@Directive({
  selector: '[checkAccess]',
  exportAs: 'checkAccess'

})
export class HasPermissionDirective {
  // the permission the user must have 
  @Input() public checkAccess: Permissions[];

  stop$ = new Subject();

  private isVisible:boolean = false;

  /**
   * @param {ViewContainerRef} viewContainerRef 
   * 	-- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef 
   *   -- the templateRef to be potentially rendered
   */
  constructor(private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef, private authService: AuthService) {
     

  }

  public ngOnInit():void {
    //  We subscribe to the roles$ to know the roles the user has
    let userPermission = this.authService.currentUser.UserPermissions;
    //     // If he doesn't have any roles, we clear the viewContainerRef
    if (!userPermission) {
      this.viewContainerRef.clear();
    }
    // If the user has the role needed to 
    // render this component we can add it
    if (this.checkAccess != undefined && this.checkAccess != null && this.checkAccess.length > 0) {
      let requiredPermissions = this.checkAccess;
      if (userPermission && userPermission.filter(perm => requiredPermissions.find(f => f === perm)).length > 0) {
        // If it is already visible (which can happen if
        // his roles changed) we do not need to add it a second time
        if (!this.isVisible) {
          // We update the `isVisible` property and add the 
          // templateRef to the view using the 
          // 'createEmbeddedView' method of the viewContainerRef
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {
        // If the user does not have the role, 
        // we update the `isVisible` property and clear
        // the contents of the viewContainerRef
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    }
    else {
      this.isVisible = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }

  }

  // Clear the subscription on destroy
  public ngOnDestroy():void {
    // this.stop$.next();
  }

  
}
