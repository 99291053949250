import {Directive, HostListener, ElementRef, Input} from '@angular/core';
import { KeyCodeEnum } from '../../enums/KeyCodeEnum';
@Directive({
    selector: '[trap-focus]'
})
export class TrapFocusDirective {
    @Input() trapFocus: boolean;

    constructor(private _el:ElementRef) {}

    @HostListener('keydown', ['$event'])
    private _onKeydown($event: KeyboardEvent) {

        // TAB
        if ($event.keyCode == KeyCodeEnum.TAB && this.trapFocus == true) {
            $event.preventDefault();
            const tabbableNodes = this._getTabbableNodes();
            const currentFocusIndex = tabbableNodes.indexOf($event.target);
            const firstTabbableNode = tabbableNodes[0];
            const lastTabbableNode = tabbableNodes[tabbableNodes.length - 1];

            if ($event.shiftKey) {
                if ($event.target === firstTabbableNode) {
                    this._tryFocus(lastTabbableNode);
                    return;
                }
                this._tryFocus(tabbableNodes[currentFocusIndex - 1]);
                return;
            }
            if ($event.target === lastTabbableNode) {
                this._tryFocus(firstTabbableNode);
                return;
            }
            this._tryFocus(tabbableNodes[currentFocusIndex + 1]);
        }
    }

    private _getTabbableNodes() {
        var basicTabbables = [];
        var orderedTabbables = [];

        var candidateNodelist = this._el.nativeElement.querySelectorAll('input, select, a[href], textarea, button, [tabindex]');
        var candidates = Array.prototype.slice.call(candidateNodelist);

        var candidate, candidateIndex;
        for (var i = 0, l = candidates.length; i < l; i++) {
            candidate = candidates[i];
            candidateIndex = candidate.tabIndex;

            if (
                candidateIndex < 0
                || (candidate.tagName === 'INPUT' && candidate.type === 'hidden')
                || candidate.disabled
            ) {
                continue;
            }

            if (candidateIndex === 0) {
                basicTabbables.push(candidate);
            } else {
                orderedTabbables.push({
                    tabIndex: candidateIndex,
                    node: candidate,
                });
            }
        }

        var tabbableNodes = orderedTabbables
            .sort((a, b) => {
                return a.tabIndex - b.tabIndex;
            })
            .map((a) => {
                return a.node;
            });

        Array.prototype.push.apply(tabbableNodes, basicTabbables);

        return tabbableNodes;
    }

    private _tryFocus(node) {
        if (!node || !node.focus) {
            return;
        }
        node.focus();
        if (node.tagName.toLowerCase() === 'input') {
            node.select();
        }
    }
}