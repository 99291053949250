import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { DisposalBag } from 'src/app/shared/utils/DisposalBag';
import { ActionContext } from '../../../action-bar-panel/model/ActionContext';
import { ActionsListService } from '../../../action-bar-panel/model/ActionsListService';
import { TargetsDescription } from '../../../action-bar-panel/model/TargetsDescription';
import { IContextualActionBarItem } from '../../../action-bars/contextual-action-bar/model/IContextualActionBarItem';
import { PopUp2 } from '../../pop-up/pop-up2.ctrl';

@Component({
    selector: 'modal-actions',
    templateUrl: './modal-actions.ctrl.html',
    styleUrls: ['./modal-actions.ctrl.scss']
})
export class ModalActionsControl implements OnInit, OnDestroy {
    public targetsDescription: TargetsDescription;
    public descriptionMaxWidth: number = null;

    public primaryActions: IContextualActionBarItem<ActionContext>[] = [];
    public menuActions: IContextualActionBarItem<ActionContext>[] = [];

    @ViewChild('popup')
    public menuActionsPopUp: PopUp2;
    @Input() disabled: boolean = false;

    public menuOpen: boolean = false;

    private _disposalBag: DisposalBag = new DisposalBag();
    ngOnDestroy(): void {
        this._disposalBag.dispose();
        this.actionListService.dispose();
    }

    constructor(
        private actionListService: ActionsListService
    ) { }

    public ngOnInit(): void {
        if (!this.actionListService) {
            return;
        }
        this._disposalBag.nextSub = this.actionListService.actionsList.subscribe((actions: any) => {
            let modalActions = actions.filter(x => x.showOnModalAction);
            
            if (modalActions.length > 4) {
                this.primaryActions = modalActions.slice(0, 3);
                this.menuActions = modalActions.slice(3, modalActions.length);
            } else {
                this.primaryActions = modalActions;
            }
        });
        this.actionListService.init();
    }

    public onActionClicked(item: any) {
        this.actionListService.executeAction(item.data);
    }

    public popupstatusChange(status: boolean) {
        this.menuOpen = status
    }

    public close(): void {
        this._disposalBag.dispose();
        this.actionListService.dispose();
    }
}