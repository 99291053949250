import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild,
    OnInit,
} from '@angular/core';

import { DisposalBag } from '../../utils/DisposalBag';
import { KeyCodeEnum } from '../../enums/KeyCodeEnum';
import { fromEvent } from 'rxjs';

@Component({
    selector: 'soti-inline-search',
    templateUrl: './inline-search.ctrl.html',
    styleUrls: ['./inline-search.ctrl.scss']
})
export class InlineSearchControl implements AfterViewInit, OnDestroy, OnInit {
    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public keyEvent: EventEmitter<KeyCodeEnum> = new EventEmitter<KeyCodeEnum>();

    @Input('value') public set newValue(value: string) {
        this.text = value;
    }

    @Input()
    public placeholder: string;

    @Input()
    public searchTabindex: number = 0;

    @Input('auto-focus')
    public autoFocus: boolean = true;
    public text: string;
    public previousText: string;

    @Input()
    public debouncingTime: number = 100;

    @ViewChild('searchField')
    private _searchInput: ElementRef;

    @ViewChild('clearBtn')
    private _clearnBtn: ElementRef;

    private _disposalBag: DisposalBag = new DisposalBag();

    constructor(private _elementRef: ElementRef, private _rendered: Renderer2) {
        this.text = '';

        this._disposalBag.nextSub = fromEvent(_elementRef.nativeElement, 'keydown')
            .map((event: KeyboardEvent) => {
                if (event.keyCode == KeyCodeEnum.UP_ARROW || event.keyCode == KeyCodeEnum.DOWN_ARROW || event.keyCode == KeyCodeEnum.ENTER) {
                    event.preventDefault();
                }
                return event;
            })
            .subscribe((event: KeyboardEvent) => this.checkKeyAndEmit(event));


    }

    public checkKeyAndEmit(event: KeyboardEvent): void {
        if (event.keyCode == KeyCodeEnum.TAB && !event.shiftKey) {
            if (document.activeElement != this._clearnBtn.nativeElement) {
                if (this.text.length > 0) {
                    this._clearnBtn.nativeElement.focus();
                    event.preventDefault();
                    event.stopPropagation();
                }
            }
        }
        else {
            //when clear btn is pressed modal should not close
            if (event.target == this._clearnBtn.nativeElement &&
                (event.keyCode == KeyCodeEnum.ENTER || event.keyCode == KeyCodeEnum.SPACE)) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
        this.keyEvent.emit(event.keyCode)
    }

    ngOnInit(): void {
        this._disposalBag.nextSub = fromEvent(this._elementRef.nativeElement, 'keyup')
            .debounceTime(this.debouncingTime)
            .map(() => this.text)
            .subscribe(term => {
                term = term.toString().trim();
                if (this.previousText && this.previousText === this.text || (!this.previousText && !this.text)) {
                    return; //avoid emitting on different keystrokes
                }
                this.previousText = this.text;
                this.valueChange.emit(term);
            });
    }

    public ngAfterViewInit(): void {

    }

    public ngOnDestroy(): void {
        this._disposalBag.dispose();
    }

    public setValue(value: string): void {
        this.text = value;
    }

    public removePreviousValue(): void{
        this.previousText = '';
    }

    public onClear(element: HTMLElement): void {
        this.text = '';
        this.previousText = '';
        this.valueChange.emit('');
        element.focus();
    }
}