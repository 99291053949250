import { NgModule } from '@angular/core';
import { SharedInputIconModule } from '../../shared/controls/input-icon';
import { HomeComponent } from './home.component';
import { ModalDialog2Module, ModalDialogModule } from '../../shared/controls/dialogs';
import { StatusNotificationBarModule } from '../../shared/controls/toaster-messages/shared-status-notification-bar.module';
import { DashboardModule } from '../Dashboard/dashboard.module';
import { CopyrightModule } from '../copyright/copyright.module';

@NgModule({
    imports: [

        SharedInputIconModule,
        ModalDialogModule,
        ModalDialog2Module,
        StatusNotificationBarModule,
        DashboardModule,
        CopyrightModule
        
    ],
    declarations: [
        HomeComponent
    ],
    exports: [
        HomeComponent
    ],
    providers: [
        HomeComponent
    ]
})
export class HomeComponentModule {}