import { Permissions } from 'src/app/shared/enums/Permissions';
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";


import { ConnectionPositionPair, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AuthService } from '../../services/auth.service';
import { DisposalBag } from "../utils/DisposalBag";
import { HeaderService } from "./header.service";
import { ViewLocationEnum } from "../enums/ViewLocationEnum";
import { UserTypeEnum } from '../enums/UserTypeEnum';
import { User } from 'src/app/models/user.model';
import { Constants } from 'src/app/constants';
import { SotiThemeService } from 'src/app/services/soti-theme/soti-theme.service';
import { BrowserUtilsService } from 'src/app/services/browserUtil/browserUtils.service';
import { TenantLogo } from 'src/app/models/tenantLogoImages.model';
import { Utilities } from 'src/app/services/utilities';

@Component({
    selector: 'im-header',
    templateUrl: './header.view.html',
    styleUrls: ['./header.view.scss']
})

export class HeaderView implements OnInit, OnDestroy {
    public userType: UserTypeEnum;
    public showMenu: boolean = false;
    public showPopUp: boolean = false;
    public userName: string = '';
    public userInitials: string = '';
    public currentPage: string;
    public showCurrentLocation: boolean = false;
    public overlayPositions: ConnectionPositionPair[] = [
        {
            offsetX: undefined,
            offsetY: undefined,
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
        }
    ];
    public navigationMenuPermission: Permissions[] ;
    public showPopUpAfterBack: boolean = false;
    public isMobileView: boolean;
    @ViewChild(CdkConnectedOverlay)
    private _connectedOverlay: CdkConnectedOverlay;
    private _disposalBag: DisposalBag = new DisposalBag();
    private _headerLabelPrefix: string = 'lbl_header_';
    public newViews: string = "";
    public appTitle: string = "";
    public brandingLogoSet = false;
    public getImagePath: string;
    public selectedDashboardLogoReferenceId: string;
    public tenantDashboardLogoImages: TenantLogo[];
    public selectedTenantDashboardLogoImagesUrl: string;
    public isLogoLoaded: boolean = false;
    public selectedTenantDashboardLogoImages: TenantLogo;

    constructor(private authService: AuthService,
        private _headerService: HeaderService,
        private _sotiThemeService: SotiThemeService,
        private _browserUtilService: BrowserUtilsService

    ) {

        this._headerService.currentLocation.subscribe(val => {
            if (val === ViewLocationEnum.Dashboard) {
                this.currentPage = '';
                this.showCurrentLocation = false;
            }
            else {
                this.currentPage = `${this._headerLabelPrefix}${val}`;  
                this.showCurrentLocation = true;
            }
        });
        this._sotiThemeService._isSotiTheme = false;
        this.isMobileView = this._browserUtilService.isMobileOrTablet();
    }
    
    ngOnDestroy(): void {
        this._disposalBag.dispose();
    }

    public ngOnInit(): void {
        this.getImagePath = Utilities.getAssestPath();
        this.getNavigationPermission();
        if (this.authService.currentUser !== undefined || this.authService.currentUser !== null) {
            let currentUser: User = this.authService.currentUser;
            currentUser.LastName = (currentUser.LastName === null || currentUser.LastName === undefined) ? '' : currentUser.LastName;
            this.userInitials = currentUser.FirstName.charAt(0).toLocaleUpperCase() + currentUser.LastName.charAt(0).toLocaleUpperCase()
            if (currentUser.FirstName !== undefined && currentUser.FirstName.length > 0) {
                this.userName = currentUser.FirstName + ' ' + currentUser.LastName.charAt(0).toLocaleUpperCase();
            }
            this.userType = currentUser.UserType;            
        }
    }

    public onToggleMenu($event?: MouseEvent | TouchEvent): void {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }

        this.showPopUp = !this.showPopUp;

        if (this.showPopUp) {
            this._disposalBag.nextSub = this._connectedOverlay.backdropClick.subscribe(() => {
                this.onToggleMenu();
            });
        } else {
            this._disposalBag.dispose();
        }

    }

    public onToggleMenuClose(): void {
        setTimeout( () => {
            this.showPopUp = false;
        }, 100);
    }
    private getNavigationPermission(): void {
        this.navigationMenuPermission = Constants.navigationPermissions;
    }
    
}
