export class Maybe<T> {
    private readonly _item: T[];

    public static instance<T>(item?: T): Maybe<T> {
        if (arguments.length == 0 || item === undefined) {
            return new Maybe<T>(false);
        } else {
            return new Maybe<T>(true, item);
        }
    }

    protected constructor(private _wasAny: boolean, item?: T) {
        this._item = [];
        if (_wasAny) {
            this._item.push(item);
        }
        
    }

    public get iterator(): IterableIterator<T> {
        return this._item[Symbol.iterator]();
    }
}