// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'GOTHAMROUNDEDBOOK';
    src: 
         url('GOTHAMROUNDEDBOOK.woff2') format('woff2'),
         url('GOTHAMROUNDEDBOOK.woff') format('woff');
}
@font-face {
    font-family: 'GOTHAMROUNDEDMEDIUM';
    src: url('GOTHAMROUNDEDMEDIUM.woff2') format('woff2'),
         url('GOTHAMROUNDEDMEDIUM.woff') format('woff');
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/styles/fonts/GothamRounded/gotham_rounded.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC;;qDAEuD;AAC3D;AACA;IACI,kCAAkC;IAClC;uDACyD;AAC7D","sourcesContent":["@font-face {\r\n    font-family: 'GOTHAMROUNDEDBOOK';\r\n    src: \r\n         url('fonts/GOTHAMROUNDEDBOOK.woff2') format('woff2'),\r\n         url('fonts/GOTHAMROUNDEDBOOK.woff') format('woff');\r\n}\r\n@font-face {\r\n    font-family: 'GOTHAMROUNDEDMEDIUM';\r\n    src: url('fonts/GOTHAMROUNDEDMEDIUM.woff2') format('woff2'),\r\n         url('fonts/GOTHAMROUNDEDMEDIUM.woff') format('woff');\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
