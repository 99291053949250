export class AzureADParams {
    
    constructor() {
        this.AzureADName = null;
        this.EventType = 0;
        this.SelectedDomainIds = [];
        this.SelectedDomainReferenceIds = [];

    }
    public AzureADName:string;

    public EventType: number;

    public SelectedDomainIds: number[];

    public SelectedDomainReferenceIds: string[];

}