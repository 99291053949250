import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from 'rxjs/Observable';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ErrorCode } from '../shared/enums/ErrorCode';
import { Constants } from '../constants';
import { AppTranslationService } from './app-translation.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private authService: AuthService, private translationService: AppTranslationService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const lang = this.translationService.getCurrentLanguage();
        const authReq = req.clone({
            headers: req.headers
                .set(Constants.userReferenceHeader, this.authService.accessToken ? this.authService.accessToken : this.authService.tempAccessToken)
                .set('Accept-Language', lang)
        });
        return next.handle(authReq).pipe(catchError((err: HttpErrorResponse): Observable<any> => {
            switch (err.status) {
                case 401:
                    this.authService.ssoLogout();
                    break;
                case 503:
                    this.authService.underMaintenance.next(true);
                    break;
                default:
                    {
                        if (err.error && err.error.ErrorCode === ErrorCode.TokenExpired ) {
                            this.authService.ssoLogout();
                        }
                        throwError(err);
                    }
            }
            return throwError(err);
        }));
    }
}
