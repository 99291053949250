import { SharedDialogsModule } from './controls/dialogs/shared-dialogs.module';
 
import { DropdownDialog } from './controls/dialogs/dropdown/dropdown-dialog/dropdown-dialog-component/dropdown-dialog.component';
import { ModalDialogService } from './controls/dialogs/modal-dialog/modal-dialog-service/modal-dialog.service';
import { DropdownDialogService } from './controls/dialogs/dropdown/dropdown-dialog/dropdown-dialog-service/dropdown-dialog.service';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {HeaderView} from './header/header.view';
import { RouterModule } from '@angular/router';
import { CdkTableModule } from '@angular/cdk/table';
import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { OverlayModule } from '@angular/cdk/overlay';
import { ObserversModule } from '@angular/cdk/observers';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { PlatformModule } from '@angular/cdk/platform';

import { SotiIdpButton } from './controls/Buttons/soti-idp-button.component';
import { PopUpModule } from './controls/dialogs/pop-up/pop-up.module';
import { StatusNotificationBarModule } from './controls/toaster-messages/shared-status-notification-bar.module';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateLanguageLoader } from '../services/app-translation.service';
import { CoreCommonModule } from './core-common.module';
import { DROPDOWN_DIRECTIVES } from './controls/dialogs/dropdown/dropdown-component';
import { SharedSelectBoxModule } from './soti-node-modules/select-box/shared-select-box.module';
import { PageLoadingControl } from './controls/page-loading/page-loading.ctrl';
import { SoHeaderView } from './header/so-header/so-header.view';
import { ProfileMenuView } from './header/profile-menu/profile-menu.view';
import { SharedTooltipModule } from './controls/tooltip';
import { NotificationModule } from '../components/Notifications/notification.module';
import { SharedInlineSearchModule } from './controls/inline-search/shared-inline-search.module';
import { NavigationMenuModule } from './header/navigation-menu/navigation-menu.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreCommonModule,
        RouterModule,
        CdkTableModule,
        A11yModule,
        BidiModule,
        StatusNotificationBarModule,
        OverlayModule,
        PlatformModule,
        ObserversModule,
        PortalModule,
        ScrollingModule,
        CdkStepperModule,
        PlatformModule,
        SharedDialogsModule,
        PopUpModule,
        SharedTooltipModule,
        TranslateModule.forRoot({

            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        NotificationModule,
        SharedInlineSearchModule,
        NavigationMenuModule
    ],
    declarations: [
        SotiIdpButton,
        HeaderView,
        PageLoadingControl,
        
       
       
        DropdownDialog,
        
        DROPDOWN_DIRECTIVES,
        
        SoHeaderView,
        ProfileMenuView
    ],
    providers: [DropdownDialogService, ModalDialogService],
    exports: [
        HeaderView,
        PageLoadingControl,
       SotiIdpButton,
        PopUpModule,
        SharedSelectBoxModule,
        
        DROPDOWN_DIRECTIVES,
        DropdownDialog,
      
        SharedDialogsModule,
        
        SoHeaderView,
        ProfileMenuView
    ]
})

export class SharedModule {
}
