/**
 * Created by dsingh on 2017-01-09.
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { TooltipModel } from './tooltip-model';

@Injectable()
export class TooltipService {

    private _tooltipData: Subject<TooltipData> = new Subject<TooltipData>();

    constructor() { }

    public ngOnDestroy(): void {
        this._tooltipData.complete();
    }

    public get observable(): Observable<TooltipData> {
        return this._tooltipData;
    }

    public show(data: TooltipModel, target: HTMLElement): void {
        this._tooltipData.next(new TooltipData('show', data, target));
    }

    public hide(): void {
        this._tooltipData.next(new TooltipData('hide', null, null));
    }
}

export class TooltipData {
    constructor(
        public action: string,
        public model: TooltipModel,
        public target: HTMLElement
    ) {
    }
}