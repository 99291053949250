import { Pipe, PipeTransform } from '@angular/core';
import { AppTranslationService } from '../services/app-translation.service';
@Pipe({
    name: 'filterbyproperty',
    pure: false
})
export class FilterPipe implements PipeTransform {
    constructor(private translate : AppTranslationService) {
        
    }

    transform(items: any[], filter: string, filterParam: string): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out       
        return items.filter(item => this.translate.getTranslation(item[filterParam]).toLowerCase().includes(filter.toLowerCase()) 
                || item.Id < 0);
    }
}