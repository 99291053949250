import {ModalControl} from './modal/modal.ctrl';
import {ModalBodyControl} from './modal-body/modal-body.ctrl';
import {ModalFooterControl} from './modal-footer/modal-footer.ctrl';
import {ModalSuffixControl} from './modal-suffix/modal-suffix.ctrl';
import { ModalHeaderComponent } from './modal-header/modal-header.ctrl';
import { ModalActionsControl } from './modal-actions/modal-actions.ctrl';

export { ModalBase } from './modal';

export const MODAL_DIRECTIVES = [
    ModalControl,
    ModalBodyControl,
    ModalFooterControl,
    ModalSuffixControl,
    ModalHeaderComponent,
    ModalActionsControl
];

