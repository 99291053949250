import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FileEndpoint } from 'src/app/services/file-service/file-endpoint.service';
import { FileService } from 'src/app/services/file-service/file.service';
import { SharedInfoMessageBarModule } from 'src/app/shared/controls/info-message-bar';
import { NoContentModule } from 'src/app/shared/no-content/no-content.module';
import { ListGridBtnSwitcherModule } from 'src/app/shared/controls/listgrid-btn-switcher/listgrid-btn-switcher.module';
import { SharedTabSwitcherModule, TabSwitcher } from 'src/app/shared/controls/tab-switcher';
import { SharedModule } from 'src/app/shared/shared.module';
import { SotiOneLogoModule } from 'src/app/shared/soti-one-logo/soti-one-logo.module';
import { AppErrorHandler } from '../../app-error.handler';
import { getBaseUrl } from '../../app.module';
import { AccountEndpoint } from '../../services/account-services/account-endpoint.service';
import { AccountService } from '../../services/account-services/account.service';
import { AppTranslationService, TranslateLanguageLoader } from '../../services/app-translation.service';
import { ApplicationEndpoint } from '../../services/application-services/application-endpoint.service';
import { ApplicationService } from '../../services/application-services/application.service';
import { UserEndpoint } from '../../services/user-services/user-endpoint.service';
import { UserService } from '../../services/user-services/user.service';
import { SuccessmsgModule } from '../../shared/controls/success-msg/success-msg.module';
import { SharedLoadingDirectiveModule } from '../../shared/directives/loading/shared-loading.module';
import { ApplicationRedirect } from '../Application/ApplicationRedirect/app-redirect.ctrl';
import { DashboardApplicationComponent } from '../Application/dashboard-application.component';
import { AppFormSubmit } from '../Application/RedirectForm/appFormSubmit.ctrl';
import { CopyrightModule } from '../copyright/copyright.module';
import { DashboardComponent } from './dashboard.view';
import { DashboardApplicationModule } from '../Application/dashboard-application.module';
import { UmbracoService } from 'src/app/services/umbraco-services/umbraco.service';
import { UmbracoEndpoint } from 'src/app/services/umbraco-services/umbraco-endpoint.service';
@NgModule({

    imports: [
        BrowserModule,
        NoContentModule,
        FormsModule,
        SuccessmsgModule,
        SharedLoadingDirectiveModule,
        SharedInfoMessageBarModule,
        CopyrightModule,
        SotiOneLogoModule,
        SharedTabSwitcherModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        ListGridBtnSwitcherModule,
        DashboardApplicationModule,
        SharedModule
     ],
    declarations:
     [
        DashboardComponent,
        AppFormSubmit,
        ApplicationRedirect
    ],
    exports: [
        DashboardComponent,
        AppFormSubmit,
        ApplicationRedirect
    ],
     providers: [
        { provide: 'BASE_URL', useFactory: getBaseUrl },
        { provide: ErrorHandler, useClass: AppErrorHandler },

        AppTranslationService,
        AccountService,
        AccountEndpoint,
        UserService,
        UserEndpoint,
        FileEndpoint,
        FileService,
        ApplicationService,
        ApplicationEndpoint,
        UmbracoEndpoint,
        UmbracoService
    ],
})
export class DashboardModule {
}
