import { Component, Host, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'pop-up-footer',
    templateUrl: './pop-up-footer.ctrl.html',
    styleUrls: ['./pop-up-footer.ctrl.scss']
})
export class PopUpFooterControl implements OnInit {
    @Input('single-item')
    @HostBinding('class.single')
    /**
     * Used to determine if the content to be to the right
     */
    public single: boolean = false;

    constructor() {
    }

    public ngOnInit(): void {
    }
}