import { NgModule } from '@angular/core';
import { SharedInputIconModule } from '../../shared/controls/input-icon';
import { ModalDialog2Module, ModalDialogModule } from '../../shared/controls/dialogs';
import { ChangePasswordModal } from './Modals/change-password-modal/change-password-modal.ctrl';
import { ChangePasswordComponent } from './change-password/component/change-password.ctrl';
import { RegisterPasskeysComponent } from './PasskeysManagement/RegisterPasskey/register-passkey.ctrl';
import { FormsModule }   from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { WarningDialogBoxModule } from 'src/app/shared/controls/im-warning-dialog/im-warning-dialog.module';
import { TranslateLanguageLoader, AppTranslationService } from 'src/app/services/app-translation.service';
import { SharedFormPanelModule } from 'src/app/shared/controls/form-panel/shared-form-panel.module';
import { SharedLoadingDirectiveModule } from 'src/app/shared/directives/loading/shared-loading.module';
import { StatusNotificationBarModule } from 'src/app/shared/controls/toaster-messages/shared-status-notification-bar.module';
import { SharedDialogsModule } from 'src/app/shared/controls/dialogs';
import { SharedTooltipModule } from 'src/app/shared/controls/tooltip/shared-tooltip.module';
import { TooltipService } from 'src/app/shared/controls/tooltip/tooltip.service';
import { SharedInfoMessageBarModule } from 'src/app/shared/controls/info-message-bar';
import { AnnounceService } from '../../services/announce.service';
import { CheckPasswordService } from 'src/app/shared/services/check-password/check-password.service';
import { SharedAccordionModule } from 'src/app/shared/controls/accordion/shared-accordion.module';
import { AuthenticationComponent } from './Authentication/authentication.ctrl';
import { SharedTabSwitcherModule } from 'src/app/shared/controls/tab-switcher/shared-tab-switcher.module';
import { ConfirmationDialogModalModule } from 'src/app/shared/internal-controls/confirmation-dialog/confirmation-dialog.module';
import { EditPasskeyModal } from './PasskeysManagement/RegisterPasskey/Modals/edit-passkey/edit-passkey-modal.ctrl';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedInputIconModule,
        ModalDialogModule,
        ModalDialog2Module,
        SharedFormPanelModule,
        StatusNotificationBarModule,
        SharedLoadingDirectiveModule,
        SharedDialogsModule,
        SharedTooltipModule,
        SharedAccordionModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        SharedInfoMessageBarModule,
        SharedTabSwitcherModule,
        WarningDialogBoxModule,
        ConfirmationDialogModalModule
    ],
    declarations: [
        ChangePasswordModal,
        ChangePasswordComponent,
        RegisterPasskeysComponent,
        EditPasskeyModal,
        AuthenticationComponent
    ],
    exports: [
        ChangePasswordComponent,
        RegisterPasskeysComponent,
        AuthenticationComponent
    ],
    providers: [
        AppTranslationService,
        TooltipService,
        AnnounceService,
        CheckPasswordService
    ]
})
export class PasswordManagementModule {}