import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AppTranslationService } from '../../../services/app-translation.service';
import { KeyCodeEnum } from '../../enums/KeyCodeEnum';

export interface FileUploadEvent {
    file: File;
    reason?: FileCheckType;
}

export interface FileMetaData {
    FileName: string;
    FileType: string;
    Content: string;
    IsNew: boolean;
    IsDeleted: boolean;
    isError: boolean;
    reason?: string;
}

export enum FileCheckType {
    MaxFileSize,
    MinFileSize,
    FileType,
    FileCorrupt,
    FileResolution
}

@Component({
    selector: 'soti-file-upload',
    templateUrl: './file-upload.ctrl.html',
    styleUrls: ['./file-upload.ctrl.scss']
})

//Usecase : Used for freshly uploading a new file.
//Caution : Does not support feature to show already uploaded file(In such a situation use FileUploader Controller)
export class FileUploadControl implements AfterViewInit, OnInit, OnChanges {
    @ViewChild('imageFile')
    public set content(imageFile: ElementRef) {
        this._imageFile = imageFile;
    }
    @ViewChild('fileInput', {static: false})
    public fileInput: ElementRef;

    @ViewChild('SotiUploadComponent', {static: true})
    public SotiUploadComponent: ElementRef;

    @ViewChild('sotiUploadComponenthiddenInput', {static: true})
    public sotiUploadComponenthiddenInput: ElementRef;

    public SotiUploadKeydownEvent($event){
        if(this.isFocused(this.SotiUploadComponent.nativeElement as HTMLElement)){
            switch($event.keyCode){
                case KeyCodeEnum.ENTER:
                case KeyCodeEnum.SPACE:
                    this.fileInput.nativeElement.click();
                    break;
            }
        }
    }
    public isFocused(el: HTMLElement){
        return (document.activeElement === el);
    }

    @Input('ariaLabelledby')
    public ariaLabelledby: string = '';
    @Input()
    public maxFileSize: number = 0;
    @Input()
    public minFileSize: number = 0;
    @Input()
    public fileTypes: string[] = [];
    @Input()
    public isImageUpload: boolean = false;
    @Input()
    public disabled: boolean = false;
    @Input()
    public isDragAndDropStyle: boolean = false;
    @Input()
    public restrictFileSelection: boolean = false;
    @Input()
    public customDragAndDropMessage: string;
    @Input()
    public set dragAndDropStyleIcon(iconName: string) {
        this.dragAndDropIconName = !this.isDragAndDropStyle || !iconName ? '' : iconName;
    }
    @Input()
    public downloadButton: boolean = false;
    @Input()
    public uploadDescription: boolean = false;
    @Input()
    public readyToUploadState: boolean = false;
    @Input()
    public imageFile: FileMetaData = null;
    @Input()
    public readFileExtension: boolean = false;
    @Input()
    public iconRequired: boolean = false;
    @Input()
    public refreshFile: boolean = false;
    @Input()
    public showFileNameForDeleteMessage: boolean = true;
    @Input()
    public showLoader: boolean = false;
    @Input()
    public uploadButtonText: string ;
    @Input()
    public isShowuploadButtonText: boolean = true;
    @Input()
    public displayImage: boolean = true;
    @Input()
    public imageMinimumHeight: number;
    @Input()
    public imageMinimumWidth: number;
    @Output()
    public fileDownloadEvent: EventEmitter<FileUploadEvent> = new EventEmitter<FileUploadEvent>();
    @Output()
    public fileUploadEvent: EventEmitter<FileUploadEvent> = new EventEmitter<FileUploadEvent>();
    @Output()
    public fileDeleteEvent: EventEmitter<FileUploadEvent> = new EventEmitter<FileUploadEvent>();
    @Output()
    public fileUploadCancelEvent: EventEmitter<void> = new EventEmitter<void>();
    public violated: boolean = false;
    public isfocused:boolean = false;

    @Input()
    public hasFile: boolean = false;
    public isDragOver: boolean = false;
    public confirmDelete: boolean = false;
    @Input()
    public file: File;
    public dragAndDropIconName: string;
    public fileUploadMessage: string = '';
    public _imageFile: ElementRef;
    constructor(private _liveAnnouncer: LiveAnnouncer, private _translate : AppTranslationService) {      
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(changes.showLoader && !changes.showLoader.firstChange && changes.showLoader.currentValue === false && document.activeElement == this.sotiUploadComponenthiddenInput.nativeElement){
                this.SotiUploadComponent.nativeElement.focus();
        }
    }

    @Input()
    public ngOnInit(): void {
    }

    public ngAfterViewInit(): void {
        if (!this.imageFile) {
            this.fileCheck(this.base64ToFile(this.imageFile));
        }
    }
    public fileCheck(file: File, previewMode: boolean = false): void {
        if (!file) {
            return;
        }
        let isMinFileSizeValid = this.minFileSize === 0 || file.size >= this.minFileSize;
        let isMaxFileSizeValid = this.maxFileSize === 0 || file.size <= this.maxFileSize;
        let fileType = file.type === '' || this.readFileExtension ? this._getFileExtension(file) : file.type;
        fileType = this._zipConvertion(fileType, file);
        let fileTypeLowered = this.fileTypes.toLocaleString().toLowerCase().split(',');
        let isFileTypeValid = !this.fileTypes || this.fileTypes.length === 0 || fileTypeLowered.includes(fileType.toLowerCase());
        if (isMinFileSizeValid && isMaxFileSizeValid && isFileTypeValid) {
            if (this.isImageUpload) {
                let reader: FileReader = new FileReader();
                reader.onload = ((aImg: HTMLImageElement): any => (e): void => {
                   
                     if (this.imageMinimumHeight || this.imageMinimumWidth) {
                         this._checkImageResolution(reader.result, aImg, e.target.result, previewMode,file);
                     }
                     else {
                        this._updateValidators(false, true, file, null, previewMode);
                        aImg.src = e.target.result;
                     }
                })(this._imageFile.nativeElement);
                reader.readAsDataURL(file);
            } else {
                this._updateValidators(false, true, file, null, previewMode);
            }
        } else if (!isFileTypeValid) {
            this._updateValidators(true, false, null, FileCheckType.FileType, previewMode);
        } else if (!isMaxFileSizeValid) {
            this._updateValidators(true, false, null, FileCheckType.MaxFileSize, previewMode);
        } else if (!isMinFileSizeValid) {
            this._updateValidators(true, false, null, FileCheckType.MinFileSize, previewMode);
        }
    }

    public badFile(event: Event): void {
        this._stopEventBubble(event);
        this._updateValidators(true, false, null, FileCheckType.FileCorrupt);
    }

    public fileSelected(files: FileList | Event): void {
        this.sotiUploadComponenthiddenInput.nativeElement.focus();
        if (files instanceof Event && files.currentTarget instanceof HTMLInputElement) {
            this.fileCheck(files.currentTarget.files[0]);
        } else {
            this.fileCheck(files[0]);
        }
    }

    public openFileExplorer($event: any = null): void {
        if (this.disabled) {
            return;
        }
        let input = this.fileInput.nativeElement;
        input.value = null;
        input.click();
        if($event)
            this._stopEventBubble($event);
    }

    public removeFile(event: Event): void {
        this.confirmDelete = !this.confirmDelete;
        this.fileDeleteEvent.emit()
        this._fileCleanUpOperation();
        if (!event.returnValue){
            this.sotiUploadComponenthiddenInput.nativeElement.focus();
        }
        
        let message = this._translate.getTranslation('msg_deleted');
        this._liveAnnouncer.announce(message);
    }

    public onDelete(event: Event): void {
        if (this.disabled) {
            return;
        }
        this.confirmDelete = this.refreshFile ? !this.violated : !this.confirmDelete;

        if (this.refreshFile && this.violated) {
            this._fileCleanUpOperation();
        }
    }

    public onDownload(event: Event): void {
        this.fileDownloadEvent.emit();
        this._stopEventBubble(event);
    }

    public fileDropped(event: DragEvent): void {
        if (this.disabled) {
            return;
        }

        this._removeThumbnail();
        this.isDragOver = false;
        this._stopEventBubble(event);
        this.fileSelected(event.dataTransfer.files);
    }

    public dragOver(event: Event): void {
        this.isDragOver = true;
        this._stopEventBubble(event);
    }

    public notDragOver(event: Event): void {
        this.isDragOver = false;
        this._stopEventBubble(event);
    }
    public getAcceptedFileExtensions(): string {
        if (!this.restrictFileSelection || !this.fileTypes || this.fileTypes.length === 0) {
            return null;
        }

        return this.fileTypes.join(',');
    }
    public base64ToFile(file: FileMetaData): File {
        var blob;
        if (!file || !file.Content) {
            return null;
        }
        const byteString = atob(file.Content);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        if ((window.navigator as any).msSaveOrOpenBlob) {
            blob = this._blobToFileIE(new Blob([arrayBuffer]), file.FileName);
        }
        else {
            blob = new File([arrayBuffer], file.FileName, { type: file.FileType });
        }

        return blob;
    }

    public resetFile(previewMode: boolean = false): void {
        this.confirmDelete = false;
        this._updateValidators(false, false, null, null, previewMode);
        this._removeThumbnail();
        this._stopEventBubble(event);
    }

    public fileNameUnziped(fileName: string): string {
        if (!fileName) {
            return fileName;
        }

        let extension = fileName.toLowerCase().substring(fileName.length - 4, fileName.length);

        if (extension == '.zip') {
            return fileName.toLowerCase().substring(0, (fileName.length - 4));
        } else {
            return fileName;
        }
    }

    public cancelFileUpload(): void {
      this.fileUploadCancelEvent.emit();
      this._fileCleanUpOperation();
    }

    private _stopEventBubble(event: Event): void {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    private _removeThumbnail(): void {
        if (this._imageFile) {
            this._imageFile.nativeElement.file = null;
        }
        let input = this.fileInput.nativeElement;
        if (input) {
            input.value = null;
        }
    }

    private _updateValidators(violated: boolean, hasFile: boolean, file: File, reason?: FileCheckType, previewMode: boolean = false): void {
        this.file = file;
        this.hasFile = hasFile;
        this.violated = violated;
        if (!previewMode) {
            this.fileUploadEvent.emit({ file: file, reason: reason });
        }
    }

    private _getFileExtension(file: File): string {
        if (file === null || file === undefined) {
            return '';
        }
        return file.name.substring(file.name.lastIndexOf('.'), file.name.length).toLowerCase();
    }

    private _fileCleanUpOperation(): void {
        this._updateValidators(false, false, null);
        this._removeThumbnail();
        this._stopEventBubble(event);
        if (this.readyToUploadState) {
            this.customDragAndDropMessage = this.fileUploadMessage;
        }
        
    }
    private _blobToFileIE = (blob: Blob, fileName: string): File => {
        var blobobject: any = blob;
        blobobject.lastModifiedDate = new Date();
        blobobject.name = fileName;
        return <File>blobobject;
    }

    private _checkImageResolution(imageDataURL: string | ArrayBuffer, imgElement: HTMLImageElement, image: string, previewMode: boolean, file: File): void {
        var img = new Image();
        img.onload = () => {
            if (this.imageMinimumWidth && this.imageMinimumWidth > img.width) {
                this._updateValidators(true, false, null, FileCheckType.FileResolution, previewMode);
            }
            else if (this.imageMinimumHeight && this.imageMinimumHeight > img.height) {
                this._updateValidators(true, false, null, FileCheckType.FileResolution, previewMode);
            }
            else {
                this._updateValidators(false, true, file, null, previewMode);
                imgElement.src = image;
            }
        };
        img.onerror = (event) => {
            this.badFile(event as Event);
        };
        img.src = imageDataURL as string;  
    }

    private _zipConvertion(fileType: string, file: File): string {
        if (fileType == '.zip') {
            let nameWithoutZip = file.name.substring(0, (file.name.length - 4));
            let unzippedExtension = nameWithoutZip.substr((nameWithoutZip.length - 4), nameWithoutZip.length);
            fileType = unzippedExtension;
            return fileType;
        } else {
            return fileType;
        }
    }
}
