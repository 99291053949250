import { Pipe, PipeTransform } from '@angular/core';
import { Group } from '../../models/group.model';

@Pipe({
  name: 'groupFilter'
})

export class GroupFilterPipe implements PipeTransform {

  transform(items: Group[], searchText: string): any[] {

    if (!items) {
        return [];
    }

    if (!searchText) {
        return items;
    }

    searchText = searchText.toLowerCase();
    return items.filter( it => {
     return it.Name.toLowerCase().includes(searchText);
    });
   }
}