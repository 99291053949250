
import { Injectable } from '@angular/core';
import { EulaEndpointService } from './eula-endpoint.service';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class EulaService {

    constructor(
        private eulaEndpoint: EulaEndpointService) {
    }

    public getEulaText(lang: string): Observable<string> {
        return this.eulaEndpoint.getEulaText(lang);
    }
}
