
import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date/date.service';
@Pipe({
    name: 'sotiDate',
    pure: true
})
export class DatePipe implements PipeTransform {

    constructor(private _date: DateService) {}

    public transform(date: string | Date | number, timeFormat: boolean = false, format?: string): string {
        if (format) {
            return this._date.customFormat(date, format);
        }
        if (timeFormat) {
            return this._date.formatTime(date);
        }
        return this._date.format(date);
    }
}
