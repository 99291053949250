import { Component, Inject, OnInit } from "@angular/core";
import { Application } from "src/app/models/application.model";
import { AppTranslationService } from "src/app/services/app-translation.service";
import {
    ModalDialogRef,
    ModalDialogService2,
    SOTI_MODAL_DATA
} from "src/app/shared/controls/dialogs";

export class ChangeModalParam {
    public sotionetheme: boolean;

    public title: string;

    public okButtonText: string;
    
    public application: Application;

    constructor(sotiOneTheme: boolean) {
        this.sotionetheme = sotiOneTheme;
    }
}
@Component({
    selector: "change-default-xsight-server-modal",
    styleUrls: ["./change-default-xsight-server-modal.ctrl.scss"],
    templateUrl: "./change-default-xsight-server-modal.ctrl.html",
})
export class ChangeDefaultXsightServerModal implements OnInit {
    public sotionetheme: boolean;

    public titleName: string;

    public buttonText: string;

    public application: Application;

    constructor(
        private _modalRef: ModalDialogRef<ChangeDefaultXsightServerModal>,
        private translationService: AppTranslationService,
        private _modalDialogService: ModalDialogService2,
        @Inject(SOTI_MODAL_DATA) public data: ChangeModalParam
    ) {
        this.sotionetheme = this.data.sotionetheme;
    }

    ngOnInit(): void {
        this.titleName = this.data.title
            ? this.translationService.getTranslation(this.data.title)
            : this.translationService.getTranslation("lbl_change_default_server");
        this.buttonText = this.data.okButtonText 
            ? this.translationService.getTranslation(this.data.okButtonText)
            : this.translationService.getTranslation("lbl_change");
        this.application = this.data.application;
    }

    public closeModal(value): void {
        this._modalDialogService.closeModal(value);
    }
}
