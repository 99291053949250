import {Component, Input, OnInit} from '@angular/core';
import { InfoMessageBarOptions } from './InfoMessageBarOptions';
import { InfoMessageBarPagerOption } from './InfoMessageBarPagerOption';

@Component({
    selector: 'soti-info-message-bar',
    templateUrl: './info-message-bar.ctrl.html',
    styleUrls: ['./info-message-bar.ctrl.scss']
})

export class InfoMessageBarControl {

    // array of all messages to be paged
    @Input()
    public options: InfoMessageBarOptions[];

    // pager object
    public pager: InfoMessageBarPagerOption;

    // paged message
    public currentMessage: InfoMessageBarOptions;

    public enablepagination: boolean = false;

    public showAlert: boolean = true;

    constructor() {
    }

    public ngOnChanges(): void {
        // enable pagination
        if (this.options.length > 0 ) {
            this.enablepagination = this.options.length > 1;

            // initialize to page 1
            this.setPage(1);
        }
        else {
            this.showAlert = false;
        }
    }

    public setPage(page: number): void {
        // get pager object from service
        this.pager = this._getPager(this.options.length, page);

        // get current page of message
        this.currentMessage = this.options[this.pager.itemIndex];
    }

    private _getPager(totalItems: number, currentPage: number = 1): InfoMessageBarPagerOption {

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalItems) {
            currentPage = totalItems;
        }
        // calculate current indexe
        const currentIndex = currentPage - 1;

        return new InfoMessageBarPagerOption(
            totalItems, 
            currentPage, 
            currentIndex
        );
    }
}
