/**
 * Created by aprabhakar on 2016-06-16.
 */
import {IDropdown} from '../dropdown-dialog/dropdown-dialog-model/dropdown-dialog.model';
import {DialogRequestBase} from './DialogRequestBase';

export class OpenDialogRequest extends DialogRequestBase {
    public dropdown:IDropdown;
    public event: MouseEvent;
    
    
}   