import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { EndpointFactory } from '../endpoint-factory.service';

@Injectable()
export class DirectoryEndpoint extends EndpointFactory {

    private readonly _directoryUrl: string = 'Connection';
    private readonly _ldapSearchUrl: string = '/Ldap/';
    private readonly _SearchUrl: string = '/Search';
    private get directoryUrl(): string { return this.configurations.baseUrl + this._directoryUrl; }
    private get directorySearchUrl(): string { return this.configurations.baseUrl + this._directoryUrl + this._ldapSearchUrl; }

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

        super(http, configurations, injector);
    }
    public getLdapEndpoint<T>(type?: Number): Observable<T> {
        const endpointUrl = `${this.directoryUrl}/` + type;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders());
    }
    public searchUserLdapEndpoint<T>(connectionRefrenceId: string, keyword: string): Observable<T> {
        const endpointUrl = `${this.directorySearchUrl}${connectionRefrenceId}${this._SearchUrl}`;
        return this.http.post<T>(endpointUrl, JSON.stringify(keyword) , this.getRequestHeaders());
    }
}