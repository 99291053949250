import { Component, OnInit, Input } from '@angular/core';
import { DataInitService, KeyValuePair } from 'src/app/services/data-init-services/data-init.service';
import { Constants } from 'src/app/constants';
@Component({
    selector: 'soti-copyright',
    templateUrl: './copyright.component.html',
    styleUrls: ['./copyright.component.scss']
})
export class CopyrightControl  implements OnInit {
    public termsconditionsurl: string;
    @Input() public linkShow: boolean = true;

    constructor(
        private datainitService: DataInitService
    ) {
        
    }
    public ngOnInit(): void {
        this.datainitService.dataInitLoaded.subscribe((result) => {
        let termsConditionsUrl: KeyValuePair =  this.datainitService.appDefaultDescription.find(f => f.Text === Constants.termsAndConditionsUrlKey);
        if (termsConditionsUrl) {
            this.termsconditionsurl = termsConditionsUrl.Value;
        }
    });
    }    
}