
export enum EventMessageSubtypeEnum {
    Unknown,
    Unenroll,
    Update,
    UserUpdate,
    Moved,
    Alert,
    Rename,
    Delete,
    Disconnect,
    Added,
    Agent,
    ProgressEvent,
    Helpdesk,
    Logoff,
    EntityEvent,
    DeviceLocated,
    DeviceLocationTimeout,
    DeviceTrackingDurationExpired,
    RuleLoaded,
    LdapConnectionEvent,
    LdapGroupEvent,
    Status,
    ElasticSearchGreen,
    ElasticSearchYellow,
    ElasticSearchRed,
    PermissionsChanged,
}