

//    import { NotificationKindEnum } from './../enums/NotificationKindEnum';

import { NotificationKindEnum } from '../shared/enums/NotificationKindEnum';
import { NotificationStatusEnum } from '../shared/enums/NotificationStatusEnum';

/**
 * 
 * @class Notification
 */
export class BaseNotification  {
    public id: string;
    public title: string;
    public text: string;
    public translated: boolean;
    public read: boolean;
    public createdOn: Date;
    private kind: string;
    private status: string = '';
    public notificationActionType: string = '';
    
    public get Kind():NotificationKindEnum {
        return NotificationKindEnum[this.kind];
    }
    public set Kind(value:NotificationKindEnum) {
        this.kind = NotificationKindEnum[value];
    }
    public get Status(): NotificationStatusEnum {
        return NotificationStatusEnum[this.status];
    }
    public set Status(value: NotificationStatusEnum) {
        this.status = NotificationStatusEnum[value];
    }
}