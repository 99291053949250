import { NgModule } from '@angular/core';
import { CoreCommonModule } from './../../core-common.module';
import { FileDownloadControl } from './file-download.ctrl';
import { FileDownloadService } from '../../services/file-download/file-download.service';

@NgModule({
    imports: [
        CoreCommonModule
    ],
    declarations: [
        FileDownloadControl
    ],
    exports: [
        FileDownloadControl
    ],
    providers: [
        FileDownloadService
    ]
})

export class SharedFileDownloadModule {
}
