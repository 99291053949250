/**
 * Created by rgubarenko on 2016-11-29.
 */

import { Subscription } from 'rxjs/Subscription';

export class DisposalBag {

    private _subs: Subscription[];
    private _branches: Map<string, DisposalBag>;

    constructor() {
        this._subs = [];
        this._branches = new Map<string, DisposalBag>();
    }

    /**
     * Branches the parent bag to sub-bag that will be disposed whenever
     * parent is disposed, but can also be disposed separately on its own.
     * @param key
     */
    public branch(key: string): DisposalBag {
        if (!this._branches.has(key)) {
            this._branches.set(key, new DisposalBag());
        }
        return this._branches.get(key);
    }

    /**
     * Adds observable's subscription to the disposal list.
     * @param value
     */
    public set nextSub(value: Subscription) {
        this._subs.push(value);
    }

    public dispose(): void {
        // dispose all own subscriptions
        this._subs
            .filter(s => typeof s.unsubscribe === 'function')
            .forEach(s => s.unsubscribe());
        this._subs = [];

        // also dispose all child branches
        this._branches.forEach(bag => bag.dispose());
        this._branches.clear();
    }
}