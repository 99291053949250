export class BrowserUtils {

    /**
     * Enhanced version of encodeURIComponent that encodes EVERY special character including ! ~ * ' ( )
     * @param str
     * @returns {string}
     */
    public static forcedEncodeURIComponent(str: string): string {
        return encodeURIComponent(str).replace(/[!'()*]/g, c => {
            return '%' + c.charCodeAt(0).toString(16);
        });
    }

    /**
     * Gets a cookie that matches the name
     * @param cookie Name of the cookie
     */
    public static getCookie(cookie: string): string | null {
        if (typeof document !== 'undefined') {
            let b = document.cookie.match('(^|;)\\s*' + cookie + '\\s*=\\s*([^;]+)');
            return b ? b.pop() : null;
        } else {
            return null;
        }
    }

    /**
     * Gets value by key from local storage
     * @param {string} key     
     *  @param {string} value 
     */
    public static setLocalStorageValue(key: string, value: string) {
        if (value !== undefined) {
            localStorage.setItem(key, value);
        }
    }

    /**
     * Gets value by key from local storage
     * @param {string} key
     * @returns {string}
     */
    public static getLocalStorageValue(key: string): string | null {
        if (typeof localStorage !== 'undefined') {
            return localStorage.getItem(key);
        } else {
            return null;
        }
    }

    public static getAppBasePath(): string {
        return `${location.pathname.substring(0, location.pathname.lastIndexOf('WebConsole'))}`;
    }

    public static getHomeBasePath(): string {
        return location.pathname.substring(location.pathname.indexOf('/home'));
    }

    public static getQueryStringParams(key:string):string
    { 
        var url = window.location.href;
       
            if (!url) url = window.location.href;
            key = key.replace(/[\[\]\\]/g, '\\$&');
            var regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return null;
            var value = decodeURIComponent(results[2].replace(/\+/g, ' '));            
            return value;
        
    }
}