import { Component, Inject, Input, OnInit } from "@angular/core";
import { User } from "src/app/models/user.model";
import { AppTranslationService } from "src/app/services/app-translation.service";
import { AuthService } from "src/app/services/auth.service";
import {
    ModalDialogRef,
    ModalDialogService2,
    SOTI_MODAL_DATA
} from "src/app/shared/controls/dialogs";
import { UserTypeEnum } from "src/app/shared/enums/UserTypeEnum";

export class PreferencesModalParam {
    public sotionetheme: boolean;

    public title: string;

    public okButtonText: string;

    public isSetByAdmin: boolean = false;

    public userId: string;
    constructor(sotiOneTheme: boolean) {
        this.sotionetheme = sotiOneTheme;
    }
}
@Component({
    selector: "preferences-modal",
    styleUrls: ["./preferences-modal.ctrl.scss"],
    templateUrl: "./preferences-modal.ctrl.html",
})
export class PreferencesModal implements OnInit {  

    public selectedTemplate: string = 'authentication';
    public submitted: boolean;
    public sotionetheme: boolean;
    @Input()
    public isChangedByAdmin: boolean;

    public titleName: string;

    public saveBtnTxt: string = 'btn_save';

    public userId: string;
    public helpId: string;
    public userType: UserTypeEnum;    
    public SupportAccountLogin: boolean = false;

    constructor(
        private _modalRef: ModalDialogRef<PreferencesModal>,
        private translationService: AppTranslationService,
        private _modalDialogService: ModalDialogService2,        
        private authService: AuthService,
        @Inject(SOTI_MODAL_DATA) public data: PreferencesModalParam
    ) {
        this.sotionetheme = this.data.sotionetheme;
    }

    ngOnInit(): void {
        this.titleName = this.data.title
            ? this.translationService.getTranslation(this.data.title)
            : this.translationService.getTranslation("lbl_preferences");
        
        this.userId = this.data.userId;
        this.helpId = 'ViewPreferences';
        if (this.authService.currentUser !== undefined || this.authService.currentUser !== null) {
            let currentUser: User = this.authService.currentUser;
            this.userType = currentUser.UserType;            
        }
    }
    
    public closeModal(): void {
        this._modalDialogService.closeModal();
    }
    public isMspAdminAccess(): boolean {
        if (this.userType === UserTypeEnum.MSPTENANTREFERENCEDUSER){
            return true;
        }
        return false;
    }
    public showChangePassword(): boolean {
        if(this.authService.currentUser.IsSupportAccountLogin){
            this.SupportAccountLogin = true;
        }

        if (this.userType === UserTypeEnum.DBUSER && !this.SupportAccountLogin) {
            return true;
        } else {
            return false;
        }
    }
}
