import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ConnectionListService {
    public connectionListValueObserver : Subject<boolean> = new Subject<boolean>();
    public deletedDirectoryListValueObserver : Subject<string[]> = new Subject<string[]>();
    constructor() {

    }
}
