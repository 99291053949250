
import { tap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { EndpointFactory } from '../endpoint-factory.service';
@Injectable()
export class DataInitEndPointService extends EndpointFactory {

    public countriesCode: Subject<Array<any>> = new BehaviorSubject<Array<any>>(null);

    private readonly _dataInitUrl: string = 'datainit';
    get dataInitUrl() { return this.configurations.baseUrl + this._dataInitUrl; }

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

        super(http, configurations, injector);
    }

    public getAdminConsoleConfiguration(): Observable<string> {
        const endpointUrl = this.dataInitUrl + '/Configuration';
        return this.http.get<string>(endpointUrl, this.getRequestHeaders());
    }

    getDataInitEndpoint(emailId?: string): Observable<string> {
        const endpointUrl = this.dataInitUrl;
        return this.http.get<string>(endpointUrl, this.getRequestHeaders());
    }
    
}
