import {NgModule} from '@angular/core';
import {CoreCommonModule} from '../../core-common.module';
import { InfoMessageBarControl } from './info-message-bar.ctrl';

@NgModule({
    imports: [
        CoreCommonModule
    ],
    declarations: [
        InfoMessageBarControl
    ],
    providers: [
    ],
    exports: [
        InfoMessageBarControl
    ]
})
export class SharedInfoMessageBarModule {}