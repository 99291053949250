/**
 * Created by aprabhakar on 2016-05-16.
 */
import { Component, ElementRef, Input, Renderer2, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'soti-tab',
    templateUrl: './tab.component.html',
    styleUrls: ['./tab.component.scss']
})
export class Tab {
    @Input() public templateRef: TemplateRef<object>;
    @Input() public target: {};
    @Input() public set tabId(value: string) {
        this._tabId = value;
    }
    @Input() public name: string;
    @Input() public iconname: string;
    @Input() public padding: boolean = true;
    @Input() public showTabError: boolean = false;

    public get tabId(): string {
        return this._tabId ? this._tabId : this.name;
    }
    public pullIn: boolean;
    public pushIn: boolean;
    public pushOut: boolean;
    public pullOut: boolean;
    public hidden: boolean = true;
    private _tabId: string;

    @ViewChild('tabContainer') private _tabContainer: ElementRef;
    constructor(private _renderer: Renderer2) {}
    public setTabFocus(): void {
        const childElement = this._tabContainer.nativeElement.firstElementChild;
        if (this._tabContainer && childElement) {
            this._renderer.setAttribute(childElement, 'tabindex', '-1');
            childElement.focus();
        }
    }
}
