export class PreferenceLocalStorageModel {
    public backgroundImage: string;
    public logoImage: string;
    public isLogoDefault: string;
    public theme: string;
    public dashboardView: string;
    public language: string;
    public portal: string;
    public hasPortalPreference: string;
    public hasUserBackgroundPreference: string = 'false';
}