/**
 * Created by dsingh on 2016-12-15.
 */
import {
    Component, OnInit, ElementRef, ChangeDetectorRef, OnDestroy, AfterViewInit, Inject, Output, EventEmitter, Renderer2
} from '@angular/core';
import { TooltipService, TooltipData } from '../tooltip.service';
import { Subscription } from 'rxjs/Subscription';
import { DOCUMENT } from '@angular/common';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { logger } from '../../../utils/Logger';
import { TooltipModel } from '../tooltip-model';

@Component({
    selector: 'tooltip-content',
    templateUrl: './tooltip-content.component.html',
    styleUrls: ['./tooltip-content.component.scss']
})
export class TooltipContent implements AfterViewInit, OnInit, OnDestroy {
    public loadToolTip: boolean;
    public positionClass: string;
    public subTitle: string;
    public title: string;
    public innerContent: string | string[];
    public hasOneLineContent: boolean;
    public isRightPosition: boolean;
    public tooltipX: number;
    public tooltipY: number;
    public largeTooltip: boolean;
    public showFullText: boolean;
    public tooltipellipsis: boolean;
    private _tooltip: ElementRef;
    private _tooltipStream: Subscription;
    private _tooltipVisible: boolean = false;
    private _touchEventListener: Function;
    private _clickEventListener: Function;

    public constructor(private _renderer: Renderer2, element: ElementRef, private _changeDetector: ChangeDetectorRef, private _tooltipService: TooltipService, private _localizationService: AppTranslationService) {
        this._tooltip = element.nativeElement;
        this.loadToolTip = false;
    }

    public ngOnInit(): void {
        this._tooltipStream = this._tooltipService.observable.subscribe((data: TooltipData) => {
            if (data.action === 'show') {
                this._showTooltip(data.model, data.target);
            } else if (data.action === 'hide') {
                this._hideTooltip();
            }
        });

        // register touch events
        this._touchEventListener = this._renderer.listen('document', 'touchstart', (touchEvent: TouchEvent) => {
            this._onDocumentClick(touchEvent);
        });
        this._clickEventListener = this._renderer.listen('document', 'click', (clickEvent: MouseEvent) => {
            this._onDocumentClick(clickEvent);
        });
    }

    public ngAfterViewInit(): void {
        this._hideTooltip();
    }

    public ngOnDestroy(): void {
        if (this._tooltipStream) {
            this._tooltipStream.unsubscribe();
        }
        this._touchEventListener && this._touchEventListener();
        this._touchEventListener && this._clickEventListener();
    }

    private _showTooltip({ positionClass, subTitle, title, innerContent, offsetPadding, largeTooltip, showFullText, tooltipellipsis  }: TooltipModel, target: HTMLElement): void {

        this.hasOneLineContent = (typeof innerContent == 'string');

        var tooltipHostElementPos = target.getBoundingClientRect();
        this.tooltipX = tooltipHostElementPos.left + tooltipHostElementPos.width / 2;
        if (positionClass.indexOf('n') >= 0) {
            this.tooltipY = tooltipHostElementPos.top - 8;
            this.tooltipY -= +offsetPadding;
        } else if (positionClass.indexOf('s') >= 0) {
            this.tooltipY = tooltipHostElementPos.bottom + 4;
            this.tooltipY += +offsetPadding;
        }

        const threshold = window.innerWidth * 0.25;
        const distanceToWindowEnd = window.innerWidth - this.tooltipX;

        if (distanceToWindowEnd > threshold) {
            this.isRightPosition = true;
        } else {
            this.isRightPosition = false;
            this.tooltipX = distanceToWindowEnd;
        }

        this.loadToolTip = true;
        this.positionClass = positionClass;
        this.subTitle = subTitle;
        this.title = title;
        this.innerContent = innerContent;
        this.largeTooltip = largeTooltip;
        this.showFullText = showFullText;
        this.tooltipellipsis = tooltipellipsis;
        this._changeDetector.markForCheck();

        setTimeout(() => {
            if (this.positionClass == '') {
                this.positionClass = 's';
                var tooltipElement: HTMLElement = <HTMLElement>document.querySelector('.tooltip-content');
                var tooltipHeight = tooltipElement.clientHeight;
                var tooltipWidth = tooltipElement.clientWidth;
                if ((this.tooltipY + tooltipHeight) > window.innerHeight) {
                    this.positionClass = 'n';
                    this.tooltipY = tooltipHostElementPos.top + tooltipHostElementPos.height * 0.75;
                }
                if (!this.isRightPosition) {
                    if (this.tooltipX < tooltipWidth / 2) {
                        this.positionClass += 'e';
                    }
                }
                this._changeDetector.markForCheck();
            }
        }, 10);
    }

    private _hideTooltip(): void {
        if (this.loadToolTip) {
            this.loadToolTip = false;
            this._changeDetector.markForCheck();
        }
    }

    public _getStyle(): { [key: string]: string } {
        const style: { [key: string]: string } = {
            'top': this.tooltipY + 'px'
        };
        const attribute = this.isRightPosition ? 'left' : 'right';
        style[attribute] = this.tooltipX + 'px';
        return style;
    }

    private _onDocumentClick(targetElement: MouseEvent | TouchEvent): void {
        if (this._tooltipVisible) {
            this._hideTooltip();
        }
        this._tooltipVisible = !this._tooltipVisible;
    }
}