import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddApplicationMetaData } from 'src/app/models/addApplicationMetaData';
import { ApplicationGroupModel } from 'src/app/models/applicationgroup.model';
import { CertificateValidationModel } from 'src/app/models/certificateValidationModel';
import { integratedApplicationViewCredentialModel } from 'src/app/models/integratedApplicationViewCredential.model';
import { ProductScopeModel } from 'src/app/models/productScopeModel';
import { ApplicationListFilterParams } from '../../models/ApplicationListFilterParams';
import { IntergratedApplicationCreateCredentialModel } from '../../models/IntergratedApplicationCreateCredential.model';
import { IntergratedApplicationTypes } from '../../models/IntergratedApplicationTypes';
import { Application } from '../../models/application.model';
import { ApplicationList } from '../../models/applicationList.modal';
import { Roles } from './../../models/applicationRoles.model';
import { ApplicationEndpoint } from './application-endpoint.service';
import { XsightDetails } from 'src/app/models/xsightDetails.model';
import { FilteringParams } from 'src/app/models/filteringparams';
import { ListResponse } from 'src/app/models/list-response.model';
import { RoleStatus } from 'src/app/models/role-status.model';
import { XsightDefaultUrlPreferenceModel } from 'src/app/models/XsightDefaultUrlPreferenceModel';


@Injectable()
export class ApplicationService {

    public selectedApplication: BehaviorSubject<ApplicationList[]> = new BehaviorSubject([]);
    public selectedTenantReferenceId: BehaviorSubject<string> = new BehaviorSubject('');
    public selectedRoles: BehaviorSubject<Roles[]> = new BehaviorSubject([]);

    constructor(private applicationEndPoint: ApplicationEndpoint) {
    }

    public AddApplication(appObject: Application) {
        return this.applicationEndPoint.AddApplicationEndpoint(appObject);
    }

    public AddOidcApplication(appObject: Application) {
        return this.applicationEndPoint.AddOidcApplicationEndpoint(appObject);
    }

    public UpdateApplication(appObject: Application) {
        return this.applicationEndPoint.UpdateApplicationEndpoint(appObject);
    }

    public UpdateOidcApplication(appObject: Application) {
        return this.applicationEndPoint.UpdateOidcApplicationEndpoint(appObject);
    }

    public GetAllAppsByCompanyId(filteringParams?: ApplicationListFilterParams) {
        return this.applicationEndPoint.GetAllApplicationsEndPoint(filteringParams);
    }

    public getSelectedApplication(): Observable<ApplicationList[]> {
        return this.selectedApplication;
    }
    public getApplicationroles(applicationReferenceId: string): Observable<Roles[]> {
        return this.applicationEndPoint.getRolesListByApplicationIdEndPoint(applicationReferenceId);
    }
    public getAdminConsoleRolesList(applicationReferenceId: string, filteringParams: FilteringParams): Observable<ListResponse<Roles>> {
        return this.applicationEndPoint.getAdminConsoleRolesList(applicationReferenceId, filteringParams);
    }
    public AddIntergratedApplication(sotiApp: IntergratedApplicationCreateCredentialModel) {
        return this.applicationEndPoint.AddIntergratedApplication(sotiApp);
    }

    public UpdateIntergratedApplication(sotiApp: IntergratedApplicationCreateCredentialModel) {
        return this.applicationEndPoint.UpdateIntergratedApplication(sotiApp);
    }

    public GetApplicationUsers(applicationReferenceId?: string): Observable<any> {
        return this.applicationEndPoint.getUsersByApplicationIdEndPoint(applicationReferenceId);
    }

    public addApplicationRoles(applicationReferenceId: string, roles?: Roles[]): Observable<any> {
        return this.applicationEndPoint.addApplicationRolesEndPoint(applicationReferenceId, roles);
    }

    public readAndValidateApplicationMetaDataFile(param: AddApplicationMetaData): Observable<Application> {
        return this.applicationEndPoint.readAndValidateApplicationMetaDataFile(param);
    }
    public readApplicationMetaDataUrl(param: string): Observable<Application> {
        return this.applicationEndPoint.readApplicationMetaDataUrl(param);
    }


    public readAndValidateApplicationCertificateFile(param: AddApplicationMetaData): Observable<CertificateValidationModel> {
        return this.applicationEndPoint.readAndValidateCertificateFile(param);
    }

    public getApplicationCredentials(param: string): Observable<integratedApplicationViewCredentialModel> {
        return this.applicationEndPoint.getApplicationCredentials(param);
    }

    public createApplicationCredentials(param: string): Observable<integratedApplicationViewCredentialModel> {
        return this.applicationEndPoint.createApplicationCredentials(param);
    }

    public refreshApplicationCredentials(param: string): Observable<integratedApplicationViewCredentialModel> {
        return this.applicationEndPoint.refreshApplicationCredentials(param);
    }

    public verifyApplicationName(appName: string): Observable<boolean> {
        return this.applicationEndPoint.verifyApplicationName(appName);
    }

    public verifyScimUrl(scimUrl: string): Observable<boolean> {
        return this.applicationEndPoint.verifyScimUrl(scimUrl);
    }

    public searchApplication(searchText: string): Observable<ApplicationList[]> {
        return this.applicationEndPoint.searchApplication(searchText);
    }

    public getApplicationDetailById(param: string): Observable<Application> {
        return this.applicationEndPoint.getApplicationDetailById(param);
    }

    public getOidcApplicationDetailById(applicationRefId: string): Observable<Application> {
        return this.applicationEndPoint.getOidcApplicationDetailById(applicationRefId);
    }

    public activateApplications(applicationReferenceIds: string[]): Observable<void> {
        return this.applicationEndPoint.activateApplications(applicationReferenceIds);
    }

    public deActivateApplications(applicationReferenceIds: string[]): Observable<void> {
        return this.applicationEndPoint.deActivateApplications(applicationReferenceIds);
    }

    public deleteApplications(applicationReferenceIds: string[]): Observable<void> {
        return this.applicationEndPoint.deleteApplications(applicationReferenceIds);
    }

    public getProductScopeByType(param: number): Observable<ProductScopeModel[]> {
        return this.applicationEndPoint.getProductScopeByType(param);
    }

    public getApplicationScopeById(param: string): Observable<ProductScopeModel[]> {
        return this.applicationEndPoint.getApplicationScopeById(param);
    }

    public updateApplicationScope(applicationReferenceId: string, scopeIds: string[]): Observable<void> {
        return this.applicationEndPoint.updateApplicationScope(applicationReferenceId, scopeIds);
    }

    public enableSOTIOneCommunication(applicationIds: string[]): Observable<void> {
        return this.applicationEndPoint.enableSOTIOneCommunication(applicationIds);
    }

    public disableSOTIOneCommunication(applicationIds: string[]): Observable<void> {
        return this.applicationEndPoint.disableSOTIOneCommunication(applicationIds);
    }

    public getProviders(applicationId: string): Observable<ApplicationList[]> {
        return this.applicationEndPoint.getConsumerProviders(applicationId);
    }

    public getTenantProviders(): Observable<ApplicationList[]> {
        return this.applicationEndPoint.getProviders();
    }

    public updateProviders(consumerApplicationId: string, providerApplicationIds: string[]): Observable<void> {
        return this.applicationEndPoint.updateConsumerProviders(consumerApplicationId, providerApplicationIds);
    }

    public SortApplications(applications : Application[]) : Application[] {
        if(!applications || applications.length < 1)
        {
            return [];
        }
        return  [
            ...applications.filter((x) => x.ApplicationType == IntergratedApplicationTypes.MobiControl),
            ...applications.filter((x) => x.ApplicationType == IntergratedApplicationTypes.SotiAssist),
            ...applications.filter((x) => x.ApplicationType == IntergratedApplicationTypes.SotiXsight),
            ...applications.filter((x) => x.ApplicationType == IntergratedApplicationTypes.SotiInsight),
            ...applications.filter((x) => x.ApplicationType == IntergratedApplicationTypes.SotiSnap),
            ...applications.filter((x) => x.ApplicationType == IntergratedApplicationTypes.SotiConnect),
            ...applications.filter((x) => x.ApplicationType == IntergratedApplicationTypes.SotiCentral),
            ...applications.filter((x) => x.ApplicationType == IntergratedApplicationTypes.ThirdParty),
        ];
    }

    public filterSotiApplications(applications : Application[]) : Application[] {
        if(!applications || applications.length < 1)
        {
            return [];
        }
        return  [
            ...applications.filter((x) => x.ApplicationType == IntergratedApplicationTypes.MobiControl || 
                x.ApplicationType == IntergratedApplicationTypes.SotiConnect ||
                x.ApplicationType == IntergratedApplicationTypes.SotiCentral ||
                x.ApplicationType == IntergratedApplicationTypes.SotiXsight ||
                x.ApplicationType == IntergratedApplicationTypes.SotiAssist ||
                x.ApplicationType == IntergratedApplicationTypes.SotiInsight ||
                x.ApplicationType == IntergratedApplicationTypes.SotiSnap),
        ];
    }

    public filter3rdPartyApplications(applications : Application[]) : Application[] {
        if(!applications || applications.length < 1)
        {
            return [];
        }
        return  [
            ...applications.filter((x) => !(x.ApplicationType == IntergratedApplicationTypes.MobiControl || 
                x.ApplicationType == IntergratedApplicationTypes.SotiConnect ||
                x.ApplicationType == IntergratedApplicationTypes.SotiCentral ||
                x.ApplicationType == IntergratedApplicationTypes.SotiXsight ||
                x.ApplicationType == IntergratedApplicationTypes.SotiAssist ||
                x.ApplicationType == IntergratedApplicationTypes.SotiInsight ||
                x.ApplicationType == IntergratedApplicationTypes.SotiSnap)),
        ];
    }

    public ApplicationAccess(applicationReferenceId : string) : Observable<void> {
        return this.applicationEndPoint.ApplicationAccess(applicationReferenceId);
    }

    public xsightAccessLog(name: string, url: string) : Observable<void> {
        let xsightAccessLogModel:XsightDetails = {Name:name, Url:url};
        return this.applicationEndPoint.xsightAccessLog(xsightAccessLogModel);
    }
    
    public searchAssociatedApplicationToApplicationGroup(searchText: string): Observable<ApplicationList[]> {
        return this.applicationEndPoint.searchAssociatedApplicationToApplicationGroup(searchText);
    }

    public createApplicationGroup(applicationGroupModel: ApplicationGroupModel): Observable<void> {
        return this.applicationEndPoint.createApplicationGroup(applicationGroupModel);
    }

    public VerifyDuplicateApplicationGroupName(groupName: string): Observable<boolean> {
        return this.applicationEndPoint.verifyDuplicateApplicationGroupName(groupName);
    }

    public getApplicationGroup(referenceId: string): Observable<ApplicationGroupModel> {
        return this.applicationEndPoint.getApplicationGroup(referenceId);
    }

    public updateApplicationGroup(applicationGroupModel: ApplicationGroupModel): Observable<void> {
        return this.applicationEndPoint.updateApplicationGroup(applicationGroupModel);
    }

    public updateRoleStatus(applicationReferenceId: string, roleStatus: RoleStatus): Observable<void> {
        return this.applicationEndPoint.updateRoleStatus(applicationReferenceId, roleStatus);
    }

    public deleteApplicationGroup(referenceId: string): Observable<void> {
        return this.applicationEndPoint.deleteApplicationGroup(referenceId);
    }

    public updateDefaultXsightServer(defaultXsightdata : XsightDefaultUrlPreferenceModel): Observable<void> {
        return this.applicationEndPoint.updateDefaultXsightServer(defaultXsightdata);
    }
}
