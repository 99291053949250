import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
  VerticalConnectionPos
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewContainerRef
} from '@angular/core';
import { Observable, Subject, fromEvent, merge } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { ESCAPE } from '@angular/cdk/keycodes';
import { PopUp2 } from './pop-up2.ctrl';

@Directive({
  selector: '[popUpTriggerFor]',
  exportAs: 'popUpTrigger'
})
export class PopUpTrigger implements AfterContentInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('popUpTriggerFor') public popup: PopUp2;
  @Input() public popUpDisableClick = false;
  @Input() public popUpOverlayBackdrop = true;
  @Input() public overlayClass = 'custom-overlay-class';
  @Output() public popUpOpened: EventEmitter<void> = new EventEmitter<void>();
  @Output() public popUpClosed: EventEmitter<void> = new EventEmitter<void>();
  @HostBinding('class.clicked') clickedClass = false;

  private _overlayRef: OverlayRef;
  private _portal: TemplatePortal<{}>;
  private _popupOpen = false;
  private _destroySubject = new Subject<void>();

  constructor(
    private _overlay: Overlay,
    private _el: ElementRef,
    private _viewContainerRef: ViewContainerRef,
    private _zone: NgZone
  ) {}

  public ngAfterContentInit(): void {
    this._checkPopup();

    fromEvent(window, 'resize')
      .pipe(
        takeUntil(this._destroySubject),
        debounceTime(300)
      )
      .subscribe(() => {
        this._windowSizeChanged();
      });
  }

  public ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
    }

    this._destroySubject.next();
    this._destroySubject.complete();
  }

  @HostListener('click')
  public togglePopup(): void {
    if (!this.popUpDisableClick) {
      this._popupOpen ? this.close() : this.open();
    }
  }

  public open(): void {
    if (!this._popupOpen) {
      this._createOverlay().attach(this._portal);
      this._setPopupOpen(true);
    }
  }

  public close(): void {
    if (this._popupOpen) {
      this._destroyPopup();
    }
  }

  public updatePosition(): void {
    if (this._overlayRef) {
      setTimeout(() => {
        this._overlayRef.updatePosition();
      });
    }
  }

  private _destroyPopup(): void {
    if (this._overlayRef && this._popupOpen) {
      this._overlayRef.detach();
      this._setPopupOpen(false);
    }
  }

  private _checkPopup(): void {
    if (!this.popup) {
      throw Error(`popUpTrigger: must pass in a soti-popup instance

            Example:
                <soti-popup #popup="sotiPopup"></soti-popup>
                <button [sotiPopUpTriggerFor]="popup"></button>
            `);
    }
  }

  private _setPopupOpen(isOpen: boolean): void {
    this._popupOpen = isOpen;
    if (this._popupOpen) {
      this.popUpOpened.emit();
      this._overlayRef.hostElement.parentElement.classList.add(this.overlayClass);
    } else {
      this.popUpClosed.emit();
      this._overlayRef.hostElement.parentElement.classList.remove(this.overlayClass);
    }
  }

  private _createOverlay(): OverlayRef {
    if (!this._overlayRef) {
      this._portal = new TemplatePortal(this.popup.template, this._viewContainerRef);
      const config = this._getOverlayConfig();
      this._subscribeToPositions(config.positionStrategy as FlexibleConnectedPositionStrategy);
      this._overlayRef = this._overlay.create(config);
      this._createCloseSubscriptions()
        .pipe(takeUntil(this._destroySubject))
        .subscribe(() => {
          this.close();
        });
    }
    return this._overlayRef;
  }

  private _getOverlayConfig(): OverlayConfig {
    const state = new OverlayConfig();
    state.positionStrategy = this._getPositionStrategy();
    state.backdropClass = 'transparent';
    state.hasBackdrop = this.popUpOverlayBackdrop;
    state.scrollStrategy = this.popUpOverlayBackdrop
      ? this._overlay.scrollStrategies.block()
      : this._overlay.scrollStrategies.reposition();
    return state;
  }

  /**
   * The position strategy for the popup is complex due to the fact that there are multiple fallbacks
   * for each position.
   *
   * It's especially true if the beak appears on the right or left of the popup.
   */

  private _getPositionStrategy(): PositionStrategy {
    let verticalOverlayX: HorizontalConnectionPos,
      horizontalFallbackOriginX: HorizontalConnectionPos,
      horizontalFallbackOverlayX: HorizontalConnectionPos,
      verticalOriginX: HorizontalConnectionPos,
      fallbackOriginSideBeakTopX: HorizontalConnectionPos,
      fallbackOverlaySideBeakTopX: HorizontalConnectionPos,
      fallbackOriginSideBeakBottomX: HorizontalConnectionPos,
      fallbackOverlaySideBeakBottomX: HorizontalConnectionPos;

    let verticalOverlayY: VerticalConnectionPos,
      horizontalFallbackOriginY: VerticalConnectionPos,
      horizontalFallbackOverlayY: VerticalConnectionPos,
      verticalOriginY: VerticalConnectionPos,
      fallbackOriginSideBeakTopY: VerticalConnectionPos,
      fallbackOverlaySideBeakTopY: VerticalConnectionPos,
      fallbackOriginSideBeakBottomY: VerticalConnectionPos,
      fallbackOverlaySideBeakBottomY: VerticalConnectionPos;

    if (this.popup.x === 'start') {
      // horizontal
      [horizontalFallbackOverlayX, horizontalFallbackOverlayY] = this.popup.positions.endBottom;
      [horizontalFallbackOriginX, horizontalFallbackOriginY] = this.popup.positions.endTop;

      // vertical
      [verticalOriginX, verticalOriginY] = this.popup.positions.startBottom;
      [verticalOverlayX, verticalOverlayY] = this.popup.positions.startTop;

      if (this.popup.y !== 'top') {
        // horizontal
        [horizontalFallbackOverlayX, horizontalFallbackOverlayY] = this.popup.positions.endTop;
        [horizontalFallbackOriginX, horizontalFallbackOriginY] = this.popup.positions.endBottom;

        // vertical
        [verticalOriginX, verticalOriginY] = this.popup.positions.startTop;
        [verticalOverlayX, verticalOverlayY] = this.popup.positions.startBottom;
      }
    } else if (this.popup.x === 'end') {
      // horizontal
      [horizontalFallbackOriginX, horizontalFallbackOriginY] = this.popup.positions.startBottom;
      [horizontalFallbackOverlayX, horizontalFallbackOverlayY] = this.popup.positions.startTop;

      // vertical
      [verticalOriginX, verticalOriginY] = this.popup.positions.endBottom;
      [verticalOverlayX, verticalOverlayY] = this.popup.positions.endTop;
      if (this.popup.y !== 'top') {
        // horizontal
        [horizontalFallbackOriginX, horizontalFallbackOriginY] = this.popup.positions.startTop;
        [horizontalFallbackOverlayX, horizontalFallbackOverlayY] = this.popup.positions.startBottom;

        // vertical
        [verticalOriginX, verticalOriginY] = this.popup.positions.endTop;
        [verticalOverlayX, verticalOverlayY] = this.popup.positions.endBottom;
      }
    } else if (this.popup.x === 'center') {
      [verticalOriginX, verticalOriginY] = this.popup.positions.centerBottom;
      [verticalOverlayX, verticalOverlayY] = this.popup.positions.centerTop;

      [horizontalFallbackOriginX, horizontalFallbackOriginY] = this.popup.positions.centerBottom;
      [horizontalFallbackOverlayX, horizontalFallbackOverlayY] = this.popup.positions.centerTop;

      [fallbackOriginSideBeakTopX, fallbackOriginSideBeakTopY] = this.popup.positions.endTop;
      [fallbackOverlaySideBeakTopX, fallbackOverlaySideBeakTopY] = this.popup.positions.startTop;
      [fallbackOriginSideBeakBottomX, fallbackOriginSideBeakBottomY] = this.popup.positions.endBottom;
      [fallbackOverlaySideBeakBottomX, fallbackOverlaySideBeakBottomY] = this.popup.positions.startBottom;
      if (this.popup.y !== 'top') {
        [verticalOriginX, verticalOriginY] = this.popup.positions.centerTop;
        [verticalOverlayX, verticalOverlayY] = this.popup.positions.centerBottom;

        [horizontalFallbackOriginX, horizontalFallbackOriginY] = this.popup.positions.centerTop;
        [horizontalFallbackOverlayX, horizontalFallbackOverlayY] = this.popup.positions.centerBottom;

        [fallbackOriginSideBeakTopX, fallbackOriginSideBeakTopY] = this.popup.positions.startTop;
        [fallbackOverlaySideBeakTopX, fallbackOverlaySideBeakTopY] = this.popup.positions.endTop;
        [fallbackOriginSideBeakBottomX, fallbackOriginSideBeakBottomY] = this.popup.positions.startBottom;
        [fallbackOverlaySideBeakBottomX, fallbackOverlaySideBeakBottomY] = this.popup.positions.endBottom;
      }
    }

    // y as center is a special case where the pop up will appear on the side of the origin
    if (this.popup.y === 'center') {
      [verticalOriginX, verticalOriginY] = this.popup.positions.endCenter;
      [verticalOverlayX, verticalOverlayY] = this.popup.positions.startCenter;
      [fallbackOriginSideBeakTopX, fallbackOriginSideBeakTopY] = this.popup.positions.endTop;
      [fallbackOverlaySideBeakTopX, fallbackOverlaySideBeakTopY] = this.popup.positions.startTop;
      [fallbackOriginSideBeakBottomX, fallbackOriginSideBeakBottomY] = this.popup.positions.endBottom;
      [fallbackOverlaySideBeakBottomX, fallbackOverlaySideBeakBottomY] = this.popup.positions.startBottom;
      if (this.popup.x !== 'start') {
        [verticalOriginX, verticalOriginY] = this.popup.positions.startCenter;
        [verticalOverlayX, verticalOverlayY] = this.popup.positions.endCenter;
        [fallbackOriginSideBeakTopX, fallbackOriginSideBeakTopY] = this.popup.positions.startTop;
        [fallbackOverlaySideBeakTopX, fallbackOverlaySideBeakTopY] = this.popup.positions.endTop;
        [fallbackOriginSideBeakBottomX, fallbackOriginSideBeakBottomY] = this.popup.positions.startBottom;
        [fallbackOverlaySideBeakBottomX, fallbackOverlaySideBeakBottomY] = this.popup.positions.endBottom;
      }
    }

    // fallbacks
    const fallbackOriginX: HorizontalConnectionPos = verticalOverlayX;
    const fallbackOriginY: VerticalConnectionPos = verticalOverlayY;
    const fallbackOverlayX : HorizontalConnectionPos= verticalOriginX;
    const fallbackOverlayY : VerticalConnectionPos= verticalOriginY;

    const connection = this._overlay
      .position()
      .flexibleConnectedTo(this._el);

    const offsetX = this.popup.offsetX;
    connection.withDefaultOffsetX(offsetX);
    const offsetY = this.popup.offsetY;
    connection.withDefaultOffsetY(offsetY);
    const positionsArr: ConnectedPosition[] = [];

    positionsArr.push({
        originX: verticalOriginX,
        originY: verticalOriginY,
        overlayX: verticalOverlayX,
        overlayY: verticalOverlayY
      });
    // if no fallback is set on the popup, we don't include any fallbacks
    if (!this.popup.noFallback) {

      positionsArr.push(
        {
          originX: fallbackOriginX,
          originY: fallbackOriginY,
          overlayX: fallbackOverlayX,
          overlayY: fallbackOverlayY,
        }
      );

      // if the beak appears on the left or right side, then we need to have additional fallbacks
      // otherwise we add the horizontal and horizontal/vertical fallbacks
      if (fallbackOverlaySideBeakTopX && fallbackOverlaySideBeakTopY) {
        positionsArr.push(
          {
            originX: fallbackOverlaySideBeakTopX,
            originY: fallbackOverlaySideBeakTopY,
            overlayX: fallbackOriginSideBeakTopX,
            overlayY: fallbackOriginSideBeakTopY
          },
          {
            originX: fallbackOriginSideBeakTopX,
            originY: fallbackOriginSideBeakTopY,
            overlayX: fallbackOverlaySideBeakTopX,
            overlayY: fallbackOverlaySideBeakTopY
          },
          {
            originX: fallbackOriginSideBeakBottomX,
            originY: fallbackOriginSideBeakBottomY,
            overlayX: fallbackOverlaySideBeakBottomX,
            overlayY: fallbackOverlaySideBeakBottomY
          },
          {
            originX: fallbackOverlaySideBeakBottomX,
            originY: fallbackOverlaySideBeakBottomY,
            overlayX: fallbackOriginSideBeakBottomX,
            overlayY: fallbackOriginSideBeakBottomY
        })
      }
      // keep the original functionality first and then allow for the additional horizontal fallbacks
      positionsArr.push(
        {
          originX: horizontalFallbackOriginX,
          originY: horizontalFallbackOriginY,
          overlayX: horizontalFallbackOverlayX,
          overlayY: horizontalFallbackOverlayY
        },
        {
          originX: horizontalFallbackOverlayX,
          originY: horizontalFallbackOverlayY,
          overlayX: horizontalFallbackOriginX,
          overlayY: horizontalFallbackOriginY
      });
    }

    return connection.withPositions(positionsArr);
  }

  /**
   * Listens to changes on the overlay and sets the correct class for the beak position.
   */
  private _subscribeToPositions(position: FlexibleConnectedPositionStrategy): void {
    position.positionChanges.pipe(takeUntil(this._destroySubject)).subscribe((change) => {
      const x: HorizontalConnectionPos = change.connectionPair.overlayX;
      const y: VerticalConnectionPos = change.connectionPair.overlayY;
      const originX = change.connectionPair.originX;
      const originY = change.connectionPair.originY;
      /*
       * ConnectedPositionStrategy includes Classes that, in turn,
       * run logic outside of Angular.  As a result we were seeing the pop-up requiring
       * two clicks to have the position update correctly.  By forcing the following to run in
       * Angular, the expected behaviour returned to normal.
       */
      this._zone.run(() => {
        this.popup.setBeakPosition([x, y], [originX, originY]);
      });
    });
  }

  private _createCloseSubscriptions(): Observable<void> {
    return merge<void, void>(
      this._overlayRef.keydownEvents().pipe(filter((event) => event.keyCode === ESCAPE)),
      this._overlayRef.backdropClick(),
      this.popup.closed
    );
  }

  private _windowSizeChanged(): void {
    if (this._overlayRef) {
      this._overlayRef.updatePosition();
    }
  }
}