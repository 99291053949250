import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, Type } from '@angular/core';
import { BaseNotification } from '../../../models/base-notification.model';
import { NotificationActionTypeEnum, NotificationTypeEnum } from 'src/app/shared/enums/notification-type.enum';
import { Event } from '@angular/router';

export class NotificationListingFooterActionModel{
    notificationIds : string[] = []
    action : NotificationActionTypeEnum
    type : NotificationTypeEnum
}

@Component({
    selector: 'app-notification-listing',
    templateUrl: './notification-listing.component.html',
    styleUrls: ['./notification-listing.component.scss']
})

export class NotificationListingComponent {

    public position: number = 0;
    public eventType: string;
    
    @Input('notifications')
    public notifications: BaseNotification[];
    public notificationCount: number = 0;

    @Input('announcements')
    public announcements: BaseNotification[];
    @Output() public notificationListingFooterAction: EventEmitter<NotificationListingFooterActionModel> = new EventEmitter<NotificationListingFooterActionModel>();
    
    @ViewChild('alertListing') public alertListingDiv : ElementRef;
    @ViewChild('announcementListing') public announcementListingDiv : ElementRef;

    public unReadNotificationCount: number;
    public unReadAnnouncementCount: number;

    public alert : NotificationTypeEnum = NotificationTypeEnum.Alert
    public announcement : NotificationTypeEnum = NotificationTypeEnum.Announcement
    public scrolled: boolean = false;

    @Output() backdropClick: EventEmitter<void> = new EventEmitter<void>();
    constructor(
        private elementRef: ElementRef
    ) {
        this.notifications = [];
        this.announcements = [];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.notifications) {
            this.notificationCount = 0;
        }
      }

    checkViewStatus(): void {
        if (this.notificationCount === this.notifications.length) {
            this.onNotificationsChange();
            this.notificationCount = 0;
        }
    }

    onNotificationsChange() {
        if(this.eventType === "keydown")
        {
            let temp = this.elementRef.nativeElement.querySelectorAll('.identity-icon-3dots-more-menu');
            if(temp.length>0)
            {
                let elementToFocus = temp[temp.length> this.position? this.position: temp.length-1];
                elementToFocus.focus();
            }
        }
    }

    public countUnreadNotification(){
        this.unReadNotificationCount = this.notifications.filter(x => !x.read).length;
        if(this.unReadNotificationCount > 0){
            return ` (${this.unReadNotificationCount})`;
        }
    }

    public countUnreadAnnouncement(){
        this.unReadAnnouncementCount = this.announcements.filter(x => !x.read).length;
        if(this.unReadAnnouncementCount > 0){
            return ` (${this.unReadAnnouncementCount})`;
        }
    }
  
    public isNotificationsEmpty(): boolean {
        return this.notifications.length == 0;
    }

    public isAnnouncementsEmpty(): boolean{
        return this.announcements.length == 0;
    }

    public unreadAlertsPresent(): boolean {
        return this.notifications.filter((x : BaseNotification) => !x.read).length > 0 ? true : false;
    }
    
    public unreadAnnouncementsPresent(): boolean {
        return this.announcements.filter((x : BaseNotification) => !x.read).length > 0 ? true : false;
    }

    public updateFocusPosition(data: {notificationId:string, eventType: string}) {
        this.eventType = data.eventType;
        this.position = this.notifications.findIndex(obj => obj.id === data.notificationId);
      }

    public updateNotificationCount()
    {
        this.notificationCount++;
        this.checkViewStatus()

    }

    public clearAllNotifications(type : NotificationTypeEnum){
        let notificationIdsToClear : string[] = this.getArrayToFilter(type).map((x : BaseNotification, idx : number)=> {return x.id});
        let emitObj = { notificationIds : notificationIdsToClear, action : NotificationActionTypeEnum.ClearAll, type : type } as NotificationListingFooterActionModel
        this.notificationListingFooterAction.emit(emitObj)
    }

    public markAllNotificationsRead(type : NotificationTypeEnum){
        let notificationIdsToMarkRead : string[] = this.getArrayToFilter(type).filter((x : BaseNotification )=> !x.read).map((x : BaseNotification, idx : number)=> {return x.id});
        let emitObj = { notificationIds : notificationIdsToMarkRead, action : NotificationActionTypeEnum.MarkAllRead, type : type } as NotificationListingFooterActionModel
        if (type === NotificationTypeEnum.Alert ? this.unreadAlertsPresent() : this.unreadAnnouncementsPresent()){
            this.notificationListingFooterAction.emit(emitObj)
        }        
    }

    private getArrayToFilter = (type : NotificationTypeEnum) : BaseNotification[] => {
        switch(type){
            case NotificationTypeEnum.Alert:
                return this.notifications;
            case NotificationTypeEnum.Announcement:
                return this.announcements;
        }
    };

    public onScroll(type : NotificationTypeEnum){
        let div = type === NotificationTypeEnum.Alert ? this.alertListingDiv : this.announcementListingDiv 
        if (div.nativeElement.scrollTop > 0) {
            this.scrolled = true;
        } 
        else {
            this.scrolled = false;
        }
    }
    public backdropClickEmit(): void{
        this.backdropClick.emit();
    }
}