import { Injectable } from "@angular/core";
import { Constants } from "src/app/constants";
import { AppTranslationService } from "src/app/services/app-translation.service";

@Injectable()
export class CheckPasswordService {
    private atleastonedigit: boolean = false;
    private atleastoneuppercase: boolean = false;
    private atleastonelowercase: boolean = false;
    private atleastonespecialchar: boolean = false;
    private noWhiteSpace: boolean = true;
    private charLength: number;
    constructor(private translationService: AppTranslationService){
    }

    public getPasswordPolicyTooltip(pattern: string, password:string): string  {
        this.applyPolicyRegex(pattern);
        if(password == undefined) {
            password = "";
        }
        let iconClass = password.length >= this.charLength ? 'identity-icon-tick':'identity-icon-error-warning';
        let passwordConditions = '<div class="pop-up-password-policy"><h5 class="p-manager">' + this.translationService.getTranslation('lbl_password_manager') + '</h5><br><h5 class="ps-manager">' + this.translationService.getTranslation('lbl_password_generate') + '</h5><h6 class="title">' + this.translationService.getTranslation('lbl_PasswordPolicies') + '</h6><ul><li><i _ngcontent-c14="" class="selection-icon ' + iconClass + '"></i> ' + this.getCharacterLengthMessage() + this.AddContentRuleDidNotPass(iconClass) + '</li>';

        if(this.atleastonedigit) {
            iconClass =  this.checkRegExValidation(Constants.alteastOneDigit,password)? 'identity-icon-tick':'identity-icon-error-warning';
            passwordConditions += '<li><i _ngcontent-c14="" class="selection-icon '+ iconClass +'"></i>' + this.translationService.getTranslation('msg_at_least_one_digit') + this.AddContentRuleDidNotPass(iconClass) + '</li>';
        }
        
        if(this.atleastoneuppercase) {
            iconClass =  this.checkRegExValidation(Constants.alteastOneUpper,password)? 'identity-icon-tick':'identity-icon-error-warning';
            passwordConditions += '<li><i _ngcontent-c14="" class="selection-icon '+ iconClass +'"></i>' + this.translationService.getTranslation('msg_at_least_one_upper_case_letter') + this.AddContentRuleDidNotPass(iconClass) + '</li>';
        }
        
        if(this.atleastonelowercase) {
            iconClass =  this.checkRegExValidation(Constants.alteastOneLower,password)? 'identity-icon-tick':'identity-icon-error-warning';
            passwordConditions += '<li><i _ngcontent-c14="" class="selection-icon '+ iconClass +'"></i>' + this.translationService.getTranslation('msg_at_least_one_lower_case_letter') + this.AddContentRuleDidNotPass(iconClass) + '</li>';
        }
        
        if (this.atleastonespecialchar) {
            iconClass =  this.checkRegExValidation(Constants.alteastOneSpecialChar,password)? 'identity-icon-tick':'identity-icon-error-warning';
            passwordConditions += '<li><i _ngcontent-c14="" class="selection-icon '+ iconClass +'"></i>' + this.translationService.getTranslation('msg_at_least_one_special_character') + this.AddContentRuleDidNotPass(iconClass) + '</li>';
        }

        if (this.noWhiteSpace) {
            iconClass =  this.checkRegExValidation(Constants.noWhiteSpaceChar, password) ? 'identity-icon-tick' : 'identity-icon-error-warning';
            passwordConditions += '<li><i _ngcontent-c14="" class="selection-icon ' + iconClass + '"></i>' + this.translationService.getTranslation('msg_no_white_space') + this.AddContentRuleDidNotPass(iconClass) + '</li>';
        }
        passwordConditions += '</ul></div>';
        return passwordConditions;
    }

    private AddContentRuleDidNotPass(status : string) : string{
        switch(status){
            case 'identity-icon-tick':
                return '<span class="visually-hidden"> Rule passed, </span>';
            default:
                return '<span class="visually-hidden"> Rule did not pass, </span>';
        }
    }

    private applyPolicyRegex(pattern: string){
        this.atleastonelowercase = pattern.indexOf(Constants.alteastOneLower) >= 0 ? true : false;
        this.atleastoneuppercase = pattern.indexOf(Constants.alteastOneUpper) >= 0 ? true : false;
        this.atleastonedigit = pattern.indexOf(Constants.alteastOneDigit) >= 0 ? true : false;
        this.atleastonespecialchar = pattern.indexOf(Constants.alteastOneSpecialChar) >= 0 ? true : false;
        this.charLength = parseInt(pattern.substring(pattern.lastIndexOf('{') + 1, pattern.lastIndexOf(',')));
    }

    private getCharacterLengthMessage(): string {
        return this.translationService.getTranslation('msg_character_length').replace('<no>', this.charLength);
    }

    private checkRegExValidation(regEx:string, control:string) : boolean {
        return RegExp(regEx).test(control);
    }
}