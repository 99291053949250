import { Component, Inject, Input, OnInit } from "@angular/core";
import { AppTranslationService } from "src/app/services/app-translation.service";
import {
    ModalDialogRef,
    ModalDialogService2,
    SOTI_MODAL_DATA
} from "src/app/shared/controls/dialogs";

export class ChangeModalParam {
    public sotionetheme: boolean;

    public title: string;

    public okButtonText: string;

    public isSetByAdmin: boolean = false;

    public userId: string;
    constructor(sotiOneTheme: boolean) {
        this.sotionetheme = sotiOneTheme;
    }
}
@Component({
    selector: "change-password-modal",
    styleUrls: ["./change-password-modal.ctrl.scss"],
    templateUrl: "./change-password-modal.ctrl.html",
})
export class ChangePasswordModal implements OnInit {
    public submitted: boolean;
    public sotionetheme: boolean;
    @Input()
    public isChangedByAdmin: boolean;

    public titleName: string;

    public buttonText: string;

    public userId: string;
    public helpId: string;
    constructor(
        private _modalRef: ModalDialogRef<ChangePasswordModal>,
        private translationService: AppTranslationService,
        private _modalDialogService: ModalDialogService2,
        @Inject(SOTI_MODAL_DATA) public data: ChangeModalParam
    ) {
        this.sotionetheme = this.data.sotionetheme;
    }

    ngOnInit(): void {
        this.titleName = this.data.title
            ? this.translationService.getTranslation(this.data.title)
            : this.translationService.getTranslation("lbl_change_password");
        this.isChangedByAdmin = this.data.isSetByAdmin
            ? this.data.isSetByAdmin
            : false;
        this.userId = this.data.userId;
        this.buttonText = this.data.okButtonText;
        this.helpId = this.isChangedByAdmin ? 'UserResetPassword' : 'UserChangePassword';
    }

    public closeModal(): void {
        this._modalDialogService.closeModal();
    }
}
