/**
 * Created by snardi on 2016-01-12.
 */
import {isObject, truncate, noop} from 'lodash';
import {BrowserUtils} from './BrowserUtils';

// tslint:disable
declare var document;
declare var window;
declare var ENV: any;

export enum LogStyle {
    White = 0,
    Blue = 2,
    Yellow = 3,
    NeonHuge = 4,
    H1 = 5,
    BlackOnLime = 7,
    YellowOnGray = 8,
    Http = 9,
    Flux = 10,
    BlackOnBlue = 11,
    Msgr = 12
}

export interface ILoggerFacade {
    debug: INguiLogger;
    h1: INguiLogger;
}

export interface INguiLogger {
    (msg: string, ...params: any[]): void;
}

// TODO: Still need to ascertain whether the logger is enabled via the cookie or settings - SP
export class Logger {
    private _formatMessage: string;
    private _formatParam: string;

    public debug: INguiLogger;
    public highlight2: INguiLogger;

    private _isLogEnabled: boolean;

    constructor() {
        this._formatMessage = null;
        this._formatParam = null;

        // set logger enabled flag
        // if (BrowserUtils.getLocalStorageValue('NGUILoggerEnabled') === 'true') {
        //     this._isLogEnabled = true;
        // } else 
        if (this._isInBrowser() && typeof(ENV) !== 'undefined' && ENV == 'production') {
            this._isLogEnabled = false;
        } else if (!this._isInBrowser() && process && process.env && process.env.NguiSuppressLogs) {
            this._isLogEnabled = false;
        } else {
            this._isLogEnabled = true;
        }

        this.debug = this._bindLogger(null, null);
        this.highlight2 = this._bindLogger('color:blue;', '\x1b[34m');
    }

    public withPrefix(prefix: string): ILoggerFacade {
        return {
            debug: this.debug.bind(null, prefix),
            h1: this.highlight2.bind(null, prefix)
        };
    }

    public warn(msg: string, ...params: any[]) {
        if (!this._isLogEnabled) {
            return;
        }

        console.warn.apply(console, [this._prepareMessage(msg), ...(this._prepareParams(params))]);
    }

    public error(msg: string, ...params: any[]) {
        if (!this._isLogEnabled) {
            return;
        }

        // add better description for error objects in NodeJS mode
        if (!this._isInBrowser()) {
            params.forEach((val, idx) => {
                let detals = '';

                if (val.message) detals += 'Message: ' + val.message + '\r\n';
                if (val.stack) detals += 'Stack: ' + val.stack.toString() + '\r\n';

                if (detals.length > 0) {
                    detals = '\r\nERROR info from logger:\r\n' + detals;
                    params[idx] = detals;
                }
            });
        }

        console.error.apply(console, [this._prepareMessage(msg), ...(this._prepareParams(params))]);
    }

    public style(logStyle: LogStyle): Logger {
        this._formatMessage = null;
        this._formatParam = null;

        switch (logStyle) {
            case LogStyle.NeonHuge:
                this._setStyleFormat('color: #fff;text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff2d95, 0 0 30px #ff2d95, 0 0 40px #ff2d95, 0 0 50px #ff2d95, 0 0 75px #ff2d95;letter-spacing: 5px;font: 80px "MisoRegular";background-color:black;', null);
                break;
            case LogStyle.H1:
                this._setStyleFormat('color:white;', '\x1b[30m\x1b[44m');
                break;
            case LogStyle.White:
                this._setStyleFormat('color:white;', '\x1b[37m');
                break;
            case LogStyle.Flux:
                this._setStyleFormat('color:#775D80;font-size:smaller;font-style:italic;margin-left:20px;border-left:solid 10px #775D80;padding-left:5px;', '\x1b[32m');
                break;
            case LogStyle.Msgr:
                this._setStyleFormat('color:green;font-size:smaller;font-style:italic;', '\x1b[32m');
                break;
            case LogStyle.Yellow:
                this._setStyleFormat('color:yellow;', '\x1b[33m');
                break;
            case LogStyle.Http:
                this._setStyleFormat('color:#538899;font-size:smaller;font-style:italic;margin-left:20px;border-left:solid 10px #538899;padding-left:5px;', '\x1b[90m');
                break;
            case LogStyle.Blue:
                this._setStyleFormat('color:blue;', '\x1b[90m');
                break;
            case LogStyle.BlackOnLime:
                this._setStyleFormat('color:black;background-color:lime', '\x1b[30m\x1b[44m');
                break;
            case LogStyle.BlackOnBlue:
                this._setStyleFormat('color:black;background-color:#99ccff', '\x1b[30m\x1b[44m');
                break;
            case LogStyle.YellowOnGray:
                this._setStyleFormat('color:yellow;background-color:#b0b0b0', '\x1b[33m');
                break;
            default:
                break;
        }

        return this;
    }

    public neon(msg: string, ...params: any[]) {
        this.style(LogStyle.NeonHuge)._debugOld(msg, ...params);
    }

    public highlight(msg: string, ...params: any[]) {
        this.style(LogStyle.BlackOnLime)._debugOld(msg, ...params);
    }

    // public highlight2(msg: string, ...params: any[]) {
    //     this.style(LogStyle.Blue)._debugOld(msg, ...params);
    // }

    public highlight3(msg: string, ...params: any[]) {
        this.style(LogStyle.BlackOnBlue)._debugOld(msg, ...params);
    }

    public ui(msg: string, ...params: any[]) {
        this.style(LogStyle.Blue)._debugOld(msg, ...params);
    }

    public dl(msg: string, ...params: any[]) {
        this.style(LogStyle.Http)._debugOld(msg, ...params);
    }

    public flux(msg: string, ...params: any[]) {
        this.style(LogStyle.Flux)._debugOld(msg, ...params);
    }

    public msgr(msg: string, ...params: any[]) {
        this.style(LogStyle.Msgr)._debugOld(msg, ...params);
    }

    private _debugOld(msg: string, ...params: any[]) {
        if (!this._isLogEnabled) {
            return;
        }

        console.log.apply(console, [this._prepareMessage(msg), ...(this._prepareParams(params))]);
    }

    private _bindLogger(browserStyle: string, nodeJSStyle: string): any {
        if (!this._isLogEnabled) {
            return noop;
        }

        let logger: any;

        if (this._isInBrowser()) {
            if (browserStyle !== null) {
                logger = console.log.bind(console, "%c%s", browserStyle);
            } else {
                logger = console.log.bind(console);
            }
        } else {
            logger = (msg: string, ...params: any[]) => {
                if (nodeJSStyle !== null) {
                    msg = nodeJSStyle + msg + '\x1b[0m';
                }
                // NOTE: using just straing call, no binding - it's not needed because NodeJS console does not show any code lines anyway
                console.log.apply(console, [this._prepareMessage(msg), ...(this._prepareParams(params))]);
            };
        }

        return logger;
    }

    private _isInBrowser(): boolean {
        try {
            return !!(window) && !!(window.document);
        } catch (ex) {
            return false;
        }
    }

    private _prepareParams(params: any[]): any[] {
        var resultParams: any[] = params.map(p => {
            if (!this._isInBrowser() && isObject(p)) {
                let jsonString = '';
                try {
                    jsonString = JSON.stringify(p);
                } catch (ex) {}
                return truncate(jsonString, {length: 1000});
            } else {
                return p;
            }
        });
        if (this._formatParam) {
            resultParams.unshift(this._formatParam);
        }
        this._formatParam = null;
        return resultParams;
    }

    private _prepareMessage(msg: string): string {
        if (this._formatMessage) {
            msg = this._formatMessage.replace('{msg}', msg);
        }
        this._formatMessage = null;
        return msg;
    }

    private _setStyleFormat(browserFormat: string, nodeJSFormat: string) {
        if (this._isInBrowser()) {
            this._formatMessage = '%c{msg}';
            this._formatParam = browserFormat;
        } else {
            // TODO: implement for NodeJS
            this._formatMessage = nodeJSFormat + '{msg}\x1b[0m';
            this._formatParam = null;
        }
    }
}

export var logger = new Logger();