/**
 * Created by jcammisuli on 2016-03-26.
 */

export {ModalDialog} from './modal-dialog-component/modal-dialog.component';
export {ModalDialogService} from './modal-dialog-service/modal-dialog.service';
export {CloseModal, NewModal} from './modal-dialog-model/modal-dialog.model';

export { ModalDialogModule } from './modal-dialog.module';

