import { NgModule } from '@angular/core';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateLanguageLoader } from '../../services/app-translation.service';
import { NotificationListingComponent } from './notification-listing/notification-listing.component';
import { SimpleNotificationComponent } from './simple-notification/simple-notification.component';
import { NotificationView } from './notification.view';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedLoadingDirectiveModule } from 'src/app/shared/directives/loading/shared-loading.module';
import { SharedFileDownloadModule } from 'src/app/shared/controls/file-download/shared-file-download.module';
import { SharedTooltipModule } from 'src/app/shared/controls/tooltip';
import { CoreCommonModule } from 'src/app/shared/core-common.module';
import { A11yModule } from '@angular/cdk/a11y';
import { SharedTabSwitcherModule } from 'src/app/shared/controls/tab-switcher';
import { PopUpModule } from 'src/app/shared/controls/dialogs/pop-up/pop-up.module';
import { SimpleAnnouncementComponent } from './simple-announcement/simple-announcement.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        SharedLoadingDirectiveModule,
        SharedFileDownloadModule,
        SharedTooltipModule,
        CoreCommonModule,
        A11yModule,
        SharedTabSwitcherModule,
        PopUpModule
    ],
    declarations:
        [
            NotificationView,
            NotificationListingComponent,
            SimpleNotificationComponent,
            SimpleAnnouncementComponent
        ],
    providers:[DatePipe],
    exports: [
        NotificationView
    ]

})

export class NotificationModule  {

}
