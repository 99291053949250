import { Injectable } from '@angular/core';
import { FileEndpoint } from './file-endpoint.service';


@Injectable()
export class FileService {
    constructor(
        private fileEndpoint: FileEndpoint
    ) { }

    getResource(url:string) {
        return this.fileEndpoint.getResource(url);
    }
}
