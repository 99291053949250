/* tslint:disable */
export function BodyBinding(attributeBinding: string): PropertyDecorator {
    return ((target: any, key: string) => {
        let bodyBindingClass = class {
            value;

            constructor(target: any, propertyKey: string) {
                let valueReference = this.value;

                //Define new property to watch for value changes
                Object.defineProperty(target, propertyKey, {
                    configurable: true,
                    get: function() {
                        return valueReference;
                    },
                    set: function(value: any) {
                        const [attr, attrValue] = attributeBinding.split('.');

                        if (value) {
                            //Add value
                            if (attr === 'class') {
                                document.body.classList.add(attrValue);
                            } else {
                                document.body.setAttribute(attr, attrValue);
                            }
                        } else {
                            //Remove value
                            if (attr === 'class') {
                                document.body.classList.remove(attrValue);
                            } else {
                                document.body.removeAttribute(attr);
                            }
                        }
                        valueReference = value;
                    }
                });
            }
        };

        return new bodyBindingClass(target, key);
    });
}