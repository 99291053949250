export enum IntergratedApplicationTypes
{
    Default = 1,
    MobiControl = 2,
    SotiConnect = 3,
    ResourceShare = 9,
    SotiInsight = 4,
    SotiSnap = 7,
    SotiAssist = 8,
    SotiCentral = 10,
    Broker = 11,
    ThirdParty = 12,
    SotiXsight = 14,
}