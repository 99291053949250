import {
    animate,
    state,
    style,
    transition,
    trigger,
    AnimationTriggerMetadata,
    group,
    query
} from '@angular/animations';

/** Time and timing curve for expansion panel animations. */
export const CUBIC_BEZIER_ANIMATION = 'cubic-bezier(0.4,0.0,0.2,1)';
export const EXPANSION_PANEL_ANIMATION_TIMING = '225ms cubic-bezier(0.4,0.0,0.2,1)';

export const sotiExpansionAnimations: {
    readonly indicatorRotate: AnimationTriggerMetadata;
    readonly bodyExpansion: AnimationTriggerMetadata;
    readonly gridRowExpansion: AnimationTriggerMetadata;
} = {
    /** Animation that rotates the indicator arrow. */
    indicatorRotate: trigger('indicatorRotate', [
        state('collapsed', style({transform: 'rotate(0deg)'})),
        state('expanded', style({transform: 'rotate(-90deg)'})),
        transition('expanded <=> collapsed', animate(EXPANSION_PANEL_ANIMATION_TIMING)),
    ]),

    /** Animation that expands and collapses the panel content. */
    bodyExpansion: trigger('bodyExpansion', [
        state('collapsed', style({height: '0px', visibility: 'hidden'})),
        state('expanded', style({height: '*', visibility: 'visible'})),
        transition('expanded <=> collapsed', animate(EXPANSION_PANEL_ANIMATION_TIMING)),
    ]),

    /** Animation that expands and collapses a row group in the new table component. */
    gridRowExpansion: trigger('detailExpand', [
        state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
        state('expanded', style({ height: '*', visibility: 'visible' })),
        transition('expanded => collapsed', [
            group([
                query('soti-cell', animate(`50ms ${CUBIC_BEZIER_ANIMATION}`, style({ borderColor: 'transparent' }))),
                animate(EXPANSION_PANEL_ANIMATION_TIMING)
            ])
        ]),
        transition('collapsed => expanded', [
            group([
                query('soti-cell', animate('0ms', style({ borderColor: 'transparent' }))),
                animate(EXPANSION_PANEL_ANIMATION_TIMING)
            ])
        ])
    ])
};
