import { NgModule } from '@angular/core';
import { ModalDialog2Module, ModalDialogModule } from '../../shared/controls/dialogs';
import { SharedInputIconModule } from '../../shared/controls/input-icon';
import { StatusNotificationBarModule } from '../../shared/controls/toaster-messages/shared-status-notification-bar.module';
import { DashboardModule } from '../Dashboard/dashboard.module';
import { CopyrightModule } from '../copyright/copyright.module';
import { ProfileModalModule } from './Modals/profile-modal/profile-modal.module';
import { ProfileDetailModule } from './profile-detail/profile-detail.module';
import { ProfileManagementViewComponent } from './profile-management.view';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateLanguageLoader } from 'src/app/services/app-translation.service';

@NgModule({
    imports: [
        SharedInputIconModule,
        ModalDialogModule,
        ModalDialog2Module,
        StatusNotificationBarModule,
        DashboardModule,
        CopyrightModule,
        ProfileModalModule,
        ProfileDetailModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
    ],
    declarations: [
        ProfileManagementViewComponent
    ],
    exports: [
        ProfileManagementViewComponent
    ],
    providers: [
        ProfileManagementViewComponent
    ]
})
export class ProfileManagementModule {}