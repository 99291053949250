import {Component, Input, OnInit, Output, EventEmitter, HostListener, HostBinding, ElementRef} from '@angular/core';

@Component({
    selector: 'soti-checkbox-button, soti-checkbox',
    templateUrl: 'checkbox-button.control.html',
    styleUrls: ['checkbox-button.control.scss']
})
export class CheckboxButtonControl {

    @Input()
    @HostBinding('attr.aria-checked')
    public checked: boolean = false;

    @Input()
    public disable: boolean = false;

    @Output()
    public checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @HostBinding('class.checked')
    public get getChecked(): boolean {
        return this.checked;
    }

    constructor(public elementRef: ElementRef) {
    }

    ngOnInit(): void {
        if(!this.disable)
        {
            this.elementRef.nativeElement.addEventListener('click', () => this._toggle());
        }
    }

    private _toggle(): void {
        this.checked = !this.checked;
        this.checkedChange.emit(this.checked);
    }

}