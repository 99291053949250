/**
 * Created by jcammisuli on 2017-04-19.
 */
import { Component, Host, OnInit, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'pop-up-title',
    templateUrl: './pop-up-title.ctrl.html',
    styleUrls: ['./pop-up-title.ctrl.scss']
})
export class PopUpTitleControl implements OnInit {

    @Input()
    @HostBinding('class.border')
    public border: boolean = true;

    constructor() {
    }

    public ngOnInit(): void {
    }
}