
import { Injectable } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { Observable ,  Subject ,  BehaviorSubject } from "rxjs";
import "rxjs/add/operator/map";
@Injectable()
export class ControlStatusService {
    constructor() {
        this.FormOkButtonEnableSubscriber.subscribe((value) => {

            this.IsFormOkButtonEnable = value;
        });;
    }
    public FormOkButtonEnableSubscriber: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public IsFormOkButtonEnable: boolean = false;


}