import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { CoreCommonModule } from '../../../../shared/core-common.module';
import { TranslateLanguageLoader } from '../../../../services/app-translation.service';
import { SharedDialogsModule } from '../../../../shared/controls/dialogs';
 
import { SharedInlineSearchModule } from '../../../../shared/controls/inline-search';
import { SharedFormPanelModule } from '../../../../shared/controls/form-panel';
import { ContactUs } from './contactus.ctrl';


@NgModule({
    imports: [
        CoreCommonModule,
        SharedDialogsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
         
        SharedFormPanelModule
    ],
    declarations: [
        ContactUs
    ],
    exports: [
        ContactUs
    ]
})
export class ContactUsModule {
}
