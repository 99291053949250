import { Component, OnInit, Host, Input, Output, EventEmitter } from '@angular/core';

import {ModalControl} from '../modal/modal.ctrl';

@Component({
    selector: 'modal-footer',
    templateUrl: './modal-footer.ctrl.html',
    styleUrls: ['./modal-footer.ctrl.scss']
})
export class ModalFooterControl implements OnInit {
    constructor( @Host() _modal: ModalControl) { }

    public ngOnInit() { }
}