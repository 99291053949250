import { EndpointFactory } from "../endpoint-factory.service";
import { oAuthBaseConnectionModel } from '../../models/oAuthBaseConnectionModel';
import { oAuthBaseLoginUrlParams  } from '../../models/oAuthBaseLoginUrlParams';
import { oAuthBaseConnectionFilterParams } from '../../models/oAuthBaseConnectionFilterParams';
import { Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigurationService } from "../configuration.service";
import { Observable } from "rxjs";
import { GSuiteParams } from "src/app/models/GSuiteParams.model";

@Injectable()
export class GSuiteEndpoint extends EndpointFactory {
    private readonly _connectionUrl: string = 'Connection';
    private readonly _gsuiteUrl: string = '/GSuite/';
    private readonly _filteredGSuiteUrl: string = '/filteredGSuite';
    private readonly _gsuiteUrlParamsUrl: string = 'UrlParams';
    
    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {
        super(http, configurations, injector);
    }
    public getGSuiteConnections(gsuiteFilteringParams?: oAuthBaseConnectionFilterParams): Observable<oAuthBaseConnectionModel[]>{
        return this.http.post<oAuthBaseConnectionModel[]>(this.getGSuiteFilteredUrl, gsuiteFilteringParams , this.getRequestHeaders());
    }
    public getGSuiteLoginUrlParams(name: string, selectedDomains: string[], eventType: number): Observable<oAuthBaseLoginUrlParams> {
        let gsuiteParam = new GSuiteParams();
        gsuiteParam.gsuiteName = name;
        gsuiteParam.EventType = eventType;
        gsuiteParam.SelectedDomainReferenceIds = selectedDomains;
        return this.http.post<oAuthBaseLoginUrlParams>(this.getGSuiteUrlParams, gsuiteParam , this.getRequestHeaders());
    }
    public getGSuiteConnectionDataById(connectionId: string): Observable<oAuthBaseConnectionModel> {
        const endpointUrl = `${this.getGSuiteUrl}` + connectionId;
        return this.http.get<oAuthBaseConnectionModel>(endpointUrl, this.getRequestHeaders());
    }
    public updateGSuiteConnection(param : oAuthBaseConnectionModel): Observable<oAuthBaseConnectionModel> {
        return this.http.put<oAuthBaseConnectionModel>(`${this.getGSuiteUrl}` + param.ConnectionReferenceId, JSON.stringify(param) , this.getRequestHeaders());
    }
    private get getGSuiteUrl(): string {
        return this.configurations.baseUrl + this._connectionUrl + this._gsuiteUrl;
    }

    private get getGSuiteFilteredUrl(): string {
        return this.configurations.baseUrl + this._connectionUrl + this._filteredGSuiteUrl;
    }
    
    private get getGSuiteUrlParams(): string {
        return this.configurations.baseUrl + this._connectionUrl + this._gsuiteUrl + this._gsuiteUrlParamsUrl;
    }
}
