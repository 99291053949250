/**
 * Created by jcammisuli on 2016-05-18.
 */
import {Component, Host, Input, HostListener, Optional, ElementRef, ViewChild} from '@angular/core';
import {DropdownComponent} from '../dropdown/dropdown.component';

const MENU_PADDING: number = 9;
const WND_PADDING: number = 40;

@Component({
    selector: 'soti-sub-dropdown',
    templateUrl: './sub-dropdown.component.html',
    styleUrls: ['./sub-dropdown.component.scss']
})
export class SubDropdownComponent {

    @Input()
    public title: string = 'show more';
    @Input()
    public icon: string = '';

    /**
     * This list contains the position of the subdropdown in the dropdown list, and the number of items in the
     * dropdown list itself. These values are then used to align the subdropdown menu with the top/bottom of the
     * dropdown list, based on if the subdropdown needs to open upwards/downwards. For example, the colour picker
     * is the second element in the device group context menu, which contains 4 items.
     */
    @Input()
    public listPosition: number[] = [1, 1];

    @ViewChild('menu')
    public menuElem: ElementRef;

    public panelUp: string;
    public panelDown: string;
    public visibility: string;

    public showDropdown: boolean = false;

    constructor(@Optional() @Host() private _dropdown: DropdownComponent, private _elementRef: ElementRef) {
    }

    @HostListener('touchstart', ['$event'])
    @HostListener('mouseenter', ['$event'])
    private _showContent(): void {

        this.visibility = 'hidden';
        this.showDropdown = true;

        // let it actually show before calculating
        setTimeout(() => {
            let btnY = this._elementRef.nativeElement.getBoundingClientRect().top;
            let wndH = window.innerHeight;
            let menuH = this.menuElem.nativeElement.clientHeight;

            let availY = wndH - btnY - WND_PADDING;
            let menuOff = Math.min(availY - menuH, -MENU_PADDING);

            this.panelUp = `${menuOff}px`;
            this.panelDown = 'auto';
            this.visibility = 'visible';
        });
    }

    @HostListener('mouseleave')
    private _hideContent(): void {
        this.showDropdown = false;
    }
}