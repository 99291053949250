import { Component, Host, Input, OnInit } from '@angular/core';
import { ModalControl } from '../modal/modal.ctrl';


@Component({
    selector: 'modal-body',
    templateUrl: './modal-body.ctrl.html',
    styleUrls: ['./modal-body.ctrl.scss']
})
export class ModalBodyControl implements OnInit {

    @Input() public title: string;
    @Input() public titleName: string;
    @Input() public name: string;
    @Input() public icon: string;
    @Input() public helpId: string;
    @Input() public showActions: boolean = false;
    @Input() public showHelpBtn: boolean = false;
    @Input() public disabled: boolean = false;

    constructor( @Host() private _modal: ModalControl) { }

    public ngOnInit():void { 
        this._modal.title = this.title;
        this._modal.titleName = this.titleName;
    }

    public close(): void {
        this._modal.close();
    }

}