import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { CoreCommonModule } from '../../../core-common.module';
import { ModalDialogService2 } from './modal-dialog-2.service';
 
@NgModule({
    imports: [CoreCommonModule, OverlayModule],
    declarations: [],
    exports: [],
    providers: [ModalDialogService2]
})
export class ModalDialog2Module {}
