/**
 * Created by jcammisuli on 2016-03-26.
 */
import {Type} from '@angular/core';
import { ModalBase } from '../../modal-control';

/**
 * Base class for all modal events
 * 
 * @export
 * @class ModalMetadata
 */
export class ModalMetadata {
}

/**
 * Used to open a new modal with the modal dialog service
 * 
 * @export
 * @class NewModal
 * @extends {ModalMetadata}
 */
export class NewModal extends ModalMetadata {
    constructor(public component: Type<ModalBase>, public input?) {
        super();
    }
}

/**
 * Used to close the modal with the modal dialog service
 * 
 * @export
 * @class CloseModal
 * @extends {ModalMetadata}
 */
export class CloseModal extends ModalMetadata {
}