import {NgModule} from '@angular/core';
import {CoreCommonModule} from '../../core-common.module';
import { SotiHelpButton } from './soti-help-button.ctrl';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateLanguageLoader } from '../../../services/app-translation.service';

@NgModule({
    imports: [
        CoreCommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        })
    ],
    declarations: [
        SotiHelpButton
    ],
    exports: [
        SotiHelpButton
    ]
})
export class SharedHelpModule {}