import { Language } from './../../models/language.model';
import { EncryptionType } from 'src/app/models/EncryptionType.model';
import { LdapType } from 'src/app/models/ldapType.model';
import { AuthenticationType } from 'src/app/models/authenticationType.model';
import { SystemParams } from 'src/app/models/systemparams.model';
import { DBkeys } from './../db-keys';

import { tap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs';
import { EndpointFactory } from '../endpoint-factory.service';
import { Observable } from 'rxjs/Observable';
import { ConfigurationService } from '../configuration.service';
import { User } from 'src/app/models/user.model';
import { SystemAttributes } from 'src/app/models/systemattributes.model';
import { LogoutResponse } from 'src/app/models/logoutResponse.model';
import { ChangePassword } from 'src/app/models/ChangePassword.model';
import { CaptchaResult } from 'src/app/models/captchaResult.model';
import { LocalStoreManager } from '../local-store-manager.service';
import { ValidateResetPasswordTokenResponse } from 'src/app/models/validateResetPasswordTokenResponse';
import { ValidateGAuthTokenResponse } from 'src/app/models/validateGAuthTokenResponse';
import { ForgotPassword } from 'src/app/models/ForgotPassword.modal';
import { SystemAttributeType } from 'src/app/shared/enums/SystemAttributeType';
import { ContactUsMessage } from 'src/app/models/ContactUsMessage';
import { LoginRedirectInfo } from 'src/app/models/login-redirect-indo.model';
import { Certificate } from 'src/app/models/certificate';
import { CertificateValidationModel } from 'src/app/models/certificateValidationModel';
import { Algorithms } from 'src/app/models/algorithm.model';
import { NameIdFormats } from 'src/app/models/nameIDFormat.model';
import { ValidateMicrosoftAuthSetupTokenResponse } from 'src/app/models/validateMicrosoftAuthTokenResponse';
import { Localization } from 'src/app/models/Localization.model';
import { UICaptchaScreenEnum } from 'src/app/shared/enums//UICaptchaScreenEnum';
import { PortalPreference } from 'src/app/models/PortalPreferenceList.model';

@Injectable()
export class AccountEndpoint {

    public countriesCode: Subject<Array<any>> = new BehaviorSubject<Array<any>>(null);

    constructor(private http: HttpClient, private configurations: ConfigurationService, injector: Injector, private localstorageManager: LocalStoreManager) {

        //super(http, configurations, injector);
    }

    private controllerBaseUrl = 'Account';
    private readonly _resetpasswordUrl: string = this.controllerBaseUrl;
    private readonly _validateResetPasswordTokenUrl: string = this.controllerBaseUrl + '/ValidateToken';

    private readonly _validateGAuthTokenUrl: string = this.controllerBaseUrl + '/ValidateGAuthSetupToken';
    private readonly _validateMicrosoftAuthTokenUrl: string = this.controllerBaseUrl + '/ValidateMicrosoftAuthSetupToken';
    
    private readonly _currentUserUrl: string = 'user/users/me';
    private readonly _ssoredirectUrl: string = 'auth/samlrequest';
    private readonly _systemAttributesUrl: string = this.controllerBaseUrl + '/attributes'
    private readonly _systemParamsUrl: string = this.controllerBaseUrl + '/params'
    
    private readonly _getAuthTypeUrl: string = this.controllerBaseUrl + '/AuthenticationTypes';
    private readonly _getencryptionTypeUrl: string = this.controllerBaseUrl + '/EncryptionTypes';
    private readonly _getldapTypeUrl: string = this.controllerBaseUrl + '/LdapTypes';
    private readonly _relaystateValue: string = '?relayState=';
    private readonly _getPasswordRegex: string = this.controllerBaseUrl + '/PasswordComplexityRegex';
    private readonly _changePasswordUrl: string = this.controllerBaseUrl + '/ChangePassword';
    private readonly _captchaUrl: string = this.controllerBaseUrl + '/Captcha';
    private readonly _forgotPasswordUrl: string = 'user/SendForgotPasswordLink';
    private readonly _contactUsUrl: string = this.controllerBaseUrl + '/ContactUs';
    private readonly _tenantPasswordPolicy: string = this.controllerBaseUrl + '/PasswordPolicy';
    private readonly _loginRedirectInfo: string = 'auth/loginRedirectInfo';
    private readonly _validateCerficate: string = this.controllerBaseUrl + '/ValidateCertificate';
    private readonly _validateUserSession: string = this.controllerBaseUrl + '/validatesession';
    private readonly _getAlgorithms: string = this.controllerBaseUrl + '/Algorithm';
    private readonly _getNameIdFormats: string = this.controllerBaseUrl + '/NameIdFormat';
    private readonly _getLanguageListurl: string = this.controllerBaseUrl + '/LanguageList';
    private readonly _getPortalPreferenceListurl: string = this.controllerBaseUrl + '/PortalPreferenceList';
    private readonly _getCaptchaApplicableUrl: string = this.controllerBaseUrl + '/CaptchaApplicable';



    private get getTenantPasswordPolicyUrl() { return this.configurations.baseUrl + this._tenantPasswordPolicy; }

    private get IdpHostApiPath() { return this.configurations.idpHostApiPath + this._ssoredirectUrl; }
    private readonly _logOutPath: string = 'Authentication/logout';
    private readonly _logOutPathForRestrictedUser: string = 'Authentication/relogin';
    private get validateResetPasswordTokenUrl() { return this.configurations.baseUrl + this._validateResetPasswordTokenUrl; }

    private get validateGAuthTokenUrl() { return this.configurations.baseUrl + this._validateGAuthTokenUrl; }

    private get validateMicrosoftAuthTokenUrl(): string { return this.configurations.baseUrl + this._validateMicrosoftAuthTokenUrl; }

    private get getSystemAttributesUrl() { return this.configurations.baseUrl + this._systemAttributesUrl; }
    private get getSystemParamsUrl() { return this.configurations.baseUrl + this._systemParamsUrl; }
    private get validateSession() { return this.configurations.baseUrl + this._validateUserSession; }
    private get logoutUrl() { return this.configurations.baseUrl + this._logOutPath; }
    private get logoutUrlForRestrictedUser() { return this.configurations.baseUrl + this._logOutPathForRestrictedUser; }


    private get getAuthTypeUrl() { return this.configurations.baseUrl + this._getAuthTypeUrl; }
    private get getencryptionTypeUrl() { return this.configurations.baseUrl + this._getencryptionTypeUrl; }
    private get getldapTypeUrl() { return this.configurations.baseUrl + this._getldapTypeUrl; }

    public get getPasswordRegexUrl(): string { return this.configurations.baseUrl + this._getPasswordRegex; }

    public get getChangePasswordUrl(): string { return this.configurations.baseUrl + this._changePasswordUrl; }

    public get getCaptchaUrl(): string { return this.configurations.baseUrl + this._captchaUrl; }

    public get getForgotPasswordUrl(): string {return this.configurations.baseUrl + this._forgotPasswordUrl; }
    public get getContactUsUrl(): string {return this.configurations.baseUrl + this._contactUsUrl ; }
    public get getLoginRedirectInfoUrl(): string { return this.configurations.baseUrl + this._loginRedirectInfo; }
    public certificateValidate(): string { return this.configurations.baseUrl + this._validateCerficate; }
    public get getAlgorithm(): string { return this.configurations.baseUrl + this._getAlgorithms; }
    public get getNameIdFormat(): string { return this.configurations.baseUrl + this._getNameIdFormats; }
    public get getLanguageListurl(): string { return this.configurations.baseUrl + this._getLanguageListurl; }
    public get getPortalPreferenceListurl(): string { return this.configurations.baseUrl + this._getPortalPreferenceListurl; }
    public get getCaptchaApplicableUrl(): string { return this.configurations.baseUrl + this._getCaptchaApplicableUrl; }
    

    public validateResetPasswordToken(token: string): Observable<ValidateResetPasswordTokenResponse> {
        const endpointUrl = `${this.validateResetPasswordTokenUrl}/${token}`;
        return this.http.get<ValidateResetPasswordTokenResponse>(endpointUrl, this.getRequestHeaders());

    }

    public validateGAuthSetupToken(token: string): Observable<ValidateGAuthTokenResponse> {
        const endpointUrl = `${this.validateGAuthTokenUrl}/${token}`;
        return this.http.get<ValidateGAuthTokenResponse>(endpointUrl, this.getRequestHeaders());

    }

    public validateMicrosoftAuthSetupToken(token: string): Observable<ValidateMicrosoftAuthSetupTokenResponse> {
        const endpointUrl = `${this.validateMicrosoftAuthTokenUrl}/${token}`;
        return this.http.get<ValidateMicrosoftAuthSetupTokenResponse>(endpointUrl, this.getRequestHeaders());

    }

    public ValidateUserSession(): Observable<boolean> {
        return this.http.post<boolean>(this.validateSession, null, this.getFormDataRequestHeaders());
    }

    public readAndValidateCertificateFile(param: Certificate): Observable<CertificateValidationModel> {
        const uploadData = new FormData();
        if (param.file !== undefined && param.file !== null) {
            uploadData.append('certificateFile', param.file, param.file.name);
        }
        return this.http.post<CertificateValidationModel>(this.certificateValidate(), uploadData, this.getFormDataRequestHeaders());
    }

    public redirectToSSOLoginEndpoint(relayState: string): any {

        let endpointUrl = `${this.IdpHostApiPath}${this._relaystateValue}${relayState}`;

        return this.http.get(endpointUrl, this.getRequestHeaders());
    }

    public getSystemAttributes(systemAttribute: SystemAttributeType): Observable<SystemAttributes[]> {
        let options = new HttpParams()
            .set('systemAttributeType', systemAttribute.toString());
        const endpointUrl = this.getSystemAttributesUrl;
        return this.http.get<SystemAttributes[]>(endpointUrl, { headers: this.getHeaders(), params: options, withCredentials: true });
    }
    public getSystemParams(): Observable<SystemParams> {
        const endpointUrl = this.getSystemParamsUrl;
        return this.http.get<SystemParams>(endpointUrl, this.getRequestHeaders());
    }
    public logoutUser(): Observable<LogoutResponse> {
        const endpointUrl = this.logoutUrl;
        return this.http.post<LogoutResponse>(endpointUrl, null, this.getRequestHeaders());
    }
    public logoutForRestrictedUser(relayState: string): Observable<LogoutResponse> {
        let options = new HttpParams();           
        options = options.append('relayState', relayState);
        const endpointUrl = this.logoutUrlForRestrictedUser;
        return this.http.post<LogoutResponse>(endpointUrl, null, { headers: this.getHeaders(), params: options, withCredentials: true });
    }
    public getPasswordPolicyRegex(): Observable<string> {
        return this.http.get<string>(this.getPasswordRegexUrl, this.getRequestHeaders());
    }
    public changePassword(changePassword: ChangePassword): Observable<void> {
        return this.http.post<void>(this.getChangePasswordUrl, JSON.stringify(changePassword), this.getRequestHeaders());
    }

    public getCaptcha(): Observable<CaptchaResult> {
        var endpointUrl = this.getCaptchaUrl;
        return this.http.get<CaptchaResult>(endpointUrl, this.getRequestHeaders());

    }

    public forgotPassword(forgotPassword: ForgotPassword, token: string): Observable<void> {
        return this.http.post<void>(this.getForgotPasswordUrl, JSON.stringify(forgotPassword), this.getRequestHeadersByToken(token));
    }

    public sendContactUsMessage(message: ContactUsMessage): Observable<void>{
        return this.http.post<void>(this.getContactUsUrl, JSON.stringify(message), this.getRequestHeaders());
    }

    public getLoginRedirectInfo(token: string, subStatus: string): Observable<LoginRedirectInfo> {
        var endpointUrl = this.getLoginRedirectInfoUrl + '?subStatus=' + subStatus;
        return this.http.get<LoginRedirectInfo>(endpointUrl, this.getRequestHeadersByToken(token));
    }

    public getAlgorithms(): Observable<Algorithms[]> {
        const endpointUrl = this.getAlgorithm;
        return this.http.get<Algorithms[]>(endpointUrl, this.getRequestHeaders());
    }

    protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; }, withCredentials: boolean } {
        const headers = new HttpHeaders({          
            'Content-Type': 'application/json',
            'Accept': `application/vnd.iman.v${EndpointFactory.apiVersion}+json, application/json, text/plain, */*`,
            'App-Version': ConfigurationService.appVersion
        });

        return { headers: headers, withCredentials: true };
    }

    protected getRequestHeadersByToken(token: string): { headers: HttpHeaders | { [header: string]: string | string[]; }, withCredentials: boolean } {
        const headers = new HttpHeaders({           
            'Content-Type': 'application/json',
            'Accept': `application/vnd.iman.v${EndpointFactory.apiVersion}+json, application/json, text/plain, */*`,
            'App-Version': ConfigurationService.appVersion
        });

        return { headers: headers, withCredentials: true};
    }

    protected getFormDataRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; }, withCredentials: boolean } {
        const headers = new HttpHeaders({         
        });
           return { headers: headers, withCredentials: true };
    }

    protected getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({           
            'Content-Type': 'application/json',
            'Accept': `application/vnd.iman.v${EndpointFactory.apiVersion}+json, application/json, text/plain, */*`,
            'App-Version': ConfigurationService.appVersion
        });

        return headers;
    }

    protected getParams(params: object): HttpParams {
        let httpParams = new HttpParams();
        Object.keys(params)
            .filter(key => {
                const v = params[key];
                return (Array.isArray(v) || typeof v === 'string') ?
                    (v.length > 0) :
                    (v !== null && v !== undefined);
            })
            .forEach(key => {
                httpParams = httpParams.set(key, params[key]);
            });
        return httpParams;
    }

    public getNameIdFormats(): Observable<NameIdFormats[]> {
        const endpointUrl = this.getNameIdFormat;
        return this.http.get<NameIdFormats[]>(endpointUrl, this.getRequestHeaders());

    }

    public getLanguageList(): Observable<Language[]> {
        const endpointUrl = this.getLanguageListurl;
        return this.http.get<Language[]>(endpointUrl, this.getRequestHeaders());

    }

    public getPortalPreferenceList(): Observable<PortalPreference[]> {
        const endpointUrl = this.getPortalPreferenceListurl;
        return this.http.get<PortalPreference[]>(endpointUrl, this.getRequestHeaders());

    }

    public getCaptchaApplicable(uiScreen: UICaptchaScreenEnum): Observable<boolean> {
        const endpointUrl = this.getCaptchaApplicableUrl + '?uiCaptchaScreen=' + uiScreen;
        return this.http.get<boolean>(endpointUrl, this.getRequestHeaders())
    }
    
}
