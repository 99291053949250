
import {Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'success-msg',
    templateUrl: './success-msg.component.html',
    styleUrls: ['./success-msg.component.scss']
})

export class Successmsg implements OnInit {
    @Input()  title: String = '';
    @Input()  sucessMessage: String = '';
    constructor() {

    }
    ngOnInit() {
     console.log(this.sucessMessage);

    }
} 
