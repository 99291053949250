import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Utilities } from 'src/app/services/utilities';

@Component({
    selector: 'not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent  {

  public defaultImage = Utilities.getImagePath('page-not-found-mobile.png');

  constructor(private location: Location) {

  }
  
  public backClicked(): void {
    this.location.back();
  }
}
