import { Component } from '@angular/core';
import { Utilities } from 'src/app/services/utilities';

@Component({
    selector: 'app-under-maintenance',
    templateUrl: './under-maintenance.view.html',
    styleUrls: ['./under-maintenance.view.scss']
})

export class UnderMaintenance {   

    public defaultImage = Utilities.getImagePath('under-maintenance-icon.png');
    
}