
import { Component, Inject } from '@angular/core';
import { ModalDialogRef, SOTI_MODAL_DATA } from 'src/app/shared/controls/dialogs';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { ModalDialogService2 } from '../../../../shared/controls/dialogs';

export class ProfileModalParam {
    public sotionetheme: boolean;
    constructor(sotiOneTheme: boolean) {
        this.sotionetheme = sotiOneTheme;
    }
}
@Component({
    selector: 'profile-modal',
    styleUrls: ['./profile-modal.ctrl.scss'],
    templateUrl: './profile-modal.ctrl.html'
})

export class ProfileModal {
    public submitted: boolean;
    public sotionetheme: boolean ;

    constructor(
        private _modalRef: ModalDialogRef<ProfileModal>,
        private translationService: AppTranslationService,
        private _modalDialogService: ModalDialogService2,
        @Inject(SOTI_MODAL_DATA) public data: ProfileModalParam
    ) {
        this.sotionetheme = this.data.sotionetheme;
    }
    public onDoneClick(): void {
        this._modalDialogService.closeModal();
    }
}
