import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateLanguageLoader } from 'src/app/services/app-translation.service';
import { CoreCommonModule } from './../../core-common.module';
import { FileUploadControl } from './file-upload.ctrl';
@NgModule({
    imports: [
        CoreCommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
    ],
    declarations: [
        FileUploadControl
    ], 
    exports: [
        FileUploadControl
    ]
})

export class SharedFileUploadModule {}