/**
 * Created by pguimaraes on 11/11/2016.
 */

import {ClientMessage} from './ClientMessage';

export enum ActionResultType {
    Success = 0,
    Error = 1
}

export class ActionResultMessage extends ClientMessage {
    private _actionResultType: ActionResultType;
    private _actionName: string;
    private _deviceIds: string[] = null;
    private _groupPath: string = null;

    constructor(actionResultType: ActionResultType, actionName: string, deviceIds: string[])
    constructor(actionResultType: ActionResultType, actionName: string, groupPath: string)
    constructor(actionResultType: ActionResultType, actionName: string, devicesOrGroup: string[] | string) {
        super();
        this._actionResultType = actionResultType;
        this._actionName = actionName;
        if (devicesOrGroup instanceof Array) {
            this._deviceIds = devicesOrGroup;
        } else {
            this._groupPath = devicesOrGroup;
        }
    }

    public get actionResultType(): ActionResultType {
        return this._actionResultType;
    }

    public get actionName(): string {
        return this._actionName;
    }

    public get deviceIds(): string[] {
        return this._deviceIds;
    }

    public get groupPath(): string {
        return this._groupPath;
    }
}