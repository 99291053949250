import {NgModule} from '@angular/core';
import {FormPanelCtrl} from './form-panel.ctrl';
import {FormPanelBodyCtrl} from './form-panel-body';
import {FormPanelHeaderCtrl} from './form-panel-header';
import {CoreCommonModule} from '../../core-common.module';

@NgModule({
    imports: [
        CoreCommonModule,
    ],
    declarations: [
        FormPanelCtrl,
        FormPanelBodyCtrl,
        FormPanelHeaderCtrl
    ],
    exports: [
        FormPanelCtrl,
        FormPanelBodyCtrl,
        FormPanelHeaderCtrl
    ],
    providers: [],
})
export class SharedFormPanelModule {
}
