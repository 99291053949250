import {isBoolean} from 'lodash';
import {Injectable, OnDestroy, Type} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import 'rxjs/operator/debounceTime';

import {NewModal, CloseModal, ModalMetadata} from '../modal-dialog-model/modal-dialog.model';
import {ModalBase} from '../../modal-control';
/**
 * Service for handling the opening and closing of the modal dialog
 * 
 * @deprecated Use `ModalDialogService2`
 */
@Injectable()
export class ModalDialogService implements OnDestroy {

    /**
     * Get the modal data stream
     *
     * @readonly
     */
    public get modalData(): Observable<ModalMetadata> {
        return this._modalData.asObservable();
    }

    /**
     * Triggers when dialog window opens (true) or closes (false).
     * @returns {Observable<boolean>}
     */
    public get openedOrClosed(): Observable<boolean> {
        return this._modalData
            .map(d => {
                if (d instanceof NewModal) {
                    return true;
                } else if (d instanceof CloseModal) {
                    return false;
                }
                return null;
            })
            .filter(b => isBoolean(b));
    }

    /**
     * Modal subject
     *
     * @private
     * @type {Subject<ModalMetaData>}
     */
    private _modalData: Subject<ModalMetadata> = new Subject<ModalMetadata>();

    constructor() {
    }

    public ngOnDestroy(): void {
        this._modalData.complete();
    }

    /**
     * Open dialog with a specified component and an optional input
     * @param component Component used as the modal.
     * @param input Input options that the component uses.
     */
    public open<T extends ModalBase>(component: Type<T>, input?: T['input']): void {
        this._modalData.next(new NewModal(component, input));
    }

    /**
     * Close dialog
     */
    public close(): void {
        this._modalData.next(new CloseModal());
    }
}
