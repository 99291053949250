
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateLanguageLoader, AppTranslationService } from '../../../services/app-translation.service';
import { BrowserModule } from '@angular/platform-browser';
import { ProfileDetailComponent } from './profile-detail.component';



@NgModule({

    imports: [
        BrowserModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        })
     ],
    declarations:
     [
        ProfileDetailComponent
    ],
    exports: [
        ProfileDetailComponent
    ],
     providers: [
    ],
})
export class ProfileDetailModule {
}
