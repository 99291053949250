import { Component, AfterViewInit, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-form-submit',
    templateUrl: './appFormSubmit.ctrl.html',
})
export class AppFormSubmit implements OnInit,  AfterViewInit {

    @Input() public applicationReferenceId: string;
    @Input() public token: string;
    @Input() public submitUrl: string;
    @Input() public clientTenantReferenceId: string;
    constructor(
        private authService: AuthService,
    ) {

    }
    public ngOnInit(): void {
        this.authService.isHeaderRequired.next(false);
    }
    public ngAfterViewInit(): void {
        let submitForm: HTMLFormElement = document.getElementById('submitForm') as HTMLFormElement;
        submitForm.submit();
    }
}
