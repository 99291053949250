import { HttpHeaders } from "@angular/common/http";
import { AuthService } from './auth.service'
import { Injectable } from "@angular/core";
@Injectable()
export class HttpHeaderService {
    headers = null;
    constructor(private authService: AuthService) {

    }
    public getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {

        if (this.authService.accessToken) {
            this.headers = new HttpHeaders({
                'Authorization': `Bearer ${this.authService.accessToken}`,
                'Content-Type': "application/json"

            });
        }
        else {
            this.headers = new HttpHeaders({               
                'Content-Type': "application/json"

            });
        }
        return { headers: this.headers };
    }
}