export enum ErrorCode {
    GroupAlreadyExists = 1002,
    TokenExpired = 1004,
    InvalidToken = 1051,
    DuplicateUserEmail = 1006,
    InvalidCredentials = 1008,
    ServerUnreachable = 1009,
    DomainAlreadyRegistered = 1011,
    InvalidFileFormatServerError = 1016,
    InvalidFileDimensionsServerError = 1017,
    DuplicateApplication = 1031,
    InvalidDomainName = 1038,
    DuplicateDomainName = 1039,
    DuplicateBaseDn = 1040,
    ExpiredDomainToken = 1042,
    CloudLinkAgentAlreadyExists = 1043,
    PasswordPolicyChanged = 1047,
    DomainAssociatedWithUsers = 1048,
    DomainAssociatedWithLdap = 1049,
    InvalidCaptcha = 1051,
    ResetTokenExpired = 1052,
    UnAuthorisedUserAction = 1058,
    LockedUser = 1061,
    DuplicateUserName = 1050,
    DuplicateIdpName = 1018,
    DuplicateAzureADName = 1066,
    DuplicateAzureADConnection = 1068,
    AccessTokenIsNotValid = 1069,
    AzureAdRefreshTokenIsNotValid = 1070,
    
    DuplicatePolicyName = 1088,
    DeactivatedByApi = 1103,
    DomainAssociatedWithLdapAndIDP = 1106,
    DomainAssociatedWithIDP = 1107,
    MaxRowSizeExceed = 1115,
    InValidFileFormat = 1116,
    InValidFileTemplate = 1117,
    MinRowSize = 1118,
    ApplicationNotRegistered = 1121,
    LastUserGroup = 1024,
    LastPasswordMatch = 1026,
    NotificationAlreadyExists = 1125,
    DuplicateSupportUser = 1030,
    DuplicateGSuiteConnection = 1126,
    DuplicateGSuiteName = 1127,
    GSuiteRefreshTokenIsNotValid = 1133,
    GSuiteAccessTokenIsNotValid = 1134,
    ErrorAssignResourceShareApp = 1056,
    InvalidOtp = 1003,
    ToManyRequest = 429,
    DuplicateAuthority = 1142,
    DuplicateCertificate = 1143,
    PrimaryAppAssociatedToApplicationGroup = 1144,
    InvalidCredentialsAndEnforceCaptcha = 1147,

    CannotAssignCentralApp = 1029,
    CannotAssignSnapApp_Email = 1149,
    CannotAssignSnapApp_LastName = 1148,
    
    DirectoryPolicyExists = 1158,
    ApplicationPolicyExists = 1159,
    InvalidMSP = 1167,
    ClientRequestPending = 1168,
    ClientAlreadyLinked = 1169,
    ImageSizeExceeded = 1166,
    MaxPolicyLimit =1173,
    DeleteGroupAppAccessPolicyAttachedToGroup = 1174,
    DeleteGroupAppAccessPolicyAttachedToOneOfMany = 1175,
    DeleteGroupAppAccessPolicyAttachedToMany = 1176,
    DeleteGroupBothPoliciesAttached = 1177,

    DeleteUserAppAccessPolicyAttachedToGroup = 1178,
    DeleteUserAppAccessPolicyAttachedToOneOfMany = 1179,
    DeleteUserAppAccessPolicyAttachedToMany = 1180,
    DeleteUserBothPoliciesAttached = 1181,

    DeleteUserAndGroupAttachedToAppAccessPolicy = 1182,
    DeleteUserAndGroupAttachedToBothPolicy = 1183,

    DeactivateGroupAppAccessPolicyAttachedToGroup = 1184,
    DeactivateGroupAppAccessPolicyAttachedToOneOfMany = 1185,
    DeactivateGroupAppAccessPolicyAttachedToMany = 1186,
    DeactivateGroupBothPoliciesAttached = 1187,
    PasskeyNameAlreadyExist = 1171,

    BrokerConnectFailed = 1190,

}