import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Constants } from 'src/app/constants';
import { AnnouncementNotification } from '../models/announcementLog.model';
import { NotificationTypeEnum } from '../shared/enums/notification-type.enum';
import { BaseNotification } from '../models/base-notification.model';
import { BulkOperationNotification } from '../models/bulk-operation-notification.model';
import { DownloadEventLogNotification } from '../models/download-eventLog-notification.model';
import { NotificationFilteringParams } from '../models/NotificationFilteringParams';
import { NotificationResponse } from '../models/notificationResponse.model';
import { appMessenger } from '../shared/controls/toaster-messages/AppMessenger';
import { AlertSeverityEnum } from '../shared/enums/AlertSeverityEnum';
import { NotificationStatusEnum } from '../shared/enums/NotificationStatusEnum';
import { ClientNotificationMessage, NotificationType } from '../shared/utils/toast-utils/ClientNotificationMessage';
import { AppTranslationService } from './app-translation.service';
import { NotificationEndpoint } from './notification-endpoint.service';
import { NotificationActionType } from '../shared/enums/NotificationActionType';
import { RedirectNotification } from '../models/redirect-notification.model';

@Injectable()
export class NotificationService {

    private _notifier: Subject<BaseNotification> = new Subject();

    private _recentNotifications: BaseNotification[];
    private _recentAnnouncements: BaseNotification[];

    private readonly showNotificationCount: number = 20;
    private _toastMessageShownNotificationIds: string[];

    public notifier(): Observable<BaseNotification> {
        return this._notifier.asObservable();
    }

    public get recentNotifications(): BaseNotification[] {
        return this._recentNotifications;
    }

    public set recentNotifications(notifications: BaseNotification[]) {
        this._recentNotifications = notifications;
    }

    public get recentAnnouncements(): BaseNotification[] {
        return this._recentAnnouncements;
    }

    public set recentAnnouncements(notifications: BaseNotification[]) {
        this._recentAnnouncements = notifications;
    }

    constructor(
        private notificationEndpoint: NotificationEndpoint,
        private translationService: AppTranslationService) {
        this._recentNotifications = [];
        this._recentAnnouncements = [];
        this._toastMessageShownNotificationIds = [];
    }

    public addNotification(notification: BaseNotification): void {
        this._recentNotifications = this._recentNotifications.filter(x => x.id !== notification.id);
        this._recentNotifications.unshift(notification);
        if (this._recentNotifications.length > this.showNotificationCount) {
            this._recentNotifications.pop();
        }
        this._notifier.next(notification);

        if (notification.Status === 2 && notification.text === "DownloadLogsFailureBody" && notification.title === "DownloadLogsFailureTitle") {
            appMessenger.postMessage(new ClientNotificationMessage(
                this.translationService.getTranslation('lbl_eventlog_file_generating_error'),
                this.translationService.getTranslation('lbl_eventlog_file_generating_error'),
                NotificationType.Error,
                AlertSeverityEnum.Minor));
        }
    }

    public initializeNotifications(refId: string): void {
        this._recentNotifications = [];
        this._recentAnnouncements = [];
        let filterparams: NotificationFilteringParams = new NotificationFilteringParams();
        this.notificationEndpoint.getNotificationEndpoint(refId, filterparams)
            .subscribe(result => {
                if (result && result.Result && result.Result.length > 0) {
                    result.Result.forEach((notification) => {
                        if (notification.Kind == Constants.NotificationKindPublic) {
                            let simpleAnnouncement: AnnouncementNotification = this.ConvertToAnnouncement(notification);
                            this._recentAnnouncements.push(simpleAnnouncement);
                        }
                        else {
                            const notificationActionType = notification.Data['NotificationActionType'];
                            let simpleNotification;
                            switch (notificationActionType) {
                                case NotificationActionType.NONE.toString(): {
                                    simpleNotification = this.ConvertToBulkNotification(notification);
                                    break;
                                }
                                case NotificationActionType.DOWNLOAD.toString(): {
                                    simpleNotification = this.ConvertToDownloadNotification(notification);
                                    break;
                                }
                                case NotificationActionType.REDIRECT.toString(): {
                                    simpleNotification = this.ConvertToRedirectNotification(notification);
                                    break;
                                }
                                default: {
                                    // to support previous notifications structure without 'NotificationActionType'
                                    if (notification.Data['EventLogNotification']) {
                                        simpleNotification = this.ConvertToDownloadNotification(notification);
                                    } else {
                                        simpleNotification = this.ConvertToBulkNotification(notification);
                                    }
                                    break;
                                }
                            }
                            this._recentNotifications.push(simpleNotification);
                        }
                    });

                }
            });
    }

    public ConvertToBulkNotification(notification: NotificationResponse): BulkOperationNotification {
        let simpleNotification: BulkOperationNotification = new BulkOperationNotification();
        simpleNotification.id = notification.Id;
        simpleNotification.title = notification.Title;
        simpleNotification.text = notification.Text;
        simpleNotification.translated = notification.Translated;
        simpleNotification.Status = this.setStatus(notification.Status);
        simpleNotification.read = notification.Read;
        simpleNotification.createdOn = new Date(notification.CreatedOn);
        if (notification.Data) {
            simpleNotification.notificationActionType = notification.Data['NotificationActionType'];
            if (notification.Data['TotalBatches']) {
                simpleNotification.totalBatches = parseInt(notification.Data['TotalBatches']);
            }
            if (notification.Data['TotalRecords']) {
                simpleNotification.totalRecords = parseInt(notification.Data['TotalRecords']);
            }
            if (notification.Data['SuccessRecords']) {
                simpleNotification.successRecords = parseInt(notification.Data['SuccessRecords']);
            }
            if (notification.Data['FailureRecords']) {
                simpleNotification.failedRecords = parseInt(notification.Data['FailureRecords']);
            }
            if (notification.Data['BatchExecuted']) {
                simpleNotification.batchExecuted = parseInt(notification.Data['BatchExecuted']);
            }

            simpleNotification.followUpUri = (notification.Data['ErrorFilePath']);
        }
        return simpleNotification;
    }

    public ConvertToDownloadNotification(notification: NotificationResponse): DownloadEventLogNotification {
        let simpleNotification: DownloadEventLogNotification = new DownloadEventLogNotification();
        simpleNotification.id = notification.Id;
        simpleNotification.title = notification.Title;
        simpleNotification.text = notification.Text;
        simpleNotification.translated = notification.Translated;
        simpleNotification.Status = this.setStatus(notification.Status);
        simpleNotification.read = notification.Read;
        simpleNotification.createdOn = new Date(notification.CreatedOn);
        if (notification.Data) {
            simpleNotification.notificationActionType = notification.Data['NotificationActionType'];
            simpleNotification.followUpUri = (notification.Data['FileDownloadPath']);
            simpleNotification.fileName = (notification.Data['FileName']);
        }
        return simpleNotification;
    }

    public ConvertToRedirectNotification(notification: NotificationResponse): RedirectNotification {
        let simpleNotification: RedirectNotification = new RedirectNotification();
        simpleNotification.id = notification.Id;
        simpleNotification.title = notification.Title;
        simpleNotification.text = notification.Text;
        simpleNotification.translated = notification.Translated;
        simpleNotification.Status = this.setStatus(notification.Status);
        simpleNotification.read = notification.Read;
        simpleNotification.createdOn = new Date(notification.CreatedOn);
        if (notification.Data) {
            simpleNotification.notificationActionType = notification.Data['NotificationActionType'];
            simpleNotification.redirectUri = (notification.Data['RedirectURI']);
            simpleNotification.data = notification.Data;
        }
        return simpleNotification;
    }

    public ConvertToAnnouncement(notification: NotificationResponse): AnnouncementNotification {
        let simpleAnnouncement: AnnouncementNotification = new AnnouncementNotification();
        simpleAnnouncement.id = notification.Id;
        simpleAnnouncement.title = notification.Title;
        simpleAnnouncement.text = notification.Text;
        simpleAnnouncement.translated = notification.Translated;
        simpleAnnouncement.Status = this.setStatus(notification.Status);
        simpleAnnouncement.read = notification.Read;
        simpleAnnouncement.createdOn = new Date(notification.CreatedOn);
        if (notification.Data) {
            simpleAnnouncement.SupportUrl = (notification.Data['SupportUrl']);
            simpleAnnouncement.ViewUpdateUrl = (notification.Data['ViewUpdateUrl']);
        }
        return simpleAnnouncement;
    }

    public updateNotificationReadStatus(refId: string, notificationIds: string[], type: NotificationTypeEnum): void {
        let arrayToFilter: string =
            type === NotificationTypeEnum.Announcement ?
                Constants.NotificationService.AnnouncementsVariable :
                Constants.NotificationService.AlertsVariable
        this.notificationEndpoint.updateNotificationReadStatus(refId, notificationIds).subscribe(() => {
            this[arrayToFilter].filter((x: BaseNotification) => notificationIds.includes(x.id)).forEach((x: BaseNotification) => x.read = true)
            this._notifier.next(undefined);
        });
    }

    public deleteNotifications(refId: string, notificationIds: string[], type: NotificationTypeEnum): void {
        switch (type) {
            case NotificationTypeEnum.Announcement:
                this.notificationEndpoint.deleteAnnouncements(refId, notificationIds).subscribe(() => {
                    this.initializeNotifications(refId);
                    this._notifier.next(undefined);
                },
                    (err) => {
                        this.onEndpointError(err)
                    });
                break;
            case NotificationTypeEnum.Alert:
            default:
                this.notificationEndpoint.deleteNotifications(refId, notificationIds).subscribe(() => {
                    this.initializeNotifications(refId);
                    this._notifier.next(undefined);
                },
                    (err) => {
                        this.onEndpointError(err)
                    });
        }
    }

    private onEndpointError(error: any) {
        let errorMessage = this.translationService.getTranslation('msg_notification_delete_failure');
        appMessenger.postMessage(new ClientNotificationMessage('',
            errorMessage,
            NotificationType.Error,
            AlertSeverityEnum.Minor));
    }

    public download(id: string): Observable<string> {
        return this.notificationEndpoint.download(id);
    }


    public isFinalToastMessageShownForBulkOperation(notificationId: string): boolean {
        var index = this._toastMessageShownNotificationIds.indexOf(notificationId);
        if (index < 0) {
            this._toastMessageShownNotificationIds.push(notificationId);
            return true;
        }
        return false;
    }

    private setStatus(status: string): NotificationStatusEnum {
        status = status.toLowerCase();
        if (status === 'success') {
            return NotificationStatusEnum.Success;
        } else if (status === 'failure') {
            return NotificationStatusEnum.Error;
        } else if (status === 'inprogress') {
            return NotificationStatusEnum.InProgress;
        } else {
            return NotificationStatusEnum.Info;
        }
    }

}
