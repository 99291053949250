import { Component, Input, OnInit } from '@angular/core';
import { Utilities } from '../../services/utilities';

@Component({
    selector: 'soti-one-logo',
    templateUrl: './soti-one-logo.html',
    styleUrls: ['./soti-one-logo.scss'],
  })
  export class SotiOneLogo implements OnInit {
    
    @Input()
    public TenantLogo: string = "";
    
    ngOnInit(): void {
      if (this.TenantLogo == undefined || this.TenantLogo == null || this.TenantLogo.trim() == "")
      {
          this.TenantLogo = Utilities.getLogoSotiOne();
      }
    }
  }