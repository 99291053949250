import {
    Component,
    OnInit,
    Host,
    Input,
    ElementRef,
    Renderer2
} from '@angular/core';

import {
    transition,
    animate,
    style,
    trigger,
    state
} from '@angular/animations';


import { ModalControl } from '../modal/modal.ctrl';

@Component({
    selector: 'modal-suffix',
    templateUrl: './modal-suffix.ctrl.html',
    styleUrls: ['./modal-suffix.ctrl.scss'],
    animations: [
        trigger('suffix', [
            state(
                'show',
                style({
                    height: '*'
                })
            ),
            state(
                'hide',
                style({
                    visibility: 'hidden',
                    height: '0px'
                })
            ),
            transition('hide => show', animate('250ms cubic-bezier(0.4, 0, 0.2, 1)')),
            transition('show => hide', animate('100ms cubic-bezier(0.4, 0, 0.2, 1)'))
        ])
    ]
})
export class ModalSuffixControl implements OnInit {
    @Input()
    public set show(value: boolean) {
        if (value) {
            this._setPosition();
        }
        this._show = value;
    }
    public get show(): boolean {
        return this._show;
    }

    private _show: boolean;

    constructor(@Host() private _modal: ModalControl, private _el: ElementRef, private _renderer: Renderer2) {}

    public ngOnInit(): void {}

    private _setPosition(): void {
        const modalContainer: HTMLElement = this._modal.container.nativeElement;
        const modalContainerBounds = modalContainer.getBoundingClientRect();
        const suffixContainer = (<HTMLElement>this._el.nativeElement).querySelector('.suffix-container');
        //this._renderer.setElementStyle(suffixContainer, 'top', `${Math.ceil(modalContainerBounds.height) - 4}px`);
        this._renderer.setStyle(suffixContainer, 'top', `${Math.ceil(modalContainerBounds.height) - 4}px`);
    }
}
