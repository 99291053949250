import {
    Component,
    Host,
    ElementRef, Optional
} from '@angular/core';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { SubDropdownComponent } from '../sub-dropdown/sub-dropdown.component';

@Component({
    selector: 'soti-dropdown-header-node',
    templateUrl: './dropdown-header-node.component.html',
    styleUrls: ['./dropdown-header-node.component.scss']
})
export class DropdownHeaderNodeComponent {

    constructor(
        @Optional() @Host() private _dropdown: DropdownComponent,
        @Optional() private _subdropdown: SubDropdownComponent,
        public element: ElementRef
    ) { }
}