import {Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'im-form-panel',
    templateUrl: './form-panel.ctrl.html',
    styleUrls: ['./form-panel.ctrl.scss']
})
export class FormPanelCtrl {

    @Input()
    @HostBinding('class.card')
    public styleAsCard: boolean = false;

    @Input()
    public displayMode: 'spaced' | 'flat' = 'spaced';

    constructor() {
    }

}