
import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedInfoMessageBarModule } from 'src/app/shared/controls/info-message-bar';
import { AppErrorHandler } from '../../app-error.handler';
import { getBaseUrl } from '../../app.module';
import { AccountEndpoint } from '../../services/account-services/account-endpoint.service';
import { AccountService } from '../../services/account-services/account.service';
import { AppTranslationService, TranslateLanguageLoader } from '../../services/app-translation.service';
import { SharedInputIconModule } from '../../shared/controls/input-icon/shared-input-icon.module';
import { SuccessmsgModule } from '../../shared/controls/success-msg/success-msg.module';
import { CoreCommonModule } from '../../shared/core-common.module';
import { ResendTokenControl } from './resend-token.component';


@NgModule({

    imports: [
        CommonModule,
        FormsModule,
        SuccessmsgModule,
        SharedInputIconModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        CoreCommonModule,
        SharedInfoMessageBarModule
    ],
    declarations:
        [
            ResendTokenControl,
        ],
    exports: [
        ResendTokenControl
    ],
    providers: [
        { provide: 'BASE_URL', useFactory: getBaseUrl },
        { provide: ErrorHandler, useClass: AppErrorHandler },

        AppTranslationService,
        AccountService,
        AccountEndpoint


    ],
})
export class ResendTokenModule {
}
