import { LdapUser } from './../../models/ldapUser.model';
import { Injectable } from '@angular/core';
import { DirectoryEndpoint } from './directory-endpoint.service';
import { Ldap } from '../../models/ldapdirectory';





@Injectable()
export class DirectoryService {

    constructor(private directyoryEndPoint: DirectoryEndpoint) {

    }
    getLdapList(type?: Number) {
        return this.directyoryEndPoint.getLdapEndpoint<Ldap[]>(type);
    }
    searchLdapUserList(connectionReferenceId: string, keyWord: string) {
        return this.directyoryEndPoint.searchUserLdapEndpoint<LdapUser[]>(connectionReferenceId, keyWord);
    }
}
