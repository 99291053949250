import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector, OnDestroy, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '../../../../services/auth.service';
import { ModalDialogService } from '../modal-dialog/modal-dialog-service/modal-dialog.service';
import { ModalDialogRef } from './modal-dialog-2-ref';

/**
 * Injection token that contains the data passed to the opened dialog
 */
export const SOTI_MODAL_DATA = new InjectionToken('SOTI_MODAL_DATA');

/**
 * Service for handling the opening and closing of the modal dialog
 */
@Injectable()
/**
 * Modal Dialog Service 2: Return of the Routes.
 */
export class ModalDialogService2 implements OnDestroy {

    public ForceClosedModal: Subject<boolean> = new Subject<boolean>();
    private _modalList: ModalDialogRef<any, any>[] = [];
    constructor(private _overlay: Overlay, private _injector: Injector, private authService: AuthService,private _liveAnnouncer: LiveAnnouncer,) {}

    public ngOnDestroy(): void {}

    /**
     * Open dialog with a specified component and an optional input
     * @param component Component used as the modal.
     * @param data Input options that the component uses.
     */
    public open<T, D = null, R = {}>(component: Type<T>, data: D = null, returnFocusElement: HTMLElement = null): ModalDialogRef<T, R> {
        this.authService._loginStatus.subscribe(value => {
            if(!value){
                this.authService.redirectLogoutUser();
            }
        });
        const overlayRef = this._createOverlay();
        const dialogRef = this._attachModalContent<T, D, R>(overlayRef, component, data, returnFocusElement);
        this._modalList.push(dialogRef);
        return dialogRef;
    }

    /**
     * Provided for backwards compatibility with `ModalDialogService`. Use `ModalDialogRef.close()` instead
     * 
     * @deprecated
     */
    public close(): void {}

    public closeModalTree(): void {
        this._modalList.forEach(modal => {
            if (modal && modal.close) {
                modal.close();                
            }
        });   
        
        this._modalList = [];
    }
    
    public closeModal(result?): void {
        let modalToClose = this._modalList.pop() 
        if (modalToClose && modalToClose.close) {
            modalToClose.close(result);
            if(modalToClose.returnFocusElementId != null && this.isElementInsideModal(modalToClose.returnFocusElementId))
            {
                this._liveAnnouncer.announce("Exited dialog");
            }
            setTimeout(() => {
                
                if (modalToClose.returnFocusElementId) {
                    modalToClose.returnFocusElementId.focus()       
                }
                else if(this._modalList.length > 0) {
                    try{
                        this._modalList.slice(-1)[0].componentInstance.modalElementRef?.el.nativeElement.focus() 
                    }
                    catch(e){
                        this.closeModalTree();
                    }
                }
            }, 1000);

        };
    }

    private _createOverlay(): OverlayRef {
        const overlayConfig = this._getOverlayConfig();
        return this._overlay.create(overlayConfig);
    }

    private _getOverlayConfig(): OverlayConfig {
        return new OverlayConfig({
            positionStrategy: this._overlay
                .position()
                .global()
                .centerHorizontally()
                .centerVertically(),
            scrollStrategy: this._overlay.scrollStrategies.block(),
            hasBackdrop: true,
            backdropClass: 'transparent'
        });
    }

    private _attachModalContent<T, D, R>(overlayRef: OverlayRef, component: Type<T>, data: D, returnFocusElement: HTMLElement = null): ModalDialogRef<T, R> {
        const dialogRef = new ModalDialogRef<T, R>(overlayRef, component);
        const injectionTokens = this._createInjector<T, D>(dialogRef, data);
        
        const componentPortal = new ComponentPortal(component, null, injectionTokens);
        const componentRef = overlayRef.attach(componentPortal);
        dialogRef.componentInstance = componentRef.instance;
        dialogRef.returnFocusElementId = returnFocusElement;
        return dialogRef;
    }

    private _createInjector<T, D>(dialogRef: ModalDialogRef<T>, data: D): Injector {
        const injectionTokens = new WeakMap<{}, {}>([
            [SOTI_MODAL_DATA, data],
            // override the old ModalDialogService with the ModalDialogRef. This is for the Modal control.
            [ModalDialogService, dialogRef],
            [ModalDialogRef, dialogRef]
        ]);

        return new PortalInjector(this._injector, injectionTokens);
    }

    private isElementInsideModal(element: HTMLElement): boolean {
        let parent = element.parentElement;
    
        while (parent !== null) {
          if (parent.tagName.toLowerCase() === 'modal') {
            return true;
          }
          parent = parent.parentElement;
        }
    
        return false;
      }
}
