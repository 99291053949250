import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { ViewLocationEnum } from '../enums/ViewLocationEnum';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class HeaderService {

    public _isPopOpne: boolean = false;
    private _currentLocation: BehaviorSubject<ViewLocationEnum> = new BehaviorSubject<ViewLocationEnum>(ViewLocationEnum.None);

    public get currentLocation(): Observable<ViewLocationEnum> {
        return this._currentLocation.asObservable();
    }

    public setCurrentLocation(val: ViewLocationEnum): void {
        this._currentLocation.next(val);
    }
}
