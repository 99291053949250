
import { HttpClient, HttpParams } from '@angular/common/http';
// ====================================================
//  
// 
// ====================================================
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ListResponse } from '../models/list-response.model';
import { NotificationFilteringParams } from '../models/NotificationFilteringParams';
import { NotificationResponse } from '../models/notificationResponse.model';
import { NotificationStatusEnum } from '../shared/enums/NotificationStatusEnum';
import { ConfigurationService } from './configuration.service';
import { EndpointFactory } from './endpoint-factory.service';
@Injectable()
export class NotificationEndpoint extends EndpointFactory {
  private readonly _notificationUrl: string = 'Notification';
  private readonly _downloadMethodsUrl: string = '/Download';

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {
    super(http, configurations, injector);
  }

  public getNotificationEndpoint(refId: string, filterparams: NotificationFilteringParams): Observable<ListResponse<NotificationResponse>> {
    let notificationUrl: string = this.configurations.baseUrl + 'User/' + refId + '/Notification';
    let options = new HttpParams()
      .set('SortColumn', filterparams.SortColumn)
      .set('SortOrder', filterparams.SortOrder)
      .set('PageNo', filterparams.PageNo.toString())
      .set('PageSize', filterparams.PageSize.toString());


    return this.http.get<ListResponse<NotificationResponse>>(notificationUrl, { headers: this.getHeaders(), params: options, withCredentials: true });

  }

  public updateNotificationReadStatus(refId: string, notificationIds: string[]): Observable<void> {
    let notificationUrl: string = this.configurations.baseUrl + 'User/' + refId + '/Notification';
    let options = new HttpParams()
      .set('notificationId', JSON.stringify(notificationIds));
    //return this.http.patch<void>(notificationUrl, { headers: this.getHeaders(), params: options, withCredentials: true }); //, JSON.stringify('notificationId', notificationIds), this.getRequestHeaders());
    return this.http.patch<void>(notificationUrl, JSON.stringify(notificationIds), this.getRequestHeaders());
  }

  public deleteNotifications(refId: string, notificationIds: string[]) {
    let notificationUrl: string = this.configurations.baseUrl + 'User/' + refId + '/DeleteNotifications';
    return this.http.post<void>(notificationUrl, JSON.stringify(notificationIds), this.getRequestHeaders());
  }

  public deleteAnnouncements(refId: string, notificationIds: string[]) {
    let notificationUrl: string = this.configurations.baseUrl + 'User/' + refId + '/DeleteAnnouncements';
    return this.http.post<void>(notificationUrl, JSON.stringify(notificationIds), this.getRequestHeaders());
  }

  public download(id: string): Observable<string> {
    return this.http.get<string>(this.DownloadUrl(id), this.getRequestHeaders());
  }

  private DownloadUrl(id: string): string {
    return `${this.configurations.baseUrl}${this._notificationUrl}/${id}${this._downloadMethodsUrl}`;
  }

  private setStatus(status: string): NotificationStatusEnum {
    status = status.toLowerCase();
    if (status === 'success') {
      return NotificationStatusEnum.Success;
    } else if (status === 'failure') {
      return NotificationStatusEnum.Error;
    } else if (status === 'inprogress') {
      return NotificationStatusEnum.InProgress;
    } else {
      return NotificationStatusEnum.Info;
    }
  }
}
