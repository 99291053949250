import { UserService } from '../../services/user-services/user.service';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { AccountService } from 'src/app/services/account-services/account.service';
import { NgForm, NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { InfoMessageBarOptions } from 'src/app/shared/controls/info-message-bar/InfoMessageBarOptions';
import { InfoMessageBarTypeEnum } from 'src/app/shared/controls/info-message-bar/InfoMessageBarTypeEnum';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { ClientNotificationMessage, NotificationType } from 'src/app/shared/utils/toast-utils/ClientNotificationMessage';
import { appMessenger } from 'src/app/shared/controls/toaster-messages/AppMessenger';
import { AlertSeverityEnum } from 'src/app/shared/enums/AlertSeverityEnum';
import { ErrorCode } from 'src/app/shared/enums/ErrorCode';

@Component({
    selector: 'im-resend-token',
    templateUrl: './resend-token.component.html',
    styleUrls: ['./resend-token.component.scss']
})

export class ResendTokenControl implements OnInit, AfterViewInit {
    public captchaPristine: boolean = true;
    @Input('resetToken') public resetToken: string;
    @Input('isTokenValid') public isTokenValid: boolean = true;
    public captchaVal: string = "";
    @Input('tokenErrorMessage') public tokenErrorMessage: string;
    public captchaHash: string;
    public captchaId: string;
    @ViewChild('captcha') public captchaField: NgModel;

    @ViewChild('captchaImage', { static: false })
    public captchaTag: ElementRef;

    success: boolean = false;
    public isInvalidCaptcha: boolean = false;
    public infoMessageBarOptions: InfoMessageBarOptions = null;
    public submitted: boolean = false;
    
    constructor(private alertService: AlertService, private userService: UserService, private accountservice: AccountService, private router: Router, private appTranslationService: AppTranslationService) {
    }

    
    public ngOnInit(): void {
    this.infoMessageBarOptions = new InfoMessageBarOptions(
        InfoMessageBarTypeEnum.Error,
        this.appTranslationService.getTranslation(this.tokenErrorMessage),
        'identity-icon-error-warning');
    }
        
    ngAfterViewInit(): void {
        this.getCaptchaCode();
    }


    public changePristine(controlName: string): void {
        switch (controlName) {
            case 'captcha': this.captchaPristine = false; break;
            default: { }
        }
    }

    loginNow(): void {
        this.router.navigateByUrl('/');
    }

    resendResetPasswordLink(formValue: NgForm, formvalid: boolean): void {
        if (formValue.value && formvalid) {
            this.userService.SendExpiredPasswordLink(formValue.value.captcha, this.captchaHash, this.resetToken)
                .subscribe(
                    result => {
                        this.success = true;
                        this.infoMessageBarOptions = null;
                        appMessenger.postMessage(new ClientNotificationMessage(
                            this.appTranslationService.getTranslation('msg_reset_password_mail_sent_successfully'),
                            this.appTranslationService.getTranslation('msg_reset_password_mail_sent_successfully'),
                            NotificationType.Success,
                            AlertSeverityEnum.Minor));
                        this.isInvalidCaptcha = false;
                    },
                    error => {
                        this.getCaptchaCode(); // refreshing the old one captcha with new one.
                        if (error.error.ErrorCode === ErrorCode.InvalidCaptcha) {

                            this.isInvalidCaptcha = true;
                            this.infoMessageBarOptions = new InfoMessageBarOptions(
                                InfoMessageBarTypeEnum.Error,
                                this.appTranslationService.getTranslation('msg_invalid_captcha'),
                                'identity-icon-error-warning');
                        } else {
                            this.isInvalidCaptcha = false;
                            this.infoMessageBarOptions = new InfoMessageBarOptions(
                                InfoMessageBarTypeEnum.Error,
                                this.appTranslationService.getTranslation('msg_Somethingwentworng'),
                                'identity-icon-error-warning');
                        }
                        this.success = false;
                        this.alertService.stopLoadingMessage();
                    });
        }
    }

    getCaptchaCode(): void {
        this.accountservice.getCaptcha().subscribe(captchaResult => {
            this.captchaTag.nativeElement.src = 'data:image/png;base64,' + captchaResult.CaptchaByteData;
            this.captchaHash = captchaResult.SecureValue;
            this.captchaField.control.reset();
        });

    }

}
