import { EndpointFactory } from "../endpoint-factory.service";
import { oAuthBaseConnectionModel } from './../../models/oAuthBaseConnectionModel';
import { oAuthBaseLoginUrlParams  } from '../../models/oAuthBaseLoginUrlParams';
import { oAuthBaseConnectionFilterParams } from '../../models/oAuthBaseConnectionFilterParams';
import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ConfigurationService } from "../configuration.service";
import { Observable } from "rxjs";
import { UrlParameterEncodingCodec } from "src/app/shared/utils/url-parameter-encoding-codec";
import { AzureADParams } from "src/app/models/AzureADParams.model";

@Injectable()
export class AzureADEndpoint extends EndpointFactory {
    private static readonly URL_PARAMETER_ENCODING_CODEC = new UrlParameterEncodingCodec();
    private readonly _connectionUrl: string = 'Connection';
    private readonly _azureADUrl: string = '/AzureAD/';
    private readonly _filteredAzureADUrl: string = '/filteredAzureAD';
    private readonly _azureADUrlParamsUrl: string = 'UrlParams';
    
    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {
        super(http, configurations, injector);
    }
    public getAzureADConnections(azureADFilteringParams?: oAuthBaseConnectionFilterParams): Observable<oAuthBaseConnectionModel[]>{
        return this.http.post<oAuthBaseConnectionModel[]>(this.getAzureADFilteredUrl, azureADFilteringParams , this.getRequestHeaders());
    }
    public getAzureADLoginUrlParams(name: string, selectedDomains: string[], eventType: number): Observable<oAuthBaseLoginUrlParams> {
        let azureAdParam = new AzureADParams();
        azureAdParam.AzureADName = name;
        azureAdParam.EventType = eventType;
        azureAdParam.SelectedDomainReferenceIds = selectedDomains;
        return this.http.post<oAuthBaseLoginUrlParams>(this.getAzureADUrlParams, azureAdParam , this.getRequestHeaders());
    }
    public getAzureADConnectionDataById(connectionId: string): Observable<oAuthBaseConnectionModel> {
        const endpointUrl = `${this.getAzureADUrl}` + connectionId;
        return this.http.get<oAuthBaseConnectionModel>(endpointUrl, this.getRequestHeaders());
    }
    public updateAzureADConnection(param : oAuthBaseConnectionModel): Observable<oAuthBaseConnectionModel> {
        return this.http.put<oAuthBaseConnectionModel>(`${this.getAzureADUrl}` + param.ConnectionReferenceId, JSON.stringify(param) , this.getRequestHeaders());
    }

    private get getAzureADUrl(): string {
        return this.configurations.baseUrl + this._connectionUrl + this._azureADUrl;
    }

    private get getAzureADFilteredUrl(): string {
        return this.configurations.baseUrl + this._connectionUrl + this._filteredAzureADUrl;
    }
    
    private get getAzureADUrlParams(): string {
        return this.configurations.baseUrl + this._connectionUrl + this._azureADUrl + this._azureADUrlParamsUrl;
    }
}
