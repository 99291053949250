import { TranslateLanguageLoader } from '../../../services/app-translation.service';
import { SharedDialogsModule } from '../../controls/dialogs/shared-dialogs.module';
import {NgModule} from '@angular/core';

import {ConfirmationDialogModal} from './confirmation-dialog.ctrl';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CoreCommonModule } from '../../core-common.module';
import { SharedCheckboxButtonModule } from '../../controls/checkbox-button/shared-check-box.module';
import { SharedInfoMessageBarModule } from '../../controls/info-message-bar';
import { ModalDialogService2 } from '../../controls/dialogs';

@NgModule({
    imports: [
        SharedDialogsModule,
        CoreCommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        SharedCheckboxButtonModule,
        SharedInfoMessageBarModule
    ],
    providers: [
        ModalDialogService2
    ],
    declarations: [
        ConfirmationDialogModal
    ],
    exports: [
        ConfirmationDialogModal
    ]
})
export class ConfirmationDialogModalModule {
}
