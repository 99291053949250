
import { CookieDisableComponent } from './cookie-disable.component';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [        
     ],
    declarations:
     [
        


    ],
     providers: [
       
    ]

})

export class CookieDisableModule {
}
