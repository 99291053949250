import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingControl } from './loading-control/loading.ctrl';
import { LoadingDirective } from './loading.directive';

@NgModule({
    declarations: [
        LoadingControl,
        LoadingDirective
    ],
    exports: [
        LoadingControl,
        LoadingDirective
    ],
    imports: [
        CommonModule
    ]
})
export class SharedLoadingDirectiveModule {}
