import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IntergratedApplicationCreateCredentialModel } from 'src/app/models/IntergratedApplicationCreateCredential.model';
import { AddApplicationMetaData } from 'src/app/models/addApplicationMetaData';
import { ApplicationGroupModel } from 'src/app/models/applicationgroup.model';
import { CertificateValidationModel } from 'src/app/models/certificateValidationModel';
import { integratedApplicationViewCredentialModel } from 'src/app/models/integratedApplicationViewCredential.model';
import { ProductScopeModel } from 'src/app/models/productScopeModel';
import { ApplicationListFilterParams } from '../../models/ApplicationListFilterParams';
import { ApplicationList } from '../../models/applicationList.modal';
import { ListResponse } from '../../models/list-response.model';
import { ConfigurationService } from '../configuration.service';
import { EndpointFactory } from '../endpoint-factory.service';
import { Application } from './../../models/application.model';
import { Roles } from './../../models/applicationRoles.model';
import { ApplicationUser } from './../../models/applicationUser.model';
import { XsightDetails } from 'src/app/models/xsightDetails.model';
import { FilteringParams } from 'src/app/models/filteringparams';
import { RoleStatus } from 'src/app/models/role-status.model';
import { XsightDefaultUrlPreferenceModel } from 'src/app/models/XsightDefaultUrlPreferenceModel';


@Injectable()
export class ApplicationEndpoint extends EndpointFactory {

    public countriesCode: Subject<Array<any>> = new BehaviorSubject<Array<any>>(null);

    private readonly _applicationUrl: string = 'Application';
    private readonly _currentUserUrl: string = 'user/users/me';
    private readonly _intergratedApplicationUrl: string = 'IntegratedApplication';
    private readonly _resetpasswordUrl: string = '/ResetPassword';
    private readonly _sendResetPasswordLinkUrl: string = '/SendResetPasswordLink';
    private readonly _userAppsUrl: string = '/Applications';
    private readonly _getRolesforAplication: string = 'Roles';
    private readonly _getUsersforAplication: string = 'Users';
    private readonly _addApplicationRoles: string = 'Roles';
    private readonly _addApplicationValidateMetadata: string = '/ValidateMetadata';
    private readonly _validateCerficate: string = 'Account/ValidateCertificate';
    private readonly _applicationCredentials: string = 'Credential';
    private readonly _applicationRefreshCredentials: string = 'RefreshCredential';
    private readonly _verifyAppName: string = '/VerifyDuplicateApplication';
    private readonly _verifyScimUrl: string = '/VerifyScimUrl';
    private readonly _verifyDuplicateApplicationGroup: string = '/VerifyDuplicateApplicationGroupName';
    private readonly _defaultXsight: string = '/DefaultXsight';
    private readonly _searchApplication: string = 'SearchApplication?searchText=';
    private readonly _searchAssociatedApplicationToApplicationGroup: string = 'SearchAssociatedApplicationToApplicationGroup?searchText=';
    private readonly _deactivate: string = '/Deactivate';
    private readonly _activate: string = '/Activate';
    private readonly _delete: string = '/Delete';
    private readonly _productScope: string = '/ProductScope';
    private readonly _applicationScope: string = '/ApplicationScope';
    private readonly _getMetadataByUrl: string = '/MetadataByUrl';
    private readonly _enableSOTIOneCommunication: string = '/EnableSOTIOneCommunication';
    private readonly _disableSOTIOneCommunication: string = '/DisableSOTIOneCommunication';
    private readonly _consumerProviders: string = '/providers';
    private readonly _applicationAccessLog: string = '/ApplicationAccessLog';
    private readonly _xsightAccessLog: string = '/XsightAccessLog';
    private readonly createApplicationGroupUrl: string = '/ApplicationGroup';


    public get applicationUrl() { return this.configurations.baseUrl + this._applicationUrl; }
    public get currentUserUrl() { return this.configurations.baseUrl + this._currentUserUrl; }
    public get intergratedApplicationUrl() { return this.configurations.baseUrl + this._intergratedApplicationUrl; }

    get resetPasswordUrl() { return this._resetpasswordUrl; }
    get sendResetPasswordLinkUrl() { return this._sendResetPasswordLinkUrl; }
    get userAppUrl() { return this._userAppsUrl; }
    public get verifyApplicationNameUrl(): string { return this.configurations.baseUrl + this._applicationUrl + this._verifyAppName; }
    public get verifyScimUrlEndpoint(): string { return this.configurations.baseUrl + this._applicationUrl + this._verifyScimUrl; }
    public get VerifyDuplicateApplicationGroupNameUrl(): string { return this.configurations.baseUrl + this._applicationUrl + this._verifyDuplicateApplicationGroup; }
    public addApplicationMetaDataValidate(): string { return this.configurations.baseUrl + this._applicationUrl + this._addApplicationValidateMetadata; }
    public certificateValidate(): string { return this.configurations.baseUrl + this._validateCerficate; }

    public get deacticateApplication(): string { return this.configurations.baseUrl + this._applicationUrl + this._deactivate; }
    public get acticateApplication(): string { return this.configurations.baseUrl + this._applicationUrl + this._activate; }
    public get applicationGroup(): string { return this.configurations.baseUrl + this._applicationUrl + this.createApplicationGroupUrl; }
    public get deleteApplication(): string { return this.configurations.baseUrl + this._applicationUrl + this._delete; }
    public get productScope(): string { return this.configurations.baseUrl + this._applicationUrl + this._productScope; }
    public get applicationScope(): string { return this.configurations.baseUrl + this._applicationUrl + this._applicationScope; }
    public get getMetadataByUrl(): string { return this.configurations.baseUrl + this._applicationUrl + this._getMetadataByUrl; }
    public get enableSOTIOneCommunicationURL(): string { return this.configurations.baseUrl + this._applicationUrl + this._enableSOTIOneCommunication; }
    public get disableSOTIOneCommunicationURL(): string { return this.configurations.baseUrl + this._applicationUrl + this._disableSOTIOneCommunication; }

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

        super(http, configurations, injector);
    }

    public AddApplicationEndpoint(appObject: Application): Observable<Application> {
        const uploadData = new FormData();
        if (appObject.appLogo !== undefined && appObject.appLogo !== null) {
            uploadData.append('Images', appObject.appLogo, appObject.appLogo.name);
        }
        uploadData.append("ApplicationData", JSON.stringify(appObject));
        var appType: number = appObject.ApplicationType;
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${appType}`;
        return this.http.post<Application>(endpointUrl, uploadData, this.getFormDataRequestHeaders());
    }

    public AddOidcApplicationEndpoint(appObject: Application): Observable<integratedApplicationViewCredentialModel> {
        const uploadData = new FormData();
        if (appObject.appLogo !== undefined && appObject.appLogo !== null) {
            uploadData.append('Images', appObject.appLogo, appObject.appLogo.name);
        }
        uploadData.append("OidcApplicationData", JSON.stringify(appObject));
        var appType: number = appObject.ApplicationType;
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${appType}/oidc`;
        return this.http.post<integratedApplicationViewCredentialModel>(endpointUrl, uploadData, this.getFormDataRequestHeaders());
    }

    public UpdateApplicationEndpoint(appObject: Application): Observable<Application> {
        const uploadData = new FormData();
        if (appObject.appLogo !== undefined && appObject.appLogo !== null) {
            uploadData.append('Images', appObject.appLogo, appObject.appLogo.name);
        }
        uploadData.append('ApplicationData', JSON.stringify(appObject));
        var applicationRefId: string = appObject.ReferenceId;
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${appObject.ApplicationType}/${applicationRefId}`;

        return this.http.put<Application>(endpointUrl, uploadData, this.getFormDataRequestHeaders());
    }
    
    public UpdateOidcApplicationEndpoint(appObject: Application): Observable<Application> {
        const uploadData = new FormData();
        if (appObject.appLogo !== undefined && appObject.appLogo !== null) {
            uploadData.append('Images', appObject.appLogo, appObject.appLogo.name);
        }
        uploadData.append('OidcApplicationData', JSON.stringify(appObject));
        var applicationRefId: string = appObject.ReferenceId;
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${appObject.ApplicationType}/${applicationRefId}/oidc`;

        return this.http.put<Application>(endpointUrl, uploadData, this.getFormDataRequestHeaders());
    }

    public GetAllApplicationsEndPoint(params: ApplicationListFilterParams): Observable<ListResponse<ApplicationList>> {

        let options = new HttpParams()
            .set("SortColumn", params.SortColumn)
            .set("SortOrder", params.SortOrder)
            .set("KeyWord", params.KeyWord)
            .set("PageNo", params.PageNo.toString())
            .set("PageSize", params.PageSize.toString());


        return this.http.get<ListResponse<ApplicationList>>(this.applicationUrl, { headers: this.getHeaders(), params: options, withCredentials: true });
    }

    public getRolesListByApplicationIdEndPoint(applicationReferenceId: string): Observable<Roles[]> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${applicationReferenceId}/${this._getRolesforAplication}`;
        let options = new HttpParams();
        return this.http.get<Roles[]>(endpointUrl, { headers: this.getHeaders(), params: options, withCredentials: true });
    }

    public getAdminConsoleRolesList(applicationReferenceId: string, filteringParams: FilteringParams): Observable<ListResponse<Roles>> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${applicationReferenceId}/${this._getRolesforAplication}`;
        let options = new HttpParams()
        .set('PageNo', filteringParams.PageNo)
        .set('PageSize', filteringParams.PageSize)
        .set('SortColumn', filteringParams.SortColumn)
        .set('SortOrder', filteringParams.SortOrder)
        .set('KeyWord', filteringParams.KeyWord);
        
        return this.http.get<ListResponse<Roles>>(endpointUrl, { headers: this.getHeaders(), params: options, withCredentials: true });
    }

    public AddIntergratedApplication(appObject: IntergratedApplicationCreateCredentialModel): Observable<integratedApplicationViewCredentialModel> {
        const uploadData = new FormData();
        if (appObject.AppLogo !== undefined && appObject.AppLogo !== null) {
            uploadData.append('LogoImage', appObject.AppLogo, appObject.AppLogo.name);
        }
        uploadData.append('ApplicationData', JSON.stringify(appObject));
        var appType: number = appObject.ApplicationType;
        let endpointUrl = `${this.intergratedApplicationUrl}/${appType}`;

        return this.http.post<integratedApplicationViewCredentialModel>(endpointUrl, uploadData, this.getFormDataRequestHeaders());
    }

    public UpdateIntergratedApplication(appObject: IntergratedApplicationCreateCredentialModel): Observable<void> {
        const uploadData = new FormData();
        if (appObject.AppLogo !== undefined && appObject.AppLogo !== null) {
            uploadData.append('LogoImage', appObject.AppLogo, appObject.AppLogo.name);
        }
        uploadData.append('ApplicationData', JSON.stringify(appObject));
        let endpointUrl = `${this.intergratedApplicationUrl}/${appObject.ApplicationType}/${appObject.ApplicationReferenceId}`;

        return this.http.put<void>(endpointUrl, uploadData, this.getFormDataRequestHeaders());
    }

    public getUsersByApplicationIdEndPoint(applicationReferenceId: string): Observable<ApplicationUser[]> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${applicationReferenceId}/${this._getUsersforAplication}`;
        return this.http.get<ApplicationUser[]>(endpointUrl, this.getRequestHeaders());
    }

    public addApplicationRolesEndPoint(applicationReferenceId: string, users?: Roles[]): Observable<Roles[]> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${applicationReferenceId}/${this._addApplicationRoles}`;
        return this.http.post<Roles[]>(endpointUrl, JSON.stringify(users), this.getRequestHeaders());
    }

    public readAndValidateApplicationMetaDataFile(param: AddApplicationMetaData): Observable<Application> {
        const uploadData = new FormData();
        if (param.file !== undefined && param.file !== null) {
            uploadData.append('MetaDataXML', param.file, param.file.name);
        }

        return this.http.post<Application>(this.addApplicationMetaDataValidate(), uploadData, this.getFormDataRequestHeaders());
    }

    public readApplicationMetaDataUrl(param: string): Observable<Application> {

        let endpointUrl = `${this.getMetadataByUrl}?spMetadataUri=${param}`;
        return this.http.get<Application>(endpointUrl, this.getRequestHeaders());
    }


    public readAndValidateCertificateFile(param: AddApplicationMetaData): Observable<CertificateValidationModel> {
        const uploadData = new FormData();
        if (param.file !== undefined && param.file !== null) {
            uploadData.append('certificateFile', param.file, param.file.name);
        }

        return this.http.post<CertificateValidationModel>(this.certificateValidate(), uploadData, this.getFormDataRequestHeaders());
    }

    public getApplicationCredentials(applicationReferenceId: string): Observable<integratedApplicationViewCredentialModel> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${applicationReferenceId}/${this._applicationCredentials}`;
        return this.http.get<integratedApplicationViewCredentialModel>(endpointUrl, this.getRequestHeaders());
    }

    public createApplicationCredentials(applicationReferenceId: string): Observable<integratedApplicationViewCredentialModel> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${applicationReferenceId}/${this._applicationCredentials}`;
        return this.http.post<integratedApplicationViewCredentialModel>(endpointUrl, {}, this.getRequestHeaders());
    }

    public refreshApplicationCredentials(applicationReferenceId: string): Observable<integratedApplicationViewCredentialModel> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${applicationReferenceId}/${this._applicationRefreshCredentials}`;
        return this.http.post<integratedApplicationViewCredentialModel>(endpointUrl, {}, this.getRequestHeaders());
    }

    public verifyApplicationName(appName: string): Observable<boolean> {
        let endpointUrl = `${this.verifyApplicationNameUrl}?appName=${appName}`;
        return this.http.get<boolean>(endpointUrl, this.getRequestHeaders());
    }

    public verifyScimUrl(scimUrl: string): Observable<boolean> {
        let endpointUrl = `${this.verifyScimUrlEndpoint}`;
        return this.http.post<boolean>(endpointUrl, JSON.stringify(scimUrl), this.getRequestHeaders());
    }

    public searchApplication(searchText: string): Observable<ApplicationList[]> {
        let search = encodeURIComponent(searchText);
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${this._searchApplication}` + search;
        return this.http.get<ApplicationList[]>(encodeURI(endpointUrl), this.getRequestHeaders());
    }

    public searchAssociatedApplicationToApplicationGroup(searchText: string): Observable<ApplicationList[]> {
        let search = encodeURIComponent(searchText);
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${this._searchAssociatedApplicationToApplicationGroup}` + search;
        return this.http.get<ApplicationList[]>(encodeURI(endpointUrl), this.getRequestHeaders());
    }

    public getApplicationDetailById(applicationReferenceId: string): Observable<Application> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${applicationReferenceId}`;
        return this.http.get<Application>(endpointUrl, this.getRequestHeaders());
    }

    public getOidcApplicationDetailById(applicationReferenceId: string): Observable<Application> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${applicationReferenceId}/oidc`;
        return this.http.get<Application>(endpointUrl, this.getRequestHeaders());
    }


    public deActivateApplications(applicationReferenceIds: string[]): Observable<void> {
        return this.http.post<void>(this.deacticateApplication, applicationReferenceIds, this.getRequestHeaders());
    }

    public activateApplications(applicationReferenceIds: string[]): Observable<void> {
        return this.http.post<void>(this.acticateApplication, applicationReferenceIds, this.getRequestHeaders());
    }

    public deleteApplications(applicationReferenceIds: string[]): Observable<void> {
        return this.http.post<void>(this.deleteApplication, applicationReferenceIds, this.getRequestHeaders());
    }

    public getProductScopeByType(productType: number): Observable<ProductScopeModel[]> {
        let endpointUrl = `${this.productScope}/${productType}`;
        return this.http.get<ProductScopeModel[]>(endpointUrl, this.getRequestHeaders());
    }

    public getApplicationScopeById(applicationReferenceId: string): Observable<ProductScopeModel[]> {
        let endpointUrl = `${this.applicationScope}/${applicationReferenceId}`;
        return this.http.get<ProductScopeModel[]>(endpointUrl, this.getRequestHeaders());
    }

    public updateApplicationScope(applicationReferenceId: string, scopeReferenceIds: string[]): Observable<void> {
        let endpointUrl = `${this.applicationScope}/${applicationReferenceId}`;
        return this.http.put<void>(endpointUrl, scopeReferenceIds, this.getRequestHeaders());
    }

    public enableSOTIOneCommunication(referenceIds: string[]): Observable<void> {
        return this.http.post<void>(this.enableSOTIOneCommunicationURL, referenceIds, this.getRequestHeaders());
    }

    public disableSOTIOneCommunication(referenceIds: string[]): Observable<void> {
        return this.http.post<void>(this.disableSOTIOneCommunicationURL, referenceIds, this.getRequestHeaders());
    }

    public getConsumerProviders(referenceId: string): Observable<ApplicationList[]> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${referenceId}${this._consumerProviders}`;
        return this.http.get<ApplicationList[]>(endpointUrl, this.getRequestHeaders());
    }

    public getProviders(): Observable<ApplicationList[]> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}${this._consumerProviders}`;
        return this.http.get<ApplicationList[]>(endpointUrl, this.getRequestHeaders());
    }

    public updateConsumerProviders(referenceId: string, providerReferenceIds: string[]): Observable<void> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${referenceId}${this._consumerProviders}`;
        return this.http.post<void>(endpointUrl, providerReferenceIds, this.getRequestHeaders());
    }
    
    public ApplicationAccess(referenceId: string): Observable<void> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${referenceId}${this._applicationAccessLog}`;
        return this.http.post<void>(endpointUrl, {}, this.getRequestHeaders());
    }

    public xsightAccessLog(xsightAccessLog: XsightDetails): Observable<void> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}${this._xsightAccessLog}`;
        return this.http.post<void>(endpointUrl, xsightAccessLog, this.getRequestHeaders());
    }

    public createApplicationGroup(applicationGroupModel : ApplicationGroupModel): Observable<void> {
        return this.http.post<void>(this.applicationGroup, applicationGroupModel, this.getRequestHeaders());
    }
    public verifyDuplicateApplicationGroupName(appName: string): Observable<boolean> {
        let endpointUrl = `${this.VerifyDuplicateApplicationGroupNameUrl}?appName=${appName}`;
        return this.http.get<boolean>(endpointUrl, this.getRequestHeaders());
    }
    public getApplicationGroup(referenceId: string): Observable<ApplicationGroupModel> {
        return this.http.get<ApplicationGroupModel>(this.applicationGroup + '/' + referenceId, this.getRequestHeaders());
    }
    public updateApplicationGroup(applicationGroupModel : ApplicationGroupModel): Observable<void> {
        return this.http.put<void>(this.applicationGroup + '/' + applicationGroupModel.ReferenceId, applicationGroupModel, this.getRequestHeaders());
    }
    public updateRoleStatus(applicationReferenceId: string, roleStatus: RoleStatus): Observable<void> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}/${applicationReferenceId}/${this._addApplicationRoles}`;
        return this.http.patch<void>(endpointUrl, roleStatus, this.getRequestHeaders());
    }
    
    public deleteApplicationGroup(applicationGroupReferenceId: string): Observable<void> {
        let endpointUrl = this.applicationGroup + `/${applicationGroupReferenceId}`;
        return this.http.delete<void>(endpointUrl, this.getRequestHeaders());
    }

    public updateDefaultXsightServer(defaultXsightdata : XsightDefaultUrlPreferenceModel): Observable<void> {
        let endpointUrl = `${this.configurations.baseUrl}${this._applicationUrl}${this._defaultXsight}`;
        return this.http.post<void>(endpointUrl, defaultXsightdata, this.getRequestHeaders());
    }
}
