import { Component, ViewChild, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlStatusService } from '../../../services/controls-status.service';

@Component({
    selector: 'soti-idp-button',
    template: `
<button  [disabled] = "(IsAlwaysEnable == true ?  null : controlStatusService.IsFormOkButtonEnable)">{{text}}</button>


`

})
export class SotiIdpButton implements OnInit {
    @Input() DisplayText: string;
    @Input() IsDisable: boolean = false;
    @Input() IsAlwaysEnable: boolean ;

    @Output() CallBackFunction = new EventEmitter<string>();
    constructor(public controlStatusService:ControlStatusService) {

    }
    ngOnInit() {
        this.text = this.DisplayText;
        this.disabled = this.IsDisable;
    }
    public text: string = '';
    public disabled = false;
    public isAlwaysEnable:boolean;
    public Close($event) {
        this.CallBackFunction.emit('');
    }

}
