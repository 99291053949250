import { ESCAPE } from '@angular/cdk/keycodes';
import { OverlayRef } from '@angular/cdk/overlay';
import { Type } from '@angular/core';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';

export class ModalDialogRef<T, R = {}> {
    public componentInstance: T;
    public returnFocusElementId: HTMLElement;

    private subscription: Subscription;

    private _closed: Subject<R> = new Subject<R>();

    constructor(private _overlayRef: OverlayRef, private _modalContent: Type<T>) {
        this.subscription = _overlayRef
            .keydownEvents()
            .pipe(filter((event) => event.keyCode === ESCAPE))
            .subscribe(() => this.close());
    }

    /**
     * Closes the dialog and emits the `closed` observable with the result.
     * @param result The expected result from closing the dialog
     */
    public close(result?: R): void {
        if (!this._closed) {
            return;
        }
        this._closed.next(result);
        this._closed.complete();
        this._closed = null;
        this._overlayRef.dispose();
        this.componentInstance = null;
    }

    public unSubscribeEscapeCloseEvent(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Returns an observable that emits results when the dialog closes
     */
    public closed(): Observable<R> {
        return this._closed.asObservable();
    }
}
