
import { Component, OnInit, HostBinding, Input } from '@angular/core';
import { UserService } from 'src/app/services/user-services/user.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { DisposalBag } from '../../../shared/utils/DisposalBag';
import { SotiThemeService } from '../../../services/soti-theme/soti-theme.service';
import { ThemeType } from '../../../shared/enums/ThemeType';
import { UserPreferenceModel } from 'src/app/models/UserPreference.model';
import { LightTheme, DarkTheme } from 'src/app/services/soti-theme/theme';
import { UserPreferenceType } from 'src/app/shared/enums/UserPreferenceType';
import { BrowserUtilsService } from 'src/app/services/browserUtil/browserUtils.service';
import { Location } from '@angular/common';
import { ModalDialogService2 } from 'src/app/shared/controls/dialogs/modal-dialog-2';
@Component({
    selector: 'profile-detail',
    templateUrl: './profile-detail.component.html',
    styleUrls: ['./profile-detail.component.scss']
})

export class ProfileDetailComponent implements OnInit {
    @Input() public showFooter: boolean = false;
    
    public userInitials: string;
    public userName: string;
    public phoneNumber: string;
    public emailAddress: string;
    public name: string;    
    public isDarkTheme:boolean = false;
    
    public isMobileView: boolean;
    public sotionetheme: boolean;
    public themeMode: UserPreferenceModel = {
        TypeId: UserPreferenceType.THEME,
        Preference: ThemeType.LIGHT
    };
    public lightThemeType: number = ThemeType.LIGHT;
    public darkThemeType: number = ThemeType.DARK;
    
    @HostBinding('class.dark-mode-bg')
    public isDarkMode: boolean = false;
    private _disposalBag: DisposalBag = new DisposalBag();
    constructor(private userService: UserService,        
        private themeService: SotiThemeService,
        private location: Location,
        private _modalDialogService: ModalDialogService2,
        private _browserUtilService: BrowserUtilsService,
        private translationService: AppTranslationService) {
            this.isMobileView = this._browserUtilService.isMobileOrTablet();
    }

    public ngOnInit(): void {
        this.getUserDetail();
        
        this._disposalBag.nextSub = this.themeService.selectedTheme.subscribe((val) => {
            this.isDarkTheme = val === ThemeType.DARK;
        });
        this.sotionetheme = this.themeService._isSotiTheme;
        if (this.isMobileView && this.sotionetheme) {
            this.themeService.setTheme(new LightTheme());
            this.loadPreferences();
        }
    }

    public setTheme(themeType: ThemeType, savePreference: boolean = true): void {
        this.themeMode.Preference = themeType;

        if (savePreference) {
            this.savePreferences(this.themeMode);
        }

        if (themeType == ThemeType.LIGHT) {
            this.themeService.setTheme(new LightTheme());
            this.isDarkMode = false;
        } else {
            this.themeService.setTheme(new DarkTheme());
            this.isDarkMode = true;
        }
    }

    private savePreferences(userPreference) {
        this.userService.savePreferences([userPreference]).subscribe();
    }

    public loadPreferences() {
        this.sotionetheme = this.themeService._isSotiTheme;
        this._disposalBag.nextSub = this.userService.getPreferences().subscribe((data) => {
            const themePreference = data.find(x => x.TypeId == UserPreferenceType.THEME);
            if (themePreference) {
                this.setTheme(themePreference.Preference, false);
            }
        });
    }
    public onDoneClick(): void {
        this._modalDialogService.closeModal();
    }

    private getUserDetail(): void {
        this.userService.getUserDetails().subscribe(userModel => {
            userModel.LastName = (userModel.LastName === null || userModel.LastName === undefined) ? '' : userModel.LastName;
            this.userName = userModel.FirstName + ' ' + userModel.LastName;
            this.userInitials = (userModel.FirstName.substring(0, 1) + userModel.LastName.substring(0, 1)).toUpperCase();
            this.emailAddress = userModel.Email;
            this.phoneNumber = userModel.PhoneNumber;
            this.phoneNumber = userModel.PhoneNumber;
            this.name = userModel.UserName;
        });
    }


}

