import { ExternalIdpConnection } from './../../models/external-idp-connection.model';
import { ExternalIdpconnectionMetaData } from './../../models/externalIdpconnectionMetaData';
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { EndpointFactory } from '../endpoint-factory.service';
import { ExternalIdpCertificate } from 'src/app/models/externalIdpCertificate';
import { CertificateValidationModel } from 'src/app/models/certificateValidationModel';
import { BaseIdpConnection } from 'src/app/models/baseIdpConnection';

@Injectable()
export class ExternalIdpEndpoint extends EndpointFactory {
   
    private readonly _externalIdpUrl: string = 'Connection';
    private readonly _validateCerficate: string = 'Account/ValidateCertificate';
    private readonly _verifyIdpNameUrl: string = 'Connection/VerifyDuplicateIdpName';
    private readonly _verifyEntityIdUrl: string = 'Connection/VerifyDuplicateEntityId';
    private get getEcternalIdpUrl() : string { return this.configurations.baseUrl + this._externalIdpUrl; }
    private readonly _accountControllerUrl: string = 'Account';
    private readonly _metadataDownloadUrl: string = '/IdpMetadata';
    private readonly _certificateDownloadUrl: string = '/IdpPublicCertificate';

    private readonly _idpValidateMetadata: string = '/ExternalIDP/ValidateMetadata';
    private readonly _externalIdp: string = '/ExternalIDP';
    public get externalIdpMetaDataValidate(): string { return this.configurations.baseUrl + this._externalIdpUrl + this._idpValidateMetadata; }
    public get createIdpConnection(): string { return this.configurations.baseUrl + this._externalIdpUrl + this._externalIdp; }
    public certificateValidate(): string {return this.configurations.baseUrl + this._validateCerficate; }

    public get verifyIdpNameUrl(): string {return this.configurations.baseUrl + this._verifyIdpNameUrl; }

    public get verifyEntityIdUrl(): string {return this.configurations.baseUrl + this._verifyEntityIdUrl; }
    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

        super(http, configurations, injector);
    }

    public getExternalIdpConnections<BaseIdpConnection>(type?: Number): Observable<BaseIdpConnection> {
        const endpointUrl =  `${this.getEcternalIdpUrl}?type=` + type;
        return this.http.get<BaseIdpConnection>(endpointUrl, this.getRequestHeaders());
    } 

    public validateExternalIdpMetaDataFile(param: ExternalIdpconnectionMetaData): Observable<ExternalIdpConnection> {
        const uploadData = new FormData();
        if (param.MetaDataXML !== undefined && param.MetaDataXML !== null) {
            uploadData.append('MetaDataXML', param.MetaDataXML, param.MetaDataXML.name);
        }      
        
        return this.http.post<ExternalIdpConnection>(this.externalIdpMetaDataValidate, uploadData, this.getFormDataRequestHeaders());
    }

    public createExternalIdpConnection(param : ExternalIdpConnection): Observable<ExternalIdpConnection> {

        return this.http.post<ExternalIdpConnection>(this.createIdpConnection, JSON.stringify(param) , this.getRequestHeaders());

    }

    public validateIdpCertificate(param : ExternalIdpCertificate): Observable<CertificateValidationModel> {
        const uploadData = new FormData();
        if (param.signingCertificate !== undefined && param.signingCertificate !== null) {
            uploadData.append('certificateFile', param.signingCertificate, param.signingCertificate.name);
        }      
        
        return this.http.post<CertificateValidationModel>(this.certificateValidate(), uploadData, this.getFormDataRequestHeaders());
    }

    public verifyIdpName(companyName: string): Observable<boolean> {
        return this.http.post<boolean>(this.verifyIdpNameUrl, JSON.stringify(companyName), this.getRequestHeaders());
    }

    public verifyEntityId(entityId: string): Observable<boolean> {
        let name = btoa(entityId);
        return this.http.post<boolean>(this.verifyEntityIdUrl, JSON.stringify(name), this.getRequestHeaders());
    }

    public getMetadataDownloadUrl(applicationId: number = 0): string {
        return this.configurations.baseUrl + this._accountControllerUrl + this._metadataDownloadUrl + (applicationId !== 0 ? '?applicationId='+ applicationId : '');
    }

    public getCertificateDownloadUrl(): string {
        return this.configurations.baseUrl + this._accountControllerUrl + this._certificateDownloadUrl;
    }

    public getpemCertificateDownloadUrl(): string {
        return this.configurations.baseUrl + this._accountControllerUrl + this._certificateDownloadUrl + "?certificateDownloadType=2" ;
    }

    public getExternalIdpConnectionDataById(connectionrefId: string): Observable<ExternalIdpConnection> {
        const endpointUrl = `${this.createIdpConnection}/` + connectionrefId;
        return this.http.get<ExternalIdpConnection>(endpointUrl, this.getRequestHeaders());
    }

    public updateExternalIdpConnection(param : ExternalIdpConnection): Observable<ExternalIdpConnection> {

        return this.http.put<ExternalIdpConnection>(`${this.createIdpConnection}/` + param.ReferenceId, JSON.stringify(param) , this.getRequestHeaders());

    }
}