
import { Location } from '@angular/common';
import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';
import { UserPreferenceModel } from 'src/app/models/UserPreference.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AuthService } from 'src/app/services/auth.service';
import { BrowserUtilsService } from 'src/app/services/browserUtil/browserUtils.service';
import { SotiThemeService } from 'src/app/services/soti-theme/soti-theme.service';
import { DarkTheme, LightTheme } from 'src/app/services/soti-theme/theme';
import { UserService } from 'src/app/services/user-services/user.service';
import { ThemeType } from 'src/app/shared/enums/ThemeType';
import { UserPreferenceType } from 'src/app/shared/enums/UserPreferenceType';
import { DisposalBag } from 'src/app/shared/utils/DisposalBag';
import { Permissions } from 'src/app/shared/enums/Permissions';
@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.ctrl.html',
    styleUrls: ['./authentication.ctrl.scss']
})

export class AuthenticationComponent implements OnInit, OnDestroy {
    

    public buttonText: string;
    public showPasswordIcon: boolean = true;
    @Output() 
    public onClose: EventEmitter<void> = new EventEmitter();
    public isMobileView: boolean;
    public sotionetheme: boolean;

    public themeMode: UserPreferenceModel = {
        TypeId: UserPreferenceType.THEME,
        Preference: ThemeType.LIGHT
    };
    public lightThemeType: number = ThemeType.LIGHT;
    public darkThemeType: number = ThemeType.DARK;
    @HostBinding('class.dark-mode-bg')
    public isDarkMode: boolean = false;
    private _disposalBag: DisposalBag = new DisposalBag();
    public passkeyAuthentication: Permissions[];
    public addButtonLoader = false;
    public SupportAccountLogin: boolean = false;
    public IsSupportCustomerTenant: boolean = false;
    constructor(
        private translationService: AppTranslationService,
        private location: Location,
        private userService: UserService,
        private _browserUtilService: BrowserUtilsService,
        private authService: AuthService,
        private themeService: SotiThemeService,
        ) {
            this.isMobileView = this._browserUtilService.isMobileOrTablet();
    }

    public ngOnInit(): void {
        
        this.buttonText = this.buttonText? this.translationService.getTranslation(this.buttonText) : this.translationService.getTranslation('lbl_change_password');
        
        
        if (this.isMobileView) {
            this.authService.isHeaderRequired.next(false);
        }

        
        this.sotionetheme = this.themeService._isSotiTheme;
        if (this.isMobileView && this.sotionetheme) {
            this.applyTheme(ThemeType.LIGHT, false);
            this.loadPreferences();
        }
        this.passkeyAuthentication = [Permissions.PASSKEYAUTHENTICATION];
        if (this.authService.currentUser.IsSupportAccountLogin) {
            this.SupportAccountLogin = true;
        }
        if (this.authService.currentUser.IsSupportCustomerTenant) {
            this.IsSupportCustomerTenant = true;
        }

    }

    public backClicked(): void {        
        this.onClose.emit();

        if (this.isMobileView) {
            this.location.back();
        }
        
    }


    

    public ngOnDestroy(): void {
        this._disposalBag.dispose();
    }
    private savePreferences(userPreference) {
        this._disposalBag.nextSub = this.userService.savePreferences([userPreference]).subscribe();
    }

    public setTheme(themeType: ThemeType, savePreference: boolean = true): void {
        this.applyTheme(themeType, savePreference);
       
    }

    
    public loadPreferences() {
        this.sotionetheme = this.themeService._isSotiTheme;
        this._disposalBag.nextSub = this.userService.getPreferences().subscribe((data) => {
            const themePreference = data.find(x => x.TypeId == UserPreferenceType.THEME);
            if (themePreference) {
                this.setTheme(themePreference.Preference, false);
            }
        });
    }

    private applyTheme(themeType: ThemeType, savePreference: boolean): void {
        this.themeMode.Preference = themeType;
    
        if (savePreference) {
            this.savePreferences(this.themeMode);
        }
    
        if (themeType == ThemeType.LIGHT) {
            this.themeService.setTheme(new LightTheme());
            this.isDarkMode = false;
        } else {
            this.themeService.setTheme(new DarkTheme());
            this.isDarkMode = true;
        }
    }
}
