/**
 * Created by pguimaraes on 11/8/2016.
 */
import { NgModule } from '@angular/core';
import { StatusNotificationBar } from './status-notification-bar.ctrl';
import { CommonModule } from "@angular/common"
import { ToastNotificationService } from '../../services/toast-services/toast-notification.service';
import { NotificationAggregator } from '../../utils/toast-utils/notification-aggregator';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateLanguageLoader } from '../../../services/app-translation.service';

@NgModule({  
    imports:[
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        })
    ], 
    declarations: [
        
        StatusNotificationBar
    ],
  
    exports: [
       
        StatusNotificationBar
    ],
    providers: [
        ToastNotificationService,
        NotificationAggregator
    ]
})
//tslint:disable
export class StatusNotificationBarModule {}