import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[sotiPopUpContent]'
})
/**
 * Directive used to wrap pop up content in a template, so that proper life cycle hooks are applied. 
 */
export class PopUpContent {
    constructor(public templateRef: TemplateRef<{}>, public viewContainer: ViewContainerRef) {}
}