
import { Injectable } from '@angular/core';

import { AppTranslationService } from './app-translation.service';
import { LocalStoreManager } from './local-store-manager.service';
import { DBkeys } from './db-keys';
import { Utilities } from './utilities';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';


type UserConfiguration = {
    language: string,
    homeUrl: string
};

@Injectable()
export class ConfigurationService {

    static readonly appVersion: string = '1'; 
    public apiUrlObserver : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);   
    
    idpHost = environment.IdpUrl;
    public baseUrl: string;
    public idpHostApiPath: string;
    public isProductionEnvironment : boolean;
    public ClientToSignalRLoadingTime : number;
  
    static readonly defaultLanguage: string = 'en-US';
    static readonly defaultHomeUrl: string = '/';

    private _language: string = null;
    private _homeUrl: string = null;

    constructor(
        private localStorage: LocalStoreManager, 
        private translationService: AppTranslationService,
        private http: HttpClient) {
            
        this.loadLocalChanges();
    }

    public setApiUrl(): void {
        
        this.http.get('environments/environment.json').subscribe((data: any) => {
            this.baseUrl = data.apiUrl;
            this.idpHostApiPath = data.apiUrl;
            this.isProductionEnvironment = data.isProductionEnvironment;
            this.ClientToSignalRLoadingTime = data.ClientToSignalRLoadingTime
            this.apiUrlObserver.next(true);
        });
    }


    private loadLocalChanges() {

    }


    private saveToLocalStore(data: any, key: string) {
        setTimeout(() => this.localStorage.savePermanentData(data, key));
    }


    import(jsonValue: string) {

        this.clearLocalChanges();

        if (!jsonValue)
            return;

        const importValue: UserConfiguration = Utilities.JSonTryParse(jsonValue);

        if (importValue.language != null)
            this.language = importValue.language;

        if (importValue.homeUrl != null)
            this.homeUrl = importValue.homeUrl;

    }


    export(changesOnly = true): string {

        const exportValue: UserConfiguration = {
            language: changesOnly ? this._language : this.language,
            homeUrl: changesOnly ? this._homeUrl : this.homeUrl

        };

        return JSON.stringify(exportValue);
    }


    clearLocalChanges() {
        this._language = null;
        this._homeUrl = null;


        this.localStorage.deleteData(DBkeys.LANGUAGE);
        this.localStorage.deleteData(DBkeys.HOME_URL);


        this.resetLanguage();
    }


    private resetLanguage() {
        const language = this.translationService.useBrowserLanguage();

        if (language) {
            this._language = language;
        } else {
            this._language = this.translationService.changeLanguage();
        }
    }


    set language(value: string) {
        this._language = value;
        this.saveToLocalStore(value, DBkeys.LANGUAGE);
        this.translationService.changeLanguage(value);
    }

    get language() {
        if (this._language != null)
            return this._language;

        return ConfigurationService.defaultLanguage;
    }


    set homeUrl(value: string) {
        this._homeUrl = value;
        this.saveToLocalStore(value, DBkeys.HOME_URL);
    }

    get homeUrl() {
        if (this._homeUrl != null)
            return this._homeUrl;

        return ConfigurationService.defaultHomeUrl;
    }










}
