import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CopyrightModule } from 'src/app/components/copyright/copyright.module';
import { TranslateLanguageLoader } from '../../../../services/app-translation.service';
 
import { SharedDialogsModule } from '../../../../shared/controls/dialogs';
import { SharedFormPanelModule } from '../../../../shared/controls/form-panel';
import { CoreCommonModule } from '../../../../shared/core-common.module';
import { AboutSotiIdentity } from './aboutSotiIdentity.ctrl';

@NgModule({
    imports: [
        CoreCommonModule,
        SharedDialogsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
         
        SharedFormPanelModule,
        CopyrightModule
    ],
    declarations: [
        AboutSotiIdentity
    ],
    exports: [
        AboutSotiIdentity
    ]
})
export class AboutSotiIdentityModule {
}
