
import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from
    "@angular/router";
import { AuthService } from "./auth.service";
import { DBkeys } from "./db-keys";


@Injectable()
export class CookieGuard  {
    private cookieRoute = "./cookiedisable";
    private dashBoardRoute = "./";
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {        
        return this.checkCookie();
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    canLoad(route: Route): boolean {        
        return this.checkCookie();
    }

    checkCookie(): boolean { 
        if (window.navigator.cookieEnabled === false) {
            this.router.navigate([this.cookieRoute]);         
        }        
        return true;
    }
}
