import { OnInit, Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AccountService } from 'src/app/services/account-services/account.service';
import { UserService } from 'src/app/services/user-services/user.service';
import { DomainService } from 'src/app/services/domain-services/domain-service';
import { HeaderService } from 'src/app/shared/header/header.service';
import { ViewLocationEnum } from 'src/app/shared/enums/ViewLocationEnum';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorCode } from 'src/app/shared/enums/ErrorCode';
import { ConfigurationService } from 'src/app/services/configuration.service';


@Component({
    selector: 'app-verifydomain',
    templateUrl: './verify-domain.view.html',
    styleUrls: ['./verify-domain.view.scss']
})

export class VerifyDomainComponent implements OnInit {

    public loadingPage: boolean = false;
    public verificationSuccessfull: boolean = false;
    public verificationFailed: boolean = false;
    public verificationsuccessfullMsg: string;
    public verificationfailedMsg: string;
    public verificationError: boolean = false;
    public isHeaderHide:boolean = false;

    constructor(private route: ActivatedRoute,
        private domainService: DomainService,
        private appTranslationService: AppTranslationService,
        private _headerService: HeaderService, 
        private authService: AuthService,
        private configurations: ConfigurationService) {
    }

    public ngOnInit(): void {
        this.authService.isHeaderRequired.next(true);
        this._headerService.setCurrentLocation(ViewLocationEnum.Verifydomain);
        this.route.queryParams.subscribe((params: Params) => {
            const verificationToken = params['verificationtoken'];
            if (verificationToken) {
                this.configurations.apiUrlObserver.subscribe((result) => {
                    if (result) {
                        this.verifiyDomain(verificationToken);
                    }
                });
            } else {
                this.verificationError = true;
            }
            this.loadingPage = false;    
        });

    }

    private verifiyDomain(verificationToken : string) : void {
        this.domainService.verifyDomain(verificationToken).subscribe(result => {
            let name = result.Domain;
            if (result.IsVerified) {
                this.verificationsuccessfullMsg = this.appTranslationService.getTranslation('msg_domain_verification_successfull').replace('<domain>', name);
                this.verificationSuccessfull = true;
            } else {
                this.verificationfailedMsg = this.appTranslationService.getTranslation('msg_domain_verification_failed').replace('<domain>', name);
                this.verificationFailed = true;
            }
           }, (error: HttpErrorResponse ) => {
               if (error.error && error.error.ErrorCode === ErrorCode.ExpiredDomainToken) {
                    this.verificationfailedMsg = this.appTranslationService.getTranslation('msg_domain_verification_failed').replace('<domain>', error.error.ErrorInfo);
                    this.verificationFailed = true;
                } else {
                    this.verificationError = true;
                }   
        });
    }
}