import { DataInitEndPointService } from './data-init-endpoint.service';
import { Injectable } from '@angular/core';
import { Constants } from 'src/app/constants';
import { environment } from 'src/environments/environment';
import { LocalStoreManager } from '../local-store-manager.service';
import { DBkeys } from '../db-keys';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class DataInitService {


    public encrArray: EnumArray[] = [];
    public ldapArray: EnumArray[] = [];
    public authArray: EnumArray[] = [];
    public appDefaultDescription: KeyValuePair[] = [];
    public idpForgotPasswordReturnUrl: string;
    public locationGranularity: KeyValuePair[] = [];

    public dataInitLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(private dataInitEndpoint: DataInitEndPointService, private localStoreManager: LocalStoreManager) {

    }
    public getAdminConsoleConfiguration(): void {
        this.dataInitEndpoint.getAdminConsoleConfiguration().subscribe(value => {
            this.setAdminConsoleConfiguration(JSON.parse(value));
        });
    }
    public getEnumsData(): void {

        this.dataInitEndpoint.getDataInitEndpoint().subscribe(value => {
            this.setEnumValues(JSON.parse(value));
        });
    }

    private setAdminConsoleConfiguration(items: any): void {
        environment.IdpUrl = items.consoleConfiguration.IdpInitiatedLoginUrl;
        this.appDefaultDescription.push({ Text: Constants.termsAndConditionsUrlKey, Value: items.consoleConfiguration.TermsAndConditionsUrl });
        this.idpForgotPasswordReturnUrl = items.consoleConfiguration.IdpForgotPasswordReturnUrl;
        this.localStoreManager.savePermanentData(items.consoleConfiguration.IdpInitiatedLoginUrl, DBkeys.IDP_URL);
        this.localStoreManager.savePermanentData(items.consoleConfiguration.IdpGenericBaseUrl, DBkeys.IDP_GENERIC_URL);
        this.localStoreManager.savePermanentData(items.consoleConfiguration.SoftwareVersion, DBkeys.SoftwareVersion);
        this.localStoreManager.savePermanentData(items.consoleConfiguration.GoogleWorkspaceEnabled, DBkeys.GOOGLE_WORKSPACE_ENABLED);
        
        this.dataInitLoaded.next(true);
    }

    private setEnumValues(items: any): void {

        this.encrArray.push({ Text: 'Ssl', Value: Number(items.encryptionTypeEnumModel.Ssl) });
        this.encrArray.push({ Text: 'None', Value: Number(items.encryptionTypeEnumModel.None) });

        this.authArray.push({ Text: 'Anonymous', Value: Number(items.authenticationTypeModel.Anonymous) });
        this.authArray.push({ Text: 'Basic', Value: Number(items.authenticationTypeModel.Basic) });
        this.authArray.push({ Text: 'Negotiate', Value: Number(items.authenticationTypeModel.Negotiate) });

        this.ldapArray.push({ Text: 'Active Directory', Value: Number(items.ldapTypeModel.Activedirectory) });
        this.ldapArray.push({ Text: 'Domino', Value: Number(items.ldapTypeModel.Domino) });
        this.ldapArray.push({ Text: 'Open Directory', Value: Number(items.ldapTypeModel.Opendirectory) });

        this.appDefaultDescription.push({ Text: Constants.mobiControlDefaultDescriptionKey, Value: items.appDefaultDescription.AppType2DefaultDescription });
        this.appDefaultDescription.push({ Text: Constants.connectDefaultDescriptionKey, Value: items.appDefaultDescription.AppType3DefaultDescription });
        this.appDefaultDescription.push({ Text: Constants.assistDefaultDescriptionKey, Value: items.appDefaultDescription.AppType8DefaultDescription });

        if (items.locationGranularityLevel) {
            this.locationGranularity.push({Text: Constants.cityLocationGranularity, Value: items.locationGranularityLevel.City});
            this.locationGranularity.push({Text: Constants.stateOrRegionLocationGranularity, Value: items.locationGranularityLevel.StateOrRegion});
            this.locationGranularity.push({Text: Constants.countryLocationGranularity, Value: items.locationGranularityLevel.Country}); 
        }

        this.dataInitLoaded.next(true);
    }

}

export interface EncryptionType {
    None: string;
    NoneValue: number;
    Ssl: string;
    SslValue: number;
}

export interface AuthenticationType {
    Anonymous: string;
    Basic: string;
    Negotiate: string;
    AnonymousValue: number;
    BasicValue: number;
    NegotiateValue: number;

}
export interface EnumArray {
    Text: string;
    Value: number;
}

export interface KeyValuePair {
    Text: string;
    Value: string;
}

export interface LdapType {
    Activedirectory: string;
    Domino: string;
    Opendirectory: string;
    ActivedirectoryValue: number;
    DominoValue: number;
    OpendirectoryValue: number;
}
