import { NgModule } from '@angular/core';
import { InputIconControl } from './input-icon.ctrl';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CoreCommonModule } from '../../core-common.module';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        CoreCommonModule
    ],
    declarations: [
        InputIconControl
    ],
    exports: [
        InputIconControl
    ]
})
export class SharedInputIconModule {}