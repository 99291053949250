import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseNotification } from 'src/app/models/base-notification.model';
import { BulkOperationNotification } from 'src/app/models/bulk-operation-notification.model';
import { DownloadEventLogNotification } from 'src/app/models/download-eventLog-notification.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { appMessenger } from 'src/app/shared/controls/toaster-messages/AppMessenger';
import { AlertSeverityEnum } from 'src/app/shared/enums/AlertSeverityEnum';
import { ClientNotificationMessage, NotificationType } from 'src/app/shared/utils/toast-utils/ClientNotificationMessage';
import { NotificationListingFooterActionModel } from './notification-listing/notification-listing.component';
import {NotificationActionTypeEnum} from 'src/app/shared/enums/notification-type.enum'

@Component({
    selector: 'app-notification-view',
    templateUrl: './notification.view.html',
    styleUrls: ['./notification.view.scss']
})

export class NotificationView implements OnInit, OnDestroy {
    public notifications: BaseNotification[];
    public announcements: BaseNotification[];
    public showNotifications: boolean = false;
    private subscription: Subscription;

    @Input()
    public tabIndex: number = 0;

    constructor(
        private notificationService: NotificationService,
        private translationService: AppTranslationService,
        private authService: AuthService) {
        
    }

    @HostListener('keydown', ['$event'])
    public onkeydown(event: KeyboardEvent): void {
        if(event.key === 'Escape'){
            this.showNotifications = false;
        }
    }

    public ngOnInit(): void {
        this.notifications = this.notificationService.recentNotifications;
        this.announcements = this.notificationService.recentAnnouncements;
        this.subscription = this.notificationService.notifier().subscribe(notification => {
            if (notification) {
                if(notification instanceof BulkOperationNotification){
                    this.handleBulkNotification(notification);
                } else {
                    this.handleEventLogNotification((<DownloadEventLogNotification>notification));
                }
            } else{                
                this.notifications = this.notificationService.recentNotifications;
            }
           this.announcements = this.notificationService.recentAnnouncements;
        });
    }

    public unReadNotification():boolean{
        return (this.notifications.filter(x => !x.read).length > 0 || this.announcements.filter(x => !x.read).length > 0);
    }

    public toggleUnreadNotifications(): void {
        this.showNotifications = !this.showNotifications;
                   
    }
    public ngOnDestroy(): void {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
    }

    private handleBulkNotification(notification: BulkOperationNotification): void {
        this.notifications = this.notificationService.recentNotifications;
        if (notification.totalBatches && notification.totalBatches === notification.batchExecuted && 
            this.notificationService.isFinalToastMessageShownForBulkOperation(notification.id)) {
                 this.showToastMessage(notification);           
        }
    }
    private showToastMessage(notification: BulkOperationNotification): void {
        
        if (notification.totalRecords === notification.failedRecords) {
            appMessenger.postMessage(new ClientNotificationMessage(
                this.translationService.getTranslation('msgAllUploadedUsersFailed'),
                this.translationService.getTranslation('msgAllUploadedUsersFailed'),
                NotificationType.Error,
                AlertSeverityEnum.Critical));
        } else {
            let msg: string = this.translationService.getTranslation(notification.text + 'Success')
            .replace('<SuccessRecords>', notification.successRecords)
            .replace('<TotalRecords>', notification.totalRecords);
            appMessenger.postMessage(new ClientNotificationMessage(
                msg, msg, NotificationType.Success, AlertSeverityEnum.Critical));
        }
    }
    
    private handleEventLogNotification(notification: DownloadEventLogNotification): void {
        this.notifications = this.notificationService.recentNotifications;
        if (notification.followUpUri) {
                 this.showEventLogToastMessage();           
        }
    }

    private showEventLogToastMessage(){
        appMessenger.postMessage(new ClientNotificationMessage(
            this.translationService.getTranslation('msg_log_file_success_export'),
            this.translationService.getTranslation('msg_log_file_success_export'),
            NotificationType.Success,
            AlertSeverityEnum.Critical));
    }

    public notificationListingFooterAction(actionModel : NotificationListingFooterActionModel){
        switch (actionModel.action){
            case NotificationActionTypeEnum.MarkAllRead:
                this.notificationService.updateNotificationReadStatus(this.authService.currentUser.ReferenceId, actionModel.notificationIds, actionModel.type);
                break;
            case NotificationActionTypeEnum.ClearAll:
                this.notificationService.deleteNotifications(this.authService.currentUser.ReferenceId, actionModel.notificationIds, actionModel.type);
                break;
        }
    }
}
