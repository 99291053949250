
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalDialogRef, ModalDialogService2 } from 'src/app/shared/controls/dialogs';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { ContactUsMessage } from '../../../../models/ContactUsMessage';
import { AccountService } from 'src/app/services/account-services/account.service';
import { appMessenger } from 'src/app/shared/controls/toaster-messages/AppMessenger';
import { ClientNotificationMessage, NotificationType } from 'src/app/shared/utils/toast-utils/ClientNotificationMessage';
import { AlertSeverityEnum } from 'src/app/shared/enums/AlertSeverityEnum';
import { LocalStoreManager } from 'src/app/services/local-store-manager.service';
import { Company } from 'src/app/models/company.model';
import { DBkeys } from 'src/app/services/db-keys';
import { ContactUs } from '../contactUs/contactus.ctrl';
import { EulaAcceptanceModal } from 'src/app/components/EulaManagement/Modals/eula-acceptance-modal.ctrl';

@Component({
    selector: 'aboutSotiIdentity',
    styleUrls: ['./aboutSotiIdentity.ctrl.scss'],
    templateUrl: './aboutSotiIdentity.ctrl.html'
})
export class AboutSotiIdentity implements OnInit {

 constructor(private localStorageManager : LocalStoreManager,  private _modalDialogService: ModalDialogService2)
 {

 }   
private previousYear:number;
private currentYear:number;
public identityVersion: string;
private showOnlyCloseButtonOnEulaScreen : boolean = true;
@Output('action')
public action: EventEmitter<boolean> = new EventEmitter<boolean>();

public ngOnInit(): void {
     this.previousYear = (new Date()).getFullYear() - 1;
     this.currentYear = (new Date()).getFullYear();
     this.identityVersion = this.localStorageManager.getData(DBkeys.SoftwareVersion);
}
public onContactSupportClick(): void{    
    this._modalDialogService.open(ContactUs);
    this.action.emit(true);
}

public onEulaClick(): void {    
    this._modalDialogService.open(EulaAcceptanceModal, this.showOnlyCloseButtonOnEulaScreen);    
    this.action.emit(true);
}
}
