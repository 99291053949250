import {
    Directive, ElementRef, OnInit, HostListener, HostBinding, Output, EventEmitter,
    AfterContentChecked, Renderer2, OnDestroy, ChangeDetectorRef
} from '@angular/core';
@Directive({
    selector: '[resize]'
})
export class ResizeDirective implements OnInit, AfterContentChecked, OnDestroy {

    @HostBinding('style.width.px') public currentWidth: number;
    @Output() public newWidth: EventEmitter<number> = new EventEmitter<number>();

    private _clicked: boolean = false;
    private _diffX: number;

    private _minWidth: number;
    private _maxWidth: number;

    private _bar: HTMLElement;

    private _listeners: Function[] = [];
    private _mouseDownListener: Function;

    constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {

    }

    public ngOnInit(): void {
        this.currentWidth = this._elementRef.nativeElement.width;
        let componentStyles = window.getComputedStyle(this._elementRef.nativeElement);
        this._minWidth = parseInt(componentStyles.minWidth.substring(0, componentStyles.minWidth.indexOf('px')));
        this._maxWidth = parseInt(componentStyles.maxWidth.substring(0, componentStyles.maxWidth.indexOf('px')));

        let node = document.createElement('span');
        node.style.position = 'absolute';
        node.style.height = '100%';
        node.style.left = '100%';
        node.style.width = '4px';
        node.style.cursor = 'e-resize';
        node.style.zIndex = '10';
        node.style.userSelect = 'none';
        node.style.webkitUserSelect = 'none';
        node.id = 'resize-bar';
        node.setAttribute("aria-hidden", "true");
        this._elementRef.nativeElement.appendChild(node);
        this._bar = node;
        this._mouseDownListener = this._renderer.listen(node, 'mousedown', (event) => this.onMouseDown(event));
    }

    public ngAfterContentChecked(): void {
    }

    public ngOnDestroy(): void {
        this._mouseDownListener();
        this._removeGlobalListeners();
    }

    public onMouseDown(event: MouseEvent): void {
        this._listeners.push(
            this._renderer.listen('document', 'mousemove', (event) => this.onMouseMove(event)),
            this._renderer.listen('document', 'mouseup', (event) => this.onMouseUp(event))
        );
        this._clicked = true;
        this._diffX = this._bar.clientWidth;
    }

    public onMouseUp(event: MouseEvent): void {
        if (this._clicked) {
            let deviceList = this._elementRef.nativeElement.parentNode.parentNode;
            this._clicked = false;
            if (this._minWidth && this.currentWidth < this._minWidth) {
                this.newWidth.emit(this._minWidth);
            } else if (this._maxWidth && this.currentWidth > this._maxWidth) {
                this.newWidth.emit(this._maxWidth);
            } else {
                this.newWidth.emit(this.currentWidth);
            }
            deviceList.style.cursor = 'initial';
            this._elementRef.nativeElement.style.cursor = 'initial';
            deviceList.style.msUserSelect = 'initial';
            deviceList.style.webkitUserSelect = 'initial';

            this._removeGlobalListeners();
        }
    }

    public onMouseMove(event: MouseEvent): void {
        let deviceList = this._elementRef.nativeElement.parentNode.parentNode;
        if (event.clientX < this._elementRef.nativeElement.clientWidth + this._bar.clientWidth && event.clientX > this._elementRef.nativeElement.clientWidth - this._bar.clientWidth) {
            this._elementRef.nativeElement.style.cursor = 'e-resize';
            deviceList.style.msUserSelect = 'none';
            deviceList.style.webkitUserSelect = 'none';
        }
        if (this._clicked && this._elementRef.nativeElement.style.cursor === 'e-resize') {
            this._renderer.setStyle(this._elementRef.nativeElement, 'width', event.clientX + 'px');
            this.currentWidth = event.clientX;
            deviceList.style.cursor = 'e-resize';
        }
    }

    private _removeGlobalListeners(): void {
        this._listeners.forEach(func => func && func());
        this._listeners = [];
    }

}