import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    OnChanges,
    OnDestroy,
    Optional,
    SimpleChanges
} from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { AccordionCtrl } from '../accordion.ctrl';
import { sotiExpansionAnimations } from '../expansion-animations';
import { ExpansionPanelState } from './expansion-panel-state';
import { Subject, Observable } from 'rxjs';

@Component({
    selector: 'im-expansion-panel',
    exportAs: 'imExpansionPanel',
    templateUrl: './expansion-panel.ctrl.html',
    styleUrls: ['./expansion-panel.ctrl.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['disabled', 'expanded'],
    outputs: ['opened', 'closed', 'expandedChange'],
    animations: [sotiExpansionAnimations.bodyExpansion]
})
export class ExpansionPanelCtrl extends CdkAccordionItem implements OnChanges, OnDestroy {
    @HostBinding('class.panel-spacing')
    public get hasSpacing(): boolean {
        return this.accordion ? (<AccordionCtrl>this.accordion).displayMode == 'spaced' : false;
    }

    public get inputChanges(): Observable<SimpleChanges> {
        return this._inputChanges.asObservable();
    }

    private readonly _inputChanges: Subject<SimpleChanges> = new Subject<SimpleChanges>();

    constructor(
        @Optional() accordion: AccordionCtrl,
        _changeDetectorRef: ChangeDetectorRef,
        _uniqueSelectionDispatcher: UniqueSelectionDispatcher
    ) {
        super(accordion, _changeDetectorRef, _uniqueSelectionDispatcher);
        this.accordion = accordion;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this._inputChanges.next(changes);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this._inputChanges.complete();
    }

    //region HTML methods

    public getExpandedState(): ExpansionPanelState {
        return this.expanded ? 'expanded' : 'collapsed';
    }

    public onBodyAnimation(event: AnimationEvent): void {
        const cssClass = 'panel-expanded';

        // Toggle the body's `overflow: hidden` class when closing starts or when expansion ends in
        // order to prevent the cases where switching too early would cause the animation to jump.
        // Note that we do it directly on the DOM element to avoid the slight delay that comes
        // with doing it via change detection.
        if (event.phaseName === 'done' && event.toState === 'expanded') {
            event.element.classList.add(cssClass);
        } else if (event.phaseName === 'start' && event.toState === 'collapsed') {
            event.element.classList.remove(cssClass);
        }
    }

    //endregion
}
