import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { UserService } from 'src/app/services/user-services/user.service';
import { InfoMessageBarOptions } from 'src/app/shared/controls/info-message-bar/InfoMessageBarOptions';
import { UserPreferenceModel } from 'src/app/models/UserPreference.model';
import { UserPreferenceType } from 'src/app/shared/enums/UserPreferenceType';
import { ThemeType } from 'src/app/shared/enums/ThemeType';
import { appMessenger } from 'src/app/shared/controls/toaster-messages/AppMessenger';
import { ClientNotificationMessage, NotificationType } from 'src/app/shared/utils/toast-utils/ClientNotificationMessage';
import { AlertSeverityEnum } from 'src/app/shared/enums/AlertSeverityEnum';
import { UserPasskeyList } from 'src/app/models/UserPasskeyList';
import { List } from 'lodash';
import { toArray } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs-compat';
import { ConfirmationDialogModal, ConfirmationDialogOptions } from 'src/app/shared/internal-controls/confirmation-dialog/confirmation-dialog.ctrl';
import { ModalDialogService2, PopUpTrigger, SOTI_MODAL_DATA } from 'src/app/shared/controls/dialogs';
import { EditPasskeyModal, EditModalParam } from './Modals/edit-passkey/edit-passkey-modal.ctrl';
import { UserListService } from 'src/app/services/user-list-service';
import { DisposalBag } from 'src/app/shared/utils/DisposalBag';


@Component({
    selector: 'register-passkeys',
    templateUrl: './register-passkey.ctrl.html',
    styleUrls: ['./register-passkey.ctrl.scss']
})

export class RegisterPasskeysComponent implements OnInit {
    @Input()
    public showLoader: boolean = false;
    public userPasskeyList: UserPasskeyList[] = [];
    public showTable: boolean = false;
    public showCriteriaError: boolean = true;

    public charLength: number;
    public existingPasswordIncorrect: boolean = false;
    public infoMessageBarOptions: InfoMessageBarOptions = null;
    public confiramtionDialogOptions : ConfirmationDialogOptions;
    public message: string = '';

    @Input()
    public mobileView: boolean = false;

    @Input()
    public footerHide: boolean = true;

    @Input()
    public isChangedByAdmin: boolean = false;

    @Input()
    public userId: string;
    @Input()
    public buttonText: string;
    public showPasswordIcon: boolean = true;
    @Output()
    public onClose: EventEmitter<void> = new EventEmitter();
    public isMobileView: boolean;

    public themeMode: UserPreferenceModel = {
        TypeId: UserPreferenceType.THEME,
        Preference: ThemeType.LIGHT
    };
    public lightThemeType: number = ThemeType.LIGHT;
    public darkThemeType: number = ThemeType.DARK;
    @HostBinding('class.dark-mode-bg')
    public isDarkMode: boolean = false;

    public addButtonLoader = false;
    public passkeyresponse: any = null;
    @Input()
    public sotionetheme: boolean = true;
    private _disposalBag: DisposalBag = new DisposalBag();
	
    constructor(
        private translationService: AppTranslationService,
        private userService: UserService,
        private _modalDialogService: ModalDialogService2,
        private userlistservice: UserListService,
    ) { }

    public ngOnInit(): void {
        this.showLoader = true;
        this.buttonText = this.buttonText ? this.translationService.getTranslation(this.buttonText) : this.translationService.getTranslation('lbl_register-passkeys');
        this.getUserPasskeyData();
        this._disposalBag.nextSub = this.userlistservice.groupListValueObserver.subscribe(value => {
            if (value === true) {
                this.getUserPasskeyData();
            }
        });
    }

    public getUserPasskeyData(): void{
        this.userService.getUserPasskeyData().pipe(
            toArray()
          ).subscribe((responseData: any) => {
            this.userPasskeyList = responseData[0];
            this.showLoader = false;
          });   
    }

    public deleteUserPasskey(userPasskey: UserPasskeyList): void {
        this.message = this.translationService.getTranslation('lbl_delete_passkey_warning');
        this.showDeletePasskeyWarning(this.message,() => this.onDeletePasskeyClick(userPasskey));
        
    }
    
    public onDeletePasskeyClick(userPasskey: UserPasskeyList): void{
        this.userService.deletePasskey(userPasskey.ReferenceId).subscribe(() => {
            this._modalDialogService.closeModal();
            this.getUserPasskeyData();
                appMessenger.postMessage(new ClientNotificationMessage(
                    this.translationService.getTranslation('msg_delete_passkey'),
                    this.translationService.getTranslation('msg_delete_passkey'),
                    NotificationType.Success,
                    AlertSeverityEnum.Minor));

        },
            (err) => {
                this._modalDialogService.closeModal();
                let message = this.translationService.getTranslation('msg_delete_passkey_failed');
                appMessenger.postMessage(new ClientNotificationMessage(this.translationService.getTranslation('msg_Error'),
                    message,
                    NotificationType.Error,
                    AlertSeverityEnum.Minor));

       });
    }

    public addPasskeys(): void {
        this.showLoader = true;
        this.openEditPasskeyModal(null, -1);
    }
    
    public editUserPasskey(userPasskey: UserPasskeyList, index: number): void {
        this.openEditPasskeyModal(userPasskey, index);
    }

    private openEditPasskeyModal(userPasskey: UserPasskeyList | null, index: number): void {
        const modalRef = this._modalDialogService.open(EditPasskeyModal, new EditModalParam(this.sotionetheme, userPasskey));

        const refreshSubscription = modalRef.componentInstance.refreshPassKeyList.subscribe(() => {
            this.getUserPasskeyData();
        });
    
        const duplicateSubscription = modalRef.componentInstance.checkDuplicatePassKey.subscribe((platform: string) => {
            modalRef.componentInstance.duplicatePasskeyName = this.userPasskeyList.some((passkey, i) => (index === -1 || i !== index) && passkey.Platform.toLowerCase() === platform.toLowerCase());
        });
    
        modalRef.closed().subscribe(() => {
            this.showLoader = false;
            if (refreshSubscription.closed){
                refreshSubscription.unsubscribe();
            }
            setTimeout(()=>{
                refreshSubscription.unsubscribe();
            })
            duplicateSubscription.unsubscribe();
        });
    }

    private showDeletePasskeyWarning(message: string, confirmationCallback): void {
        this.confiramtionDialogOptions  =  new ConfirmationDialogOptions();
        this.confiramtionDialogOptions.title = this.translationService.getTranslation('lbl_deletePasskey_title');
        this.confiramtionDialogOptions.icon = 'identity-icon-delete';
        this.confiramtionDialogOptions.okButtonText = this.translationService.getTranslation('btn_delete');
        this.confiramtionDialogOptions.okButtonCssClass = 'delete';
        this.confiramtionDialogOptions.sotionetheme = this.sotionetheme;
        this.confiramtionDialogOptions.hideHelpBtn = true;
        this.confiramtionDialogOptions.showButtonLoader = new BehaviorSubject<boolean>(true);
        this.confiramtionDialogOptions.body = message;
        this.confiramtionDialogOptions.onSubmit.subscribe(() => { confirmationCallback() });
        this._modalDialogService.open(ConfirmationDialogModal, this.confiramtionDialogOptions);
    }
}