
import { Component, OnInit } from '@angular/core';
import { ModalDialogService2 } from 'src/app/shared/controls/dialogs';
import { EulaAcceptanceModal } from './Modals/eula-acceptance-modal.ctrl';

@Component({
    selector: 'eula',
    styleUrls: ['./eula-management.view.scss'],
    templateUrl: './eula-management.view.html'
})
export class EulaManagementViewComponent implements OnInit {

    constructor(
        private _modalDialogService: ModalDialogService2
    ) {}

    public ngOnInit(): void {
      this._modalDialogService.open(EulaAcceptanceModal);
    }
}
