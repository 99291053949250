import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationListFilterParams } from 'src/app/models/ApplicationListFilterParams';
import { AssociatedMspTenants } from 'src/app/models/AssociatedMspTenants';
import { MspChangeStatusRequest } from 'src/app/models/MspChangeStatusRequest';
import { MspParentTenantAssociation } from 'src/app/models/MspParentTenantAssociation';
import { TenantAuthority } from 'src/app/models/TenantAuthority.Model';
import { TenantAuthorityDetailsUI } from 'src/app/models/TenantAuthorityDetailsUI.model';
import { Application } from 'src/app/models/application.model';
import { ApplicationList } from 'src/app/models/applicationList.modal';
import { Roles } from 'src/app/models/applicationRoles.model';
import { ApplicationUser } from 'src/app/models/applicationUser.model';
import { CompanyListUI } from 'src/app/models/company-list-ui.model';
import { Company } from 'src/app/models/company.model';
import { DuoAuthenticationConfiguration } from 'src/app/models/duoAuthenticationConfiguration.model';
import { DuoUsernameFormat } from 'src/app/models/duoUsernameFormat.model';
import { EmailAuthenticationConfiguration } from 'src/app/models/emailAuthenticationConfiguration';
import { FilteringParams } from 'src/app/models/filteringparams';
import { GoogleAuthenticationConfiguration } from 'src/app/models/googleAuthenticationConfiguration.model';
import { ListResponse } from 'src/app/models/list-response.model';
import { MspAssociationRequest } from 'src/app/models/mspAssociationRequest.model';
import { MspChildRequest } from 'src/app/models/mspChildRequest.model';
import { MspRequestHistory } from 'src/app/models/mspRequestHistory.model';
import { MspTenantLinkingFilterParams } from 'src/app/models/mspTenantLinkingFilterParams';
import { TenantBackgroundImages } from 'src/app/models/tenantBackgroundImages.model';
import { TenantLogo } from 'src/app/models/tenantLogoImages.model';
import { TenantFeature } from 'src/app/models/tenantFeature.model';
import { TenantPreferenceseModel } from 'src/app/models/tenantPreferences.model';
import { UserApplication } from 'src/app/models/userApplication.model';
import { Country } from '../../models/country.model';
import { Group } from '../../models/group.model';
import { IndustryType } from '../../models/industry-type.model';
import { CompanyFeatureEnum } from '../../shared/enums/CompanyFeatureEnum';
import { ConfigurationService } from '../configuration.service';
import { EndpointFactory } from '../endpoint-factory.service';
import { PasswordPolices } from './../../models/passwordPolicies';
import { AuthorityListFilterParams } from 'src/app/models/AuthorityListFilterParams';
import { AssignedApplicationModel } from 'src/app/models/assignedApplication.model';
import { MspDisplayApplication } from '../../models/mspdisplayapplication.model';
import { MspLinkingModel } from '../../models/msplinking.model';
import { RestrictedUserTenantPreferences } from 'src/app/models/RestrictedUserTenantPreferences';
import { MspTenantInfo } from 'src/app/models/MspTenantInfo';
import { GetApplicationsResponseModel } from 'src/app/models/GetApplicationsResponseModel.model';

@Injectable()
export class CompanyEndpoint extends EndpointFactory {
    get baseUrl() { return this.configurations.baseUrl; }


    private readonly _companyUrl: string = 'Company';

    private readonly _groupByComanyIdUrl: string = '/Groups';
    private readonly _applicationsByComanyIdUrl: string = '/Applications';
    private readonly _featuresByCompanyIdUrl: string = '/Features';
    private readonly _associatedMspTenantsUrl: string = '/AssociatedMspTenants';
    private readonly _mspRequestHistoryUrl: string = '/MspChildRequestHistory';
    private readonly _mspPortalApplicationsUrl: string = '/MspPortalApplications';
    private readonly _getCountryUrl: string = 'Account/Country';
    private readonly _getIndustryTypeUrl: string = 'Account/IndustryType';
    private readonly _verifyEmailUrl: string = '/VerifyEmailAddress';
    private readonly _verifyCompanyName: string = '/VerifyDuplicateCompany';
    private readonly _verifyTenantId: string = '/VerifyTenantId';
    private readonly _setPasswordPolicies: string = '/SetPasswordPolicy';
    private readonly _getPasswordPolicies: string = '/GetPasswordPolices';
    private readonly _emailAuthenticationConfiguration: string = '/AuthenticationByEmailConfiguration';
    private readonly _googleAuthenticationConfiguration: string = '/AuthenticationByGoogleConfiguration';

    private readonly _microsoftAuthenticationConfiguration: string = '/AuthenticationByMicrosoftConfiguration';
    private readonly _deleteCompaniesUrl: string = '/Delete';
    private readonly _deactivateCompaniesUrl: string = '/Deactivate';
    private readonly _activateCompaniesUrl: string = '/Activate';
    private readonly _updateCompaniesUrl: string = '/UpdatePasswordPolices';
    private readonly _assignApplicationUrl: string = '/AssignApplication';
    private readonly _assignFeatureUrl: string = '/AssignFeatures';
    private readonly _strPasswordPolicy: string = 'PasswordPolicy';
    private readonly _duoAuthenticationConfiguration: string = '/AuthenticationByDuoConfiguration';
    private readonly _duoUsernameFormat: string = 'DuoUsernameFormats';
    private readonly _filterCompany: string = '/FilterCompany';
    private readonly _validatePolicyUrl: string = '/ValidatePolicy';
    private readonly _tenantPreference: string = '/Preference';
    private readonly _tenantPreferences: string = '/Preferences';
    private readonly _background: string = '/BackgroundImages';
    private readonly _backgrounds: string = '/BackgroundImage';
    private readonly _tenantLogo: string = '/TenantLogo';
    private readonly _logo: string = '/Logo';
    private readonly _tenantLogos: string = '/TenantLogos';
    private readonly _tenantAuthority: string = '/TenantAuthority';
    private readonly _verifyDuplicateAuthorityName: string = '/Authority/VerifyDuplicateName';
    private readonly _restrictedUserTenantPreferences: string = '/RestrictedUserTenantPreference';
    private readonly _tokenUserTenantPreferences: string = '/TokenUserTenantPreference';




    get groupByComanyIdUrl() { return this._groupByComanyIdUrl; }

    public get companyUrl(): string { return this.configurations.baseUrl + this._companyUrl; }


    public get getCountryUrl(): string { return this.configurations.baseUrl + this._getCountryUrl; }

    public get getIndustryTypeUrl(): string { return this.configurations.baseUrl + this._getIndustryTypeUrl; }

    public get verifyEmailUrl(): string { return this.configurations.baseUrl + this._companyUrl + this._verifyEmailUrl; }

    public get verifyCompanyNameUrl(): string { return this.configurations.baseUrl + this._companyUrl + this._verifyCompanyName; }

    public get verifyTenantIdUrl(): string { return this.configurations.baseUrl + this._companyUrl + this._verifyTenantId; }

    public get setPasswordPoliciesUrl(): string { return this.configurations.baseUrl + this._companyUrl + this._setPasswordPolicies; }

    public get getPasswordPoliciesUrl(): string { return this.configurations.baseUrl + this._companyUrl + this._getPasswordPolicies; }

    public get updatePasswordPoliciesUrl(): string { return this.configurations.baseUrl + this._companyUrl + this._updateCompaniesUrl; }

    public get DeleteCompaniesUrl(): string { return this.configurations.baseUrl + this._companyUrl + this._deleteCompaniesUrl; }

    public get companyApplications(): string { return this.configurations.baseUrl + this._companyUrl; }

    public get assignApplicationUrl(): string { return this.configurations.baseUrl + this._companyUrl + this._deleteCompaniesUrl; }

    public get DeactivateCompaniesUrl(): string { return this.configurations.baseUrl + this._companyUrl + this._deactivateCompaniesUrl; }

    public get ActivateCompaniesUrl(): string { return this.configurations.baseUrl + this._companyUrl + this._activateCompaniesUrl; }

    public get getPasswordPoliciesByIdentifierUrl(): string { return this.configurations.baseUrl + this._companyUrl; }
    public get filterCompany(): string { return this.configurations.baseUrl + this._companyUrl + this._filterCompany; }

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

        super(http, configurations, injector);
    }


    getCompanyByIdEndPoint<Company>(referenceId?: string): Observable<Company> {
        let endpointUrl = `${this.companyUrl}/${referenceId}`;

        return this.http.get<Company>(endpointUrl, this.getRequestHeaders());
    }


    getCompanyGroupsEndpoint(referenceId?: string): Observable<Group[]> {
        let endpointUrl = `${this.companyUrl}/${referenceId}` + this.groupByComanyIdUrl;
        return this.http.get<Group[]>(endpointUrl, this.getRequestHeaders());
    }

    public createCompanyEndpointService(company: Company): Observable<Company> {
        return this.http.post<Company>(this.companyUrl, JSON.stringify(company), this.getRequestHeaders());
    }

    public updateCompany(company: Company): Observable<void> {
        return this.http.put<void>(this.companyUrl, JSON.stringify(company), this.getRequestHeaders());
    }

    public getCountries(): Observable<Country[]> {
        let endpointUrl = this.getCountryUrl;
        return this.http.get<Country[]>(endpointUrl, this.getRequestHeaders());
    }

    public getIndustryType(): Observable<IndustryType[]> {
        let endpointUrl = this.getIndustryTypeUrl;
        return this.http.get<IndustryType[]>(endpointUrl, this.getRequestHeaders());
    }

    public verifyEmailAddress(emailAddress: string): Observable<boolean> {
        return this.http.post<boolean>(this.verifyEmailUrl, JSON.stringify(emailAddress), this.getRequestHeaders());
    }
    public verifyTenantId(tenantId: string): Observable<boolean> {
        let endpointUrl = `${this.verifyCompanyNameUrl}?companyId=${tenantId}&companyName=${tenantId}`;
        return this.http.get<boolean>(endpointUrl, this.getRequestHeaders());
    }

    public verifyDuplicateTenantId(tenantId: string): Observable<void> {
        let endpointUrl = `${this.verifyTenantIdUrl}?tenantId=${tenantId}`;
        return this.http.get<void>(endpointUrl, this.getRequestHeaders());
    }

    public getCompanies(param?: FilteringParams): Observable<ListResponse<CompanyListUI>> {
        return this.http.post<ListResponse<CompanyListUI>>(this.filterCompany, param, this.getRequestHeaders());
    }

    public setPasswordPolices(passwordPolices: PasswordPolices): Observable<PasswordPolices> {
        return this.http.post<PasswordPolices>(this.setPasswordPoliciesUrl, JSON.stringify(passwordPolices), this.getRequestHeaders());
    }

    public getCompanyPolicy(): Observable<PasswordPolices> {
        let endpointUrl = this.getPasswordPoliciesUrl;
        return this.http.get<PasswordPolices>(endpointUrl, this.getRequestHeaders());
    }

    public getEmailAuthenticationConfiguration(companyReferenceId: string): Observable<EmailAuthenticationConfiguration> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId + this._emailAuthenticationConfiguration}`;
        return this.http.get<EmailAuthenticationConfiguration>(endpointUrl, this.getRequestHeaders());
    }

    public saveEmailAuthenticationConfiguration(companyReferenceId: string, configuration: EmailAuthenticationConfiguration)
        : Observable<void> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId + this._emailAuthenticationConfiguration}`;
        return this.http.post<void>(endpointUrl, configuration, this.getRequestHeaders());
    }

    public getGoogleAuthenticationConfiguration(companyReferenceId: string): Observable<GoogleAuthenticationConfiguration> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId + this._googleAuthenticationConfiguration}`;
        return this.http.get<GoogleAuthenticationConfiguration>(endpointUrl, this.getRequestHeaders());
    }

    public validateGoogleAuthenticationConfiguration(companyReferenceId: string): Observable<boolean> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId + this._googleAuthenticationConfiguration + this._validatePolicyUrl}`;
        return this.http.get<boolean>(endpointUrl, this.getRequestHeaders());
    }

    public validateEmailAuthenticationConfiguration(companyReferenceId: string): Observable<boolean> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId + this._emailAuthenticationConfiguration + this._validatePolicyUrl}`;
        return this.http.get<boolean>(endpointUrl, this.getRequestHeaders());
    }

    public validateDuoAuthenticationConfiguration(companyReferenceId: string): Observable<boolean> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId + this._duoAuthenticationConfiguration + this._validatePolicyUrl}`;
        return this.http.get<boolean>(endpointUrl, this.getRequestHeaders());
    }

    public updateGoogleAuthenticationConfiguration(companyReferenceId: string, configuration: GoogleAuthenticationConfiguration)
        : Observable<void> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId + this._googleAuthenticationConfiguration}`;
        return this.http.put<void>(endpointUrl, configuration, this.getRequestHeaders());
    }

    public getMicrosoftAuthenticationConfiguration(companyReferenceId: string): Observable<GoogleAuthenticationConfiguration> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId + this._microsoftAuthenticationConfiguration}`;
        return this.http.get<GoogleAuthenticationConfiguration>(endpointUrl, this.getRequestHeaders());
    }

    public validateMicrosoftAuthenticationConfiguration(companyReferenceId: string): Observable<boolean> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId + this._microsoftAuthenticationConfiguration + this._validatePolicyUrl}`;
        return this.http.get<boolean>(endpointUrl, this.getRequestHeaders());
    }

    public updateMicrosoftAuthenticationConfiguration(companyReferenceId: string, configuration: GoogleAuthenticationConfiguration)
        : Observable<void> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId + this._microsoftAuthenticationConfiguration}`;
        return this.http.put<void>(endpointUrl, configuration, this.getRequestHeaders());
    }

    public updateEmailAuthenticationConfiguration(companyReferenceId: string, configuration: EmailAuthenticationConfiguration)
        : Observable<void> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId + this._emailAuthenticationConfiguration}`;
        return this.http.put<void>(endpointUrl, configuration, this.getRequestHeaders());
    }

    public updateCompanyPolicy(param: PasswordPolices): Observable<PasswordPolices> {
        return this.http.put<PasswordPolices>(`${this.updatePasswordPoliciesUrl}/` + param.ReferenceId, JSON.stringify(param), this.getRequestHeaders());
    }

    public getDuoAuthenticationConfiguration(companyReferenceId: string): Observable<DuoAuthenticationConfiguration> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId + this._duoAuthenticationConfiguration}`;
        return this.http.get<DuoAuthenticationConfiguration>(endpointUrl, this.getRequestHeaders());
    }

    public postDuoAuthenticationConfiguration(companyReferenceId: string, configuration: DuoAuthenticationConfiguration): Observable<void> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId + this._duoAuthenticationConfiguration}`;
        return this.http.post<void>(endpointUrl, configuration, this.getRequestHeaders());
    }

    public putDuoAuthenticationConfiguration(companyReferenceId: string, configuration: DuoAuthenticationConfiguration): Observable<void> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId + this._duoAuthenticationConfiguration}`;
        return this.http.put<void>(endpointUrl, configuration, this.getRequestHeaders());
    }

    public getDuoUsernameFormats(): Observable<DuoUsernameFormat[]> {
        let endpointUrl = `${this.companyUrl}/${this._duoUsernameFormat}`;
        return this.http.get<DuoUsernameFormat[]>(endpointUrl, this.getRequestHeaders());
    }

    public deleteCompanies(companyReferenceIds: string[]): Observable<void> {
        return this.http.post<void>(this.DeleteCompaniesUrl, companyReferenceIds, this.getRequestHeaders());
    }

    public getCompanyApplications(companyReferenceId: string): Observable<ApplicationList[]> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId}` + this._applicationsByComanyIdUrl;
        return this.http.get<ApplicationList[]>(endpointUrl, this.getRequestHeaders());
    }

    public getCompanyFeatures(companyReferenceId: string): Observable<TenantFeature[]> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId}` + this._featuresByCompanyIdUrl;
        return this.http.get<TenantFeature[]>(endpointUrl, this.getRequestHeaders());
    }

    public assignCompanyFeatures(companyReferenceId: string, features: CompanyFeatureEnum[]): Observable<void> {
        let options = new HttpParams();
        if (features) {
            features.forEach(id => {
                options = options.append('features', id.toString());
            });
        }
        let endpointUrl = `${this.companyUrl}/${companyReferenceId}` + this._assignFeatureUrl;
        return this.http.post<void>(endpointUrl, features, { headers: this.getHeaders(), params: options, withCredentials: true });
    }

    public assignApplication(companyReferenceId: string, applicationReferenceIds: string[]): Observable<void> {
        let endpointUrl = `${this.companyUrl}/${companyReferenceId}` + this._assignApplicationUrl;
        return this.http.post<void>(endpointUrl, applicationReferenceIds, this.getRequestHeaders());
    }

    public deactivateCompanies(companyRererenceIds: string[]): Observable<void> {
        return this.http.post<void>(this.DeactivateCompaniesUrl, companyRererenceIds, this.getRequestHeaders());
    }

    public activateCompanies(companyRererenceIds: string[]): Observable<void> {
        return this.http.post<void>(this.ActivateCompaniesUrl, companyRererenceIds, this.getRequestHeaders());
    }

    public getTenantPasswordPolicy(token: string): Observable<PasswordPolices> {
        let endpointUrl = `${this.getPasswordPoliciesByIdentifierUrl}/${this._strPasswordPolicy}`;
        return this.http.get<PasswordPolices>(endpointUrl, this.getRequestHeadersByToken(token));
    }

    public getGeneratedPassword(): Observable<string> {
        let endpointUrl = `${this.companyUrl}/GeneratePassword`;
        return this.http.get<string>(endpointUrl, this.getRequestHeaders());
    }

    public postMspAssociationRequest(mspAssociationRequest: MspAssociationRequest): Observable<void> {
        let endpointUrl = `${this.companyUrl}/associate`;
        return this.http.post<void>(endpointUrl, mspAssociationRequest, this.getRequestHeaders());
    }

    public postMspAssignApplication(parentTenantReferenceId: string, applicationReferenceIds: string[]): Observable<void> {
        let endpointUrl = `${this.companyUrl}/${parentTenantReferenceId}/mspApplications`;
        return this.http.post<void>(endpointUrl, applicationReferenceIds, this.getRequestHeaders());
    }

    public getMspTenantAssociation(): Observable<MspParentTenantAssociation> {
        let endpointUrl = `${this.companyUrl}/msp`;
        return this.http.get<MspParentTenantAssociation>(endpointUrl, this.getRequestHeaders());
    }

    public getAssociatedMspTenants(isMspTenantDropDown?: boolean): Observable<AssociatedMspTenants[]> {
        let endpointUrl = `${this.companyUrl}` + this._associatedMspTenantsUrl;
        let params = new HttpParams();
        if (isMspTenantDropDown) {
            params = params.set('isMspTenantDropDown', isMspTenantDropDown);
        }
        return this.http.get<AssociatedMspTenants[]>(endpointUrl, {params, headers: this.getHeaders(), withCredentials: true});
    }

    public GetMspChildRequestEndpoint(params: MspTenantLinkingFilterParams): Observable<ListResponse<MspChildRequest>> {
        let endpointUrl = `${this.companyUrl}/MspChildRequest`;
        let options = new HttpParams()
            .set("SortColumn", params.SortColumn)
            .set("SortOrder", params.SortOrder)
            .set("PageNo", params.PageNo.toString())
            .set("PageSize", params.PageSize.toString());
        return this.http.get<ListResponse<MspChildRequest>>(endpointUrl, { headers: this.getHeaders(), params: options, withCredentials: true });
    }

    public getApplicationAssignedByMspChild(childTenantReferenceId: string, filter: ApplicationListFilterParams): Observable<ListResponse<ApplicationList>> {
        let endpointUrl = `${this.companyUrl}/${childTenantReferenceId}/mspApplications`;
        let options = new HttpParams()
            .set('SortColumn', filter.SortColumn)
            .set('SortOrder', filter.SortOrder)
            .set('KeyWord', filter.KeyWord)
            .set('PageNo', filter.PageNo.toString())
            .set('PageSize', filter.PageSize.toString());
        return this.http.get<ListResponse<ApplicationList>>(endpointUrl, { headers: this.getHeaders(), params: options, withCredentials: true });
    }

    public patchMspRequestAction(childTenantReferenceId: string, mspRequestReferenceId: string, mspChangeStatusRequest: MspChangeStatusRequest): Observable<void> {
        let endpointUrl = `${this.companyUrl}/${childTenantReferenceId}/msp/${mspRequestReferenceId}`;
        return this.http.patch<void>(endpointUrl, mspChangeStatusRequest, this.getRequestHeaders());
    }

    public patchMspRequestActionFromChild(parentTenantReferenceId: string, mspRequestReferenceId: string, mspChangeStatusRequest: MspChangeStatusRequest): Observable<void> {
        let endpointUrl = `${this.companyUrl}/${parentTenantReferenceId}/mspchild/${mspRequestReferenceId}`;
        return this.http.patch<void>(endpointUrl, mspChangeStatusRequest, this.getRequestHeaders());
    }
    public getMspChildRequestHistory(childReferenceId: string): Observable<MspRequestHistory[]> {
        let endpointUrl = `${this.companyUrl}/${childReferenceId}${this._mspRequestHistoryUrl}`;
        return this.http.get<MspRequestHistory[]>(endpointUrl, this.getRequestHeaders());
    }

    public getApplicationsEndpoint(childMspTenantReferenceId: string): Observable<GetApplicationsResponseModel<MspDisplayApplication>> {
        const endpointUrl = `${this.companyUrl}/${childMspTenantReferenceId}` + this._mspPortalApplicationsUrl;
        return this.http.get<GetApplicationsResponseModel<MspDisplayApplication>>(endpointUrl, this.getRequestHeaders());
    }

    public getLongLoadingApplications(applicationType: number ,applicationreferenceIds: string[], childMspTenantReferenceId: string): Observable<MspDisplayApplication[]> {
        const endpointUrl = `${this.companyUrl}/${childMspTenantReferenceId}` + this._mspPortalApplicationsUrl + `/${applicationType}`;
        return this.http.post<MspDisplayApplication[]>(endpointUrl, applicationreferenceIds, this.getRequestHeaders());
    }
    
    public postAssignDefaultMspApplications(mspTenantInfo: MspTenantInfo): Observable<void> {
        let endpointUrl = `${this.companyUrl}/assignDefaultMspApplications`;
        return this.http.post<void>(endpointUrl, mspTenantInfo, this.getRequestHeaders());
    }
    public postTenantAuthorityConfiguration(tenantAuthorityDetailsUI: TenantAuthorityDetailsUI): Observable<void> {
        const uploadData = new FormData();
        if (tenantAuthorityDetailsUI.RootCaCertificate !== undefined && tenantAuthorityDetailsUI.RootCaCertificate !== null) {
            uploadData.append('rootCaCertificate', tenantAuthorityDetailsUI.RootCaCertificate.file, tenantAuthorityDetailsUI.RootCaCertificate.file.name)
        }
        if (tenantAuthorityDetailsUI.IntermediateCertificate !== undefined && tenantAuthorityDetailsUI.IntermediateCertificate !== null) {
            tenantAuthorityDetailsUI.IntermediateCertificate.forEach((f) => uploadData.append('intermediateCertificate', f.file, f.file.name));
        }
        if (tenantAuthorityDetailsUI.CertCrlLocationFile !== undefined && tenantAuthorityDetailsUI.CertCrlLocationFile !== null) {
             uploadData.append('certCrlLocationFile', tenantAuthorityDetailsUI.CertCrlLocationFile, tenantAuthorityDetailsUI.CertCrlLocationFile.name);
        }
        uploadData.append("TenantAuthority", JSON.stringify(tenantAuthorityDetailsUI.TenantAuthority));
        uploadData.append("TenantAuthorityCertificateConfiguration", JSON.stringify(tenantAuthorityDetailsUI.TenantAuthority.TenantAuthorityCertificateConfiguration));
        let endpointUrl = `${this.companyUrl}/Authority`;
        return this.http.post<void>(endpointUrl, uploadData, this.getFormDataRequestHeaders());
    }

    public updateTenantAuthorityConfiguration(tenantAuthorityDetailsUI: TenantAuthorityDetailsUI,referenceId: string): Observable<void> {

        const uploadData = new FormData();
        if (tenantAuthorityDetailsUI.RootCaCertificate !== undefined && tenantAuthorityDetailsUI.RootCaCertificate !== null) {
            uploadData.append('rootCaCertificate', tenantAuthorityDetailsUI.RootCaCertificate.file, tenantAuthorityDetailsUI.RootCaCertificate.file.name)
        }
        if (tenantAuthorityDetailsUI.IntermediateCertificate !== undefined && tenantAuthorityDetailsUI.IntermediateCertificate !== null) {
            tenantAuthorityDetailsUI.IntermediateCertificate.forEach((f) => uploadData.append('intermediateCertificate', f.file, f.file.name));
        }
        if (tenantAuthorityDetailsUI.CertCrlLocationFile !== undefined && tenantAuthorityDetailsUI.CertCrlLocationFile !== null) {
             uploadData.append('certCrlLocationFile', tenantAuthorityDetailsUI.CertCrlLocationFile, tenantAuthorityDetailsUI.CertCrlLocationFile.name);
        }
        uploadData.append("TenantAuthority", JSON.stringify(tenantAuthorityDetailsUI.TenantAuthority));
        uploadData.append("TenantAuthorityCertificateConfiguration", JSON.stringify(tenantAuthorityDetailsUI.TenantAuthority.TenantAuthorityCertificateConfiguration));

        let endpointUrl = `${this.companyUrl}` + this._tenantAuthority+ '/' + referenceId;
        return this.http.put<void>(endpointUrl, uploadData, this.getFormDataRequestHeaders());
    }



    public getMspApplicationUsers(childTenantReferenceId: string, applicationReferenceId: string): Observable<ApplicationUser[]> {
        let endpointUrl = `${this.companyUrl}/${childTenantReferenceId}/MspApplication/${applicationReferenceId}/Users`;
        return this.http.get<ApplicationUser[]>(endpointUrl, this.getRequestHeaders());
    }

    public deleteMspApplicationUser(childTenantReferenceId: string, applicationReferenceId: string, userReferenceId: string): Observable<void> {
        let endpointUrl = `${this.companyUrl}/${childTenantReferenceId}/MspApplication/${applicationReferenceId}/Users/${userReferenceId}`;
        return this.http.delete<void>(endpointUrl, this.getRequestHeaders());
    }

    public patchDelink(parentTenantReferenceId: string): Observable<void> {
        let endpointUrl = `${this.companyUrl}/${parentTenantReferenceId}/MspDelink`;
        return this.http.patch<void>(endpointUrl, null, this.getRequestHeaders());
    }

    public assignApplicationToMspTenantUser(childTenantReferenceId: string, applicationReferenceId: string, userReferenceId: string, userApplication: UserApplication): Observable<void> {
        let endpointUrl = `${this.companyUrl}/${childTenantReferenceId}/MspApplication/${applicationReferenceId}/User/${userReferenceId}`;
        return this.http.post<void>(endpointUrl, JSON.stringify(userApplication), this.getRequestHeaders());
    }

    public getRolesListByApplicationIdEndPoint(childTenantReferenceId: string, applicationReferenceId: string): Observable<Roles[]> {
        let endpointUrl = `${this.companyUrl}/${childTenantReferenceId}/MspApplication/${applicationReferenceId}/Roles`;
        let options = new HttpParams();
        return this.http.get<Roles[]>(endpointUrl, { headers: this.getHeaders(), params: options, withCredentials: true });
    }

    public getTenantBackgroundImages(): Observable<TenantBackgroundImages[]> {
        let endpointUrl = `${this.companyUrl}`  + this._background;
        return this.http.get<TenantBackgroundImages[]>(endpointUrl, this.getRequestHeaders());
    }

    public getRestrictedUserTenantPreferences(): Observable<RestrictedUserTenantPreferences> {
        let endpointUrl = `${this.companyUrl}`  + this._restrictedUserTenantPreferences;
        return this.http.get<RestrictedUserTenantPreferences>(endpointUrl, this.getRequestHeaders());
    }

    public getTokenUserTenantPreferences(token: string): Observable<RestrictedUserTenantPreferences> {
        let endpointUrl = `${this.companyUrl}`  + this._tokenUserTenantPreferences + '/' + token;
        return this.http.get<RestrictedUserTenantPreferences>(endpointUrl, this.getRequestHeaders());
    }

    public getTenantLogos(): Observable<TenantLogo[]> {
        let endpointUrl = `${this.companyUrl}`  + this._tenantLogos;
        return this.http.get<TenantLogo[]>(endpointUrl, this.getRequestHeaders());
    }
    public postTenantPreferences(preference:TenantPreferenceseModel[]): Observable<void> {
        let endpointUrl = `${this.companyUrl}${this._tenantPreferences}`;
        return this.http.post<void>(endpointUrl, preference, this.getRequestHeaders());
    }
    public getTenantPreferences(): Observable<TenantPreferenceseModel[]> {
        let endpointUrl = `${this.companyUrl}` + this._tenantPreference;
        return this.http.get<TenantPreferenceseModel[]>(endpointUrl, this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }
    public postCustomBackgroundImage(file: File): Observable<void> {
        const uploadData = new FormData();
        if (file !== undefined && file !== null) {
            uploadData.append('backgroundImage', file, file.name);
        }
        let endpointUrl = `${this.companyUrl}`  + this._backgrounds;
        return this.http.post<void>(endpointUrl, uploadData, this.getFormDataRequestHeaders());
    }
    public postCustomLogoImage(tenantLogo: TenantLogo): Observable<void> {
        const uploadData = new FormData();        
        if (tenantLogo.LogoFile !== undefined && tenantLogo.LogoFile !== null) {
            uploadData.append('LogoFile', tenantLogo.LogoFile, tenantLogo.LogoFile.name);
        }        
        //uploadData.append('IsCustom', tenantLogo.IsCustom.toString(),);
        uploadData.append('Type', tenantLogo.Type.toString(),);
        //uploadData.append('LogoUrl', tenantLogo.LogoUrl.toString(),);
        //uploadData.append('ReferenceId', tenantLogo.ReferenceId.toString(),);
        //uploadData.append('Title', tenantLogo.Title.toString(),);
        let endpointUrl = `${this.companyUrl}`  + this._logo;
        return this.http.post<void>(endpointUrl, uploadData, this.getFormDataRequestHeaders());
    }
    

    public deleteCustomBackgroundImage(referenceId: string): Observable<void> {
        let endpointUrl = `${this.companyUrl}`  + this._backgrounds + '/' + referenceId;
        return this.http.delete<void>(endpointUrl, this.getRequestHeaders());
    }

    public deleteCustomLogoImage(referenceId: string): Observable<void> {
        let endpointUrl = `${this.companyUrl}`  + this._tenantLogo + '/' + referenceId;
        return this.http.delete<void>(endpointUrl, this.getRequestHeaders());
    }

    public getTenantAuthorities(params: AuthorityListFilterParams): Observable<TenantAuthority[]> {
        let options = new HttpParams()
            .set("SortColumn", params.SortColumn)
            .set("SortOrder", params.SortOrder)
        let endpointUrl = `${this.companyUrl}` + this._tenantAuthority;
        return this.http.get<TenantAuthority[]>(endpointUrl,  { headers : this.getHeaders(), params: options, withCredentials: true});
    }

    public deleteTenantAuthority(referenceId: string): Observable<void> {
        let endpointUrl = `${this.companyUrl}`  + this._tenantAuthority + '/' + referenceId;
        return this.http.delete<void>(endpointUrl, this.getRequestHeaders());
    }

    public getTenantAuthoritydata(referenceId: string): Observable<TenantAuthorityDetailsUI> {
        let endpointUrl = `${this.companyUrl}` + this._tenantAuthority+ '/' + referenceId;
        return this.http.get<TenantAuthorityDetailsUI>(endpointUrl, this.getRequestHeaders());
    }

    public verifyDuplicateAuthorityName(authorityName: string): Observable<boolean> {
        let endpointUrl = `${this.companyUrl}`  + this._verifyDuplicateAuthorityName + `?authorityName=${authorityName}`;
        return this.http.get<boolean>(endpointUrl, this.getRequestHeaders());
    }

    public getMspUserApplicationRoles(tenantReferenceId: string, applicationReferenceId: string, userReferenceId: string): Observable<Roles[]> {
        let endpointUrl = `${this.configurations.baseUrl}${this._companyUrl}/${tenantReferenceId}/mspApplication/${applicationReferenceId}/user/${userReferenceId}/roles`;
        let options = new HttpParams();
        return this.http.get<Roles[]>(endpointUrl, { headers: this.getHeaders(), params: options, withCredentials: true });
    }

    public getAssignedApplications(tenantReferenceId: string, userReferenceId: string): Observable<AssignedApplicationModel[]> {
        let endpointUrl = `${this.configurations.baseUrl}${this._companyUrl}/${tenantReferenceId}/user/${userReferenceId}/applicationsRoles`;
        return this.http.get<AssignedApplicationModel[]>(endpointUrl, this.getRequestHeaders());
    }

    public searchMspApplication(tenantReferenceId: string, searchText: string): Observable<ApplicationList[]> {
        let search = encodeURIComponent(searchText);
        let endpointUrl = `${this.configurations.baseUrl}${this._companyUrl}/${tenantReferenceId}/mspApplication/search?searchText=${search}`;
        return this.http.get<ApplicationList[]>(encodeURI(endpointUrl), this.getRequestHeaders());
    }

    public postAssignAllMspApplications(childTenantReferenceId: string, userReferenceId: string): Observable<void> {
        let endpointUrl = `${this.companyUrl}/${childTenantReferenceId}/user/${userReferenceId}/mspApplications`;
        return this.http.post<void>(endpointUrl, null, this.getRequestHeaders());
    }

    public postMspChildLinkingRequest(mspLinkingModel: MspLinkingModel): Observable<void> {
        let endpointUrl = `${this.companyUrl}/MspChild`;
        return this.http.post<void>(endpointUrl, mspLinkingModel, this.getRequestHeaders());
    }

}
