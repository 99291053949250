import { Injectable } from "@angular/core";
import { GSuiteEndpoint } from "./gsuite-endpoint.service";
import { oAuthBaseConnectionModel } from '../../models/oAuthBaseConnectionModel';
import { oAuthBaseLoginUrlParams } from '../../models/oAuthBaseLoginUrlParams';
import { oAuthBaseConnectionFilterParams } from '../../models/oAuthBaseConnectionFilterParams';
import { Observable } from "rxjs";

@Injectable()
export class GSuiteService {
    constructor(private gsuiteEndpoint: GSuiteEndpoint) {

    }
    public getGSuiteLoginUrlParams(name: string, selectedDomains: string[], eventType: number): Observable<oAuthBaseLoginUrlParams> {
        return this.gsuiteEndpoint.getGSuiteLoginUrlParams(name,selectedDomains, eventType);
    }
    public getGSuiteConnections(gsuiteConnectionFilterparams?: oAuthBaseConnectionFilterParams): Observable<oAuthBaseConnectionModel[]> {
        return this.gsuiteEndpoint.getGSuiteConnections(gsuiteConnectionFilterparams);
    }
    public getGSuiteConnectionDataById(connectionId: string): Observable<oAuthBaseConnectionModel> {
        return this.gsuiteEndpoint.getGSuiteConnectionDataById(connectionId);
    }
    public updateGSuiteConnection(param: oAuthBaseConnectionModel): Observable<oAuthBaseConnectionModel> {
        return this.gsuiteEndpoint.updateGSuiteConnection(param);
    }
}