/**
 * Created by jcammisuli on 2017-03-20.
 */

import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';

export class SelectionChange {
    constructor(public source: SelectOptionControl) {

    }
}

@Component({
    selector: 'soti-option',
    templateUrl: './select-option.ctrl.html',
    styleUrls: ['./select-option.ctrl.scss']
})
export class SelectOptionControl implements OnInit {

    @Input()
    public value: string;

    @Input()
    public icon: string = '';

    @Output()
    public onSelection: EventEmitter<SelectionChange> = new EventEmitter<SelectionChange>();
    
    @HostBinding('attr.tabIndex') public tabindex = '-1';
    @HostBinding('attr.role') public role: string = 'option';
    @HostBinding('class.selected')
    private _selected: boolean = false;
    private get _hostElement(): HTMLElement {
        return this.element.nativeElement;
    }
    constructor(public element: ElementRef) { }

    public ngOnInit(): void { }
    
    @HostListener('click')
    public optionSelected(): void {
        this.select();
        this._emitSelectionChangeEvent();        
    }

    public select(): void {
        this._selected = true;
    }

    public deselect(): void {
        this._selected = false;
    }

    public viewValue(): string {
        return this._hostElement.innerHTML;
    }

    public viewName(): string {
        return this._hostElement.innerText;
    }

    public setFocus(): void{
        this.element.nativeElement.focus();
    }

    private _emitSelectionChangeEvent(): void {
        this.onSelection.emit(new SelectionChange(this));
    }

    public scrollIntoView(): void {
        this.element.nativeElement.scrollIntoView({block: "nearest"});
    }

}
