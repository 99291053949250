import { Injectable } from '@angular/core';
import { ExternalIdpEndpoint } from './external-idp-endpoint.service';
import { Observable } from 'rxjs';
import { ExternalIdpConnection } from '../../models/external-idp-connection.model';
import { ExternalIdpconnectionMetaData } from '../../models/externalIdpconnectionMetaData';
import { ExternalIdpCertificate } from '../../models/externalIdpCertificate';
import { CertificateValidationModel } from 'src/app/models/certificateValidationModel';
import { BaseIdpConnection } from 'src/app/models/baseIdpConnection';

@Injectable()
export class ExternalIdpService {

    constructor(private externalIdpEndpoint: ExternalIdpEndpoint) {

    }

    public getExternalIdpList(type: number): Observable<BaseIdpConnection[]> {
        return this.externalIdpEndpoint.getExternalIdpConnections<BaseIdpConnection[]>(type);
    }

    public readAndValidateIdpMetaDataFile(param: ExternalIdpconnectionMetaData): Observable<ExternalIdpConnection> {
        return this.externalIdpEndpoint.validateExternalIdpMetaDataFile(param);
    }

    public createExternalIdpConnection(param: ExternalIdpConnection): Observable<ExternalIdpConnection> {

      return  this.externalIdpEndpoint.createExternalIdpConnection(param);
    }

    public validateIdpCertificate(param: ExternalIdpCertificate): Observable<CertificateValidationModel> {
        return this.externalIdpEndpoint.validateIdpCertificate(param);
    }
    public verifyIdpName(companyName: string): Observable<boolean> {
        return this.externalIdpEndpoint.verifyIdpName(companyName);
    }

    public verifyEntityId(idpName: string): Observable<boolean> {
        return this.externalIdpEndpoint.verifyEntityId(idpName);
    }

    public getMetadataDownloadUrl(applicationId: number = 0): string {
        return this.externalIdpEndpoint.getMetadataDownloadUrl(applicationId);
    }

    public getCertificateDownloadUrl(): string {
        return this.externalIdpEndpoint.getCertificateDownloadUrl();
    }

    public getPemCertificateDownloadUrl(): string {
        return this.externalIdpEndpoint.getpemCertificateDownloadUrl();
    }

    public getExternalIdpConnectionDataById(connectionrefId: string): Observable<ExternalIdpConnection> {
        return this.externalIdpEndpoint.getExternalIdpConnectionDataById(connectionrefId);
    }

    public updateExternalIdpConnection(param: ExternalIdpConnection): Observable<ExternalIdpConnection> {
        return  this.externalIdpEndpoint.updateExternalIdpConnection(param);
    }
}
