/**
 * Created by rgubarenko on 2016-02-18.
 */
import { IQueueClient, queueManager, QueueOperationPriority, } from '../../utils/toast-utils/QueueManager';
import { Maybe } from '../../utils/toast-utils/Maybe';
import { Component, OnDestroy } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { AlertSeverityEnum } from '../../enums/AlertSeverityEnum';

import { NotificationEvent, ToastNotificationService } from '../../services/toast-services/toast-notification.service';
import { NotificationConfig } from '../../utils/toast-utils/notification-aggregator';
import { NotificationType } from '../../utils/toast-utils/ClientNotificationMessage';
import { DisposalBag } from '../../utils/DisposalBag';
import { LiveAnnouncer } from '@angular/cdk/a11y';

interface IBarItem {
    id: string;
    count: number;
    typeName: string;
    severity: string;
    status: string;
    title: string;
    text: string;
    isAdding: boolean;
    isRemoving: boolean;
    trash: boolean;
    showEllipsis: boolean;
}

@Component({
    selector: 'status-notification-bar',
    templateUrl: './status-notification-bar.ctrl.html',
    styleUrls: ['./status-notification-bar.ctrl.scss']
})
//tslint:disable
export class StatusNotificationBar implements OnDestroy {
    //tslint:enable

    public notificationItems: Array<IBarItem> = [];

    private _textSizeLimitWithoutEllipsis: number = 110;
    private _notificationTitle: string;
    private _operationQueue: IQueueClient;
    private _disposalBag: DisposalBag = new DisposalBag();

    public onCloseButton(itemId: string): void {
        this._notificationService.removeItem(itemId);
    }

    public onAnimationEnd($event: AnimationEvent, identifier: string): void {
        let next = this._getItem(identifier).iterator.next();

        if (next.done) {
            return;
        }

        //  logger.debug(`Animation ended for item '${identifier}'`, this.notificationItems.values());

        let itemInfo = next.value;

        // reset flag since animation is ended
        itemInfo.isAdding = false;
        //this.cdr.detectChanges();

        // physically remove the item from list
        if (itemInfo.isRemoving) {
            itemInfo.isRemoving = false;
            itemInfo.trash = true;
            this._operationQueue.add(() => { this._deleteFromList(itemInfo.id); }, QueueOperationPriority.High);
        }
    }

    constructor(private _cdr: ChangeDetectorRef, private _notificationService: ToastNotificationService, private _liveAnnouncer: LiveAnnouncer) {
        this._operationQueue = queueManager.build();
        this._disposalBag.nextSub = _notificationService.itemAdded.subscribe((msg) => this._onItemAdded(msg));
        this._disposalBag.nextSub = _notificationService.itemRemoved.subscribe((msg) => this._onItemClosed(msg));
    }

    public ngOnDestroy(): void {
        this.notificationItems = [];
        this._disposalBag.dispose();
        this._liveAnnouncer.clear();
    }


    public onMouseOver(identifier: string): void {
        let next = this._getItem(identifier).iterator.next();

        if (next.done) {
            return;
        }

        let data = next.value;

        if (data.isRemoving) {
            return;
        }

        this._notificationService.pinItem(identifier);
    }

    public onMouseLeave(identifier: string): void {
        let next = this._getItem(identifier).iterator.next();

        if (next.done) {
            return;
        }

        let data = next.value;

        if (data.isRemoving) {
            return;
        }

        this._notificationService.unpinItem(identifier);
    }

    public onShowMoreButton(identifier: string): void {
        let next = this._getItem(identifier).iterator.next();

        if (next.done) {
            return;
        }

        next.value.showEllipsis = false;
    }

    public itemIdentity(index: number, item: IBarItem): string {
        return item.id;
    }

    public ellipisify(item: IBarItem): string {
        return item.showEllipsis ? `${item.text.substring(0, this._textSizeLimitWithoutEllipsis)}...` : item.text;
    }

    //region View template interface

    private _onItemAdded(args: NotificationEvent): void {
        this._operationQueue.add(() => { this._addOrUpdateItem(args); });
    }

    private _addNewItem(item: NotificationConfig): void {
        let barItem: IBarItem = {
            id: item.identifier,
            count: 1,
            typeName: NotificationType[item.type],
            severity: AlertSeverityEnum[item.severity],
            status: 'lbl_status_notification_' + NotificationType[item.type].toLocaleLowerCase(),
            title: item.title,
            text: item.text,
            isAdding: true,
            isRemoving: false,
            trash: false,
            showEllipsis: item.text.length > this._textSizeLimitWithoutEllipsis
        };

        if (this._isSmallScreen()) {
            this.notificationItems.push(barItem);
        } else {
            this.notificationItems.unshift(barItem);
        }

        // logger.debug('Notification item added', barItem);
    }

    private _addOrUpdateItem(item: NotificationEvent): void {
        let existing = this._getItem(item.notificationItem.identifier).iterator.next();
        let message = NotificationType[item.notificationItem.type].toString() + ', ' + item.notificationItem.text.toString()
        this._liveAnnouncer.announce(message, 'polite');
        if (existing.done) {
            return this._addNewItem(item.notificationItem);
        }

        existing.value.count = item.count;
    }

    private _onItemClosed(identifier: string): void {
        let next = this._getItem(identifier).iterator.next();
        if (next.done) {
            return;
        }
        const item = next.value;
        item.isRemoving = true;

        // logger.debug(`Closting item '${identifier}'`);
    }

    private _getItem(id: string): Maybe<IBarItem> {
        return Maybe.instance<IBarItem>(this.notificationItems.find((it) => it.id === id));
    }

    private _isSmallScreen(): boolean {
        var mq = window.matchMedia('(min-width: 0px) and (max-width: 767px)');
        return mq.matches;
    }

    private _deleteFromList(id: string): void {
        let idx = this.notificationItems.findIndex((item) => item.id === id);

        if (idx > -1) {
            this.notificationItems.splice(idx, 1);
        }
    }

    //endregion
}