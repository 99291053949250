// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-wrapper {
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.loader {
  display: block;
}

@keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  30% {
    transform: translateY(-0.25em);
  }
  50% {
    transform: translateY(0%);
  }
  70% {
    transform: translateY(0.25em);
  }
}
.loader {
  display: block;
}

.loader .inner1,
.loader .inner2,
.loader .inner3 {
  display: inline-block;
  margin: 2px;
  width: 6px;
  height: 6px;
  border: 1px solid rgb(51, 82, 110);
  border-radius: 50%;
  background-color: rgb(51, 82, 110);
  transform-origin: 50%;
  animation-duration: 0.75s;
  animation-name: bounce;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loader .inner2 {
  animation-delay: 0.1875s;
}

.loader .inner3 {
  animation-delay: 0.375s;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/controls/page-loading/page-loading.ctrl.scss"],"names":[],"mappings":"AAAA;EACE,OAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE;IACI,yBAAA;EACJ;EACA;IACI,8BAAA;EACJ;EACA;IACI,yBAAA;EACJ;EACA;IACI,6BAAA;EACJ;AACF;AAEA;EACE,cAAA;AAAF;;AAGA;;;EAGE,qBAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;EACA,kCAAA;EACA,kBAAA;EACA,kCAAA;EACA,qBAAA;EACA,yBAAA;EACA,sBAAA;EACA,mCAAA;EACA,iCAAA;AAAF;;AAGA;EACE,wBAAA;AAAF;;AAGA;EACE,uBAAA;AAAF","sourcesContent":[".loader-wrapper {\r\n  left: 0;\r\n  position: fixed;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 100vh;\r\n  width: 100vw;\r\n}\r\n\r\n.loader {\r\n  display: block;\r\n}\r\n\r\n@keyframes bounce {\r\n  0% {\r\n      transform: translateY(0%);\r\n  }\r\n  30% {\r\n      transform: translateY(-0.25em);\r\n  }\r\n  50% {\r\n      transform: translateY(0%);\r\n  }\r\n  70% {\r\n      transform: translateY(0.25em);\r\n  }\r\n}\r\n\r\n.loader {\r\n  display: block;\r\n}\r\n\r\n.loader .inner1,\r\n.loader .inner2,\r\n.loader .inner3 {\r\n  display: inline-block;\r\n  margin: 2px;\r\n  width: 6px;\r\n  height: 6px;\r\n  border: 1px solid rgb(51, 82, 110);\r\n  border-radius: 50%;\r\n  background-color: rgb(51, 82, 110);\r\n  transform-origin: 50%;\r\n  animation-duration: 0.75s;\r\n  animation-name: bounce;\r\n  animation-iteration-count: infinite;\r\n  animation-timing-function: linear;\r\n}\r\n\r\n.loader .inner2 {\r\n  animation-delay: 0.1875s;\r\n}\r\n\r\n.loader .inner3 {\r\n  animation-delay: 0.375s;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
