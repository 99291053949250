import {Component, Input, HostListener, OnDestroy} from '@angular/core';
import {TooltipService} from './tooltip.service';
import { BrowserUtilsService } from '../../../services/browserUtil/browserUtils.service';
import  {TooltipModel} from './tooltip-model';

@Component({
    selector: 'mc-tooltip',
    templateUrl: './tooltip.ctrl.html',
    styleUrls: ['./tooltip.ctrl.scss']
})

export class Tooltip implements OnDestroy, TooltipModel {
    // Direction in which tooltip should appear - e, w, n, s or any combination
    @Input()
    public positionClass: 'n' | 'e' | 's' | 'w' | 'ne' | 'nw' | 'se' | 'sw' = 's';

    // Icon on which tooltip should appear while hovering
    @Input()
    public iconClass: string;

    @Input()
    public subTitle: string;

    @Input()
    public title: string;

    @Input()
    public innerContent: string | string[];

    @Input()
    /**
     * Used to give the tooltip additional spacing between itself and the icon
     */
    public offsetPadding: number = 0;

    @Input()
    public showLoading:boolean = false;

    @Input()
    public isDisabled:boolean = false;

    @Input()
    public largeTooltip:boolean = false;

    @Input()
    public showFullText:boolean = false;
    @Input()
    public tooltipellipsis:boolean = false;

    public constructor(private _tooltipService: TooltipService, private _browserUtilService: BrowserUtilsService) {
    }

    public ngOnDestroy(): void {
        this._tooltipService.hide();
    }

    public showTooltip(target:HTMLElement): void {
        if (!this._browserUtilService.isMobileOrTablet()) {
            this._tooltipService.show(this, target);
        } else if (this.largeTooltip) {
            return;
        }
    }

    public hideTooltip(target:HTMLElement): void {
        this._tooltipService.hide();
    }

    // Launch tooltip exclusively on click for column list items as LI have their own click event handler
    @HostListener('touchstart', ['$event'])
    private _launchTooltip(event: MouseEvent | TouchEvent): boolean {
        if (this._browserUtilService.isMobileOrTablet()) {
            this._tooltipService.show(this, <HTMLElement>event.target);
            // For icons who dont have any other operation defined on them as in rename, copy or delete
            if (this.iconClass == 'ngui-icon-info') {
                return false;
            }
        }
    }
}