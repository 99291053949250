import {
    Directive, ViewContainerRef, TemplateRef, Input, ComponentFactoryResolver, ComponentRef
} from '@angular/core';
import { LoadingControl } from './loading-control/loading.ctrl';

@Directive({
    selector: '[show-loading]'
})
export class LoadingDirective {

    @Input('show-loadingText')
    public loadingText: string = '';

    private _prevCondition: boolean = null;
    private _loadingControl: ComponentRef<LoadingControl>;

    /**
     * When the condition is set to false, it will show the loading indicator.
     * This allows us to pass in null async options so that loading appears.
     * @param newCondition
     */
    @Input('show-loading')
    public set loading(newCondition: any) {
        if (newCondition && (this._isBlank(this._prevCondition) || !this._prevCondition)) {
            this._prevCondition = true;
            if (this._loadingControl && this._loadingControl.instance) {
                this._loadingControl.destroy();
            }
            this._viewContainer.createEmbeddedView(this._templateRef);
        } else if (!newCondition && (this._isBlank(this._prevCondition) || this._prevCondition)) {
            this._prevCondition = false;
            this._viewContainer.clear();
            let factory = this._resolver.resolveComponentFactory(LoadingControl);
            this._loadingControl = this._viewContainer.createComponent(factory, 0, this._viewContainer.injector);
            this._loadingControl.instance.loadingText = this.loadingText;
        }
    }

    constructor(private _viewContainer: ViewContainerRef,
                private _templateRef: TemplateRef<Object>,
                private _resolver: ComponentFactoryResolver) {
    }

    private _isBlank(obj) {
        return obj === undefined || obj === null;
    }

}