import { Injectable } from "@angular/core";
import { EndpointFactory } from "../endpoint-factory.service";
import { Observable } from "rxjs";
import { UmbracoPromotionalContent } from "src/app/models/umbracoPromotionalContent";

@Injectable()
export class UmbracoEndpoint extends EndpointFactory {
    get baseUrl() { return this.configurations.baseUrl; }

    private readonly _companyUrl: string = 'Umbraco';

    private readonly _promotionalContent: string = '/PromotionalContent';

    public get getPromotionalContentUrl(): string { return this.configurations.baseUrl + this._companyUrl + this._promotionalContent; }


    public getPromotionalContent(cotentType : string): Observable<UmbracoPromotionalContent> {
        let endpointUrl = `${this.getPromotionalContentUrl}/${cotentType}`;
        return this.http.get<UmbracoPromotionalContent>(endpointUrl, this.getRequestHeaders());
    }
}