import { Component, Input, OnInit} from '@angular/core';
import { HelpURLKeys } from '../enums/HelpURLKeys';
import { AuthService } from 'src/app/services/auth.service';
import { Utilities } from 'src/app/services/utilities';

@Component({
    selector: 'nocontent',
    templateUrl: './no-content.html',
    styleUrls: ['./no-content.scss'],
  })
  export class NoContent implements OnInit {
    
   
    @Input()
    public msgText : string;
  
    @Input()
    public subMsgText : string;

    @Input()
    public helpMsgText : string = "";

    @Input()
    public helpMsgLink : string;

    @Input()
    public icon : string;

    @Input()
    public imageName : string = 'page-not-found-mobile.png';

    @Input()
    public isImage : boolean = false;

    public defaultImage:any;

    public getImagePath : string = 'page-not-found-mobile.png';


    public showhelptext : boolean = false;
  
    ngOnInit()
    {
      this.getImagePath = Utilities.getAssestPath();
      this.defaultImage = this.getImagePath + this.imageName;
      if(this.helpMsgText.length)
      {
        this.showhelptext = true;
      }
    }
    
  }