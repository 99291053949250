import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AccountEndpoint } from 'src/app/services/account-services/account-endpoint.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LocalStoreManager } from 'src/app/services/local-store-manager.service';
import { DBkeys } from 'src/app/services/db-keys';
import { IntergratedApplicationTypes } from 'src/app/models/IntergratedApplicationTypes';
import { Application } from '../../../models/application.model';
import { SupportedProtocolTypeEnum } from 'src/app/shared/enums/SupportedProtocolTypeEnum';

@Component({
    selector: 'application-redirect',
    templateUrl: './app-redirect.ctrl.html'
})
export class ApplicationRedirect implements OnInit {

    public item: Application;
    public postForm: boolean = false;
    public submitUrl: string;
    public token: string;
    public applicationReferenceId: string;
    public clientTenantReferenceId: string;
    constructor(
        private authService: AuthService,
        private accountEndpointService: AccountEndpoint,
        private route: ActivatedRoute,
        private configurations: ConfigurationService,
        private localStorage: LocalStoreManager
    ) {

    }
    public ngOnInit(): void {
        this.authService.isHeaderRequired.next(false);
        this.route.queryParams.subscribe((params: Params) => {
            let applicationReferenceId: string = params['applicationReferenceId'];
            let clientTenantReferenceId: string = params['clientTenantReferenceId'] ? params['clientTenantReferenceId'] : '';
            if (applicationReferenceId) {
                while (this.localStorage.getData(DBkeys.READLOCK + "APPLICATION_REDIRECT" + applicationReferenceId));
                this.localStorage.saveSessionData(1, DBkeys.READLOCK + "APPLICATION_REDIRECT" + applicationReferenceId);
                this.item = this.localStorage.getDataObject<Application>(DBkeys.APPLICATION_REDIRECT_INFO + applicationReferenceId);
                if (this.item.InstanceCount == 1){
                    this.localStorage.deleteData(DBkeys.APPLICATION_REDIRECT_INFO + applicationReferenceId);
                } else {
                    this.item.InstanceCount -= 1;
                    this.localStorage.saveSessionData(this.item, DBkeys.APPLICATION_REDIRECT_INFO + applicationReferenceId)
                }
                this.localStorage.deleteData(DBkeys.READLOCK + "APPLICATION_REDIRECT" + applicationReferenceId);
                
                this.configurations.apiUrlObserver.subscribe((result) => {
                    if (result) {
                        this.validateAndRedirect(applicationReferenceId, clientTenantReferenceId);
                    }
                });
            } else {
                this.moveToHomePage();
            }
        });
    }

    public validateAndRedirect(applicationReferenceId: string, clientTenantReferenceId: string): void {
        if (this.item) {
            if (this.item.ApplicationType == IntergratedApplicationTypes.SotiAssist ||
                (this.item.ApplicationType == IntergratedApplicationTypes.ThirdParty &&
                this.item.SupportedProtocol == SupportedProtocolTypeEnum.OIDC)) {
                window.location.href = this.item.RedirectionUrl;
            }
            else if (this.item.ApplicationType == IntergratedApplicationTypes.SotiXsight) {
                window.location.href = this.item.XsightDefaultPreferenceRedirectionUrl == null || this.item.XsightDefaultPreferenceRedirectionUrl == ''? this.item.RedirectionUrl: this.item.XsightDefaultPreferenceRedirectionUrl;
            } else {
                this.accountEndpointService.ValidateUserSession().subscribe(x => {
                    if (x === true) {
                        this.submitUrl = this.localStorage.getData(DBkeys.IDP_URL);
                        this.token = this.localStorage.getData(DBkeys.IsUserLoggedIn);
                        this.applicationReferenceId = applicationReferenceId;
                        this.clientTenantReferenceId = clientTenantReferenceId;
                        this.postForm = true;
                    }
                });
            }
        } else {
            this.moveToHomePage();
        }
    }
    public moveToHomePage(): void {
        window.location.href = window.location.origin;
    }
}
