import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { EndpointFactory } from '../endpoint-factory.service';

@Injectable()
export class FileEndpoint extends EndpointFactory{
    get baseUrl() { return this.configurations.baseUrl; }

    private readonly _fileUrl: string = 'File';

    public getResource(url: string): Observable<any> {
        return this.http.get<any>(url, this.getRequestHeaders());
    }
}
