export enum ViewLocationEnum {
    Users = 'users',
    Dashboard = 'dashboard',
    Application = 'applications',
    Connection = 'connection',
    Company = 'Company',
    Account = 'account',
    Domain = 'Domains',
    Verifydomain = 'verify_domain',
    Policy='policy',
    MspSupport = 'msp_tenant',
    None = ''
}