import { AfterViewChecked, Component, ContentChildren, EventEmitter, HostBinding, Input, Output, QueryList } from '@angular/core';
import { Tab } from '../tab/tab.component';

@Component({
    selector: 'vertical-tab-switcher',
    templateUrl: './vertical-tab-switcher.component.html',
    styleUrls: ['./vertical-tab-switcher.component.scss']
})

export class VerticalTabSwitcher implements  AfterViewChecked {
    @HostBinding('attr.role') role:string = "tablist";
    
    @Input('show-tabs') public showTabs: boolean;

    
    @Input('tab-heading') public tabHeading: string;

    @Input()
    public tabTabindex: number = 0;

    public noOfTabs:number = 0;

    @Output()
    public tabChange: EventEmitter<TabChange> = new EventEmitter<TabChange>();

    @Output()
    public formAction: EventEmitter<TabActionEnum> = new EventEmitter<TabActionEnum>();

    @ContentChildren(Tab) public tabs: QueryList<Tab>;
    public current: number = 0;
    
    ngAfterViewChecked(): void {
        let getAllTabs = this.tabs.toArray();
        if(getAllTabs.length != this.noOfTabs)
        {
            this.noOfTabs = getAllTabs.length;
            getAllTabs = this.tabs.toArray();
            this._resetTabs(getAllTabs, false, true);
            getAllTabs[0].hidden = false;
        }
    }

    public nextTab(): void {
        var getAllTabs = this.tabs.toArray();
        this._resetTabs(getAllTabs, true, true);
        //if we're at the last tab maintain the current tab
        if (getAllTabs.length - 1 !== this.current) {
            this.current = this.current + 1;
        }
        getAllTabs[this.current].hidden = false;
    }

    public previousTab(): void {
        var getAllTabs = this.tabs.toArray();
        this._resetTabs(getAllTabs, true, true);

        //if we're at the first tab previous maintain the current tab
        if (this.current !== 0) {
            this.current = this.current - 1;
        }
        getAllTabs[this.current].hidden = false;
    }

    public openTargetTab(target: string): void {
        var getAllTabs = this.tabs.toArray();
        var targetTab = this.tabs.filter((tab) => tab.name === target);
        var pos = getAllTabs.indexOf(targetTab[0]);
        // deactivate all tabs
        this._resetTabs(getAllTabs, true, false);

        getAllTabs[this.current].hidden = true;
        this.current = pos;
        getAllTabs[this.current].hidden = false;
        this._tabChanged(target);
    }

    public cancelDialog($event: MouseEvent): void {
        this._actionChanged(TabActionEnum.Cancel);
    }

    public saveDialog($event: MouseEvent): void {
        this._actionChanged(TabActionEnum.Save);
    }

    private _resetTabs(tabs: Tab[], deactivate: boolean, hide: boolean): void {
        tabs.forEach(tab => {
            // hide all tabs
            if (hide) {
                tab.hidden = true;
            }
        });
    }

    private _tabChanged(tab: string): void {
        this.tabChange.emit({ tab });
    }

    private _actionChanged(action: TabActionEnum): void {
        this.formAction.emit(action);
    }

}

interface TabChange {
    tab: string;
}

export enum TabActionEnum {
    Save,
    Cancel
}