/**
 * Created by jcammisuli on 2017-04-19.
 */
import { NgModule } from '@angular/core';
import { PopUpControl } from './pop-up.ctrl';
import { PopUpFooterControl } from './pop-up-footer/pop-up-footer.ctrl';
import { PopUpTitleControl } from './pop-up-title/pop-up-title.ctrl';
import { CoreCommonModule } from '../../../core-common.module';
import { PopUpTrigger } from './pop-up-trigger.directive';
import { PopUp2 } from './pop-up2.ctrl';
import { PopUpContent } from './pop-up-content.directive';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
    imports: [
        CoreCommonModule,
        OverlayModule,
        PortalModule,
        A11yModule
    ],
    exports: [
        PopUpControl,
        PopUpFooterControl,
        PopUpTitleControl,
        PopUpTrigger,
        PopUpContent,
        PopUp2
    ],
    declarations: [
        PopUpControl,
        PopUpFooterControl,
        PopUpTitleControl,
        PopUpTrigger,
        PopUpContent,
        PopUp2
    ],
    providers: [],
})
export class PopUpModule {
}
