
import { Component, OnInit } from '@angular/core';
import { ModalDialogRef, ModalDialogService2 } from 'src/app/shared/controls/dialogs';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { ContactUsMessage } from './../../../../models/ContactUsMessage';
import { AccountService } from 'src/app/services/account-services/account.service';
import { appMessenger } from 'src/app/shared/controls/toaster-messages/AppMessenger';
import { ClientNotificationMessage, NotificationType } from 'src/app/shared/utils/toast-utils/ClientNotificationMessage';
import { AlertSeverityEnum } from 'src/app/shared/enums/AlertSeverityEnum';

@Component({
    selector: 'contactUs',
    styleUrls: ['./contactus.ctrl.scss'],
    templateUrl: './contactus.ctrl.html'
})
export class ContactUs  {

    public submitted: boolean;
    public contactUs: ContactUsMessage = new ContactUsMessage();
    public addButtonLoader: boolean = false;


    constructor(
        private _modalRef: ModalDialogRef<ContactUs>,
        private translationService: AppTranslationService,
        private accountService: AccountService,
        private _modalDialogService: ModalDialogService2,
    ) { }


    public sendContactusMessage(): void {
        
        if (this.contactUs.Message.trim().length > 0) {
            this.addButtonLoader = true;

            this.accountService.sendContactUsMessage(this.contactUs).subscribe(data => {
    
                appMessenger.postMessage(new ClientNotificationMessage(
                    this.translationService.getTranslation('msg_contactUsMessageSendsuccessfully'),
                    this.translationService.getTranslation('msg_contactUsMessageSendsuccessfully'),
                    NotificationType.Success,
                    AlertSeverityEnum.Minor));
        
            }, error => {
    
                appMessenger.postMessage(new ClientNotificationMessage(
                    this.translationService.getTranslation('msg_contactUsMessageSendfailed'),
                    this.translationService.getTranslation('msg_contactUsMessageSendfailed'),
                    NotificationType.Error,
                    AlertSeverityEnum.Minor));
    
                });

                this._modalDialogService.closeModal();
                this.addButtonLoader = false;

        }
    }

    public onCancelClick(): void {
        this._modalDialogService.closeModal();
    }
}
