import { remove, sum, flatMap, uniqBy, includes } from 'lodash';

type NullableKey<T> = T | null;

/**
 * An ITrie defines the API surface for a prefix-tree
 */
export interface ITrie<K, V, C> {
    /**
     * Insert the given item at the location specified by 'key'
     * 
     * @param key The key for the new item
     * @param item The item to insert
     * 
     */
    add(key: K, item: V): C;

    /**
     * Retrieve the value at the given key.
     * 
     * @param key The key for the value to retrieve
     * @param includeChildren Whether to also return all of the target node's children
     * 
     * @returns A collection of the item present at the given key, as well as all of its children if 'includeChildren' was set
     * 
     */
    find(key: K, includeChildren: boolean): V[];

    /**
     * Retrieve all children of the given node. This effectively has a depth of 0 as
     * only the immediate children will be returned and not their children
     * 
     * @param key The key for the target node. Passing 'null' means 
     * 
     * @returns A collection of the immediate children of the specified node
     */
    getImmediateChildren(key: K): V[];

    /**
     * Apply a function to a node and its children
     * 
     * @param key The key at which to start applying the given function
     * @param mod The modifier function to run on the node
     * @param includeChildren Whether to also apply this function down to all children of the target node. Default is 'true'
     */
    apply(key: K, mod: (item: V) => void, includeChildren: boolean): void;

    /**
     * Remove the given node, including all children of that node, from the Trie.
     * 
     * @param key The key at which to remove the node
     */
    remove(key: K): void;

    /**
     * Retrive all key chains in the Trie
     */
    getKeyChains(): K[];

    /**
     * Retrieve all values contained in this Trie
     */
    allSubValues(): V[];

    getChildCount(key: NullableKey<K>, deep: boolean): number;
}

/**
 * An ITrieNode defines the base API surface for the standard container
 * used in an ITrie
 */
export interface ITrieNode<K, V, C> extends ITrie<K, V, C> {
    identifier(): K;
    item(): V;
}

/**
 * A Trie is a basic implemetation of a prefix tree. It maintains values
 * that are indexed by a set of key chains where chains below a given node
 * are 'prefixed' by those above.
 * 
 * Example:
 * 
 * ```typescript
 * // create contained type 
 * class ContainedObject {
 *     public name: string;
 *     public value: number;
 * 
 *     constructor(n: string, v: number) {
 *         this.name = n;
 *         this.value = v;
 *     }
 * }
 * 
 * // create a new Trie using . as a prefix delimiter
 * const t: Trie<ContainedObject> = new Trie();
 * 
 * // Add a new item
 * t.add('first', new ContainedObject('f', 1));
 * 
 * // t now has a single node named 'first'
 * 
 * // Add another item, as a child of the previous
 * t.add('first.second', new ContainedObject('s', 2));
 * 
 * // t now has first and first.second
 * 
 * // Get 'first' and all of its children, which will be
 * // [{name: 'f', value: 1}, {name: 's', value: 2}]
 * let values = t.find('first');
 * 
 * // Get 'first.second' and any subvalues. Since
 * // we didn't give it any children, it is only [{name: 's', value: 2}]
 * let values2 = t.find('first.second');
 * 
 * ```
 */
export class Trie<T> implements ITrie<string, T, void> {    
    protected _children: Array<ITrieNode<string, T, void>> = [];

    /**
     * Create a new Trie instance using the specified delimiter. The default 
     * delimiter is '.'
     */
    constructor(protected _delimiter: string = '.') { }

    /**
     * Insert the given item at the location specified by 'key'
     * 
     * @param key The key for the new item
     * @param item The item to insert
     * 
     */
    public add(key: string, item: T): void {
        const { existing, prefix } = this._getExistingWithPrefixAndNext(key);

        if (existing != null) {
            return existing.add(key, item);
        }

        let child = new TrieNode<T>(prefix, this._delimiter);
        this._children.push(child);
        return child.add(key, item);
    }

    /**
     * Retrieve the value at the given key.
     * 
     * @param key The key for the value to retrieve
     * @param includeChildren Whether to also return all of the target node's children
     * 
     * @returns A collection of the item present at the given key, as well as all of its children if 'includeChildren' was set
     * 
     */
    public find(key: string, includeChildren: boolean = true): T[] {
        const { existing } = this._getExistingWithPrefixAndNext(key);

        if (existing == null) {
            return [];
        }

        return existing.find(key, includeChildren);
    }

    /**
     * Retrieve all children of the given node. This effectively has a depth of 0 as
     * only the immediate children will be returned and not their children
     * 
     * @param key The key for the target node. Passing 'null' means to return all root-level node values
     * 
     * @returns A collection of the immediate children of the specified node
     */
    public getImmediateChildren(key: string): T[] {
        if (key == null) {
            return this._children.map(ch => ch.item());
        }

        const { existing } = this._getExistingWithPrefixAndNext(key);

        if (existing == null) {
            return [];
        }

        return existing.getImmediateChildren(key);
    }

    /**
     * Apply a function to a node and its children
     * 
     * @param key The key at which to start applying the given function
     * @param mod The modifier function to run on the node
     * @param includeChildren Whether to also apply this function down to all children of the target node. Default is 'true'
     */
    public apply(key: string, mod: (item: T) => void, includeChildren: boolean = true): void {
        const { existing } = this._getExistingWithPrefixAndNext(key);

        if (existing != null) {
            existing.apply(key, mod, includeChildren);
        }
    }

    /**
     * Remove the given node, including all children of that node, from the Trie.
     * 
     * @param key The key at which to remove the node
     */
    public remove(key: string): void {
        const { existing } = this._getExistingWithPrefixAndNext(key);

        if (existing == null) {
            return;
        }

        if (!key.includes(`${this._delimiter}`)) {
            remove(this._children, ch => ch.identifier() === key);
        } else {
            existing.remove(key);
        }
    }

    /**
     * Retrive all key chains in the Trie
     */
    public getKeyChains(): string[] {
        return uniqBy(flatMap(this._children, (ch) => ch.getKeyChains())
            .concat(this._children.map(ch => `${ch.identifier()}`)).filter(el => !!el), el => el);
    }

    /**
     * Retrieve all values contained in this Trie
     */
    public allSubValues(): T[] {
        return [].concat(...this._children.map(ch => ch.allSubValues())).filter(el => !!el);
    }

    /**
     * Get a total count of the children or all desendants of the given key. 
     * @param key Key of the parent node. Use `null` to get all root nodes
     * @param deep count all descendants (includes children, grandchildren, great-granchildren,
     */
    public getChildCount(key: NullableKey<string>, deep: boolean): number {
        if (key === null) {
            if (!deep) {
                return this._children.length;
            }
            return this._children.length + sum(this._children.map(ch => ch.getChildCount(null, deep)));
        }

        const { existing } = this._getExistingWithPrefixAndNext(key);
        return existing.getChildCount(key, deep);
    }

    protected _getExistingWithPrefixAndNext(key: string): { existing: ITrieNode<string, T, void>, prefix: string, next: string } {
        let prefix = getPrefix(key, this._delimiter);
        let next = removePrefix(key, this._delimiter);

        let existing = this._children.find(tn => tn.identifier() === prefix);

        return { existing, prefix, next };
    }
}

export class TrieNode<T> implements ITrieNode<string, T, void> {    
    protected _item: T;
    protected _children: Array<TrieNode<T>> = [];

    /**
     * Create a new TrieNode
     */
    constructor(protected _identifier: string, protected _delimiter: string = '.') { }

    /**
     * Retrieve the identifier of this node
     */
    public identifier(): string {
        return this._identifier;
    }

    /**
     * Return the stored value for this node, if one exists
     */
    public item(): T {
        return this._item;
    }

    /**
     * Insert the given item at the location specified by 'key'. If
     * key is this node's identifier, the value will be stored in this node,
     * otherwise it will continue to be passed down
     * 
     * @param key The key for the new item
     * @param item The item to insert
     * 
     */
    public add(key: string, item: T): void {
        if (key === this._identifier) {
            if (this._item == null) {
                this._item = item;
            }
            return;
        } else {
            const suffix = removePrefix(key, this._delimiter);
            const { existing, prefix } = this._getExistingWithPrefixAndNext(suffix);

            if (existing != null) {
                return existing.add(suffix, item);
            } else {
                let child = new TrieNode<T>(prefix, this._delimiter);
                this._children.push(child);
                return child.add(suffix, item);
            }
        }
    }

    /**
     * Retrieve the value at the given key. If key is equal to this node's identifier, 
     * this node's value will be returned. If including children, then all values for
     * all sub-nodes will also be returned
     * 
     * @param key The key for the value to retrieve
     * @param includeChildren Whether to also return all of the target node's children
     * 
     * @returns A collection of the item present at the given key, as well as all of its children if 'includeChildren' was set
     */
    public find(key: string, includeChildren: boolean = false): T[] {
        if (key === this._identifier) {
            return includeChildren ? this.allSubValues() : [this._item];
        }

        const suffix = removePrefix(key, this._delimiter);
        const { existing } = this._getExistingWithPrefixAndNext(suffix);

        if (existing == null) {
            return [];
        }

        return existing.find(suffix, includeChildren);
    }

    /**
     * Retrieve all children of the given node. This effectively has a depth of 0 as
     * only the immediate children will be returned and not their children
     * 
     * @param key The key for the target node 
     * 
     * @returns A collection of the immediate children of the specified node
     */
    public getImmediateChildren(key: string): T[] {
        if (key === this._identifier) {
            return this._children.map(ch => ch.item());
        }

        const suffix = removePrefix(key, this._delimiter);
        const { existing } = this._getExistingWithPrefixAndNext(suffix);

        if (existing == null) {
            return [];
        }

        return existing.getImmediateChildren(suffix);
    }

    /**
     * Apply a function to a node and its children. If key equals this node's identifier, then
     * application of the function will begin here.
     * 
     * @param key The key at which to start applying the given function
     * @param mod The modifier function to run on the node(s)
     * @param includeChildren Whether to also apply this function down to all children of the target node. Default is 'true'
     */
    public apply(key: string, mod: (item: T) => void, includeChildren: boolean = true): void {
        if (key === this._identifier) {
            if (includeChildren) {
                this.allSubValues().forEach(sv => mod(sv));
            } else {
                mod(this._item);
            }
            return;
        }
        const suffix = removePrefix(key, this._delimiter);

        const { existing } = this._getExistingWithPrefixAndNext(suffix);

        if (existing == null) {
            return;
        }

        existing.apply(suffix, mod, includeChildren);
    }

    /**
     * Remove the given node, including all children of that node, from the Trie.
     * If this node is the parent of the target, removal will begin here. Sending
     * a remove request to the node to be removed will fail as a node cannot remove
     * itself from the Trie.
     * 
     * @param key The key at which to remove the node
     */
    public remove(key: string): void {
        if (key == this._identifier) {
            throw 'Cannot remove self';
        }

        // Remove the prefix, which indicates this node
        const suffix = removePrefix(key, this._delimiter);

        if (!suffix.includes(this._delimiter)) {
            // Drop the entire child
            remove(this._children, (ch) => ch.identifier() === suffix);
        } else {
            const { existing } = this._getExistingWithPrefixAndNext(suffix);

            if (existing == null) {
                return;
            }
            
            existing.remove(suffix);
        }
    }

    /**
     * Retrive all key chains, starting with this node and all of its children
     */
    public getKeyChains(): string[] {
        if (this._children == null || this._children.length == 0) {
            return [this._identifier];
        }
        return uniqBy(flatMap(this._children, (ch) => ch.getKeyChains().map(c => `${this._identifier}${this._delimiter}${c}`))
            .concat(...this._children.map(ch => `${this._identifier}${this._delimiter}${ch.identifier()}`)).filter(el => !!el), (e) => e);
    }

    /**
     * Get a total count of the children or all desendants of the given key. 
     * @param key Key of the parent node. Use `null` to get all root nodes
     * @param deep count all descendants (includes children, grandchildren, great-granchildren,
     */
    public getChildCount(key: NullableKey<string>, deep: boolean): number {
        // either way, start from this node
        if (key === this._identifier || key === null) {
            if (deep) {
                return this._children.length + sum(this._children.map(ch => ch.getChildCount(null, deep)));
            } 
            return this._children.length;
        }

        const suffix = removePrefix(key, this._delimiter);
        const { existing } = this._getExistingWithPrefixAndNext(suffix);

        if (existing == null) {
            return 0;
        }
        return existing.getChildCount(suffix, deep);
    }

    /**
     * Retrieve all values contained in this node and its children
     */
    public allSubValues(): T[] {
        return [this._item].concat(...this._children.map(ch => ch.allSubValues())).filter(el => !!el);
    }
    
    protected _getExistingWithPrefixAndNext(key: string): { existing: TrieNode<T>, prefix: string, next: string } {
        let prefix = getPrefix(key, this._delimiter);
        let next = removePrefix(key, this._delimiter);

        let existing = this._children.find(tn => tn.identifier() === prefix);

        return { existing, prefix, next };
    }
}

function getPrefix(key: string, delim: string): string {
    if (!key.includes(delim)) {
        return key;
    }
    return key.slice(0, key.indexOf(delim));
}

export function removePrefix(key: string, delim: string): string {
    if (!key.includes(delim)) {
        return key;
    }
    return key.slice(key.indexOf(delim) + 1);
}