import { animate, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild
} from '@angular/core';
import { UserProfileModal } from 'src/app/components/UserManagement/Modals/user-profile-modal/user-profile-modal.ctrl';
import { KeyCodeEnum } from '../../../../enums/KeyCodeEnum';
import { ModalDialogRef, ModalDialogService2 } from '../../modal-dialog-2';
import { DisposalBag } from 'src/app/shared/utils/DisposalBag';
import { UserService } from 'src/app/services/user-services/user.service';
import { UserPreferenceType } from 'src/app/shared/enums/UserPreferenceType';
import { LanguageTypeEnum } from 'src/app/shared/enums/LanguageTypeEnum';
import { AppTranslationService } from 'src/app/services/app-translation.service';


@Component({
    selector: 'modal',
    templateUrl: './modal.ctrl.html',
    styleUrls: ['./modal.ctrl.scss'],
    animations: [
        trigger('modal', [
            transition('void => *', [
                style({
                    transform: 'scale(0)',
                    opacity: 0
                }),
                animate('250ms cubic-bezier(0.4, 0, 0.2, 1)', style({
                    transform: 'scale(1)',
                    opacity: 1,
                }))
            ]),
            transition('* => void', animate('175ms cubic-bezier(0.4, 0, 0.2, 1)', style({
                transform: 'scale(0)',
                opacity: 0
            })))
        ])
    ]
})
export class ModalControl implements OnInit, AfterViewInit {

    private _disposalBag: DisposalBag = new DisposalBag();
    @Input() public size: number;
    @Input() public showXButton: boolean = false;
    @Input() public ignoreClose: boolean = false;


    @ViewChild('container') public container: ElementRef;
    public gridSize: string = '';
    public title: string = '';
    public titleName: string = '';
    public isShowEscText: boolean = false;


    constructor(private _modalService: ModalDialogService2, public el: ElementRef, private translationService: AppTranslationService) {
    }

    public ngAfterViewInit(): void {
        // focus input on the panel (makes it listen to keydown events for esc button)
        this._focusPanel();
    }


    public ngOnInit(): void {
        this.el.nativeElement.querySelector('.focus').focus();
        if (this.size) {
            this.gridSize = 'grid-' + this.size;
        }
        let langPref = this.translationService.getCurrentLanguage()  
        if (langPref == "en-US") {
            this.isShowEscText = true;
        }
        else {
            this.isShowEscText = false;
        }
        

    }

    public close(): void {
        if (this.ignoreClose) {
            return;
        }
        this._modalService.closeModal();
    }

    public stopEvent($event: MouseEvent): void {
        $event.stopPropagation();
    }


    @HostListener('keydown', ['$event'])
    private _detectKeyPress($event: KeyboardEvent): void {
        switch ($event.keyCode) {
            case KeyCodeEnum.ESCAPE:
                $event.stopPropagation();
                this._modalService.ForceClosedModal.next(true);
                this.close();
                break;
            default:
                break;
        }
    }

    private _focusPanel(): void {
        (<HTMLElement>this.el.nativeElement).tabIndex = -1;
        // this.el.nativeElement.focus();
        this.el.nativeElement.querySelector('.modal-container').focus();
    }
}