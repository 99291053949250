import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { InfoMessageBarOptions } from 'src/app/shared/controls/info-message-bar/InfoMessageBarOptions';
import { ModalDialogRef } from '../../controls/dialogs';
import { ModalDialogService2, SOTI_MODAL_DATA } from '../../controls/dialogs/modal-dialog-2/modal-dialog-2.service';
import { KeyCodeEnum } from '../../enums/KeyCodeEnum';
import { LiveAnnouncer } from '@angular/cdk/a11y';


export class ConfirmationDialogOptions {
    public title: string;
    public body: string;
    public icon: string;
    public okButtonText: string;
    public okButtonCssClass: string;
    public errorMessage: InfoMessageBarOptions;
    public showError: boolean;
    public hideHelpBtn: boolean = false;
    public sotionetheme: boolean = false;
    public confirmationCheckBoxRequired : boolean;
    public confirmationCheckBoxLabelText : string;
    public showButtonLoader: BehaviorSubject<boolean>;
    public submitCallback: () => void | Observable<void>;
    public onSubmit: EventEmitter<void> = new EventEmitter<void>();
    public onCancel: EventEmitter<void> = new EventEmitter<void>();
    public onClose: EventEmitter<any> = new EventEmitter<any>();
    public helpId: string;
}

@Component({
    selector: 'mc-confirmation-dialog',
    templateUrl: './confirmation-dialog.ctrl.html',
    styleUrls: ['./confirmation-dialog.ctrl.scss']
})
export class ConfirmationDialogModal implements OnInit, AfterViewInit {
    
    public input: ConfirmationDialogOptions;
    public isPending: boolean = false;
    public submitted: boolean = false;
    public showingLoader: boolean = false;
    public submitErrorTooltipTitle: string = null;
    public submitErrorTooltip: string | string[] = null;
    public okButtonCssClass: string;
    public hover: boolean;
    public checkBoxDisable: boolean = false;
    public infoMessageBarOptions: InfoMessageBarOptions = null; 

    constructor( private _modalRef: ModalDialogRef<ConfirmationDialogModal>,
        private translationService: AppTranslationService,
        private _liveAnnouncer: LiveAnnouncer,
        private _modalDialogService : ModalDialogService2,
        public el: ElementRef,
        @Inject(SOTI_MODAL_DATA) @Optional() _data: ConfirmationDialogOptions
    ) {
        this.input = _data;
    }

    @HostListener('keydown', ['$event'])
    private _detectKeyPress($event: KeyboardEvent): void {
        $event.stopImmediatePropagation();
        switch ($event.keyCode) {
            case KeyCodeEnum.ESCAPE:
                this.onCancelClick();
                break;
            default:
                break;
        }
    }

    public ngOnInit(): void {
        this.input.showButtonLoader.subscribe(showButtonLoader => { this.showingLoader = showButtonLoader; });
        this.okButtonCssClass = this.input.okButtonCssClass || 'primary';
        if (this.input.confirmationCheckBoxRequired) {
            this.isPending = true;
        }
        this.input.onClose.subscribe((x)=>{
            if (x === 1){
                this._modalDialogService.closeModalTree()
            }
            else {
                this._modalDialogService.closeModal()
            }
            
        })
    }

    public ngAfterViewInit(): void {
        let infoText: string;
        if (this.input.showError) {
            infoText = this.input.errorMessage.message+';'+this.el.nativeElement.querySelector('#info-content').innerText;
        } else {
            infoText = this.el.nativeElement.querySelector('#info-content').innerText;
        }
        this._liveAnnouncer.announce(infoText,"polite")
        // focus input on the panel (makes it listen to keydown events for esc button)
        this._focusPanel();
    }

    public onCheckChanged(isChecked: boolean): void {
        this.isPending = !isChecked;
    }

    public submit(): void {
        this.infoMessageBarOptions = null;
        this.showingLoader = false;
        this.checkBoxDisable = true;
        this.input.onSubmit.emit();
    }

    public onCancelClick(): void {
        this._modalDialogService.closeModal()
        this.input.onCancel.emit();
    }

    private _focusPanel(): void {
        (<HTMLElement>this.el.nativeElement).tabIndex = -1;
    }
}