import {MessageBase} from '../../utils/toast-utils/MessageBase';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import { map, filter, scan } from 'rxjs/operators';
import {uniqBy} from 'lodash';
import {WebsocketMessageBase} from '../../utils/toast-utils/WebsocketMessageBase';

/**
 * Created by rgubarenko on 2016-07-07.
 */
//tslint:disable

export class StreamTransformStep<T> {
    constructor(
        private _filterStep: (m: any) => boolean = null,
        private _transformStep: (m: any) => T = null,
        private _filterFirst: boolean = true) {
    }

    public runStep(inStream: Observable<any>): Observable<T> {
        if (this._filterStep == null && this._transformStep == null) {
            return inStream;
        }

        return this._filterFirst ?
            this._applyTransform(this._applyFilter(inStream)) :
            this._applyFilter(this._applyTransform(inStream));
    }

    private _applyFilter(i: Observable<any>): Observable<T> {
        if (this._filterStep == null) {
            return i;
        }
        return i.pipe(filter(el => this._filterStep(el)));
        //return i.filter(el => this._filterStep(el));
    }

    private _applyTransform(i: Observable<any>): Observable<T> {
        if (this._transformStep == null) {
            return i;
        }
        return i.pipe(map(el => this._transformStep(el)));
    }
}


/**
 * This interface is use to constrain stream requests to only constructable types, ensuring
 * that items such as the base EventMessage and WebsocketMessageBase can not be directly
 * requested as this would violate the entire principal of hiding the base stream.
 *
 * In short: Only ask for what you can use!
 */
interface CC<T extends WebsocketMessageBase | MessageBase> {new(...args: any[]): T;}

export interface IAppMessengerStreamBuilder {

    /**
     * This one is needed for testing purposes - the appMessenger stream need to be reset before
     * execution of appMessenger-dependent unit tests.
     */
    reset(): void;

    /**
     * Get a stream in which each element is only one of the given types and for which null is filtered out
     */
    getCombinedStream<T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase, T5 extends WebsocketMessageBase | MessageBase, T6 extends WebsocketMessageBase | MessageBase, T7 extends WebsocketMessageBase | MessageBase>(i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>, i5: CC<T5>, i6: CC<T6>, i7: CC<T7>): Observable<T1 | T2 | T3 | T4 | T5 | T6 | T7>;

    /**
     * Get a stream in which each element is only one of the given types and for which null is filtered out
     */
    getCombinedStream<T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase, T5 extends WebsocketMessageBase | MessageBase, T6 extends WebsocketMessageBase | MessageBase>(i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>, i5: CC<T5>, i6: CC<T6>): Observable<T1 | T2 | T3 | T4 | T5 | T6>;

    /**
     * Get a stream in which each element is only one of the given types and for which null is filtered out
     */
    getCombinedStream<T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase, T5 extends WebsocketMessageBase | MessageBase>(i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>, i5: CC<T5>): Observable<T1 | T2 | T3 | T4 | T5>;

    /**
     * Get a stream in which each element is only one of the given types and for which null is filtered out
     */
    getCombinedStream<T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase>(i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>): Observable<T1 | T2 | T3 | T4>;

    /**
     * Get a stream in which each element is only one of the given types and for which null is filtered out
     */
    getCombinedStream<T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase>(i1: CC<T1>, i2: CC<T2>, i3: CC<T3>): Observable<T1 | T2 | T3>;

    /**
     * Get a stream in which each element is only one of the given types and for which null is filtered out
     */
    getCombinedStream<T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase>(i1: CC<T1>, i2: CC<T2>): Observable<T1 | T2>;

    /**
     * Get a stream in which each element is only one of the given types and for which null is filtered out
     */
    getCombinedStream<T1 extends WebsocketMessageBase | MessageBase>(i1: CC<T1>): Observable<T1>;

    /**
     * Get a stream for which each element is one of the given types and apply the given transform(s) to that
     * stream to obtain a typed stream of the resulting transform.
     */
    getTransformedStream<R, T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase, T5 extends WebsocketMessageBase | MessageBase, T6 extends WebsocketMessageBase | MessageBase, T7 extends WebsocketMessageBase | MessageBase>(steps: StreamTransformStep<R>[], i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>, i5: CC<T5>, i6: CC<T6>, i7: CC<T7>): Observable<R>;

    /**
     * Get a stream for which each element is one of the given types and apply the given transform(s) to that
     * stream to obtain a typed stream of the resulting transform.
     */
    getTransformedStream<R, T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase, T5 extends WebsocketMessageBase | MessageBase, T6 extends WebsocketMessageBase | MessageBase>(steps: StreamTransformStep<R>[], i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>, i5: CC<T5>, i6: CC<T6>): Observable<R>;

    /**
     * Get a stream for which each element is one of the given types and apply the given transform(s) to that
     * stream to obtain a typed stream of the resulting transform.
     */
    getTransformedStream<R, T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase, T5 extends WebsocketMessageBase | MessageBase>(steps: StreamTransformStep<R>[], i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>, i5: CC<T5>): Observable<R>;

    /**
     * Get a stream for which each element is one of the given types and apply the given transform(s) to that
     * stream to obtain a typed stream of the resulting transform.
     */
    getTransformedStream<R, T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase>(steps: StreamTransformStep<R>[], i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>): Observable<R>;

    /**
     * Get a stream for which each element is one of the given types and apply the given transform(s) to that
     * stream to obtain a typed stream of the resulting transform.
     */
    getTransformedStream<R, T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase>(steps: StreamTransformStep<R>[], i1: CC<T1>, i2: CC<T2>, i3: CC<T3>): Observable<R>;

    /**
     * Get a stream for which each element is one of the given types and apply the given transform(s) to that
     * stream to obtain a typed stream of the resulting transform.
     */
    getTransformedStream<R, T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase>(steps: StreamTransformStep<R>[], i1: CC<T1>, i2: CC<T2>): Observable<R>;

    /**
     * Get a stream in which each element is only one of the given types and for which null is filtered out
     */
    getTransformedStream<R, T1 extends WebsocketMessageBase | MessageBase>(steps: StreamTransformStep<R>[], i1: CC<T1>): Observable<R>;

    /**
     * Post a new message into the stream
     */
    postMessage(message: WebsocketMessageBase | MessageBase): void;
}

// Experiment, DO NOT USE
class Builder {
    private _types: Function[];
    private _operations: OperationItem[];

    constructor() {
        this._types = [];
        this._operations = [];
    }

    public forType<T>(t: CC<T>): this {
        this._types.push(t);
        return this;
    }

    public filter(filter: (i: any) => boolean): this {
        this._operations.push({operation: filter, isMap: false});
        return this;
    }

    public map(map: (i: any) => any): this {
        this._operations.push({operation: map, isMap: true});
        return this;
    }

    public get isReady(): boolean {
        return this._types.length > 0;
    }

    // public stream(): Observable<T> {
    //     return appMessenger.getCombinedStream(...this._types);
    // }
}

interface OperationItem {
    operation: Function;
    isMap: boolean;
}

class AppMessengerStreamBuilder implements IAppMessengerStreamBuilder {
    private _nullFilter = new StreamTransformStep<any>((m) => m && m !== null);

    public reset(): void {
        _appMsgr.reset();
    }

    public getCombinedStream<T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase, T5 extends WebsocketMessageBase | MessageBase, T6 extends WebsocketMessageBase | MessageBase, T7 extends WebsocketMessageBase | MessageBase>(i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>, i5: CC<T5>, i6: CC<T6>, i7: CC<T7>): Observable<T1 | T2 | T3 | T4 | T5 | T6 | T7>;
    public getCombinedStream<T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase, T5 extends WebsocketMessageBase | MessageBase, T6 extends WebsocketMessageBase | MessageBase>(i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>, i5: CC<T5>, i6: CC<T6>): Observable<T1 | T2 | T3 | T4 | T5 | T6>;
    public getCombinedStream<T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase, T5 extends WebsocketMessageBase | MessageBase>(i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>, i5: CC<T5>): Observable<T1 | T2 | T3 | T4 | T5>;
    public getCombinedStream<T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase>(i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>): Observable<T1 | T2 | T3 | T4>;
    public getCombinedStream<T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase>(i1: CC<T1>, i2: CC<T2>, i3: CC<T3>): Observable<T1 | T2 | T3>;
    public getCombinedStream<T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase>(i1: CC<T1>, i2: CC<T2>): Observable<T1 | T2>;
    public getCombinedStream<T1 extends WebsocketMessageBase | MessageBase>(i1: CC<T1>): Observable<T1>;
    public getCombinedStream(i1: any, i2?: any, i3?: any, i4?: any, i5?: any, i6?: any, i7?: any): Observable<any> {
        return this._createStreamForTypes(i1, i2, i3, i4, i5, i6, i7);
    }

    public getTransformedStream<R, T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase, T5 extends WebsocketMessageBase | MessageBase, T6 extends WebsocketMessageBase | MessageBase, T7 extends WebsocketMessageBase | MessageBase>(steps: StreamTransformStep<R>[], i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>, i5: CC<T5>, i6: CC<T6>, i7: CC<T7>): Observable<R>;
    public getTransformedStream<R, T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase, T5 extends WebsocketMessageBase | MessageBase, T6 extends WebsocketMessageBase | MessageBase>(steps: StreamTransformStep<R>[], i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>, i5: CC<T5>, i6: CC<T6>): Observable<R>;
    public getTransformedStream<R, T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase, T5 extends WebsocketMessageBase | MessageBase>(steps: StreamTransformStep<R>[], i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>, i5: CC<T5>): Observable<R>;
    public getTransformedStream<R, T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase, T4 extends WebsocketMessageBase | MessageBase>(steps: StreamTransformStep<R>[], i1: CC<T1>, i2: CC<T2>, i3: CC<T3>, i4: CC<T4>): Observable<R>;
    public getTransformedStream<R, T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase, T3 extends WebsocketMessageBase | MessageBase>(steps: StreamTransformStep<R>[], i1: CC<T1>, i2: CC<T2>, i3: CC<T3>): Observable<R>;
    public getTransformedStream<R, T1 extends WebsocketMessageBase | MessageBase, T2 extends WebsocketMessageBase | MessageBase>(steps: StreamTransformStep<R>[], i1: CC<T1>, i2: CC<T2>): Observable<R>;
    public getTransformedStream<R, T1 extends WebsocketMessageBase | MessageBase>(steps: StreamTransformStep<R>[], i1: CC<T1>): Observable<R>;
    public getTransformedStream(steps: any[], i1: any, i2?: any, i3?: any, i4?: any, i5?: any, i6?: any, i7?: any): Observable<any> {
        let inter = this.getCombinedStream(i1, i2, i3, i4, i5, i6, i7);
        if (steps == null || steps.length < 1) {
            return inter;
        }

        steps.forEach(step => inter = step.runStep(inter));
        return inter;
    }

    public postMessage(message: MessageBase | WebsocketMessageBase): void {
        _appMsgr.postMessage(message);
    }

    private _createStreamForTypes(i1: any, i2?: any, i3?: any, i4?: any, i5?: any, i6?: any, i7?: any): Observable<any> {
        // Transform the given types into funcs that will provide positive/negative feedback on given types
        const types = uniqBy(Array.from(arguments).filter(arg => !!arg), a => a);

        // Create a step that will look for any of the resulting funcs to be true        
        const step = new StreamTransformStep((item) => types.some((ty, i, col) => item instanceof ty));

        // Use the step, and a null filter to generate the stream
        return _appMsgr.getCustomStream([this._nullFilter, step]);
    }
}

export const appMessenger: IAppMessengerStreamBuilder = new AppMessengerStreamBuilder();

// APP MESSENGER HUB //
class AppMessenger {

    private _stream: Subject<MessageBase>;

    constructor() {
        this._stream = new Subject<MessageBase | WebsocketMessageBase>();
    }

    public reset(): void {
        // kill all current subscriptions
        this._stream.complete();
        // used brand new stream
        this._stream = new Subject<MessageBase | WebsocketMessageBase>();
    }

    public get stream(): Observable<MessageBase | WebsocketMessageBase> {
        return this._stream.asObservable();
    }

    public postMessage(message: MessageBase | WebsocketMessageBase): void {
        //logger.msgr('App Messenger: message posted', message);
        this._stream.next(message);
    }

    public getCustomStream<T>(steps: StreamTransformStep<T>[]): Observable<T> {
        let firstStep = steps[0];
        let remaining = steps.slice(1);

        let tmp = firstStep.runStep(this._stream.asObservable());

        if (remaining != null && remaining.length > 0) {
            remaining.forEach(step => tmp = step.runStep(tmp));
        }

        return tmp;
    }
}

/**
 * Primary application messenger service. This is singleton standalone service
 * which becomes available on early stage of application bootstrap and can be used
 * even during app initialization.
 * @type {AppMessenger}
 */
const _appMsgr = new AppMessenger();