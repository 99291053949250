import {Component,Input,OnInit, Host} from '@angular/core';
import { ModalControl } from '../modal/modal.ctrl';


@Component({
selector:'modal-header',
templateUrl:'./modal-header.ctrl.html',
styleUrls: ['./modal-header.ctrl.scss']
})

export class ModalHeaderComponent implements OnInit
{ 
    @Input() modalHeader:string;
    constructor( @Host() _modal: ModalControl) { }

    ngOnInit()
    {

           
        
    }
}
