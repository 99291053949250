// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Regular.ttf.woff2') format('woff2'),
         url('SourceSansPro-Regular.ttf.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Light.ttf.woff2') format('woff2'),
    url('SourceSansPro-Light.ttf.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Semibold.ttf.woff2') format('woff2'),
    url('SourceSansPro-Semibold.ttf.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Bold.ttf.woff2') format('woff2'),
    url('SourceSansPro-Bold.ttf.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/styles/fonts/SourceSansPro/source-sans-pro.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B;6DAC+D;IAC/D,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B;sDACwD;IACxD,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B;yDAC2D;IAC3D,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B;qDACuD;IACvD,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["@font-face {\r\n    font-family: 'Source Sans Pro';\r\n    src: url('fonts/SourceSansPro-Regular.ttf.woff2') format('woff2'),\r\n         url('fonts/SourceSansPro-Regular.ttf.woff') format('woff');\r\n    font-weight: normal;\r\n    font-style: normal;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Source Sans Pro';\r\n    src: url('fonts/SourceSansPro-Light.ttf.woff2') format('woff2'),\r\n    url('fonts/SourceSansPro-Light.ttf.woff') format('woff');\r\n    font-weight: 300;\r\n    font-style: normal;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Source Sans Pro';\r\n    src: url('fonts/SourceSansPro-Semibold.ttf.woff2') format('woff2'),\r\n    url('fonts/SourceSansPro-Semibold.ttf.woff') format('woff');\r\n    font-weight: 600;\r\n    font-style: normal;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Source Sans Pro';\r\n    src: url('fonts/SourceSansPro-Bold.ttf.woff2') format('woff2'),\r\n    url('fonts/SourceSansPro-Bold.ttf.woff') format('woff');\r\n    font-weight: 700;\r\n    font-style: normal;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
