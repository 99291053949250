import {
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef, EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, merge, Subscription } from 'rxjs';
import { KeyCodeEnum } from '../../enums/KeyCodeEnum';
import { SelectionChange, SelectOptionControl } from './select-option/select-option.ctrl';

@Component({
    selector: 'soti-select',
    templateUrl: './select-box.ctrl.html',
    styleUrls: ['./select-box.ctrl.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectBoxControl),
            multi: true
        }
    ]
})

export class SelectBoxControl implements OnInit, ControlValueAccessor, AfterContentInit, OnDestroy {

    @Input() public autoSelect: boolean = true;
    @Input() public groupedRight: boolean = false;
    @Input() public groupedLeft: boolean = false;
    @Input() public maxHeight: number = 220;
    
    @HostBinding('attr.role')
    private role = 'combobox';
    
    @HostBinding('attr.aria-autocomplete')
    private ariaAutocomplete = 'list';

    @HostBinding('attr.data-lpignore')
    private dataLpignore = 'true';

    @Input()
    public ariaLabelledby:string = '';

    @HostBinding('attr.aria-labelledby')
    public SotiSelectBoxLabel: string; 

    @HostBinding('attr.aria-expanded')
    public ariaexpanded: boolean = false;
    
    @Output('selectionChange')
    public selectionChange: EventEmitter<ISelectionChangeArgs> = new EventEmitter();

    @ContentChildren(SelectOptionControl)
    public options: QueryList<SelectOptionControl>;

    public get optionSelectionChanges(): Observable<SelectionChange> {
        return merge(...this.options.map<Observable<SelectionChange>>(option => option.onSelection));
    }

    public get selectedText(): string {
        if (this.selected) {
            return this.selected.viewValue();
        } else {
            return '';
        }
    }

    public get selectedOption(): string{
        if (this.selected){
            return this.selected.viewName();
        }
        else {
            return '';
        }
    }

    public value: string = '';
    public selected: SelectOptionControl;
    public defaultSelected: SelectOptionControl;
    public selectionEmitted: SelectOptionControl;
    @HostBinding('class.options-opened')
    public optionsOpened: boolean = false;
    @HostBinding('attr.disabled')
    public disabled: boolean = false;

    private _optionSubscription: Subscription;
    private _changeSubscription: Subscription;

    private _selectedIndex: number = 0;
    private _firstChange: boolean = true;

    private inside: boolean = false;

    private completedInitialSetup: boolean = false;

    // Elements that need to animate
    @ViewChild('selectedBackground')
    private _selectedBackground: ElementRef;
    @ViewChild('options')
    private _options: ElementRef;
    @ViewChild('optionWrap')
    private _optionWrap: ElementRef;
    @ViewChild('shadow')
    private _shadow: ElementRef;

    constructor(
        public element: ElementRef,
        private _renderer: Renderer2,
        private _cdr: ChangeDetectorRef) {
    }

    @HostListener('click')
    public toggleOpen(): boolean {
        this.inside = true;
        if (!this.disabled) {
            if (!this.optionsOpened) {
                this._checkMaxHeight();
                this._focusElement();
            }
            this._animate(!this.optionsOpened);
        }
        return false;
    }

     
    public open(): void {
        if (!this.optionsOpened) {
            this.toggleOpen();
        }
    }

    public close($event?: any): void {
        if (this.optionsOpened) {
            if($event?.keyCode ==KeyCodeEnum.TAB || $event?.keyCode == KeyCodeEnum.ESCAPE){
               this.selectCustomOption(this.selectionEmitted);
            }
            $event && $event.stopImmediatePropagation();
            setTimeout(() => {
                this.element.nativeElement.focus();
            }, 100);
            this.toggleOpen();
        }
    }

    @HostListener('keydown', ['$event'])
    public onkeydown(event: KeyboardEvent): void {
        this.completedInitialSetup = true
        switch (event.keyCode) {
            case KeyCodeEnum.TAB:
            case KeyCodeEnum.ESCAPE: {
                this.close(event);
                if (this.optionsOpened) {
                    event.stopPropagation();
                }
                
                break;
            }
            case KeyCodeEnum.DOWN_ARROW: {
                if (this.optionsOpened) {
                    this._selectNext();
                    this.selected.scrollIntoView();
                    event.preventDefault();
                } 
                break;
            }
            case KeyCodeEnum.UP_ARROW: {
                if (this.optionsOpened) {
                    this._selectPrevious();
                    this.selected.scrollIntoView();
                    event.preventDefault();
                }
                break;
            }
            case KeyCodeEnum.SPACE:
            case KeyCodeEnum.ENTER: {
                if (this.optionsOpened) {
                    this._selectAtIndex(this._selectedIndex, true);
                    this.close();
                }
                this.selectionEmitted = Object.assign(this.selected);
                break;
            }
            default:
                return;
        }
    }

    public ngOnInit(): void {
    }

    public ngAfterContentInit(): void {
        this._checkMaxHeight();
        this._changeSubscription = this.options.changes.startWith(null).subscribe(() => {
            this._listenToOptions();
            Promise.resolve(null).then(() => {
                if (this.value) {
                    this.selectOption();
                    if(this.selected){
                    this.defaultSelected = Object.assign(this.selected);
                    }
                }
            });
        });
        this.SotiSelectBoxLabel = 'SelectBoxControlLabel' + this.ariaLabelledby
    }

    public ngAfterViewInit(): void {
    }

    public ngOnDestroy(): void {
        this._optionSubscription && this._optionSubscription.unsubscribe();
        this._optionSubscription = null;

        this._changeSubscription && this._changeSubscription.unsubscribe();
        this._changeSubscription = null;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(value: string): void {
        this.value = value;
        Promise.resolve(null).then(() => {
            if (this.options) {
                this.selectOption();
            }
        });
    }

    public registerOnChange(fn: (value: string) => void): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        // TODO: register on touch
    }

    public selectOption(): void {
        this._clearSelections();
        this._selectValue(this.value);
        this._cdr.detectChanges();
    }
    
    //selection on accessibility flow 
    public selectCustomOption(option:SelectOptionControl){
        if(option){
          this.selectCustomValue(option.value);
        }else{
            this.selectCustomValue(this.defaultSelected?.value);
        }
    }

    private selectCustomValue(value:string): void {
        this._clearSelections();
        this._selectValue(value);
        this._cdr.detectChanges();
    }

    
    private _focusElement(): void {
        //this._renderer.invokeElementMethod(this._selectedBackground.nativeElement, 'focus');
        const element = this._renderer.selectRootElement(this._selectedBackground.nativeElement);
        element.focus();
    }

    private _onChange = (value: string) => {
    }

    private _listenToOptions(): void {
        this._optionSubscription = this.optionSelectionChanges.subscribe(selected => {
            this._clearSelections();
            this.value = selected.source.value;
            this._onSelect(selected.source, true);
        });
    }

    private _selectNext(): void {
        this.refreshSelection(this.selected?.value);
        if (this._selectedIndex < this.options.length - 1) {
            this._selectedIndex++;
        } else {
            this._selectedIndex = 0;
        }
        this._selectAtIndex(this._selectedIndex);
    }

    private _selectPrevious(): void {
        this.refreshSelection(this.selected?.value);
        if (this._selectedIndex > 0) {
            this._selectedIndex--;
        } else {
            this._selectedIndex = this.options.length - 1;
        }
        this._selectAtIndex(this._selectedIndex);
    }

    private refreshSelection(value:string){
        this._clearSelections();
        let option = this.options.find((option, idx) => {
            if (option.value.toString() === value.toString()) {
                this._selectedIndex = idx;
                return true;
            }
        });
    }

    private _selectAtIndex(idx: number, userInitiated: boolean = false): void {
        let selectedOptionIndex = this.options.toArray()[idx];
        this.value = selectedOptionIndex.value;
        this._onSelect(selectedOptionIndex, userInitiated);
    }

    private _onSelect(option: SelectOptionControl, isUserInitiated: boolean): void {
        if (option) {
            this.selected = option;
            option.select();
            if (this.completedInitialSetup){
                option.setFocus();
            }
            this._onChange(option.value);

            // fire extra event here - it's more detailed than simple model
            // change and can be utilized in more complex scenarios
            if (isUserInitiated) {
                this.selectionEmitted = option;
                this.defaultSelected = option;
                this.selectionChange.emit({
                    value: option.value,
                    isUserInitiated: isUserInitiated,
                    isFirstChange: this._firstChange
                });
            }

            if (this._firstChange) {
                this._firstChange = false;
            }
        }
    }

    private _selectValue(value: string): void {
        if (value == null) {
            value = '';
        }

        let option = this.options.find((option, idx) => {
            if (option.value.toString() === value.toString()) {
                this._selectedIndex = idx;
                return true;
            }
        });
        if (!option && this.autoSelect) {
            option = this.options.first;
        }
        this._onSelect(option, false);
    }

    private _clearSelections(): void {
        this.options.forEach((option) => option.deselect());
    }

    private _animate(open: boolean): void {
        const timingFunctionExpand = (t) => {
            return --t * t * t * t * t + 1;
        };
        const timingFunctionCollapse = (t) => {
            if ((t *= 2) < 1) {
                return 0.5 * t * t * t * t * t;
            }

            return 0.5 * ((t -= 2) * t * t * t * t + 2);
        };

        if (open) {
            this.optionsOpened = true;
            this.ariaexpanded = true;
            var flipGroup = FLIP.group([{
                element: this._selectedBackground.nativeElement,
                duration: 125,
                ease: timingFunctionExpand
            }, {
                element: this._options.nativeElement,
                duration: 100,
                delay: 80,
                ease: timingFunctionExpand
            }, {
                element: this._shadow.nativeElement,
                duration: 80,
                delay: 150,
                ease: timingFunctionExpand
            }]);

            // FLIP
            // First
            // Last
            // Invert
            // Play
            // https://aerotwist.com/blog/flip-your-animations/
            flipGroup.first();
            flipGroup.last('last');

            // When the 'last' class is added in the previous function, we can get the final height of both the selected box and the options.
            // Then we can set the shadow height to that total
            const shadowHeight = this._selectedBackground.nativeElement.clientHeight + this._options.nativeElement.clientHeight;
            //this._renderer.setElementStyle(this._shadow.nativeElement, 'height', shadowHeight + 'px');
            this._renderer.setStyle(this._shadow.nativeElement, 'height', shadowHeight + 'px');

            flipGroup.invert();
            flipGroup.play();
        } else {
            const optionsElement = (this._options.nativeElement as HTMLElement);
            const eventListener = () => {
                optionsElement.removeEventListener('flipComplete', eventListener);
                this.optionsOpened = false;
                this.ariaexpanded = false;
                this._cdr.markForCheck();
            };
            optionsElement.addEventListener('flipComplete', eventListener);
            // tslint:disable-next-line
            var flipGroup = FLIP.group([{
                element: this._selectedBackground.nativeElement,
                duration: 80,
                delay: 80,
                ease: timingFunctionCollapse
            }, {
                element: this._options.nativeElement,
                duration: 80,
                delay: 25,
                ease: timingFunctionCollapse
            }, {
                element: this._shadow.nativeElement,
                duration: 100,
                ease: timingFunctionCollapse
            }]);
            flipGroup.first();
            flipGroup.removeClass('last');
            flipGroup.last();
            flipGroup.invert();
            flipGroup.play();
        }
    }

    private _checkMaxHeight(): void {
        if (!this.element || !this.element.nativeElement || !this._options || !this._options.nativeElement) {
            return;
        }

        let boundingClientRect = <ClientRect>this.element.nativeElement.getBoundingClientRect();
        if (!boundingClientRect) {
            return;
        }

        let windowHeight = window.innerHeight;
        let elementOffsetTop = boundingClientRect.top;
        let elementHeight = boundingClientRect.height;
        let bottomBuffer = 20; //in pixels.

        let maxOptionsContainerHeight = 215;
        if (this.maxHeight) {
            maxOptionsContainerHeight = this.maxHeight < maxOptionsContainerHeight ? this.maxHeight : maxOptionsContainerHeight;
        }
        (<HTMLElement>this._options.nativeElement).style.maxHeight = `${maxOptionsContainerHeight}px`;
        (<HTMLElement>this._optionWrap.nativeElement).style.maxHeight = `${maxOptionsContainerHeight - 8}px`; // magic 8 to account for padding
    }

    @HostListener('document:click')
     private clickedOut():  void {
         if (this.inside === false && this.optionsOpened) {
            this.close();
         }
            this.inside = false;
        }
}

/**
 * Structure of event fired when selection changes.
 * Extend it with any other data required for complex scenarios.
 */
export interface ISelectionChangeArgs {
    /**
     * Current selection value.
     */
    value: string;
    /**
     * Indicates whether change is actually initialted by the user clicking the option.
     */
    isUserInitiated: boolean;
    /**
     * Indicates whether change is the first change (when select box loads)
     */
    isFirstChange?: boolean;
}
