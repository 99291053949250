import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    FileDownloadRequestDetails,
    FileDownloadRequestResult,
    FileDownloadService
} from '../../services/file-download/file-download.service';
import { Observable, Subject } from 'rxjs';
import { CSVData } from 'src/app/models/csvDataModel';
@Component({
    selector: 'soti-file-download',
    templateUrl: './file-download.ctrl.html',
    styleUrls: ['./file-download.ctrl.scss']
})
export class FileDownloadControl implements OnChanges {

    @ViewChild('downloadLink')
    public downloadLinkElement: ElementRef;

    @Input()
    public roleRenderedAsLink: string = 'link';
    
    @Input()
    public downloadUri: string = null;
    
    @Input()
    public downloadName: string = null;

    @Output()
    public downloadAsyncBeginEvent: EventEmitter<DownloadAsyncBeginEvent> = new EventEmitter<DownloadAsyncBeginEvent>();

    @Output()
    public downloadAsyncEndEvent: EventEmitter<FileDownloadRequestResult> = new EventEmitter<FileDownloadRequestResult>();

    @Input()
    public disabled: boolean = false;

    @Input()
    public downloadButtonLabel: string = null;
    
    @Input()
    public isExternalSource: boolean = false;

    @Input()
    public handleRequestResult: boolean = true;

    @Input()
    public downloadBlobToIframe: boolean = false;

    @Input()
    public iframeName: string = 'iframe_download';

    @Input()
    public renderAsLink: boolean = false;

    @Input()
    public ariaLabelledby: string = "";

    @Input()
    /**
     * Specifies that the downloading file is binary. This allows us to use the IE11 specific `msSaveOrOpenBlob` function
     * to save the file. Otherwise, binary downloads will fail on IE.
     */
    public binaryDownload: boolean = false;

    @Input()
    public icon: string = '';

    @Input()
    public csvDownload:boolean = false;

    @Input()
    public csvDataAsString: boolean = false;

    public getCSVData: Subject<boolean>;

    @Input()
    public csvData :CSVData<any> = new CSVData<any>() ;

    
    private _blobUri: string = null;

    private static _isAsyncDownloadSupported(): boolean {
        let userAgent = window.navigator.userAgent;

        if (/MSIE|trident/i.test(userAgent) && !/edge/i.test(userAgent)) {
            return false;
        }

        return !/(iPhone|iPod|iPad)/i.test(userAgent);
    }

    constructor(private _fileDownloadService: FileDownloadService,
                private _renderer: Renderer2) {
                    this.getCSVData = new Subject();
    }
    ngOnChanges(changes: SimpleChanges): void {
        if( changes['csvData'] &&  !changes['csvData'].firstChange ) {
            let blob = this._fileDownloadService.downloadFileAsCSV(this.csvData.data, this.csvData.jsonKeys, this.csvData.headers);
            this._downloadFile(blob, false);
          }
    }

    public downloadFromBlobUri(blobUri: string): void {
        this._blobUri = blobUri;        
        this._downloadFile(this._blobUri, this.downloadBlobToIframe);
    }

    public startDownload(): void {               
        if (this.csvDownload && !this.csvDataAsString) {
            this.getCSVData.next(true);
        } else if (this.csvDownload && this.csvDataAsString) {
            let blob = this._fileDownloadService.downloadCsvFile(this.downloadUri);
            this._downloadFile(blob, false);
        } else if (FileDownloadControl._isAsyncDownloadSupported() && !this.isExternalSource) {
            this._startDownloadAsync();
        } else if (this.binaryDownload) {
            this._startDownloadAsync(this.binaryDownload);
        } 
        else {            
            this._downloadFile(this.downloadUri, true);
        }
    }

    private _startDownloadAsync(useBlob: boolean = false): void {
        this._releaseBlob();

        let requestDetails: FileDownloadRequestDetails = {
            method: 'GET',
            url: this.downloadUri
        };
        let requestResult = this._fileDownloadService.sendRequestAsync(requestDetails, useBlob);
        let control = this;

        if (this.handleRequestResult) {
            requestResult.then(result => {
                control._finishDownloadAsync(result);
            }).catch(result => {
                control._finishDownloadAsync(result);
            });
            this.downloadAsyncBeginEvent.emit({delegateRequestHandling: false, requestResult: null});
        }
        else {
            this.downloadAsyncBeginEvent.emit({delegateRequestHandling: true, requestResult: requestResult});
        }
    }

    private _finishDownloadAsync(requestResult: FileDownloadRequestResult): void {
        if (requestResult) {
            if (requestResult.success) {
                this._downloadFile(requestResult.blobUri, this.downloadBlobToIframe);
            }

            this.downloadAsyncEndEvent.next({
                success: requestResult.success,
                blobUri: null,
                httpStatus: requestResult.httpStatus,
                errorMessage: requestResult.errorMessage
            });
        }
    }

    private _downloadFile(uri: string | Blob, useIframe: boolean): void {
        if (typeof uri === 'string') {
            let nativeElement = this.downloadLinkElement ? this.downloadLinkElement.nativeElement : null;
            this._renderer.setAttribute(nativeElement, 'href', uri);

            if (useIframe) {
                this._renderer.setAttribute(nativeElement, 'target', this.iframeName);
            }
            else {
                this._renderer.setAttribute(nativeElement, 'download', this.downloadName);
            }
            // this._renderer.invokeElementMethod(nativeElement, 'click');
            (nativeElement as any)['click'].apply(nativeElement, );
        } else {
             // tslint:disable-next-line:no-any
            if ((window.navigator as any).msSaveOrOpenBlob) {
                // tslint:disable-next-line:no-any
                (window.navigator as any).msSaveOrOpenBlob(uri, this.downloadName);
            }
        }
    }

    private _releaseBlob(): void {
        if (this._blobUri) {
            URL.revokeObjectURL(this._blobUri);
            this._blobUri = null;
        }
    }
}

export interface DownloadAsyncBeginEvent {
    requestResult: Promise<FileDownloadRequestResult>;
    delegateRequestHandling: boolean;
}
