import { Permissions } from './../shared/enums/Permissions';
import { User } from './../models/user.model';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from
    '@angular/router';
import { AuthService } from './auth.service';
import { DBkeys } from './db-keys';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { map } from 'lodash';

@Injectable()
export class AuthGuard  {
    private loginRoute :string = '/login';
    private logoutRoute :string  = '/logout';
    private errorRoute :string  = '/error';
    private eulaRoute :string  = '/eula';
    private dashBoardRoute :string = './';
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const url = state.url;
        let routePermissions = route.data['permission'];
        return this.checkLogin(url, routePermissions);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    canLoad(route: Route): Observable<boolean> {

        const url = `/${route.path}`;
        return this.checkLogin(url, []);
    }

    private checkLogin(url: string, routePermissions: Permissions[]): Observable<boolean> {
        if ((localStorage.getItem(DBkeys.IsUserLoggedIn) !== undefined && localStorage.getItem(DBkeys.IsUserLoggedIn) !== null) || (localStorage.getItem(DBkeys.CURRENT_USER) !== undefined && localStorage.getItem(DBkeys.CURRENT_USER) !== null)) {

            if (url === this.loginRoute) {
                this.router.navigate([this.dashBoardRoute]);
                return Observable.of(false);
            }            
             let userData : User = this.authService.currentUser;
            if(userData && !userData.EulaAccepted && url !== '/logout') {
                this.router.navigate([this.eulaRoute]);
                return Observable.of(false);
            }

            if (routePermissions != undefined && userData != null) {
                if (routePermissions != undefined && routePermissions.length != 0 && routePermissions.length > 0 && (this.isUserHasPermission(routePermissions, userData.UserPermissions))) {
                    return Observable.of(true);
                } else {
                    this.router.navigate([this.dashBoardRoute]);
                    return Observable.of(false);
                }
            }
            else if (routePermissions != undefined) {
                return this.authService._isUserFullyLoggedIn.pipe(
                    filter(isLoggedIn => isLoggedIn), // Emit only true values
                    take(1), // Take the first true value and then complete
                    switchMap(() => {
                        return this.checkLogin(url, routePermissions);
                    })
                );
            }
            else
            {
                return Observable.of(true); 
            }
        }
        else {
            if (url.indexOf(this.logoutRoute) !== -1 || url.indexOf(this.errorRoute) !== -1) {
                return Observable.of(true);
            }
            this.authService.loginRedirectUrl = url;
            this.authService.shouldRedirectUserToLoginPage.next(true);
            return Observable.of(false);
        }
    }
    private isUserHasPermission(routepermission: Permissions[], userpermissions: Permissions[]): boolean {
        return userpermissions.filter(perm => routepermission.find(f => f === perm)).length > 0;
    }
}
