export class GSuiteParams {
    
    constructor() {
        this.gsuiteName = null;
        this.EventType = 0;
        this.SelectedDomainIds = [];
        this.SelectedDomainReferenceIds = [];
    }
    public gsuiteName:string;

    public EventType: number;

    public SelectedDomainIds: number[];

    public SelectedDomainReferenceIds: string[];

}