import {NgModule} from '@angular/core';
import {TabSwitcher} from './tabs-switcher.component';
import {Tab} from './tab/tab.component';
import {VerticalTabSwitcher} from './vertical-tab-switcher/vertical-tab-switcher.component';
import {CoreCommonModule} from '../../core-common.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateLanguageLoader } from 'src/app/services/app-translation.service';

@NgModule({
    imports: [
        CoreCommonModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useClass: TranslateLanguageLoader
            }
          }),
    ],
    declarations: [
        TabSwitcher,
        Tab,
        VerticalTabSwitcher
    ],
    exports: [
        TabSwitcher,
        Tab,
        VerticalTabSwitcher
    ]
})
export class SharedTabSwitcherModule {

}