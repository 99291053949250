/**
 * Created by jokim on 5/16/2017.
 */

import {Directive, ElementRef, HostListener} from '@angular/core';
import { KeyCodeEnum } from '../../enums/KeyCodeEnum';

@Directive({
    selector: '[number-only]'
})
export class NumberOnlyDirective {

    constructor(private _elementRef: ElementRef) { }

    @HostListener('keydown', ['$event'])
    public onKeydown(evt: KeyboardEvent): void {

        if (KeyCodeEnum.BACKSPACE == evt.keyCode ||
            KeyCodeEnum.TAB == evt.keyCode ||
            KeyCodeEnum.ENTER == evt.keyCode ||
            KeyCodeEnum.DELETE == evt.keyCode ||
            (!evt.shiftKey && KeyCodeEnum.KEY_0 <= evt.keyCode && evt.keyCode <= KeyCodeEnum.KEY_9) ||
            (!evt.shiftKey && evt.keyCode >= KeyCodeEnum.NUMPAD_0 && evt.keyCode <= KeyCodeEnum.NUMPAD_9 ) ||
            (KeyCodeEnum.END <= evt.keyCode && evt.keyCode <= KeyCodeEnum.DOWN_ARROW)) { return; }

        evt.preventDefault();
    }
}
