import {Directive, ElementRef, Renderer2, Input, SimpleChanges, OnChanges} from '@angular/core';

/**
 * Directive used to automatically focus a input element whenever it comes into view.
 * 
 * @export
 * @class SetFocusDirective
 */
@Directive({
    selector: '[set-focus]'
})
export class SetFocusDirective implements OnChanges {
    @Input('set-focus')
    public setFocus: boolean;

    private get _applyFocus() {
        if (typeof(this.setFocus) !== 'boolean') {
            return true;
        }

        return this.setFocus;
    }

    constructor(private _el: ElementRef, private _renderer: Renderer2) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this._applyFocus) {
            return;
        }

        this._setFocus();
    }

    private _setFocus() {
        const element = this._renderer.selectRootElement(this._el.nativeElement);
        setTimeout(() => element.focus(), 200);
        // wait 200ms before setting the focus.
        // setTimeout(() => {
        //     this._renderer.invokeElementMethod(this._el.nativeElement, 'focus');
        // }, 200);
    }
}