import { NgModule } from '@angular/core';
import { CopyrightControl } from './copyright.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateLanguageLoader, AppTranslationService } from '../../services/app-translation.service';
import { CoreCommonModule } from 'src/app/shared/core-common.module';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CoreCommonModule,
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        })
    ],
    declarations: [
        CopyrightControl
    ],
    exports: [
        CopyrightControl
    ]
})
export class CopyrightModule {

}