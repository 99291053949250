import {
    Component,
    Injectable,
    ComponentRef,
    ViewChild,
    OnInit,
    OnDestroy, ViewContainerRef,
    ComponentFactoryResolver
} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';

import {NewModal, CloseModal} from '../modal-dialog-model/modal-dialog.model';
import {ModalDialogService} from '../modal-dialog-service/modal-dialog.service';

@Component({
    selector: 'soti-modal',
    templateUrl: './modal-dialog.component.html',
})
export class ModalDialog implements OnInit, OnDestroy {
    public input: any;
    public component: any;

    @ViewChild('modalComponent', {read: ViewContainerRef})
    private _modalComponent: ViewContainerRef;

    private _componentRef: ComponentRef<any>;
    private _modalDataStream: Subscription;

    constructor(private _dialogService: ModalDialogService, private _resolver: ComponentFactoryResolver) {
    }

    public ngOnInit() {
        this._modalDataStream = this._dialogService.modalData.subscribe(modal => {
            if (modal instanceof NewModal) {
                this.component = modal.component;
                this.input = modal.input;
                this._loadComponent();
            } else if (modal instanceof CloseModal) {
                this._close();
            }
        });
    }

    public ngOnDestroy() {
        this._modalDataStream.unsubscribe();
    }

    /**
     * Loads the referenced component dynamically
     *
     * @private
     */
    private _loadComponent() {
        let factory = this._resolver.resolveComponentFactory(this.component);
        this._componentRef = this._modalComponent.createComponent(factory, 0, this._modalComponent.injector);
        this._componentRef.instance.input = this.input;
    }

    /**
     * Destroys the loaded component when the dialog is closed
     *
     * @private
     */
    private _close() {
        if (this._componentRef) {
            this._componentRef.destroy();
            this._componentRef = null;
        }
    }
}

