import {NgModule} from '@angular/core';
import {InlineSearchControl} from './inline-search.ctrl';
import {CoreCommonModule} from '../../core-common.module';

@NgModule({
    imports: [
        CoreCommonModule
    ],
    declarations: [InlineSearchControl],
    exports: [InlineSearchControl]
})
export class SharedInlineSearchModule {}