import { AlertSeverityEnum } from '../../enums/AlertSeverityEnum'
import { ClientMessage } from './ClientMessage';

export enum NotificationType {
    Success = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    Processing = 4
}

export class ClientNotificationMessage extends ClientMessage {    
    public constructor(
        public notificationTitle: string, 
        public notificationText: string, 
        public notificationType: NotificationType, 
        public notificationSeverity: AlertSeverityEnum,
        public notificationTimeout: number = 10000) {
        super();
    }
}