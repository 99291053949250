import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActionEnum } from 'src/app/shared/enums/ActionEnum';

@Injectable()
export class UserListService {
    public userListValueObserver : Subject<boolean> = new Subject<boolean>();
    public groupListValueObserver : Subject<boolean> = new Subject<boolean>();
    public roleListValueObserver: Subject<boolean> = new Subject<boolean>();
    public roleActionValueObserver: Subject<ActionEnum> = new Subject<ActionEnum>();
    constructor() {

    }
}
