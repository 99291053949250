import {
    Component,
    Output,
    EventEmitter,
    Renderer2,
    OnInit,
    OnDestroy,
    Input,
    ElementRef,
    AfterContentInit,
    AfterViewInit
} from '@angular/core';
import { logger } from '../../../utils/Logger';
import * as _ from 'lodash';

@Component({
    selector: 'mc-pop-up',
    templateUrl: './pop-up.ctrl.html',
    styleUrls: ['./pop-up.ctrl.scss']
})
/**
 *
 * @deprecated Please use PopUp2 (<soti-pop-up>)
 */
export class PopUpControl
    implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {
    public popup: HTMLElement;
    public window: Window;
    //tslint:disable:deprecation
    @Input('place-bottom') public placeBottom: boolean = false;

    @Input('position') public position: 'none' | 'left' | 'right' | '' = '';

    @Input('enable-resize') public enableResize: boolean = false;

    @Input('mode') public mode: 'modal' | 'modeless' = 'modal';

    @Input('resize-mode') public resizeMode: 'fixed' | 'content' = 'fixed';

    @Input('extra-spacing') public extraSpacing: number = 0;

    @Output('clickAway')
    public clickAway: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output('closed')
    private _closed: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _popupVisible: boolean = false;
    private _touchEventListener: Function;
    private _clickEventListener: Function;
    private _stopPropagation: boolean = false;

    constructor(
        private _renderer: Renderer2,
        private _nativeElement: ElementRef
    ) {
        //this._popupVisible = true;
    }

    public ngOnInit(): void {
        this.popup = this._nativeElement.nativeElement.querySelector('#pop-up');
        this.window = window;
        this._onResize();
    }

    public ngAfterViewInit(): void {
        // the following code is wrapped in 'requestAnimationFrame()' to make sure the event handlers
        // are registered after entire layout work is done. Otherwise, we handle the click on the
        // element that triggers pop-up too early and treat it as a 'click away' scenario, closing pop-up right away
        requestAnimationFrame(() => {
            // register touch events
            this._touchEventListener = this._renderer.listen(
                'document',
                'touchstart',
                (touchEvent: TouchEvent) => {
                    if (this._stopPropagation || this.mode === 'modeless') {
                        this._stopPropagation = false;
                        return;
                    }

                    this._onClick(touchEvent);
                }
            );
            this._clickEventListener = this._renderer.listen(
                'document',
                'click',
                (clickEvent: MouseEvent) => {
                    if (this._stopPropagation || this.mode === 'modeless') {
                        this._stopPropagation = false;
                        return;
                    }

                    this._onClick(clickEvent);
                }
            );
        });
    }

    public ngAfterContentInit(): void {
        if (!_.isEmpty(this.position)) {
            // Set the class of the component
            this._renderer.setAttribute(
                this._nativeElement.nativeElement,
                this.position,
                this.position
            );
        }
    }

    public ngOnDestroy(): void {
        // remove renderer listeners
        if (this._touchEventListener) {
            this._touchEventListener();
        }
        if (this._clickEventListener) {
            this._clickEventListener();
        }
        this._closed.emit(true);
    }

    public _onResize(): void {
        if (this.enableResize) {
            let delta = this.placeBottom ? 62 : 36;
            let viewportHeight: number = this.window.innerHeight;
            let popupHeight: number = this.popup.getBoundingClientRect().height;
            let popupBottom: number =
                this.popup.getBoundingClientRect().bottom +
                (delta + this.extraSpacing); // adding the margin
            let height =
                (popupHeight - (popupBottom - viewportHeight)).toString() +
                'px';


            if (this.resizeMode === 'fixed') {
                this.popup.style.height = height;
            } else if (this.resizeMode === 'content') {
                this.popup.style.maxHeight = height;
            }
        } else {
            this.popup.style.height = null;
        }
    }

    public _onClick(event: MouseEvent | TouchEvent): void {
        if (
            this._nativeElement.nativeElement !== event.target &&
            !this._nativeElement.nativeElement.contains(event.target)
        ) {
            this.clickAway.emit(true);
        }
    }

    public _blockClosePopup(event: MouseEvent | TouchEvent): void {
        this._stopPropagation = true;
    }
}
