import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, Output, Renderer2, ViewChild } from '@angular/core';
import { IntergratedApplicationTypes } from 'src/app/models/IntergratedApplicationTypes';
import { ApplicationExtensionUrl } from 'src/app/models/applicationExtensionUrl.model';
import { AuthService } from 'src/app/services/auth.service';
import { SotiThemeService } from 'src/app/services/soti-theme/soti-theme.service';
import { Utilities } from 'src/app/services/utilities';
import { ViewType } from 'src/app/shared/controls/listgrid-btn-switcher/listgrid-btn-switcher.ctrl';
import { ThemeType } from 'src/app/shared/enums/ThemeType';
import { DisposalBag } from 'src/app/shared/utils/DisposalBag';
import { Application } from '../../models/application.model';
import { ConfigurationService } from '../../services/configuration.service';
import { DBkeys } from '../../services/db-keys';
import { FileService } from '../../services/file-service/file.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { ApplicationService } from './../../services/application-services/application.service';
import { ModalDialogService2, PopUpTrigger } from 'src/app/shared/controls/dialogs';
import { ChangeDefaultXsightServerModal, ChangeModalParam } from '../DefaultXsightServerManagement/Modal/change-default-xsight-server-modal/change-default-xsight-server-modal.ctrl';
import { IntegratedApplicationOptions } from '../ApplicationManagement/Modals/add-local-application/add-local-application-modal';
 

@Component({
    selector: 'im-dashboard-application',
    templateUrl: './dashboard-application.component.html',
    styleUrls: ['./dashboard-application.component.scss']
})

export class DashboardApplicationComponent implements OnDestroy, AfterViewInit {


    public noImage;
    @Input()
    public soTheme: boolean = true;
    @Input() public item: Application;
    @Input() public viewType: number = ViewType.Tile;
    @ViewChild('tileViewParentDiv') tileViewParentDiv: ElementRef;
    @HostBinding('class.clicked') clickedClass = false;
    @ViewChild('popUpTrigger')
    public threeDotPopup: PopUpTrigger;

    listViewType: number = ViewType.List;
    tileViewType: number = ViewType.Tile;

    public image: any;
    public appNameFirstword: string;
    public appNameOtherWord: string;
    public namePart1: string;
    public namePart2: string;
    public isDarkTheme:boolean = false;
    public appicationExtensionUrls: ApplicationExtensionUrl[];
    public show3Doticon: boolean = false;
    public elementToFocus: HTMLElement;

    public intergratedApplicationTypes: typeof IntergratedApplicationTypes = IntergratedApplicationTypes;
    private _disposalBag: DisposalBag = new DisposalBag();

    constructor(
        private localStorage: LocalStoreManager,
        private fileService: FileService,
        public authService: AuthService,
        protected configurations: ConfigurationService,
        private applicationService: ApplicationService,        
        private _sotiThemeService: SotiThemeService,
        private _modalDialogService: ModalDialogService2,
        private renderer: Renderer2
    ) {
    }

    public ngOnInit(): void {
        this.appicationExtensionUrls = this.item.ApplicationExtensionUrls;
        this.show3Doticon = (this.item.ApplicationType == IntergratedApplicationTypes.SotiXsight && this.item.ApplicationExtensionUrls?.length > 1);
        this.getAppLogoByType(this.item.ApplicationType);
        this.getResource(this.item.ThumbnailURL);
        this.splitName();
        this._disposalBag.nextSub = this._sotiThemeService.selectedTheme.subscribe((val) => {
            this.isDarkTheme = val === ThemeType.DARK;
        });
    }

    ngAfterViewInit(): void {
        const tileDiv = this.tileViewParentDiv.nativeElement;
        this.renderer.listen('document', 'click', () => {
        const childrenWithClass = tileDiv.querySelectorAll('.clicked');
        this.clickedClass = childrenWithClass.length > 0 && (window.innerWidth > 1024 || window.innerWidth < 268); //excluded for tablet and mobile screens
    });
    
    this.renderer.listen('document', 'keydown', (event) => {
        const childrenWithClass = tileDiv.querySelectorAll('.clicked');
        this.clickedClass = childrenWithClass.length > 0 && (window.innerWidth > 1024 || window.innerWidth < 268);
          });
    }
    public ngOnDestroy(): void {
        this._disposalBag.dispose();
    }
    
    public getResource(url: string): void {
        if (url && url.indexOf(this.configurations.baseUrl) >= 0) {
            this.fileService.getResource(url).subscribe((data) => {
                this.image = 'data:image/png;base64,' + data;
            });
        } else {
            this.image = url;
        }
    }

    public onAppClick(applicationReferenceId: string,event): void {
        if (event.target.className.includes("app_popup") || event.target.parentElement.className.includes("app_popup"))
        {
            return;
        }
        if (!this.authService.currentUser?.EulaAccepted) {
            this.reloadPage();
        }
        else if(this.item.ApplicationType == this.intergratedApplicationTypes.SotiXsight) {
            this.applicationService.xsightAccessLog(this.item.Name, this.item.RedirectionUrl).subscribe(response => {});
        }
        else if(this.item.ApplicationType != this.intergratedApplicationTypes.SotiAssist) {
             this.applicationService.ApplicationAccess(this.item.ReferenceId).subscribe(response => {
                 // This is intentional           
             }, error => {
                 // This is intentional
             });
         }
        
        if (this.item.ApplicationType == this.intergratedApplicationTypes.SotiXsight) {
            applicationReferenceId  = this.item.RedirectionUrl;
        }
        let url = window.location.protocol + '//' + window.location.host + '/application-redirect?applicationReferenceId=' + applicationReferenceId;

        while (this.localStorage.getData(DBkeys.READLOCK + "APPLICATION_REDIRECT" + applicationReferenceId));
        this.localStorage.saveSessionData(1, DBkeys.READLOCK + "APPLICATION_REDIRECT" + applicationReferenceId);
        let previousRedirectInfo = this.localStorage.getData(DBkeys.APPLICATION_REDIRECT_INFO + applicationReferenceId)
        if (previousRedirectInfo != null && previousRedirectInfo.InstanceCount != 0) {
            this.item.InstanceCount = previousRedirectInfo.InstanceCount + 1;
        }
        else {
            this.item.InstanceCount = 1;
        }
        this.localStorage.saveSessionData(this.item, DBkeys.APPLICATION_REDIRECT_INFO + applicationReferenceId);
        this.localStorage.deleteData(DBkeys.READLOCK + "APPLICATION_REDIRECT" + applicationReferenceId);
        window.open(url, '_blank');
    }

    public reloadPage(): void {
        window.location.reload();
    }

    public checkForTrialText(name: string): string {
        if (name.toLowerCase().indexOf('central') < 0) {
            return ' and request a trial';
        }
        return '';
    }

    public redirectXsight(url: string): void {
        window.open(url, '_blank');
        this.threeDotPopup.close();
    }

    public changeDefaultServer()
    {
        if (this.show3Doticon)
        {
            this.threeDotPopup.close();
            let cardAppContainer = document.getElementsByClassName('card-box-inner');
            let listAppContainer = document.getElementsByClassName('list-view');
            if(cardAppContainer.length > 0)
            {
                this.elementToFocus = cardAppContainer[0] as HTMLElement;
            }
            else if(listAppContainer.length > 0)
            {
                this.elementToFocus = listAppContainer[0] as HTMLElement;
            }
            let params = new ChangeModalParam(this.soTheme);
            params.application = this.item;
            let dialogRef = this._modalDialogService.open(ChangeDefaultXsightServerModal, params, this.elementToFocus);
            dialogRef.closed().subscribe((result: any) => {
                if(result){
                    let selectedServer = this.item.ApplicationExtensionUrls.find(x=> x.Id == result);
                    this.item.XsightDefaultPreferenceRedirectionUrl = selectedServer?.Url;
                    this.item.XsightDefaultPreferenceRedirectionUrlName = selectedServer?.Name;
                    this.splitName();
                }
              });
        }
    }

    private getAppLogoByType(applicationType: number): string {

        switch (applicationType) {
            case IntergratedApplicationTypes.SotiSnap:
                this.noImage = Utilities.getSnapSvgImage();
                return;
            case IntergratedApplicationTypes.SotiInsight:
                this.noImage = Utilities.getInsightSvgImage(); 
                return;
            case IntergratedApplicationTypes.SotiCentral:
                this.noImage = Utilities.getCentralSvgImage();
                return;
            case IntergratedApplicationTypes.MobiControl:
                this.noImage = Utilities.getMobicontrolSvgImage();
                return;
            case IntergratedApplicationTypes.SotiConnect:
                this.noImage = Utilities.getConnectSvgImage(); 
                return;
            case IntergratedApplicationTypes.SotiAssist:
                this.noImage = Utilities.getAssistSvgImage();
                return;
            case IntergratedApplicationTypes.SotiXsight:
                this.noImage = Utilities.getXsightSvgImage();
                return;
            default:
                this.noImage = Utilities.getDefaultAppSvgImage();
        }
    }

    private splitName(): void {
        if ( this.item.Name && this.item.Name.length > 1 )
        {
            let appName
            if ( this.item.ApplicationType == IntergratedApplicationTypes.SotiXsight)
            {
                appName = this.item.XsightDefaultPreferenceRedirectionUrlName && this.item.XsightDefaultPreferenceRedirectionUrlName.length > 0 ? this.item.XsightDefaultPreferenceRedirectionUrlName : this.item.Name
            }
            else
            {
                appName = this.item.Name
            }
            if ( appName && appName.length > 1) {
                let splittedName = appName.split(' ');
                if (splittedName.length > 1) {
                    this.namePart1 = splittedName[0].length < 15 ? splittedName[0] : splittedName[0].substring(0, 14);
                    this.namePart2 = appName.substring(this.namePart1.length, appName.length);
                } else {
                    this.namePart1 = splittedName[0];
                    this.namePart2 = "";
                }
            }
        }
    }
    
}
