import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ApplicationListService {
    public applicationListValueObserver : Subject<boolean> = new Subject<boolean>();

    constructor() {

    }
}
