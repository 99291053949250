
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SotiThemeService } from 'src/app/services/soti-theme/soti-theme.service';
import { DisposalBag } from 'src/app/shared/utils/DisposalBag';
import { Application } from 'src/app/models/application.model';
import { XsightDefaultUrlPreferenceModel } from 'src/app/models/XsightDefaultUrlPreferenceModel';
import { ApplicationService } from 'src/app/services/application-services/application.service';
import { appMessenger } from 'src/app/shared/controls/toaster-messages/AppMessenger';
import { ClientNotificationMessage, NotificationType } from 'src/app/shared/utils/toast-utils/ClientNotificationMessage';
import { AlertSeverityEnum } from 'src/app/shared/enums/AlertSeverityEnum';
import { AppTranslationService } from 'src/app/services/app-translation.service';

@Component({
    selector: 'change-default-xsight-server',
    templateUrl: './change-default-xsight-server.ctrl.html',
    styleUrls: ['./change-default-xsight-server.ctrl.scss']
})

export class ChangeDefaultXsightServerComponent implements OnInit, OnDestroy {

    @Input()
    public changeButtonText:string;

    @Input()
    public application:Application;

    @Output() 
    public onClose = new EventEmitter<number>();

    public showLoader: boolean = false;

    public defaultIdSelected: number;
    
    private _disposalBag: DisposalBag = new DisposalBag();

    constructor(
        private applicaitonService: ApplicationService,
        private themeService: SotiThemeService,
        private translationService: AppTranslationService
        ) {
    }

    public ngOnInit(): void {
        this.defaultIdSelected = this.application.ApplicationExtensionUrls.find(x=> x.IsPrimary == true)?.Id
        for (let value of this.application.ApplicationExtensionUrls) {
            if (value.Url === this.application.XsightDefaultPreferenceRedirectionUrl && value.Name === this.application.XsightDefaultPreferenceRedirectionUrlName ) {
                this.defaultIdSelected = value.Id;
                break;
            }
        }
    }
    
    public ngOnDestroy(): void {
        this._disposalBag.dispose();
    }

    public changeDefaultXsightServer()
    {
        let selectedServer = this.application.ApplicationExtensionUrls.find(x=> x.Id == this.defaultIdSelected)
        let defaultXsightdata: XsightDefaultUrlPreferenceModel = {
            Name: this.application.Name,
            Url: this.application.RedirectionUrl,
            DefaultUrl: selectedServer?.Url,
            DefaultName: selectedServer?.Name
        };
        this.showLoader = true;
        this.applicaitonService.updateDefaultXsightServer(defaultXsightdata).subscribe(() => {
            this.showLoader = false;
            appMessenger.postMessage(new ClientNotificationMessage(
                this.translationService.getTranslation('mgs_xsightDefaultLink_changed_successfully'),
                this.translationService.getTranslation('mgs_xsightDefaultLink_changed_successfully'),
                NotificationType.Success,
                AlertSeverityEnum.Minor));
            this.onClose.emit(this.defaultIdSelected)
        }, error => {
            let message = this.translationService.getTranslation('msg_Somethingwentworng');
            appMessenger.postMessage(new ClientNotificationMessage(this.translationService.getTranslation('msg_Error'),
              message,
              NotificationType.Error,
              AlertSeverityEnum.Minor));
              this.onClose.emit(this.defaultIdSelected)
          });
    }

    public backClicked()
    {
        this.onClose.emit();
    }
}
