
export class ContactUsMessage {

    public CreatedBy: number;

    public CompanyId: number;

    public CreateOn: string;

    public Id : number;

    public Message: string = '';


}