 
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';


import { ChangePasswordModal } from 'src/app/components/PasswordManagement/Modals/change-password-modal/change-password-modal.ctrl';
import { PreferencesModal, PreferencesModalParam } from 'src/app/components/PreferencesManagement/Modals/preferences-modal/preferences-modal.ctrl';
import { ProfileModal, ProfileModalParam } from 'src/app/components/ProfileManagement/Modals/profile-modal/profile-modal.ctrl';
import { LocalizationModalComponent } from "src/app/components/localization/Modals/localization-modal/localization-modal.component";
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BrowserUtilsService } from "src/app/services/browserUtil/browserUtils.service";
import { ModalDialogService2 } from '../../controls/dialogs';
import { UserTypeEnum } from '../../enums/UserTypeEnum';
import { DisposalBag } from "../../utils/DisposalBag";


@Component({
    selector: 'profile-menu',
    templateUrl: './profile-menu.view.html',
    styleUrls: ['./profile-menu.view.scss']
})

export class ProfileMenuView implements OnInit {

    @Input()
    public showPopUpFlag: boolean;

    @Output()
    public showPopUpFlagChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public soTheme: boolean;

    @Input()
    public elementToFocus: HTMLElement;

    public userType: UserTypeEnum;
    public userName: string = '';
    public userInitials: string = '';
    public SupportAccountLogin: boolean = false;
    public isMobileView: boolean;
    private _disposalBag: DisposalBag = new DisposalBag();
    constructor(private router: Router,
        private authService: AuthService,
        private _modalDialogService: ModalDialogService2,
        private _browserUtilService: BrowserUtilsService,

    ) {
        this.isMobileView = this._browserUtilService.isMobileOrTablet();
    }

    public ngOnInit(): void {
        if (this.authService.currentUser !== undefined || this.authService.currentUser !== null) {
            let currentUser: User = this.authService.currentUser;
            this.userType = currentUser.UserType;            
        }
    }

    public openprofile(): void {
        if (!this.isMobileView) {
            this._modalDialogService.open(ProfileModal, new ProfileModalParam(this.soTheme), this.elementToFocus);
            this.showPopUpFlag = !this.showPopUpFlag;
        }
        else {
           this.router.navigateByUrl('/profile');
           this.showPopUpFlag = !this.showPopUpFlag;
        }        
         this.showPopUpFlagChange.emit(this.showPopUpFlag);
     }
     public openPreferences(): void {
        if (!this.isMobileView) {
            this._modalDialogService.open(PreferencesModal, new PreferencesModalParam(this.soTheme), this.elementToFocus);
            this.showPopUpFlag = !this.showPopUpFlag;
        } 
        this.showPopUpFlagChange.emit(this.showPopUpFlag);
     }
     public openlocalization(): void {
        if (!this.isMobileView) { 
            this._modalDialogService.open(LocalizationModalComponent, new ProfileModalParam(this.soTheme));
            this.showPopUpFlag = !this.showPopUpFlag;
        }
        else {
           this.router.navigateByUrl('/language');
           this.showPopUpFlag = !this.showPopUpFlag;
        }        
         this.showPopUpFlagChange.emit(this.showPopUpFlag);
     }

     public openPortalOptions(): void {
        this.router.navigateByUrl('/portal-options');
        this.showPopUpFlag = !this.showPopUpFlag;       
         this.showPopUpFlagChange.emit(this.showPopUpFlag);
     }

     public showChangePassword(): boolean {
        if(this.authService.currentUser.IsSupportAccountLogin){
            this.SupportAccountLogin = true;
        }

        if (this.userType === UserTypeEnum.DBUSER && !this.SupportAccountLogin) {
            return true;
        } else {
            return false;
        }
    }

    public isMspAdminAccess(): boolean {
        if (this.userType === UserTypeEnum.MSPTENANTREFERENCEDUSER){
            return true;
        }
        return false;
    }

    public openAuthentication(view: string): void {
        if (!this.isMobileView) {
            this._modalDialogService.open(ChangePasswordModal, new ProfileModalParam(this.soTheme), this.elementToFocus);
            this.showPopUpFlag = !this.showPopUpFlag;
        }
        else {
           this.router.navigateByUrl('/authentication');
           this.showPopUpFlag = !this.showPopUpFlag;
        }
        this.showPopUpFlagChange.emit(this.showPopUpFlag);
    }
    public closePopup(): void {
        this.showPopUpFlagChange.emit(false);
    }

    logout() {
        this.authService.redirectToLogoutUrl();
        this.userName = '';
        this.showPopUpFlag = !this.showPopUpFlag;
        this.showPopUpFlagChange.emit(this.showPopUpFlag);
    }

}