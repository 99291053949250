import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { BulkOperationNotification } from 'src/app/models/bulk-operation-notification.model';
import { DownloadEventLogNotification } from 'src/app/models/download-eventLog-notification.model';
import { NotificationKindEnum } from 'src/app/shared/enums/NotificationKindEnum';
import { NotificationStatusEnum } from 'src/app/shared/enums/NotificationStatusEnum';
import { AuthService } from '../auth.service';
import { ConfigurationService } from '../configuration.service';
import { NotificationService } from '../notification.service';  
@Injectable()  
export class FedratedLogoutService {  
  
  private _hubConnection: HubConnection;  
  
  constructor(
    private configurations: ConfigurationService, 
    private authService: AuthService,
    private notificationService: NotificationService) {  
    
  }  
  
  public createConnection(token: string): void {    
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(this.configurations.baseUrl + 'federatedlogout' 
     ).withAutomaticReconnect([1000, 5000, 15000, 30000, 60000, 120000, 300000, 600000, 900000, null])
     .configureLogging(LogLevel.Information)
      .build(); 
      
      this._hubConnection 
      .start()  
      .then(() => {  
        this._hubConnection.invoke('Register', token); 
      });

      this._hubConnection.on('Logout', (data) => {          
        this.authService.ssoLogout();
      });

      this._hubConnection.on('ForceLogout', (data) => {
        this.authService.redirectToLogoutUrl();
      });
      this._hubConnection.on('Notification', (data) => {        
        if (data) {
          let simpleNotification: BulkOperationNotification = new BulkOperationNotification();
          simpleNotification.id = data['id'];
          simpleNotification.Kind = NotificationKindEnum.USER;;
          simpleNotification.text = data['text'];
          simpleNotification.title = data['title'];
          simpleNotification.Status = this.setStatus(data['status']);
          simpleNotification.translated = data['translated'];
          simpleNotification.createdOn = new Date(data['createdOn']);
          simpleNotification.totalRecords = parseInt(data['data'].TotalRecords);
          simpleNotification.successRecords = parseInt(data['data'].SuccessRecords);
          simpleNotification.failedRecords = parseInt(data['data'].FailureRecords);
          simpleNotification.batchExecuted = parseInt(data['data'].BatchExecuted);
          simpleNotification.totalBatches = parseInt(data['data'].TotalBatches);
          simpleNotification.followUpUri =data['data'].ErrorFilePath;
          this.notificationService.addNotification(simpleNotification);
        }          
      });

      this._hubConnection.on('FileDownloadNotification', (data) => {
        if (data) {
          let simpleNotification: DownloadEventLogNotification = new DownloadEventLogNotification();
          simpleNotification.id = data['id'];
          simpleNotification.Kind = NotificationKindEnum.USER;
          simpleNotification.text = data['text'];
          simpleNotification.title = data['title'];
          simpleNotification.Status = this.setStatus(data['status']);
          simpleNotification.translated = data['translated'];
          simpleNotification.createdOn = new Date(data['createdOn']);
          simpleNotification.followUpUri =data['data'].FileDownloadPath;
          simpleNotification.fileName =data['data'].FileName;
          this.notificationService.addNotification(simpleNotification);
        }          
      });
      
  } 
  private setStatus(status: string): NotificationStatusEnum {
    status = status.toLowerCase();
    if (status === 'success') {
      return NotificationStatusEnum.Success;
    } else if (status === 'failure') {
      return NotificationStatusEnum.Error;
    } else if (status === 'inprogress') {
      return NotificationStatusEnum.InProgress;
    } else {
      return NotificationStatusEnum.Info;
    }
  }
}  
