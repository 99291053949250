
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../configuration.service';
import { EndpointFactory } from '../endpoint-factory.service';
import { Observable } from 'rxjs/Observable';
import { AppTranslationService } from '../app-translation.service';


@Injectable()
export class EulaEndpointService extends EndpointFactory {
    private  _eulaTextLocation: string;
   
    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector, private translationService: AppTranslationService) {
        super(http, configurations, injector);
    }

    public getEulaFileOnLanguageChange(lang: string){
        if(lang == 'ja-JP'){
            this._eulaTextLocation = 'assets/htmltemplates/eula.ja-JP.html'; 
        }
        else{
            this._eulaTextLocation = 'assets/htmltemplates/eula.html';
        }
    }

    public getEulaText(lang: string): Observable<string> {
        this.getEulaFileOnLanguageChange(lang);
        return this.http.get(this._eulaTextLocation, {responseType: 'text'});
    }
}
