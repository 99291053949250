import {Component, HostBinding, Input} from '@angular/core';
import {CdkAccordion} from '@angular/cdk/accordion';

@Component({
    selector: 'im-accordion',
    templateUrl: './accordion.ctrl.html',
    styleUrls: ['./accordion.ctrl.scss']
})
export class AccordionCtrl extends CdkAccordion {

    @Input()
    @HostBinding('class.card')
    public styleAsCard: boolean = false;

    @Input()
    public displayMode: 'spaced' | 'flat' = 'spaced';

    constructor() {
        super();
    }

}