
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppErrorHandler } from '../../app-error.handler';
import { getBaseUrl } from '../../app.module';
import { AppTranslationService, TranslateLanguageLoader } from '../../services/app-translation.service';
import { DomainEndpoint } from '../../services/domain-services/domain-endpoint.service';
import { DomainService } from '../../services/domain-services/domain-service';
import { SharedInputIconModule } from '../../shared/controls/input-icon/shared-input-icon.module';

import { CommonModule } from '@angular/common';
import { ErrorsmsgModule } from 'src/app/shared/controls/error-msg/error-msg.module';
import { TextEqualityValidatorModule } from '../../directives/TextEqualityValidatorModule';
import * as successMsgModule from '../../shared/controls/success-msg/success-msg.module';
import { CoreCommonModule } from '../../shared/core-common.module';
import { ResendTokenModule } from '../ResendToken/resend-token.module';
import { CopyrightModule } from '../copyright/copyright.module';
import { VerifyDomainRoutingModule } from './verify-domain-routing.module';
import { VerifyDomainComponent } from './verify-domain.component';

@NgModule({

    imports: [
        CommonModule,
        FormsModule,
        SharedInputIconModule,
        successMsgModule.SuccessmsgModule,
        ResendTokenModule,
        TextEqualityValidatorModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        CoreCommonModule,
        CopyrightModule,
        successMsgModule.SuccessmsgModule,
        ErrorsmsgModule,
        VerifyDomainRoutingModule
     ],
    declarations:
     [
        VerifyDomainComponent

    ],
    exports: [
        VerifyDomainComponent
    ],
     providers: [
        { provide: 'BASE_URL', useFactory: getBaseUrl },
        { provide: ErrorHandler, useClass: AppErrorHandler },

        AppTranslationService,
        DomainService,
        DomainEndpoint
    ],
})
export class VerifyDomainModule {
}
