import {NgModule} from '@angular/core';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {AccordionCtrl} from './accordion.ctrl';
import {ExpansionPanelCtrl} from './expansion-panel';
import {ExpansionPanelHeaderCtrl} from './expansion-panel-header';
import {CoreCommonModule} from '../../core-common.module';
import { ExpansionPanelRowCtrl } from './expansion-panel-row/expansion-panel-row.ctrl';

@NgModule({
    imports: [
        CoreCommonModule,
        CdkAccordionModule
    ],
    declarations: [
        AccordionCtrl,
        ExpansionPanelCtrl,
        ExpansionPanelHeaderCtrl,
        ExpansionPanelRowCtrl
    ],
    exports: [
        AccordionCtrl,
        ExpansionPanelCtrl,
        ExpansionPanelHeaderCtrl,
        ExpansionPanelRowCtrl
    ],
    providers: []
})
export class SharedAccordionModule {
}
