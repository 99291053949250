import { FilteringParams } from 'src/app/models/filteringparams';
import { DomainStatusEnum } from 'src/app/shared/enums/DomainStatusEnum';
export class FilterDomain {
    public Params : FilteringParams;
    public AllDomains: boolean;
    public Status: DomainStatusEnum[];
    
    constructor() {
        this.Status = [];
    }
}