export interface IQueueClient {
    add(action: Function, priority?: QueueOperationPriority): Promise<void>;
}
export enum QueueOperationPriority {
    High,
    Low
}

export interface IQueueManager {
    build(): IQueueClient;
}

class QueueClient implements IQueueClient {

    private _raf: (i: Function) => number;
    private _highPriorityPromise: Promise<void>;
    private _lowPriorityQueue: Function[];
    private _highPriorityQueue: Function[];
    private _pendingOperations: boolean;
    private _pendingResolve: boolean;

    public constructor() {
        if (typeof (window) !== 'undefined') {
            this._raf = window.requestAnimationFrame;
        }
        this._lowPriorityQueue = [];
        this._highPriorityQueue = [];
        this._pendingOperations = false;
        this._pendingResolve = false;
        this._highPriorityPromise = Promise.resolve();
    }

    public add(action: Function, priority: QueueOperationPriority = QueueOperationPriority.Low): Promise<void> {
        return new Promise(res => {
            if (priority === QueueOperationPriority.High) {
                this._highPriorityQueue.push(action);

                if (!this._pendingResolve) {
                    this._pendingResolve = true;
                    this._highPriorityPromise.then(() => { this._processHighPriorityQueue(); });
                }
            } else {
                this._lowPriorityQueue.push(action);

                if (!this._pendingOperations) {
                    this._pendingOperations = true;
                    this._raf(() => { this._processQueues(); });
                }
            }
            res();
        });
    }

    private _processHighPriorityQueue(): void {
        while (this._highPriorityQueue.length > 0) {
            this._highPriorityQueue.shift()();
        }
        this._pendingResolve = false;
    }

    private _processQueues(): void {
        const start = Date.now();

        this._processHighPriorityQueue();

        while (this._lowPriorityQueue.length > 0 && (Date.now() - start < 40)) {
            this._lowPriorityQueue.shift()();
        }

        if (this._pendingOperations = (this._lowPriorityQueue.length > 0)) {
            this._raf(() => { this._flushQueue(); });
        }
    }

    private _flushQueue(): void {
        this._processHighPriorityQueue();

        const start = Date.now();
        while (this._lowPriorityQueue.length > 0 && (Date.now() - start < 4)) {
            this._lowPriorityQueue.shift()();
        }

        if (this._pendingOperations = (this._lowPriorityQueue.length > 0)) {
            this._raf(() => { this._processQueues(); });
        }
    }
}

// Super simple for now, but it would nice to actaully add some 'management' possibilities 
// later on
class QueueManager implements IQueueManager {
    public build(): IQueueClient {
        return new QueueClient();
    }
}

export const queueManager: IQueueManager = new QueueManager();