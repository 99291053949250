import { Component, EventEmitter, HostBinding, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { UserPreferenceModel } from 'src/app/models/UserPreference.model';
import { Language } from 'src/app/models/language.model';
import { AccountService } from 'src/app/services/account-services/account.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { UserService } from 'src/app/services/user-services/user.service';
import { appMessenger } from 'src/app/shared/controls/toaster-messages/AppMessenger';
import { AlertSeverityEnum } from 'src/app/shared/enums/AlertSeverityEnum';
import { UserPreferenceType } from 'src/app/shared/enums/UserPreferenceType';
import { ISelectionChangeArgs } from 'src/app/shared/soti-node-modules/select-box';
import { ClientNotificationMessage, NotificationType } from 'src/app/shared/utils/toast-utils/ClientNotificationMessage';
import { BrowserUtilsService } from 'src/app/services/browserUtil/browserUtils.service';
import { ThemeType } from 'src/app/shared/enums/ThemeType';
import { SotiThemeService } from 'src/app/services/soti-theme/soti-theme.service';
import { DarkTheme, LightTheme } from 'src/app/services/soti-theme/theme';
import { DisposalBag } from 'src/app/shared/utils/DisposalBag';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTypeEnum } from 'src/app/shared/enums/LanguageTypeEnum';
import { UserPreferenceService } from 'src/app/services/user-preference-service/user-preference-service';




@Component({
  selector: 'app-localization-frm-section',
  templateUrl: './localization-frm-section.component.html',
  styleUrls: ['./localization-frm-section.component.scss']
})
export class LocalizationFrmSectionComponent implements OnInit, OnDestroy {
  public submitted: boolean;
  public sotionetheme: boolean;

  public titleName: string;

  public buttonText: string;

  public userId: string;
  public helpId: string;
  public languageList: Language[];
  public languageId: number;
  public pristine: boolean;
  public isMobileView: boolean;
  public isLoaded: boolean = false;
  
@HostBinding('class.dark-mode-bg')
public isDarkMode: boolean = false;
  public themeMode: UserPreferenceModel = {
    TypeId: UserPreferenceType.THEME,
    Preference: ThemeType.LIGHT
};
public lightThemeType: number = ThemeType.LIGHT;
public darkThemeType: number = ThemeType.DARK;
private _disposalBag: DisposalBag = new DisposalBag();
  @Output() 
    public onClose: EventEmitter<void> = new EventEmitter();

  constructor(
    private translationService: AppTranslationService,
    private userService: UserService,
    private accountService: AccountService,
    private _browserUtilService: BrowserUtilsService,
    private themeService: SotiThemeService,
    private translate: TranslateService,
    private userPreferenceService: UserPreferenceService
  ) {
    this.isMobileView = this._browserUtilService.isMobileOrTablet();

  }
  
  ngOnDestroy(): void {
    this.userPreferenceService.cleanUp();
  }

  ngOnInit(): void {
    this.pristine = true;
    this.accountService.getLanguageList().subscribe(result => {
      this.languageList = result;
    });    
    this.userService.getPreferences().subscribe(result => {
      this.languageId = result.find(x => x.TypeId == UserPreferenceType.LANGUAGE)?.Preference ?? 1;
      this.isLoaded = true;
    });
    this.sotionetheme = this.themeService._isSotiTheme;
        if (this.isMobileView && this.sotionetheme) {
            this.themeService.setTheme(new LightTheme());
            this.loadPreferences();
        }

  }
  public onLanguageChange(change: ISelectionChangeArgs): void {
    this.languageId = parseInt(change.value);
    this.pristine = false;
  }

  public changelanguageprefernce(): void {
    let languagepref = new UserPreferenceModel();
    languagepref.TypeId = UserPreferenceType.LANGUAGE;
    languagepref.Preference = this.languageId;
    this.userService.savePreferences([languagepref]).subscribe(() => {
      this.userPreferenceService.updateLanguage(this.languageId.toString());
      this.updateTranslationService(this.getLanguageCode(this.languageId));
      appMessenger.postMessage(new ClientNotificationMessage(
        this.translationService.getTranslation('mgs_language_changed_successfully'),
        this.translationService.getTranslation('mgs_language_changed_successfully'),
        NotificationType.Success,
        AlertSeverityEnum.Minor));
      this.closeModal()
      setTimeout(() => {
        this._browserUtilService.reload();
      }, 2000);
    }, error => {
      let message = this.translationService.getTranslation('msg_Somethingwentworng');
      appMessenger.postMessage(new ClientNotificationMessage(this.translationService.getTranslation('msg_Error'),
        message,
        NotificationType.Error,
        AlertSeverityEnum.Minor));
      this.closeModal()
      
    });

  }
  public closeModal(): void {
    this.onClose.emit();
  }
  public setTheme(themeType: ThemeType, savePreference: boolean = true): void {
    this.themeMode.Preference = themeType;
    if (themeType == ThemeType.LIGHT) {
        this.themeService.setTheme(new LightTheme());
        this.isDarkMode = false;
    } else {
        this.themeService.setTheme(new DarkTheme());
        this.isDarkMode = true;
    }
}
  public loadPreferences() {
    this.sotionetheme = this.themeService._isSotiTheme;
    this._disposalBag.nextSub = this.userService.getPreferences().subscribe((data) => {
        const themePreference = data.find(x => x.TypeId == UserPreferenceType.THEME);
        if (themePreference) {
            this.setTheme(themePreference.Preference, false);
        }
    });
}
private getLanguageCode(languageId: number): string {
  return languageId === LanguageTypeEnum.JAPANESE ? 'ja-JP' : 'en-US';
}

private updateTranslationService(language: string): void {
  this.translationService.changeLanguage(language);
  this.translate.use(language);
}
}


