import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalDialog } from './modal-dialog-component/modal-dialog.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ModalDialog],
    exports: [ModalDialog]
})
export class ModalDialogModule {
    
}
