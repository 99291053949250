import { Injectable } from "@angular/core";
import { UmbracoEndpoint } from "./umbraco-endpoint.service";
import { UmbracoPromotionalContent } from "src/app/models/umbracoPromotionalContent";
import { Observable } from "rxjs";

@Injectable()
export class UmbracoService {
    constructor(
        private umbracoEndpoint: UmbracoEndpoint
    ) { }


    public getPromotionalContent(contentType: string) : Observable<UmbracoPromotionalContent> {
        return this.umbracoEndpoint.getPromotionalContent(contentType);
    }
}