import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateLanguageLoader } from 'src/app/services/app-translation.service';
import { CoreCommonModule } from 'src/app/shared/core-common.module';
import { ListGridBtnSwitcherCtrl } from './listgrid-btn-switcher.ctrl';
@NgModule({
  declarations: [ListGridBtnSwitcherCtrl],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    }),
    CommonModule,
    CoreCommonModule
  ],
  exports: [
    ListGridBtnSwitcherCtrl
  ]
})
export class ListGridBtnSwitcherModule { }
