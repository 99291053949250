import {EventEmitter, Injectable, OnDestroy} from '@angular/core';

import {ModalDialogService} from '../modal-dialog';

export abstract class ModalBase {
    public abstract input: any;

    private _mService: ModalDialogService;

    constructor(modalService: ModalDialogService) {
        this._mService = modalService;
     }

    public abstract submit(data: any);
    
    public close() {
        this._mService.close();
    }
}