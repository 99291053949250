import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'textFilter',
    pure: false
})
export class TextFilter implements PipeTransform {
    transform(items: any[], field: string, filter: string): any {
        if (!items || !filter || !field || items.length < 1 || !items[0][field]) {
            return items;
        }

        let filterText = filter.toLowerCase();
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        const result = items.filter(item => item[field].toLowerCase().includes(filterText));

        // Sort final items by relevancy
        result.forEach(item => {
            let relevance = 0;
            if(item[field].toLowerCase() == filterText){
                relevance = 1;
            } else if(item[field].toLowerCase().startsWith(filterText)){
                relevance = 2;
            } else {
                relevance = 3;
            }
            item.relevance = relevance;
        });

        result.sort((a,b)=>a.relevance - b.relevance);

        return result;
    }
}
