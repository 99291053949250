import { Component, Inject, OnInit } from '@angular/core';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { ModalDialogService2, SOTI_MODAL_DATA } from 'src/app/shared/controls/dialogs';


export class ChangeModalParam {
  public sotionetheme: boolean;

  public title: string;

  public okButtonText: string;

  public userId: string;
  constructor(sotiOneTheme: boolean) {
    this.sotionetheme = sotiOneTheme;
  }
}

@Component({
  selector: 'app-localization-modal',
  templateUrl: './localization-modal.component.html',
  styleUrls: ['./localization-modal.component.scss']
})
export class LocalizationModalComponent implements OnInit {
  public submitted: boolean;
  public sotionetheme: boolean;

  public titleName: string;

  public buttonText: string;

  public userId: string;
  public helpId: string;
  public pristine: boolean;

  constructor(
    private translationService: AppTranslationService,
    private _modalDialogService: ModalDialogService2,
    @Inject(SOTI_MODAL_DATA) public data: ChangeModalParam
  ) {
    this.sotionetheme = this.data.sotionetheme;
  }

  ngOnInit(): void {
    this.pristine = true;
    this.titleName = this.data.title
      ? this.translationService.getTranslation(this.data.title)
      : this.translationService.getTranslation("lbl_language");
    this.userId = this.data.userId;
    this.buttonText = this.data.okButtonText;
    this.helpId = 'Localization';

  }
  public closeModal(): void {
    this._modalDialogService.closeModal();
  }

}
