import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Utilities } from "./utilities";
import { DBkeys } from "./db-keys";

@Injectable()
export class LocalStoreManager {

    private static syncListenerInitialised = false;
    private syncKeys: string[] = [];
    private initEvent = new Subject();

    private reservedKeys: string[] = [
        "sync_keys", "addToSyncKeys", "removeFromSyncKeys",
        "getSessionStorage", "setSessionStorage", "addToSessionStorage", "removeFromSessionStorage",
        "clearAllSessionsStorage"
    ];

    static readonly DBKEY_USER_DATA = "user_data";
    private static readonly DBKEY_SYNC_KEYS = "sync_keys";


    //Todo: Implement EventListeners for the various event operations and a SessionStorageEvent for specific data keys   
 
    
    initialiseStorageSyncListener() {


        if (LocalStoreManager.syncListenerInitialised == true)
            return;

        LocalStoreManager.syncListenerInitialised = true;
        window.addEventListener("storage", this.sessionStorageTransferHandler, false);
        this.syncSessionStorage();
    }


    deinitialiseStorageSyncListener() {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        window.removeEventListener("storage", this.sessionStorageTransferHandler, false);

        LocalStoreManager.syncListenerInitialised = false;
    }


    private sessionStorageTransferHandler = (event: StorageEvent) => {
        if (window.navigator.cookieEnabled === false) {
            return;
        }

        if (!event.newValue)
            return;

        if (event.key == "getSessionStorage") {

            if (sessionStorage.length) {
                this.localStorageSetItem("setSessionStorage", sessionStorage);
                localStorage.removeItem("setSessionStorage");
            }
        } else if (event.key == "setSessionStorage") {

            if (!this.syncKeys.length)
                this.loadSyncKeys();

            const data = JSON.parse(event.newValue);
            //console.info("Set => Key: Transfer setSessionStorage" + ",  data: " + JSON.stringify(data));

            for (let key in data) {

                if (this.syncKeysContains(key))
                    this.sessionStorageSetItem(key, JSON.parse(data[key]));
            }

            this.onInit();
        } else if (event.key == "addToSessionStorage") {

            const data = JSON.parse(event.newValue);

            //console.warn("Set => Key: Transfer addToSessionStorage" + ",  data: " + JSON.stringify(data));

            this.addToSessionStorageHelper(data["data"], data["key"]);
        } else if (event.key == "removeFromSessionStorage") {

            this.removeFromSessionStorageHelper(event.newValue);
        } else if (event.key == "clearAllSessionsStorage" && sessionStorage.length) {

            this.clearInstanceSessionStorage();
        } else if (event.key == "addToSyncKeys") {


        }
    };


    private syncSessionStorage() {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        localStorage.setItem("getSessionStorage", "_dummy");
        localStorage.removeItem("getSessionStorage");
    }

    clearAllSessionsStorage() {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        this.clearInstanceSessionStorage();
        localStorage.removeItem(LocalStoreManager.DBKEY_SYNC_KEYS);

        localStorage.setItem("clearAllSessionsStorage", "_dummy");
        localStorage.removeItem("clearAllSessionsStorage");
    }


    clearInstanceSessionStorage() {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        sessionStorage.clear();
        this.syncKeys = [];
    }


    clearLocalStorage() {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        localStorage.clear();
    }


    private addToSessionStorage(data: any, key: string) {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        this.addToSessionStorageHelper(data, key);
        this.addToSyncKeysBackup(key);

        this.localStorageSetItem("addToSessionStorage", { key: key, data: data });
        localStorage.removeItem("addToSessionStorage");
    }

    private addToSessionStorageHelper(data: any, key: string) {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        this.sessionStorageSetItem(key, data);
    }


    private removeFromSessionStorage(keyToRemove: string) {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        this.removeFromSessionStorageHelper(keyToRemove);
        localStorage.setItem("removeFromSessionStorage", keyToRemove);
        localStorage.removeItem("removeFromSessionStorage");
    }


    private removeFromSessionStorageHelper(keyToRemove: string) {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        sessionStorage.removeItem(keyToRemove);
    }


    private testForInvalidKeys(key: string) {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        if (!key)
            throw new Error("key cannot be empty");

        if (this.reservedKeys.some(x => x == key))
            throw new Error(`The storage key "${key}" is reserved and cannot be used. Please use a different key`);
    }


    private syncKeysContains(key: string) {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        return this.syncKeys.some(x => x == key);
    }


    private loadSyncKeys() {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        if (this.syncKeys.length)
            return;

        this.syncKeys = this.getSyncKeysFromStorage();
    }


    private getSyncKeysFromStorage(defaultValue: string[] = []): string[] {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        const data = this.localStorageGetItem(LocalStoreManager.DBKEY_SYNC_KEYS);

        if (data == null)
            return defaultValue;
        else
            return data as string[];
    }


    private addToSyncKeysBackup(key: string) {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        const storedSyncKeys = this.getSyncKeysFromStorage();

        if (!storedSyncKeys.some(x => x == key)) {
            storedSyncKeys.push(key);
            this.localStorageSetItem(LocalStoreManager.DBKEY_SYNC_KEYS, storedSyncKeys);
        }
    }




    saveSessionData(data: any, key = LocalStoreManager.DBKEY_USER_DATA) {

        if (window.navigator.cookieEnabled === false) {
            return;
        } localStorage.removeItem(key);
        this.localStorageSetItem(key, data);
    }

    getEnumData(key = DBkeys.Shared_Enums) {
        if (window.navigator.cookieEnabled === false) {
            return null;
        }
        this.testForInvalidKeys(key);

        let data = JSON.parse(this.sessionStorageGetItem(key));

        if (data == null)
            data = JSON.parse(this.localStorageGetItem(key));

        return data;
    }



    savePermanentData(data: any, key = LocalStoreManager.DBKEY_USER_DATA) {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        this.testForInvalidKeys(key);

        this.removeFromSessionStorage(key);
        this.localStorageSetItem(key, data);
    }

    getData(key = LocalStoreManager.DBKEY_USER_DATA) {
        if (window.navigator.cookieEnabled === false) {
            return null;
        }
        this.testForInvalidKeys(key);

        let data = this.sessionStorageGetItem(key);

        if (data == null)
            data = this.localStorageGetItem(key);

        return data;
    }


    getDataObject<T>(key = LocalStoreManager.DBKEY_USER_DATA, isDateType = false): T {
        if (window.navigator.cookieEnabled === false) {
            return null;
        }
        let data = this.getData(key);

        if (data != null) {
            if (isDateType)
                data = new Date(data);

            return data as T;
        } else {
            return null;
        }
    }


    deleteData(key = LocalStoreManager.DBKEY_USER_DATA) {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        this.testForInvalidKeys(key);

        this.removeFromSessionStorage(key);
        localStorage.removeItem(key);
    }


    private localStorageSetItem(key: string, data: any) {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        localStorage.setItem(key, JSON.stringify(data));
    }

    private sessionStorageSetItem(key: string, data: any) {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        sessionStorage.setItem(key, JSON.stringify(data));
    }


    private localStorageGetItem(key: string) {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        return Utilities.JSonTryParse(localStorage.getItem(key));
    }

    private sessionStorageGetItem(key: string) {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        return Utilities.JSonTryParse(sessionStorage.getItem(key));
    }


    private onInit() {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        setTimeout(() => {
            this.initEvent.next();
            this.initEvent.complete();
        });
    }


    getInitEvent(): Observable<{}> {
        if (window.navigator.cookieEnabled === false) {
            return;
        }
        return this.initEvent.asObservable();
    }
}
