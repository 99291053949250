import {
    Component, EventEmitter, Input,
    Output
} from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'listgrid-btn-switcher-ctrl',
    templateUrl: './listgrid-btn-switcher.ctrl.html',
    styleUrls: ['./listgrid-btn-switcher.ctrl.scss']
})
// tslint:disable-next-line:component-class-suffix
export class ListGridBtnSwitcherCtrl {
    @Input() public isdisabled: boolean = false;
    @Input() public selectedView: number = ViewType.Tile;
    @Output() viewChanged = new EventEmitter();

    tileViewType: ViewType = ViewType.Tile;
    listViewType: ViewType = ViewType.List;

    public onClick(event: number) {        
        if(this.isdisabled){
           return;
        } else {
            this.selectedView = event;
            this.viewChanged.next(this.selectedView);
        }
    }
}

export enum ViewType {
    Tile = 1,
    List = 2
}

