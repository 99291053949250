import { A11yModule } from '@angular/cdk/a11y';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateLanguageLoader } from 'src/app/services/app-translation.service';
import { CoreCommonModule } from '../../core-common.module';
import { SharedHelpModule } from '../../internal-controls/help';
import { SharedTooltipModule } from '../tooltip';
import { TooltipService } from '../tooltip/tooltip.service';
import { MODAL_DIRECTIVES } from './modal-control';
import { ModalDialogModule, ModalDialogService } from './modal-dialog';
import { ModalDialog2Module } from './modal-dialog-2';
import { PopUpModule } from './pop-up/pop-up.module';



@NgModule({
    imports: [
        CoreCommonModule,
        PopUpModule,
        ModalDialogModule,
        ModalDialog2Module,
        SharedHelpModule,
        A11yModule,
        SharedTooltipModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        })
    ],
    declarations: [MODAL_DIRECTIVES],
    exports: [MODAL_DIRECTIVES, PopUpModule, ModalDialogModule, ModalDialog2Module, CoreCommonModule],
    providers: [
        TooltipService
    ]
})
export class SharedDialogsModule {
    public static forRoot(): ModuleWithProviders<SharedDialogsModule> {
        return <ModuleWithProviders<SharedDialogsModule>>{
            ngModule: SharedDialogsModule,
            providers: [ModalDialogService]
        };
    }
}
