import { Permissions } from 'src/app/shared/enums/Permissions';
import {Component, Input, ViewChild} from '@angular/core';
import {CdkConnectedOverlay, ConnectionPositionPair} from '@angular/cdk/overlay';
import {DisposalBag} from '../../utils/DisposalBag';

@Component({
    selector: 'im-navigation-menu',
    templateUrl: './navigation-menu.ctrl.html',
    styleUrls: ['./navigation-menu.ctrl.scss']
})
export class NavigationMenuControl {

    //region Template bound fields
    public showMenu: boolean = false;
    
    @Input()
    public soTheme: boolean;
    //endregion

    @Input()
    public navTabIndex: number = 0;

    public overlayPositions : ConnectionPositionPair[] = [
        {
            offsetX: undefined,
            offsetY: undefined,
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
        }
    ];

    @ViewChild(CdkConnectedOverlay)
    private _connectedOverlay: CdkConnectedOverlay;
    private _disposalBag: DisposalBag = new DisposalBag();
    private navigationPermissions:Permissions [] = [Permissions.ENABLEUSERSECTIONVIEW];


    //region Template bound methods
    public onToggleMenu($event?: MouseEvent | TouchEvent): void {
        if ($event) {
            $event.preventDefault();
        }

        this.showMenu = !this.showMenu;
        if (this.showMenu) {
            this._disposalBag.nextSub = this._connectedOverlay.backdropClick.subscribe(() =>  this.onToggleMenu() );
        } else {
            this._disposalBag.dispose();
        }
    }
    //endregion
}