import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CompanyListService {
    public companyListValueObserver : Subject<boolean> = new Subject<boolean>();

    constructor() {

    }
}
