import {
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { reduce } from 'rxjs/operators';
import { logger } from '../../../shared/utils/Logger';
import { BrowserUtilsService } from '../../../services/browserUtil/browserUtils.service';
import { Tab } from './tab/tab.component';

@Component({
    selector: 'soti-tab-switcher',
    templateUrl: './tab-switcher.component.html',
    styleUrls: ['./tab-switcher.component.scss'],
    host: {
        role: 'tablist',
    }
})
export class TabSwitcher implements AfterContentInit {
    @Input()
    public current: number = 0;
    @Input()
    public secondaryTabSwitcher: boolean;

    @Input()
    public tabTabindex: number = 0;

    @Input()
    public tabTextUpperCase: boolean;
    @Input('show-tabs')
    public showTabs: boolean;
    @Input('show-dots')
    public showDots: boolean;
    @Input('hide-arrows')
    public hideArrows: boolean;    
    @Input('hide-scroller')
    public hideScroller: boolean;
    @Output()
    public tabChange: EventEmitter<TabChange> = new EventEmitter<TabChange>();
    @ContentChildren(Tab)
    public tabs: QueryList<Tab>;
    @ViewChild('headerWrapper')
    public set headerWrapperElement(content: ElementRef) {
        this._headerWrapper = content;
    }
    public get tabHeaderAtStart(): { disabled: boolean } {
        return { disabled: this._tabHeaderScrollPosition() <= 0 };
    }
    public get tabHeaderAtEnd(): { disabled: boolean } {
        let disabled = false;
        if (this._headerWrapper) {
            const el = this._headerWrapper.nativeElement as HTMLElement;
            disabled = el.scrollWidth - this._tabHeaderScrollPosition() <= el.clientWidth;
        } else {
            disabled = true;
        }

        return {
            disabled
        };
    }

    public get isTabIndexAtEnd(): boolean {
        var getAllTabs = this.tabs.toArray();
        return getAllTabs.length - 1 === this.current;
    }

    private _headerWrapper: ElementRef;
    private _tabsChangeSub: Subscription;

    constructor(private _cdr: ChangeDetectorRef, private _browserUtils: BrowserUtilsService) {}

    public ngOnDestroy(): void {
        if (this._tabsChangeSub) {
            this._tabsChangeSub.unsubscribe();
        }
    }

    public ngAfterContentInit(): void {
        var getAllTabs = this.tabs.toArray();
        this._resetTabs(getAllTabs, false, true);
        getAllTabs[0].hidden = false;

        this._tabsChangeSub = this.tabs.changes
            .pipe(
                reduce((prevIdList, curr: QueryList<Tab>) => {
                    let tabArray = curr.toArray();
                    let prevCurrentId = prevIdList[this.current];
                    let currentTab = tabArray[this.current];

                    if (!currentTab || prevCurrentId != currentTab.tabId) {
                        this._resetTabs(getAllTabs, false, true);
                        let tabToShow = tabArray.find((tab) => tab.tabId == prevCurrentId);
                        if (tabToShow) {
                            tabToShow.hidden = false;
                            this.current = tabArray.indexOf(tabToShow);
                        } else {
                            getAllTabs[0].hidden = false;
                            this.current = 0;
                        }
                    }

                    return curr.map((item) => item.tabId);
                }, this.tabs.map((item) => item.tabId))
            )
            .subscribe();

        this._setTabFocus();
    }

    public nextTab(): void {
        var getAllTabs = this.tabs.toArray();
        this._resetTabs(getAllTabs, true, true);
        //if we're at the last tab maintain the current tab
        if (getAllTabs.length - 1 !== this.current) {
            getAllTabs[this.current].pushOut = true;
            this.current = this.current + 1;
        }
        getAllTabs[this.current].pullIn = true;
        getAllTabs[this.current].hidden = false;
    }

    public previousTab(): void {
        var getAllTabs = this.tabs.toArray();
        this._resetTabs(getAllTabs, true, true);

        //if we're at the first tab previous maintain the current tab
        if (this.current !== 0) {
            getAllTabs[this.current].pullOut = true;
            this.current = this.current - 1;
        }
        getAllTabs[this.current].pushIn = true;
        getAllTabs[this.current].hidden = false;
    }

    public openTargetTab(target: string): void {
        if (!this.tabs) {
            logger.debug('No tabs to target');
            return;
        }

        var getAllTabs = this.tabs.toArray();
        var targetTab = this.tabs.find((tab) => tab.tabId.toLocaleLowerCase() === target.toLocaleLowerCase());
        if (!targetTab) {
            targetTab = this.tabs.first;
        }
        var pos = getAllTabs.indexOf(targetTab);
        // deactivate all tabs
        this._resetTabs(getAllTabs, true, false);

        getAllTabs[this.current].hidden = true;
        this.current = pos;
        getAllTabs[this.current].hidden = false;
        this._tabChanged(target);
        this._setTabFocus();
    }

    public scrollHeader(num: number): void {
        this._tabHeaderScrollPosition(num);
        this._cdr.detectChanges();
    }

    private _resetTabs(tabs: Tab[], deactivate: boolean, hide: boolean): void {
        tabs.forEach((tab, index) => {
            // deactivate animations on all tabs
            if (deactivate) {
                tab.pullIn = tab.pushIn = tab.pullOut = tab.pushOut = false;
            }
            // hide all tabs
            if (hide) {
                tab.hidden = true;
            }
        });
    }

    private _tabChanged(tab: string): void {
        this.tabChange.emit({ tab });
    }

    private _tabHeaderScrollPosition(scroll: number = 0): number {
        if (this._headerWrapper) {
            return (this._headerWrapper.nativeElement.scrollLeft += scroll);
        } else {
            return 0;
        }
    }

    private _setTabFocus(): void {
        if (!this._browserUtils.isMobileOrTablet && this.tabs && this.current < this.tabs.length) {
            //setTimeout used to wait until the new Tab Element is renderer.
            /** Timeout is 1000ms which is long enough.
             * Safari only issue -- (╯°□°）╯︵ ┻━┻
             * https://jira.soti.net/browse/MC-41987
             */
            setTimeout(() => {
                this.tabs.toArray()[this.current].setTabFocus();
            }, 1000);
        }
    }
}

interface TabChange {
    tab: string;
}
