import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModalDialogRef, ModalDialogService2, SOTI_MODAL_DATA } from '../dialogs';

export class WarnignBoxlDialogOptions {
    public title: string = 'title_warning';
    public body: string;
    public icon: string;
    public okButtonText: string;
    public okButtonCssClass: string;
    public errorMessage: string;
    public hashelpicon: boolean = false;
    public helpid: string = '';
    public showError: boolean;
    public confirmationCheckBoxRequired : boolean;
    public confirmationCheckBoxLabelText : string;
    public showButtonLoader: BehaviorSubject<boolean>;
    public submitCallback: () => void | Observable<void>;
    public onSubmit: EventEmitter<void> = new EventEmitter<void>();
    public onCancel: EventEmitter<void> = new EventEmitter<void>();
}

@Component({
    selector: 'im-warning-dialog-box',
    templateUrl: './im-warning-dialog.ctrl.html',
    styleUrls: ['./im-warning-dialog.ctrl.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WarnignBoxControl),
            multi: true
        }
    ]
})

export class WarnignBoxControl implements OnInit, AfterViewInit, OnDestroy {

    public hashelpicon: boolean = false;
    public input: WarnignBoxlDialogOptions;
    @ViewChild('warningContent') public warningContent : ElementRef;

    constructor( private _modalRef: ModalDialogRef<WarnignBoxControl>,
        private _modalDialogService: ModalDialogService2,
        @Inject(SOTI_MODAL_DATA) @Optional() _data: WarnignBoxlDialogOptions
    ) {
        if(_data)
        {
            this.input = _data;
            this.hashelpicon = _data.hashelpicon;
        }
    }

    public ngOnInit() : void {
    }
    public ngAfterViewInit() : void {
        this.warningContent.nativeElement.setAttribute('aria-live', 'assertive');
    }
    public ngOnDestroy() : void {

    }

    public submit(): void {
        this._modalDialogService.closeModal();
        this.input.onSubmit.emit();
    }

    public onCloseAttrPopup(): void {
        this._modalDialogService.closeModal()
        this.input.onCancel.emit();
    }

}