import { Directive, ElementRef, HostListener, OnDestroy} from '@angular/core';
import { AnnounceService } from '../../../../services/announce.service';
import { KeyCodeEnum } from '../../../enums/KeyCodeEnum';
import { LiveAnnouncementType } from '../../../enums/LiveAnnouncementType';
import { Router } from '@angular/router';

@Directive({
  selector: '.form-group'
})
export class AnnounceFieldValidationDirective implements OnDestroy {

  /// Properties
  public type: LiveAnnouncementType = 1;
  private alreadyPronouncedError: boolean = false;

  /// Constructor
  constructor(
    private el: ElementRef, 
    private annouceService: AnnounceService,
    private router: Router) { }
  
  /// Implemented lifecycles
  ngOnDestroy(): void {
    this.annouceService.clearAnnouncements();
  }
  
  @HostListener('input', ['$event'])
  @HostListener('blur', ['$event'])
  private _detectKeyPress($event): void {
    $event.stopImmediatePropagation();
    this.alreadyPronouncedError = true;
    if (this.type === LiveAnnouncementType.ValidationError) {
      switch ($event.keyCode) {
        case KeyCodeEnum.ESCAPE:
        case KeyCodeEnum.TAB:
          break;
        default:
          setTimeout(() => {
            this.annouceService.announce(LiveAnnouncementType.ValidationError, null, null, this.el, 'polite');
          }, 50);
          break;
      }
    }
  }

  private loadCount: number = 0;
  @HostListener('focusout', ['$event'])
  @HostListener('focusin', ['$event'])
  private _onFocusEvent($event: FocusEvent) { 
    $event.preventDefault();
    $event.stopImmediatePropagation();
    if (this.router.getCurrentNavigation()) {
      return; // Skip announcing if the router is navigating
    }
    setTimeout(() => {
      let loading = Array.from(this.el.nativeElement.querySelectorAll('soti-loader')).length > 0
      if (loading){
        this._onFocusEvent($event);
        this.loadCount += 1;
      }
      else if (!loading && this.loadCount > 0 && this.type === LiveAnnouncementType.ValidationError){
        this.annouceService.announce(LiveAnnouncementType.ValidationError, null, null, this.el);
        this.loadCount = 0;
      }
      else if (!this.alreadyPronouncedError){
        this.annouceService.announce(LiveAnnouncementType.ValidationError, null, null, this.el);
      }
      else if (!loading && this.loadCount === 0 && this.type === LiveAnnouncementType.ValidationError) {
          this.annouceService.announce(LiveAnnouncementType.ValidationError, null, null, this.el);
      }
    }, 500);
  }
}
