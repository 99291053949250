/**
 * Created by jcammisuli on 2016-05-18.
 */
import { Component, OnInit, HostBinding, Input, ElementRef, AfterViewInit } from '@angular/core';

// The maximum height of the drop down menu before a scroll bar is applied.
const maxHeight = window.innerHeight / 3;

@Component({
    selector: 'soti-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, AfterViewInit {

    @HostBinding('class.dropdown-list')
    @Input('dropdown-list')
    public dropdownList: boolean = false;

    @Input()
    @HostBinding('class.shadow')
    public shadow: boolean = true;

    @Input()
    @HostBinding('class.has-padding')
    public padding: boolean = true;

    @Input()
    public scrollBar: boolean = false;

    @Input()
    public scrollBottom: boolean = false;

    @HostBinding('style.max-height.px')
    public dropdownHeight: number;

    @HostBinding('style.overflow-y')
    public showScroll: string = '';

    constructor(public element: ElementRef) { }

    public ngOnInit(): void {
        if (this.scrollBar) {
            this.showScroll = 'auto';
            this.dropdownHeight = maxHeight;
        }
    }

    public ngAfterViewInit(): void {
        if (this.scrollBottom) {
            // Using Promise.resolve() allows schedule a microtask on the current stack after all other operations are complete.
            // This is required when using the component in a popup because of the overlay calculations.
            Promise.resolve().then(() => {
                this.element.nativeElement.scrollTop = this.element.nativeElement.scrollHeight;
            });
        }
    }
}