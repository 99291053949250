import {
    Component
} from '@angular/core';
@Component({
    selector: 'im-page-loading',
    templateUrl: './page-loading.ctrl.html',
    styleUrls: ['./page-loading.ctrl.scss']
})
export class PageLoadingControl { 
    

    constructor() {
    }

    
}