import { Injectable, ErrorHandler } from "@angular/core";

@Injectable()
export class AppErrorHandler extends ErrorHandler {

    constructor() {
        super();
    }

    handleError(error: any) {
        console.log(error);
    }
}
