import { AppTranslationService } from './../../../services/app-translation.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { KeyCodeEnum } from "../../enums/KeyCodeEnum";
import { Utilities } from "src/app/services/utilities";

@Component({
    selector: 'file-uploader',
    templateUrl: './file-uploader.ctrl.html',
    styleUrls: ['./file-upload.ctrl.scss'],
    // providers: [
    //     { 
    //       provide: NG_VALUE_ACCESSOR,
    //       multi: true,
    //       useExisting: forwardRef(() => FileUploader),
    //     }
    //   ]
})

//Usecase : Used for displaying already uploaded file and option to change it or freshly upload a new file when no file was already uploaded.
export class FileUploader implements OnInit, OnChanges {

    @ViewChild('fileInput')
    public fileUpload: ElementRef;
    @ViewChild('imageFile')
    public set content(imageFile: ElementRef) {
        this._imageFile = imageFile;
    }
    @ViewChild('fileUploaderFocusOnSelect') fileUploaderFocusOnSelect: ElementRef;
    @ViewChild('fileUploaderFocusHere') fileUploaderFocusHere: ElementRef;

    @Input('ariaLabelledby')
    public ariaLabelledby: string = '';

    @Output()
    public fileUploadEvent: EventEmitter<FileUploadEvent> = new EventEmitter<FileUploadEvent>();
    public violated: boolean = false;
    public hasFile: boolean = false;
    public fileData: Blob = new Blob();
    public isDragOver: boolean = false;
    public confirmDelete: boolean = false;
    public file: File;
    public dragAndDropIconName: string;
    public fileUploadMessage: string = '';
    
    @Input()
    public tabIndex: number = 0;
    
    @Input()
    public maxFileSize: number = 0;
    @Input()
    public maxImgHeight: number = 0;
    @Input()
    public maxImgWidth: number = 0;
    @Input()
    public uploadInfoMessage: string;
    @Input()
    public minFileSize: number = 0;
    @Input()
    public fileTypes: string[] = [];
    @Input()
    public isImageUpload: boolean = false;
    @Input()
    public readyToUploadState: boolean = false;
    @Input()
    public isIocnShow: boolean = false;
    @Input()
    public buttonText: string = 'lbl_file_upload_btn';
    @Input()
    public disabled: boolean = false;
    @Input()
    public showfooterlist: boolean = false;
    @Input()
    public isDragAndDropStyle: boolean = false;
    @Input()
    public restrictFileSelection: boolean = false;
    @Input()
    public customDragAndDropMessage: string;
    @Input()
    public displayImage: boolean = true;
    @Input()
    public uploadButtonText: string = 'Browse';
    @Input()
    public defaultImage = Utilities.getDefaultAppPngImage();
    @Input()
    public showDeleteButton: boolean = false;
 
    @Input()
    public appLogoPath: string = '';
    @Input()
    public isdefaultplusicon: boolean = true;
    constructor(
        private translationService?: AppTranslationService,
        private _liveAnnouncer?: LiveAnnouncer,
    ) {}
    
    public showLoader: boolean = false;

    public value: string = '';

    private _imageFile: ElementRef;
    private _uploadElement: HTMLInputElement;

    @HostListener('keydown', ['$event'])
    private _detectKeyPress($event: KeyboardEvent): void {
        switch ($event.keyCode) {
            case KeyCodeEnum.SPACE:
            case KeyCodeEnum.ENTER:
                $event.preventDefault();
                this.fileUpload.nativeElement.click();
                break;
            default:
                return;
        }
    }


    public ngOnInit(): void {
        this._uploadElement = this.fileUpload?.nativeElement;
        this.appLogoPath = this.appLogoPath == '' ? this.defaultImage: this.appLogoPath;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(changes.uploadInfoMessage && !changes.uploadInfoMessage.firstChange && document.activeElement == this.fileUploaderFocusOnSelect.nativeElement){
            this.fileUploaderFocusHere.nativeElement.focus();
        }
    }

    public fileSelected(files: FileList | Event): void {
        this.fileUploaderFocusOnSelect.nativeElement.focus();
        if (files instanceof Event && files.currentTarget instanceof HTMLInputElement) {
            this.fileCheck(files.currentTarget.files[0]);
        } else {
            this.fileCheck(files[0]);
        }
    }
    public fileCheck(file: File): void {
        if (!file) {
            return;
        }
        let isMinFileSizeValid = (this.minFileSize === 0 || file.size >= this.minFileSize);
        let isMaxFileSizeValid = (this.maxFileSize === 0 || file.size <= this.maxFileSize);
        let fileType = file.type === '' ? this._getFileExtension(file) : file.type;
        let isFileTypeValid = (!this.fileTypes || this.fileTypes.length === 0 || this.fileTypes.includes(fileType));
        if (this.isImageUpload && isFileTypeValid) {
            let reader: FileReader = new FileReader();
            /* tslint:disable */
            reader.onload = ((aImg: HTMLImageElement): any =>
                (e): void => {
                    if (this.maxImgHeight || this.maxImgWidth) {
                        this._checkImageResolution(reader.result, aImg, e.target.result, file, isMaxFileSizeValid, isMinFileSizeValid);
                    }
                    else if (!isMaxFileSizeValid) {
                        this._updateValidators(true, false, null, FileCheckType.MaxFileSize);
                    } 
                    else if (!isMinFileSizeValid) {
                        this._updateValidators(true, false, null, FileCheckType.MinFileSize);
                    }
                    else {
                        this._updateValidators(false, true, file, FileCheckType.Correct);
                        aImg.src = e.target.result;
                    }
                })(this._imageFile.nativeElement);
            reader.readAsDataURL(file);
        } else if (!isFileTypeValid) {
            this._updateValidators(true, false, null, FileCheckType.FileType);

        } else if (!isMaxFileSizeValid) {
            this._updateValidators(true, false, null, FileCheckType.MaxFileSize);

        } else if (!isMinFileSizeValid) {
            this._updateValidators(true, false, null, FileCheckType.MinFileSize);
        }
        else {
            this._updateValidators(false, true, file,FileCheckType.Correct);
        }
    }

    private _checkImageResolution(imageDataURL: string | ArrayBuffer, imgElement: HTMLImageElement, image: string, file: File, isMaxFileSizeValid: boolean, isMinFileSizeValid: boolean): void {
        var img = new Image();
        img.onload = () => {
            if(((this.maxImgWidth && this.maxImgWidth != img.width) || (this.maxImgHeight && this.maxImgHeight != img.height)) && (!isMaxFileSizeValid || !isMinFileSizeValid) )
            {
                this._updateValidators(true, false, null, FileCheckType.fileResolutionMismatchandmaxsizeexceed);
                this._removeThumbnail();

            }
            else if (this.maxImgWidth && this.maxImgWidth != img.width) {
                this._updateValidators(true, false, null, FileCheckType.fileResolutionMismatch);
                this._removeThumbnail();
            }
            else if (this.maxImgHeight && this.maxImgHeight != img.height) {
                this._updateValidators(true, false, null, FileCheckType.fileResolutionMismatch);
                this._removeThumbnail();
            }
            else if (!isMaxFileSizeValid) {
                this._updateValidators(true, false, null, FileCheckType.MaxFileSize);
                this._removeThumbnail();
            } 
            else if (!isMinFileSizeValid) {
                this._updateValidators(true, false, null, FileCheckType.MinFileSize);
                this._removeThumbnail();
            }
            else {
                this._updateValidators(false, true, file, FileCheckType.Correct);
                imgElement.src = image;
            }
        };
        img.onerror = (event) => {
            this.badFile(event as Event);
        };
        img.src = imageDataURL as string;  
    }

    public badFile(event: Event): void {
        this._stopEventBubble(event);
        this._updateValidators(true, false, null, FileCheckType.FileCorrupt);
    }

    public getAcceptedFileExtensions(): string {
        if (!this.restrictFileSelection || !this.fileTypes || this.fileTypes.length === 0) {
            return null;
        }

        return this.fileTypes.join(',');
    }

    public writeValue(value: string): void {
        this.value = value;
    }

    public registerOnChange(fn: (value: string) => void): void {
        //this._onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        // TODO: register on touch
    }

    public getEmptyFileSelection(event: Event): void {
        if (this.showLoader) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
        let file: HTMLInputElement = event.target as HTMLInputElement;
        file.value = '';
    }

    // get file extension from the file name
    private _getFileExtension(file: File): string {
        if (file === null || file === undefined) {
            return '';
        }

        return file.name.substring(file.name.lastIndexOf('.'), file.name.length);
    }

    public removeFile(event: Event): void {
        if (this.disabled) {
            return;
        }
        
        this.confirmDelete = !this.confirmDelete;
        this._fileCleanUpOperation();
        if (!event.returnValue){
            this.fileUploaderFocusOnSelect.nativeElement.focus();
        }
        
        let message = this.translationService.getTranslation('msg_deleted');
        this._liveAnnouncer.announce(message);
    }
    
    private _fileCleanUpOperation(): void {
        this._updateValidators(false, false, null);
        this._removeThumbnail();
        this._stopEventBubble(event);
        if (this.readyToUploadState) {
            this.customDragAndDropMessage = this.fileUploadMessage;
        }
    }

    private _stopEventBubble(event: Event): void {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
    }
    
    public isFocused(el: HTMLElement){
        return (document.activeElement === el);
    }
    
    private _removeThumbnail(): void {
        if (this._imageFile) {
            this._imageFile.nativeElement.src = this.defaultImage;
        }
        let input = this.fileUpload.nativeElement;
        if (input) {
            input.value = null;
        }
    }

    private _updateValidators(violated: boolean, hasFile: boolean, file: File, reason?: FileCheckType): void {
        this.file = file;
        this.hasFile = hasFile;
        this.violated = violated;
        this.fileUploadEvent.emit({ file: file, reason: reason });
    }
}
export interface FileUploadEvent {
    file: File;
    reason?: FileCheckType;
}
export enum FileCheckType {
    MaxFileSize,
    MinFileSize,
    FileType,
    FileCorrupt,
    Correct,
    InValid,
    fileResolutionMismatch,
    fileResolutionMismatchandmaxsizeexceed
}