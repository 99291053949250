
import { Component, OnInit, HostListener, EventEmitter, Inject, Optional, ViewChild, ElementRef } from '@angular/core';
import { ModalDialogService2, SOTI_MODAL_DATA } from 'src/app/shared/controls/dialogs';
import { ModalDialogRef } from 'src/app/shared/controls/dialogs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { EulaService } from 'src/app/services/eula-services/eula.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user-services/user.service';
import { User } from 'src/app/models/user.model';
import { KeyCodeEnum } from 'src/app/shared/enums/KeyCodeEnum';
import { Router } from '@angular/router';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { DisposalBag } from 'src/app/shared/utils/DisposalBag';
import { UserPreferenceType } from 'src/app/shared/enums/UserPreferenceType';
import { LanguageTypeEnum } from 'src/app/shared/enums/LanguageTypeEnum';
import { UserPreferenceService } from 'src/app/services/user-preference-service/user-preference-service';


@Component({
    selector: 'eula',
    styleUrls: ['./eula-acceptance-modal.ctrl.scss'],
    templateUrl: './eula-acceptance-modal.ctrl.html'
})
export class EulaAcceptanceModal implements OnInit {
    public eulaText: string = '';
    public showLoader: boolean = false;
    public showWarningPopup: boolean = false;
    public cancelButtonActive: boolean = false;
    public acceptButtonActive: boolean = false;
    public showingButtonLoader: boolean = false;
    public showOnlyCloseButton: boolean = false;
    private _disposalBag: DisposalBag = new DisposalBag();
    @ViewChild('eula') public eulaDiv : ElementRef;


    constructor(
        private _modalRef: ModalDialogRef<EulaAcceptanceModal>,
        private _eulaService: EulaService,
        private _authService: AuthService,
        private _userService: UserService,
        private _modalDialogService: ModalDialogService2,
        public el: ElementRef,
        public router: Router,
        private translationService: AppTranslationService,
        private userPreferenceService: UserPreferenceService,
        @Inject(SOTI_MODAL_DATA) @Optional() _data: boolean
    ) {
        this._modalRef.unSubscribeEscapeCloseEvent();
        this.showOnlyCloseButton = _data ? _data : false;
     }

    public onEulaScroll(): void {

        if (this.eulaDiv.nativeElement.offsetHeight + this.eulaDiv.nativeElement.scrollTop + 10 >= this.eulaDiv.nativeElement.scrollHeight) {
            this.acceptButtonActive = true;
        } 
    }

    public ngOnInit(): void {
        this.showLoader = true;
        this._disposalBag.nextSub = this._userService.getPreferences().subscribe((data) => {
            
            const languagePreference = data.find(x => x.TypeId == UserPreferenceType.LANGUAGE);
            let language = "";
            if(languagePreference && languagePreference.Preference == LanguageTypeEnum.JAPANESE){
                this.translationService.changeLanguage("ja-JP");
                language = "ja-JP";
            }
            else{
                this.translationService.changeLanguage("en-US");
                language = "en-US";
            }

            this._eulaService.getEulaText(language).subscribe(result => {
                this.eulaText = result;
                this.showLoader = false;
                this.cancelButtonActive = true;
                setTimeout(() => {
                    this.onEulaScroll();
                }, 1);
            });
        });        
    }

    public ngAfterViewInit(): void {
        // focus input on the panel (makes it listen to keydown events for esc button)
        this._focusPanel();
    }

    @HostListener('keydown', ['$event'])
    private _detectKeyPress($event: KeyboardEvent): void {
        $event.stopImmediatePropagation();
        switch ($event.keyCode) {
            case KeyCodeEnum.ESCAPE:
                if(this.showOnlyCloseButton){
                    this.onClose();
                }
                else{
                    this.onCancelClick();
                }
                break;
            default:
                break;
        }
    }

    public submit(): void {
        this.showingButtonLoader = true;
        this._userService.acceptEula(this._authService.currentUser.ReferenceId).subscribe(result => {
            let user: User = this._authService.currentUser;
            user.EulaAccepted = true;
            this._authService.setCurrentUser(user);
            this.userPreferenceService.updateData();
            this._modalDialogService.closeModal();
            this.router.navigate(['/']);
        },
        error => {
            this.showingButtonLoader = false;
        });
    }

    public onClose(): void {              
        this._modalDialogService.closeModal();       
    }

    public onCancelClick(): void {
        this.showWarningPopup = true;
    }

    public onCloseWarningPopup(): void {
        this.showWarningPopup = false;
    }

    public onUnAcceptEula(): void {
        this._authService.redirectToLogoutUrl();
    }

    private _focusPanel(): void {
        (<HTMLElement>this.el.nativeElement).tabIndex = -1;
        this.el.nativeElement.focus();
    }
}
