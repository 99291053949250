import { ErrorHandler, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FileEndpoint } from 'src/app/services/file-service/file-endpoint.service';
import { FileService } from 'src/app/services/file-service/file.service';
import { SharedInfoMessageBarModule } from 'src/app/shared/controls/info-message-bar';
import { SharedTabSwitcherModule } from 'src/app/shared/controls/tab-switcher';
import { SharedModule } from 'src/app/shared/shared.module';
import { SotiOneLogoModule } from 'src/app/shared/soti-one-logo/soti-one-logo.module';
import { AppErrorHandler } from '../../app-error.handler';
import { getBaseUrl } from '../../app.module';
import { AccountEndpoint } from '../../services/account-services/account-endpoint.service';
import { AccountService } from '../../services/account-services/account.service';
import { AppTranslationService, TranslateLanguageLoader } from '../../services/app-translation.service';
import { ApplicationEndpoint } from '../../services/application-services/application-endpoint.service';
import { ApplicationService } from '../../services/application-services/application.service';
import { UserEndpoint } from '../../services/user-services/user-endpoint.service';
import { UserService } from '../../services/user-services/user.service';
import { SuccessmsgModule } from '../../shared/controls/success-msg/success-msg.module';
import { SharedLoadingDirectiveModule } from '../../shared/directives/loading/shared-loading.module';
import { CopyrightModule } from '../copyright/copyright.module';
import { DashboardApplicationComponent } from './dashboard-application.component';
import { PopUpModule } from 'src/app/shared/controls/dialogs/pop-up/pop-up.module';
import { CoreCommonModule } from 'src/app/shared/core-common.module';
@NgModule({

    imports: [
        SuccessmsgModule,
        SharedLoadingDirectiveModule,
        SharedInfoMessageBarModule,
        CopyrightModule,
        SotiOneLogoModule,
        SharedTabSwitcherModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        PopUpModule,
        CoreCommonModule
     ],
    declarations:
     [
        DashboardApplicationComponent,
    ],
    exports: [
        DashboardApplicationComponent,
    ],
     providers: [
        { provide: 'BASE_URL', useFactory: getBaseUrl },
        { provide: ErrorHandler, useClass: AppErrorHandler },

        AppTranslationService,
        AccountService,
        AccountEndpoint,
        UserService,
        UserEndpoint,
        FileEndpoint,
        FileService,
        ApplicationService,
        ApplicationEndpoint
    ],
})
export class DashboardApplicationModule {
}
