
import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'localization-management.view',
    templateUrl: './localization-management.view.html',
    styleUrls: ['./localization-management.view.scss']
})
export class LocalizationManagementViewComponent {
    
    constructor( private location: Location) {

    }

    public backClicked(): void {
        this.location.back();
    }

}
