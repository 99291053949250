import { ITrie, Trie, TrieNode, ITrieNode, removePrefix } from './Trie';
import { remove, sum, flatMap, uniqBy } from 'lodash';

/**
 * An ICountableTrie represents a Trie that maintains a counter in its nodes
 * that should keep track of some condition, such as the number of elements
 * or the number of element updates.
 */
export interface ICountableTrie<K, V> extends ITrie<K, V, number> {
    /**
     * Clear the data and count from the given node, this will not delete the
     * nodes themselves.
     * 
     * @param key The node to clear
     * @param includeSub Whether to also clear all subnodes of the given node. Default is 'false'
     */
    clear(key: K, includeChildren: boolean): void;

    /**
     * Retrieve the count from the given node or the sum of counts from the target node and all it's children
     * @param key The target node key
     * @param includeChildren Whether to also count all child nodes. Default is 'false'
     */
    retrieveCount(key: K, includeChildren: boolean): number;
}

/**
 * An ICountableTrieNode represents an ITrieNode that maintains an internal counter for 
 * use with an ICountableTrie
 */
export interface ICountableTrieNode<K, V> extends ITrieNode<K, V, number>, ICountableTrie<K, V> { }

/**
 * The CountedTrie is the default reference implementation of a Trie that is indexed by string
 * and that keeps count of the number of times an element has been added.
 * 
 * @see Trie<T>
 */
export class CountedTrie<T> extends Trie<T> implements ICountableTrie<string, T> {
    /**
     * Create a new StringTrie using the given delimiter, the default
     * delimiter is '.'
     */
    constructor(delimiter: string = '.') {
        super(delimiter);
    }

    /**
     * Add a new item to the Trie under the given key.
     * Note, this method is non-destructive, so an element that is added a second time
     * will only increment the item counter
     * 
     * @param key The key to index the item under
     * @param item The item to add
     * 
     * @returns The number of times since an item under this key was added without being removed  
     */
    public add(key: string, item: T): number {
        const { existing, prefix } = this._getExistingWithPrefixAndNext(key);

        if (existing != null) {
            return (<CountedTrieNode<T>>existing).add(key, item);
        }

        let child = new CountedTrieNode<T>(prefix, this._delimiter);
        this._children.push(child);
        return child.add(key, item);
    }

    /**
     * Clear the data and count from the given node, this will not delete the
     * nodes themselves.
     * 
     * @param key The node to clear
     * @param includeSub Whether to also clear all subnodes of the given node. Default is 'false'
     */
    public clear(key: string, includeSub: boolean): void {
        const { existing } = this._getExistingWithPrefixAndNext(key);

        if (existing == null) {
            return;
        }

        (<CountedTrieNode<T>>existing).clear(key, includeSub);
    }

    /**
     * Retrieve the count from the given node or the sum of counts from the target node and all it's children
     * @param key The target node key
     * @param includeChildren Whether to also count all child nodes. Default is 'false'
     */
    public retrieveCount(key: string, includeChildren: boolean = false): number {
        const { existing, next } = this._getExistingWithPrefixAndNext(key);

        if (existing == null) {
            return 0;
        }

        return (<CountedTrieNode<T>>existing).retrieveCount(next, includeChildren);
    }
}

/**
 * A CountedTrieNode is an extension of TrieNode that maintains an internal counter
 * of how many times the contained value has been inserted.
 * 
 * @see TrieNode<T>
 */
class CountedTrieNode<T> extends TrieNode<T> implements ICountableTrieNode<string, T> {
    private _count: number = 0;

    /**
     * Create a new CountedTrieNode
     */
    constructor(identifier: string, delimiter: string = '.') { super(identifier, delimiter); }

    /**
     * Add an item to the node, or one of its sub-nodes. Should the item already
     * exist, the added item will increment the count but not replace the existing item.
     * 
     * @param key The key for the item
     * @param item The item to add
     * 
     * @returns The count of this node.
     */
    public add(key: string, item: T): number {
        if (key === this._identifier) {
            if (this._item == null) {
                this._item = item;
            }
            this._count++;
            return this._count;
        } else {
            const suffix = removePrefix(key, this._delimiter);
            const { existing, prefix } = this._getExistingWithPrefixAndNext(suffix);

            if (existing != null) {
                return (<CountedTrieNode<T>>existing).add(suffix, item);
            } else {
                let child = new CountedTrieNode<T>(prefix, this._delimiter);
                this._children.push(child);
                return child.add(suffix, item);
            }
        }
    }

    /**
     * Clear the data and count from the given node, this will not delete the
     * nodes themselves. If key equals this node's identifier, clearing will begin here
     * 
     * @param key The node to clear
     * @param includeChildren Whether to also clear all subnodes of the given node. Default is 'false'
     */
    public clear(key: string, includeChildren: boolean = false): void {
        if (key === this._identifier) {
            this._count = 0;
            this._item = null;
            if (includeChildren) {
                this._children.forEach((ch: CountedTrieNode<T>) => ch.clear(ch.identifier(), includeChildren));
            }
            return;
        }
        const suffix = removePrefix(key, this._delimiter);

        const { existing } = this._getExistingWithPrefixAndNext(suffix);

        if (existing == null) {
            return;
        }
        (<CountedTrieNode<T>>existing).clear(suffix, includeChildren);
    }

    /**
     * Retrieve the count from the given node or the sum of counts from the target node and all it's children
     * @param key The target node key
     * @param includeChildren Whether to also count all child nodes. Default is 'false'
     */
    public retrieveCount(key: string, includeChildren: boolean = false): number {
        if (key == this._identifier) {
            return !includeChildren ?
                this._count :
                sum([this._count].concat(...this._children.map((ch: CountedTrieNode<T>) => ch.retrieveCount(ch.identifier(), includeChildren))));
        }

        const { existing, next } = this._getExistingWithPrefixAndNext(key);

        return existing == null ? 0 : (<CountedTrieNode<T>>existing).retrieveCount(next, includeChildren);
    }
}