import { NgModule } from '@angular/core';
import { CoreCommonModule } from '../../core-common.module';
import {CheckboxButtonControl} from './checkbox-button.control';

@NgModule({
    declarations: [
        CheckboxButtonControl
    ],
    imports: [
    ],
    exports: [
        CheckboxButtonControl
    ]
})
export class SharedCheckboxButtonModule {

}