import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { AnnounceService } from 'src/app/services/announce.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { KeyCodeEnum } from 'src/app/shared/enums/KeyCodeEnum';
import { LiveAnnouncementType } from 'src/app/shared/enums/LiveAnnouncementType';


@Directive({
  selector: '[AnnounceButtonClick]'
})
export class AnnounceButtonClickDirective implements OnInit {

  @Input() public AnnounceButtonClick: string;
  @Input() public announceOnFocusEvents = false;
  @Input() public ButtonClickLabel: string = '';
  @Input() public ButtonClickItemName: string = '';
  @Input() public ButtonClickItemType: string = '';
  @Input() public ButtonClickActionMsg: string = '';

  private buttonClickRole: string = '';

  constructor(private _elRef: ElementRef, private translate: AppTranslationService, private announceService: AnnounceService) { }

  ngOnInit(): void {
    this.buttonClickRole = this.translate.getTranslation('lbl_button');
  }

  @HostListener('click', ['$event'])
  @HostListener('input', ['$event'])
  @HostListener('keydown', ['$event'])
  private _buttonClicked(event: Event) {
    this.checkIfActionsValid();
    if (event.type === 'keydown') {
      switch ((event as KeyboardEvent).keyCode) {
        case KeyCodeEnum.SPACE:
        case KeyCodeEnum.ENTER:
          break;
        default:
          return;
      }
    }
    let msg = this.ButtonClickActionMsg + (this.announceOnFocusEvents === true ? '' : (this.ButtonClickItemType+ this.ButtonClickItemName));
    this.announceService.announce(LiveAnnouncementType.ButtonClicked, msg, null, null, 'assertive')
  }

  @HostListener('focus', ['$event'])
  private _focused(event: Event) {
    if (!this.announceOnFocusEvents) {
      return;
    }
    this.checkIfActionsValid();
    let msg = this.ButtonClickLabel + this.ButtonClickItemType + this.ButtonClickItemName + ', ' + this.buttonClickRole;
    this.announceService.announce(LiveAnnouncementType.ButtonClicked, msg, null, null, 'polite')
  }

  private checkIfActionsValid() {
    if ((this._elRef.nativeElement as HTMLElement).classList.contains('prohibited')) {
      return;
    }
  }
}
