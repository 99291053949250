import { ListResponse } from './../../models/list-response.model';
import { FilteringParams } from 'src/app/models/filteringparams';
import { Injectable } from "@angular/core";
import { DomainListUI } from 'src/app/models/domain-list-ui.model';
import { DomainEndpoint } from "./domain-endpoint.service";
import { Observable, BehaviorSubject } from "rxjs";
import { DomainVerificationMethod } from "src/app/models/domain-verification-methods.model";
import { DomainRegister } from "src/app/models/domainRegister.modal";
import { DomainVerificationResponse } from "src/app/models/domain-verification-response";
import { DomainStatusEnum } from 'src/app/shared/enums/DomainStatusEnum';

@Injectable()
export class DomainService {

    public selectedDomains: BehaviorSubject<DomainListUI[]> = new BehaviorSubject([]);
    constructor(private domainEndPoint: DomainEndpoint) {
    }

    public getSelectedDomains(): Observable<DomainListUI[]> {
        return this.selectedDomains;
    }

    public getAllDomainVerificationMethods(): Observable<DomainVerificationMethod[]> {
        return this.domainEndPoint.getAllDomainVerificationMethods();
    }

    public registerDomains(domains: DomainRegister): Observable<DomainRegister> {
        return this.domainEndPoint.registerDomains(domains);
    }

    public sendDomainVerificationLink(domainReferenceId: string, email: string): Observable<void> {
        return this.domainEndPoint.sendDomainVerificationLink(domainReferenceId, email);
    }
    public verifyDomain(domainToken: string): Observable<DomainVerificationResponse> {
        return this.domainEndPoint.verifyDomain(domainToken);
    }

    public getCompanyDomains(param : FilteringParams, allDomains: boolean, status?: DomainStatusEnum): Observable<ListResponse<DomainListUI>> {
        return this.domainEndPoint.getCompanyDomains(param, allDomains, status);
    }

    public deleteDomain(domainReferenceId: string) : Observable<void> {
        return this.domainEndPoint.deleteDomain(domainReferenceId);
    }

    public updateDomain(domain: DomainRegister): Observable<void> {
        return this.domainEndPoint.updateDomain(domain);
    }
}