import { OpenDialogRequest } from './../../dropdown-requests/OpenDialogRequest';
import { CloseDialogRequest } from './../../dropdown-requests/CloseDialogRequest';
import { DisposalBag } from './../../../../../utils/DisposalBag';
import { logger } from './../../../../../utils/Logger';
/* tslint:disable */
/**
 * TODO: jcammisuli -> Change all these "any"s to a Generic Component/Input.
 * Created by aprabhakar on 2016-05-02.
 */
import {
    Component, ComponentRef, ComponentFactoryResolver, ViewChild, OnInit, ViewContainerRef, ElementRef, OnDestroy
} from '@angular/core';
import {IDropdown} from '../dropdown-dialog-model/dropdown-dialog.model';
import {DropdownDialogService} from '../dropdown-dialog-service/dropdown-dialog.service';
 
import 'rxjs/add/operator/filter';
 
 

@Component({
    selector: 'soti-dropdown-dialog',
    templateUrl: './dropdown-dialog.component.html',
    styleUrls: ['./dropdown-dialog.component.scss']
})
export class DropdownDialog implements OnInit, IDropdown, OnDestroy {
    public opened: boolean;
    public input: any;
    public level:any;
    public body: string;
    public callback: Function;
    public component: any;
    
    @ViewChild('dropdownContainer', {read: ViewContainerRef})
    private _container:ViewContainerRef;

    private _componentRef:ComponentRef<any>;

    private _disposalBag = new DisposalBag();
    

    constructor(private _element: ElementRef, private _dialogService:DropdownDialogService, private _resolver:ComponentFactoryResolver) {
    }

    public ngOnInit(): void {
        // this._dialogService.setComponent(this._element.nativeElement);
        this._disposalBag.nextSub = this._dialogService.dropdownSubject.subscribe(req => {
            
            if (req instanceof OpenDialogRequest) {
                this.input = req.dropdown.input;
                this.opened = true;
                this.level=req.dropdown.level;
                this.component = req.dropdown.component;
                if (this.component) {
                    this._loadComponent();
                    this._calcPosition(req.event);
                }
            }
            if (req instanceof CloseDialogRequest) {
                this.opened = false;
                if (this._componentRef) {
                    this._componentRef.destroy();
                    this._componentRef = null;
                }
                var dropdownElement:HTMLElement = <HTMLElement>document.querySelector('.dropdown-container');
                dropdownElement.style.visibility = 'hidden';
            }
        });
    }

    public ngOnDestroy() {
        this._disposalBag.dispose();
    }

    
    public close(): void {
        this._dialogService.close();
    }

    private _loadComponent(): void {
        
        let factory = this._resolver.resolveComponentFactory(this.component);
        this._componentRef = this._container.createComponent(factory, 0, this._container.injector);
        this._componentRef.instance.input = this.input;
        this._componentRef.instance.level= this.level;
    }

    private _calcPosition(event: MouseEvent): void {
        var posLeft = (<HTMLElement>event.target).getBoundingClientRect().left;
        var posBottom = (<HTMLElement>event.target).getBoundingClientRect().top - 6;
        var posTop = (<HTMLElement>event.target).getBoundingClientRect().bottom + 6;
        var posRight =(<HTMLElement>event.target).getBoundingClientRect().right + 16;

        var dropdownElement: HTMLElement = <HTMLElement>this._element.nativeElement.querySelector('.dropdown-container');
        dropdownElement.style.visibility = 'hidden';
        setTimeout(() => {
            var dropdownHeight = dropdownElement.clientHeight;
            dropdownElement.style.top = dropdownElement.style.bottom = dropdownElement.style.left = dropdownElement.style.right = '';
            var windowHeight = document.documentElement.clientHeight;
            var windowWidth = document.documentElement.clientWidth;
            var overflow = posBottom + dropdownHeight - windowHeight;
            if (windowHeight - event.clientY < 32) {
                posTop -= 32;
            }

            //if can't draw left then draw right
            posLeft + dropdownHeight > windowWidth ? dropdownElement.style.right = windowWidth - posLeft + 'px' : dropdownElement.style.left = posRight + 'px';
            //if can't draw down then draw up
            posBottom + dropdownHeight > windowHeight ? dropdownElement.style.bottom = windowHeight - posTop + 'px' : dropdownElement.style.top = posBottom + 'px';

            //RARE CASE
            //can't draw the dropdown up or down then use overflow as a shift value
            if (posBottom + dropdownHeight > windowHeight && posTop - dropdownHeight <= 0) {
                dropdownElement.style.bottom = '';
                dropdownElement.style.top = overflow + 'px';
            }

            dropdownElement.style.visibility = 'visible';
        }, 10);
    }
}

