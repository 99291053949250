/**
 * Created by dsingh on 2016-04-20.
 */
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColorPrerences } from 'src/app/models/RestrictedUserTenantPreferences';
import { TenantPreferenceseModel } from 'src/app/models/tenantPreferences.model';
import { UserPreferenceType } from 'src/app/shared/enums/UserPreferenceType';

@Injectable()
export class BrowserUtilsService {
    /**
     * Returns the current URL for the page. e.g. https://localhost/mobicontrol/WebConsole
     */
    public get consoleUrl(): string {
        return `${location.protocol}//${location.host}${location.pathname}/`;
    }




    public removeCookie(key: string): void {
        document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;Path=/';
    }

    public setCookie(key: string, value: string, expiry?: Date): void {
        let cookie = key + '=' + value;
        if (expiry) {
            cookie = cookie + '; expires=' + expiry.toUTCString();
        }
        cookie = cookie + '; path=/';
        document.cookie = cookie;
    }

    public isMobileOrTablet(): boolean {
        let check = false;

        // first check using script from detectmobilebrowsers.com
        (function (a: string): void {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                )
            ) {
                check = true;
            }
        })(navigator.userAgent || navigator.vendor); // window.opera

        // also use another check (just in case first one did not work)
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            check = true;
        }

        return check;
    }

    public isWindows(): boolean {
        return navigator.userAgent.indexOf('Windows') > -1;
    }

    /**
     * Check to see if the current browser is Safari.
     */
    public isSafari(): boolean {
        return navigator.vendor && navigator.vendor.indexOf('Apple') > -1;
    }

    public isIE11(): boolean {
        // tslint:disable-next-line:no-any
        return !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
    }

    public isEdge(): boolean {
        return navigator.userAgent.toLowerCase().indexOf('edge') > -1;
    }

    public isFirefox(): boolean {
        return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    }

    public isChrome(): boolean {
        return navigator.userAgent.toLowerCase().indexOf('chrome') > -1 && /Google Inc/.test(navigator.vendor);
    }

    public isIosDevices(): boolean {
        var iDevices = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ];

        if (!!navigator.platform) {
            while (iDevices.length) {
                if (navigator.platform === iDevices.pop()) { return true; }
            }
        }

        return false;
    }

    public cssSupports(property: string, value: string): boolean {
        return 'CSS' in window && CSS.supports(property, value);
    }

    public isMediaSmall(): boolean {
        var mq = window.matchMedia('(min-width: 0px) and (max-width: 767px)');
        return mq.matches;
    }

    public updateUrlPathParameter(uri: string, key: string, value?: string): string {
        // remove the hash part before operating on the uri
        var i = uri.indexOf('#');
        var hash = i === -1 ? '' : uri.substr(i);
        uri = i === -1 ? uri : uri.substr(0, i);

        var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
        var separator = uri.indexOf('?') !== -1 ? '&' : '?';

        if (!value) {
            // remove key-value pair if value is empty
            uri = uri.replace(new RegExp('([?&]?)' + key + '=[^&]*', 'i'), '');
            if (uri.slice(-1) === '?') {
                uri = uri.slice(0, -1);
            }
            // replace first occurrence of & by ? if no ? is present
            if (uri.indexOf('?') === -1) {
                uri = uri.replace(/&/u, '?');
            }
        } else if (uri.match(re)) {
            uri = uri.replace(re, '$1' + key + '=' + value + '$2');
        } else {
            uri = uri + separator + key + '=' + value;
        }
        return uri + hash;
    }

    public isCanvasSupported(): boolean {
        return !!document.createElement('canvas').getContext;
    }

    public isWebSocketsSupported(): boolean {
        return 'WebSocket' in window || 'MozWebSocket' in window;
    }

    /**
     * Used to open a new tab based on NGUI root URL
     * @param url
     */
    public openConsoleInNewTab(url: string): void {
        this.createNewBrowserWindow(this.consoleUrl + url);
    }

    public openConsoleInNewWindow(url: string): void {
        window.open(this.consoleUrl + url, '_blank', 'location=yes,scrollbars=yes,status=yes');
    }

    public createNewBrowserWindow(url: string): void {
        window.open(url);
    }

    public createNewModalNamedBrowserWindow(url: string, windowName: string, settings: string = ''): Window {
        return window.open(url, windowName, `top=0,location=false,menubar=false,` + settings);
    }

    public createNewHelpBrowserWindow(url: string): void {
        window.open(
            url,
            'helpwindow',
            'top=0,left=0,height=500,width=900,menubar=yes,toolbar=yes,location=yes,status=yes,resizable=yes'
        );
    }

    public getMaxHeight(element: HTMLElement, possibleMaxHeight?: number): number {
        if (!element) {
            return 0;
        }

        //in pixels - based on action bar height, to avoid overlapping.
        const bottomMargin = 65;

        let elementBounding = element.getBoundingClientRect();
        let bottomSpacing = window.innerHeight - elementBounding.top - bottomMargin;

        if (!possibleMaxHeight || possibleMaxHeight === 0) {
            possibleMaxHeight = bottomSpacing;
        }

        return Math.min(bottomSpacing, possibleMaxHeight);
    }

    /**
     * Reload the current window
     */
    public reload(): void {
        location.reload();
    }


    /**
     * Open a MobiControl route based on how it was triggered
     */
    public routeNavigation(event: MouseEvent, relativeUrl: string, route: ActivatedRoute): void {
        if (event && event.ctrlKey || event.metaKey) {
            //Opens in a new Tab
            this.openConsoleInNewTab(relativeUrl);
        } else if (event && event.shiftKey) {
            //Opens in a new window
            this.openConsoleInNewWindow(relativeUrl);
        } else {

        }
    }

    public copyTextToClipboard(text: string): void {
        let windowNavigator: any = window.navigator;
        if (!windowNavigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        windowNavigator.clipboard.writeText(text).then(function () {
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    public getUrlPart(url: string): string {
        return url.substring(0, url.indexOf('?'));
    }

    public getQueryString(url: string): string {
        return url.substring(url.indexOf('?'), url.length);
    }

    private fallbackCopyTextToClipboard(text: string): void {
        var textArea = document.createElement("textarea");

        textArea.style.position = 'fixed';
        textArea.style.left = '-9999';
        textArea.style.top = '0';
        textArea.style.opacity = '0';

        textArea.value = text;
        document.body.appendChild(textArea);
        if (this.isIosDevices()) {
            textArea.setAttribute('readonly', 'true');
            textArea.setAttribute('contenteditable', 'true');
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
        textArea.focus();


        try {
            document.execCommand('copy');
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }

    public scrollToTop(containerId) {
        document.getElementById(containerId).scrollTo(0, 0);
    }

    /**
     * Browser specific issue for chrome based browser
     * Focus shifted on table sorting..need to be re focused on column header
     * @param event 
     */
    public preventFocusOut(event:any){
      if((this.isChrome() || this.isEdge()) && 
      (event.relatedTarget == null && event.sourceCapabilities == null)){
       event.preventDefault();
       event.stopImmediatePropagation();
       if (event.target instanceof HTMLElement) { 
            let ele = event.target as HTMLElement;
            return {shouldPrevent:true,element:ele};
        }
      }
      return {shouldPrevent:false,element:null};
    }

    public focusElement(element:HTMLElement,afterMiliSec = 100){
        window.setTimeout(() => {
            if(element){
            element.focus();
            }
        },afterMiliSec);
    }

    public focusElementById(elementId:string,afterMiliSec = 100){
        window.setTimeout(() => {
            let element = document.getElementById(elementId);
            if(element){
            element.focus();
            }
        },afterMiliSec);
    }

    setBodyBackgroundImage(imageUrl: string) {
        document.body.style.backgroundImage = `url('${imageUrl}')`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundPosition = 'center';
      }

      public setCustomColor(colorPreference: ColorPrerences[]) {
        const root = document.documentElement;
        colorPreference.forEach(x => {
            switch (x.TypeId) {
                case UserPreferenceType.TENANTPRIMARYCOLOR:
                    root.style.setProperty('--primary', x.ColorCode.toString());
                    root.style.setProperty('--btn-focus-visible',x.ColorCode.toString());
                    root.style.setProperty('--focus-ring-bdr',x.ColorCode.toString());
                    break;
                case UserPreferenceType.TENANTTEXTCOLOR:
                    root.style.setProperty('--text', x.ColorCode.toString());
                    root.style.setProperty('--btn-primary-focus-visible', x.ColorCode.toString());
                    break;
                default:
                    break;
            }
        });
    }
}
