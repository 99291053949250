import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectBoxControl } from './select-box.ctrl';
import { SelectOptionControl } from './select-option/select-option.ctrl';

@NgModule({
    declarations: [
        SelectBoxControl,
        SelectOptionControl
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SelectBoxControl,
        SelectOptionControl
    ],
    providers: [],
    bootstrap: []
})
export class SharedSelectBoxModule {}
