import { merge as observableMerge, Observable } from 'rxjs';

import { filter } from 'rxjs/operators';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Host,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import { ExpansionPanelCtrl } from '../expansion-panel';
import { sotiExpansionAnimations } from '../expansion-animations';
import { ExpansionPanelState } from '../expansion-panel/expansion-panel-state';
import { DisposalBag } from '../../../utils/DisposalBag';

@Component({
    selector: 'im-expansion-panel-header',
    templateUrl: './expansion-panel-header.ctrl.html',
    styleUrls: ['./expansion-panel-header.ctrl.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [sotiExpansionAnimations.indicatorRotate]
})
export class ExpansionPanelHeaderCtrl implements OnDestroy {
    @Input()
    public indicatorPosition: 'left' | 'right' = 'right';

    @Input()
    public roundIndicator: boolean = true;

    @Input()
    public indicatorText: string = '';

    private _disposalBag: DisposalBag = new DisposalBag();

    constructor(@Host() public panel: ExpansionPanelCtrl, private _cdr: ChangeDetectorRef, private renderer: Renderer2, private el: ElementRef) {
        if (!this.panel) {
            throw new Error('Expansion Panel Header should be used inside ExpansionPanelCtrl element');
        }

        this._checkForParentPanelChanges();
    }

    public ngOnDestroy(): void {
        this._disposalBag.dispose();
    }

    @HostBinding('attr.aria-expanded')
    public isPanelExpanded = false;

    @HostListener('click', ['$event'])
    public onClick($event: UIEvent): void {
        $event.preventDefault();
        this.panel.toggle();
    }

    //region HTML methods

    public getExpandedState(): ExpansionPanelState {
        return this.panel.expanded ? 'expanded' : 'collapsed';
    }

    public isExpanded(): boolean {
        this.isPanelExpanded = this.panel.expanded;
        const element = this.el.nativeElement;
        if(this.isPanelExpanded)
            this.renderer.addClass(element, 'expanded'); // 'newClass' is the class you want to add
        else
            this.renderer.removeClass(element, 'expanded'); // 'oldClass' is the class you want to remove
        return this.panel.expanded;
    }

    //endregion

    private _checkForParentPanelChanges(): void {
        // Since the toggle state depends on an @Input on the panel, we
        // need to subscribe and trigger change detection manually.
        this._disposalBag.nextSub = observableMerge(
            this.panel.opened,
            this.panel.closed,
            this.panel.inputChanges.pipe(
                filter((changes: SimpleChanges) => 'hideToggle' in changes || 'disabled' in changes)
            )
        ).subscribe(() => this._cdr.markForCheck());
    }
}
