import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { BrowserUtilsService } from '../../../services/browserUtil/browserUtils.service';
import { HelpURLKeys } from '../../enums/HelpURLKeys';

@Component({
    selector: 'soti-help-button',
    templateUrl: './soti-help-button.ctrl.html',
    styleUrls: ['./soti-help-button.ctrl.scss']
})

export class SotiHelpButton implements OnInit {

    @Input()
    public topic: string;

    @Input()
    public helpLink: string;

    @ViewChild('link', { read: ElementRef }) link: ElementRef;

    constructor(private _localizationService: AppTranslationService,
        private _browserUtilsService: BrowserUtilsService,
        private authService: AuthService,
    ) {
    }

    public ngOnInit(): void {
    }

    public setFocus() {
        if (this.link) {
            this.link.nativeElement.focus();
        }
    }

    public onClick(): boolean {

        let helpId = HelpURLKeys[this.helpLink];

        if (helpId) {
            let helpUrl = `${this.authService.systemParams.HelpFileUrl}${helpId}`;
            this._browserUtilsService.createNewBrowserWindow(helpUrl);
        }

        return false;
    }
}