import {
    ClientNotificationMessage,
    NotificationType,
} from './ClientNotificationMessage';
import {WebsocketAlertMessage} from './WebsocketAlertMessage';
import {EventMessageSubtypeEnum} from '../../enums/EventMessageSubtypeEnum';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Md5} from 'ts-md5';
import {appMessenger, StreamTransformStep} from '../../controls/toaster-messages/AppMessenger';
import {
    ActionResultMessage,ActionResultType  
} from './ActionResultMessage';



import {AlertSeverityEnum} from '../../enums/AlertSeverityEnum';
import { ClientMessage } from './ClientMessage';


export class NotificationConfig {
    public type: NotificationType;
    public severity: AlertSeverityEnum;
    public title: string;
    public text: string;
    public timeout: number;
    public identifier: string;
    /**
     *
     */
    constructor(type: NotificationType, severity: AlertSeverityEnum, title: string, text: string, timeout: number, identifier: string) {
        this.type = type;
        this.severity = severity;
        this.title = title;
        this.text = text;
        this.timeout = timeout;
        this.identifier = identifier;
    }
}

type ExpectedNotificationType =  ActionResultMessage   | WebsocketAlertMessage | ClientNotificationMessage ;
@Injectable()
export class NotificationAggregator {

    public messageStream: Observable<NotificationConfig>;

    private _defaultTimeout: number = 10000;
    private _transformSteps: Array<StreamTransformStep<NotificationConfig>> = [
        new StreamTransformStep((i) => this._hasRelatedContent(i), (m) => this._transformMessage(m)),
        new StreamTransformStep((e => !!e), (m) => this._assignIdentifier(m))
    ];

    public constructor() {
        this.messageStream = appMessenger.getTransformedStream(
            this._transformSteps,
            ActionResultMessage,
            ClientMessage,
            ClientMessage,
            WebsocketAlertMessage,
            ClientNotificationMessage,
            ClientMessage,
            ClientMessage

           );
    }

    /**
     * Logic for routing incoming messages to various transforms starts here.
     *
     * @param msg The message to transform
     */
    private _transformMessage(msg: ExpectedNotificationType): NotificationConfig {

        if (msg instanceof ActionResultMessage) {
          
            return this._processActionResultMessage(msg);
        }

        

       

        if (msg instanceof WebsocketAlertMessage) {
            return this._processAlertMessage(msg);
        }

        if (msg instanceof ClientNotificationMessage) {
       
            return this._processClientNotificationMessage(msg);
        }        

        
       
        return null;
    }

    /**
     * This is where the outgoing messages will have identifiers attached. If you need to group messages together
     * then you can inspect and modify the ID assignment process here.
     * @param cfg The transformed message to assign an identifier to
     */
    private _assignIdentifier(cfg: NotificationConfig): NotificationConfig {
        if (cfg.identifier != null) {
            return cfg;
        }
    
        let type = NotificationType[cfg.type];
        let dateTime = new Date().getMilliseconds(); //if we want to maintain counter on popup if they have same content, remove this line.
        let severity = AlertSeverityEnum[cfg.severity];
        let title = cfg.title == null || cfg.title.length < 1 ? `${type}${severity}` : cfg.title;
        // TODO: This is the identifier that will be used when collecting notifications
        cfg.identifier = `${dateTime}.${type}.${severity}.${Md5.hashStr(title)}`;
        return cfg;
    }

    // LOGIC FOR MESSAGE TRANSFORMATION

    private _processClientNotificationMessage(msg: ClientNotificationMessage): NotificationConfig {
   
        return this._generateNotificationConfig(
            msg.notificationType,
            msg.notificationSeverity,
            msg.notificationTitle,
            msg.notificationText,
            msg.notificationTimeout);
    }


    

    private _processActionResultMessage(msg: ActionResultMessage): NotificationConfig {
        let actionName = msg.actionName.toLowerCase();

        let type: NotificationType;
        let label: string;
        let target: string;

        if (msg.actionResultType === ActionResultType.Success) {
            type = NotificationType.Success;
            label = 'msg_action_notification_success';
        } else if (msg.actionResultType === ActionResultType.Error) {
            type = NotificationType.Error;
            label = 'msg_action_notification_error';
        }

        if (msg.deviceIds) {
            target = msg.deviceIds.length.toString();
        } else if (msg.groupPath) {
            target = msg.groupPath;
            label += '_group';
        }

        let text = "hoohlr";
        return this._generateNotificationConfig(type, AlertSeverityEnum.Minor, '', text, this._defaultTimeout, `${type}.${AlertSeverityEnum[AlertSeverityEnum.Minor]}.${Md5.hashStr(actionName)}`);
    }

    private _processAlertMessage(msg: WebsocketAlertMessage): NotificationConfig {
        let text = msg.Data;
        return this._generateNotificationConfig(NotificationType.Warning, msg.alertSeverity, msg.Topic, text, this._defaultTimeout, `${NotificationType[NotificationType.Warning]}.${msg.AlertType}.${msg.Topic}.${Md5.hashStr(msg.Data)}`);
    }

    private _hasRelatedContent(msg: ActionResultMessage | WebsocketAlertMessage): boolean {
        if (msg == null) {
            return false;
        }

        if (msg instanceof WebsocketAlertMessage) {
            return msg.messageType !== EventMessageSubtypeEnum.Logoff &&
                msg.messageType !== EventMessageSubtypeEnum.PermissionsChanged;
        }
        return true;
    }

    


    /**
     * Generate a new INotificationConfig from the provided parameters
     * @param type The notification type
     * @param severity The notification severity
     * @param title The title of the message
     * @param text The text of the notification
     * @param timeout The timeout it should have (may be removed)
     */
    private _generateNotificationConfig(type: NotificationType, severity: AlertSeverityEnum, title: string, text: string, timeout: number, identifier: string = null): NotificationConfig {
        return new NotificationConfig(type == null ? NotificationType.Information : type, severity == null ? AlertSeverityEnum.Minor : severity, title, text, timeout, identifier);
    }
}

