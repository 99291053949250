import { User } from '../../models/user.model';
import { Component, OnInit } from '@angular/core';
import { Role } from '../../models/role.model';
import { ConfigurationService } from '../../services/configuration.service';
import { appMessenger } from '../../shared/controls/toaster-messages/AppMessenger';
import { ClientNotificationMessage, NotificationType } from '../../shared/utils/toast-utils/ClientNotificationMessage';
import { AlertSeverityEnum } from '../../shared/enums/AlertSeverityEnum';
import { HeaderService } from '../../shared/header/header.service';
import { ViewLocationEnum } from '../../shared/enums/ViewLocationEnum';
declare let $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    public imageUrl: string = '';

    columns: any[] = [];
    rowData: User[] = [];
    rowsCache: User[] = [];
    editingUserName: { name: string };
    loadingIndicator: boolean;
    displayText: string = 'Create User';
    allRoles: Role[] = [];
    showDialog: boolean = false;
    constructor(
        public configurations: ConfigurationService,
        private _headerService: HeaderService) {
    }


    ngOnInit() {
        this._headerService.setCurrentLocation(ViewLocationEnum.Dashboard);
    }

    OpenPopup() {
        appMessenger.postMessage(new ClientNotificationMessage(
            'LBL_STATUS_NOTIFICATION_SUCCESS',
            'Users saved successfully.',
            NotificationType.Success,
            AlertSeverityEnum.Minor));
    }
}
