import { AriaLivePoliteness, LiveAnnouncer } from '@angular/cdk/a11y';
import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { LiveAnnouncementType } from '../shared/enums/LiveAnnouncementType';
import { IQueueClient, queueManager, QueueOperationPriority } from '../shared/utils/toast-utils/QueueManager';
import { AppTranslationService } from './app-translation.service';

export class PageContent {
  first: number;
  last: number;
  total: number;

  constructor(first: number, last: number, total: number) {
    this.first = first;
    this.last = last;
    this.total = total;
  }
}

@Injectable()
export class AnnounceService implements OnDestroy {

  /// Properties 
  // private
  private baseMessage: string = '';
  private variableMessage: string = '';
  private connectorMessageOf: string = '';
  private connectorMessageTo: string = '';
  private validationMessage: string = '';

  private validationErrorText: string = '';
  private multipleValidationErrorText: string = ''

  private firstPageItem: number = 0;
  private lastPageItem: number = 0;
  private totalItems: number = 0;
  private queue: { message: string; politeness: AriaLivePoliteness}[] = [];
  private announcing : boolean = false;
  private isAnnouncing : boolean = false;

  constructor(
    private liveAnnouncer: LiveAnnouncer,
    private appTranslationService: AppTranslationService,) {
    this.initService();
  }

  public ngOnDestroy(): void {
    this.clearAnnouncements();
  }
  
  /// primary method
  public async announce(
    type: LiveAnnouncementType = LiveAnnouncementType.ValidationError,
    messageExtraParam: string | undefined = undefined,
    pageContent: PageContent = undefined,
    el: any = undefined,
    ariaLivePoliteness: AriaLivePoliteness = "assertive",
  ) {
    switch (type) {
      case LiveAnnouncementType.FilterApplied:
        if (messageExtraParam === undefined || pageContent === undefined) return;
        this.announceMessage(this.getFilterAppliedMessage(messageExtraParam, pageContent), messageExtraParam, ariaLivePoliteness);
        break;
      case LiveAnnouncementType.ValidationError:
        this.announceMessage(this.generateErrorMessage(el), messageExtraParam, ariaLivePoliteness);
        break;
      case LiveAnnouncementType.ButtonClicked:
        this.announceMessageCanDelay(messageExtraParam, ariaLivePoliteness);
        break;
      default:
        return;
    }
  }

  public announced(message: string, politeness: AriaLivePoliteness) {
    this.queue.push({ message, politeness });
    if (!this.isAnnouncing) {
      this.announceNext();
    }
  }

  private announceNext() {
    if (this.queue.length === 0) {
      return;
    }
    const { message, politeness } = this.queue.shift()!;
    this.isAnnouncing = true;
    this.liveAnnouncer.announce(message, politeness);
    setTimeout(() => {
      this.isAnnouncing = false;
      this.announceNext();
    }, 1000); 
  }

  public announceMessageCanDelay(message, politeness){
    if (politeness === 'assertive'){
      this.announcing = true;
      this.announceMessage(message, null, politeness);
      setTimeout(() => {
        this.announcing = false;
      }, 1000);
    } else if (politeness === 'polite'){
      if (this.announcing){
        setTimeout(() => {
          this.announceMessage(message, null, politeness);
        }, 1200);
      }
      else{
        this.announceMessage(message, null, politeness);
      }
        
    }
  }

  public clearAnnouncements() {
    this.liveAnnouncer.clear();
  }

  /// private class functions
  private delay(ms: number = 1000) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private announceMessage(message: string, messageExtraParam: string | undefined = null, ariaLivePoliteness: AriaLivePoliteness = "assertive") {
    if (message === undefined || message === "") {
      return;
    }
    let messageExtra = messageExtraParam !== null ? this.appTranslationService.getTranslation(messageExtraParam) : ""
    message += messageExtra ?? ""
    return this.announced(message, ariaLivePoliteness);
  }

  private initService() {
    this.baseMessage = this.appTranslationService.getTranslation('lbl_showing');
    this.connectorMessageOf = this.appTranslationService.getTranslation('lbl_table_of');
    this.connectorMessageTo = this.appTranslationService.getTranslation('lbl_to');
    this.validationErrorText = this.appTranslationService.getTranslation('msg_validation_error');
    this.multipleValidationErrorText = this.appTranslationService.getTranslation('msg_multiple_validation_error');
  }

  private isElementHidden(element: HTMLDivElement) {
    var style = window.getComputedStyle(element)
    if (style.display === "none" || element.innerText === '') {
      return true;
    }
    return false;
  }

  private generateErrorMessage(el: any): string {
    let validationMessage = '';
    let validationMessages: string[] = [];

    if (el instanceof Array) {
      el.forEach(x => validationMessages.unshift(...this.getValidationMessagesAsList(x)));
    }
    else if (el instanceof ElementRef) {
      validationMessages = this.getValidationMessagesAsList(el.nativeElement);
    }

    if (validationMessages.length === 0) {
      return '';
    }
    else if (validationMessages.length > 1) {
      validationMessages.forEach((x: string, index: number, array: string[]) => {
        array[index] = this.parseSpecialCharacters(x);
      })
      validationMessage = this.multipleValidationErrorText + validationMessages.join(', ');
    }
    else {
      validationMessage = this.parseSpecialCharacters(validationMessages[0]);
    }

    if (validationMessage !== "")
      return this.validationErrorText + validationMessage;
    else
      return "";
  }

  private getValidationMessagesAsList(el): string[] {
    let validationMessages = [];

    const nodeList = el.querySelectorAll('.errorMessage')
    let array: HTMLDivElement[] = Array.from(nodeList)
    array.forEach((x: HTMLDivElement) => {
      if (!this.isElementHidden(x)) {
        if (x.childElementCount !== 0) {
          Array.from(x.children).forEach((element: HTMLDivElement) => {
            if (!this.isElementHidden(element)) {
              validationMessages.push(element.innerText)
            }
          });
        }
        else {
          validationMessages.push(x.innerText)
        }
      }
    });
    return validationMessages;
  }

  private getFilterAppliedMessage(messageExtraParam: string | undefined = null, pageContent: PageContent): string {
    let additionalMessage = messageExtraParam !== null ? this.appTranslationService.getTranslation(messageExtraParam) : ""
    this.variableMessage = '';
    if (pageContent.total == 0) {
      this.variableMessage = '0';
    }
    else {
      this.variableMessage = pageContent.first + this.connectorMessageTo + pageContent.last;
    }

    return [this.baseMessage + additionalMessage + this.variableMessage + this.connectorMessageOf + pageContent.total].join(' ');
  }

  private parseSpecialCharacters(text: string): string {
    text = text
      .replace(/ , /g, ' punctuation mark ')
      .replace(/\[/g, 'opening square bracket')
      .replace(/\]/g, 'closing square bracket')
      .replace(/\?/g, 'question mark')
      .replace(/\|/g, 'pipe')
      .replace(/;/g, 'semicolon')
      .replace(/!/g, 'exclamation')
      .replace(/&/g, 'ampersand');
    return text;
  }
}
